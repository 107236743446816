import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  dropdownData: [],
  orgType: [],
  isOpen: false,
  errorMsg: "",
  isSuccess: false,
  genderDropDown: [],
  beaconTypeDropdown: [],
  locationDropdown: [],
  locationLegend: [],
  activityLegend: [],
  precaution: [],
};

const DropdownSlice = createSlice({
  name: "dropdownData",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },

    setIsLoadingFalse(state) {
      state.loading = false;
    },
    setErrorMessage(state, action) {
      state.loading = false;
      state.isOpen = true;
      state.errorMsg = action.payload;
    },

    getDropdownSuccess(state, action) {
      state.loading = false;
      state.dropdownData = action.payload;
    },
    setOrgTypeSuccess(state, action) {
      state.loading = false;
      state.orgType = action.payload;
    },
    setGenderDropDown(state, action) {
      state.loading = false;
      state.genderDropDown = action.payload
    },
    setBeaconType(state, action) {
      state.loading = false;
      state.beaconTypeDropdown = action.payload;
    },
    setLocation(state,action) {
      state.loading = false;
      state.locationDropdown = action.payload;
    },
    setLocationLegendD(state, action) {
      state.loading = false;
      state.locationLegend = action.payload;
    },
    setActivityLegend(state, action) {
      state.loading = false;
      state.activityLegend = action.payload;
    },
    setPrecautionLegend(state, action) {
      state.precaution = action.payload
    }
  },
});

export const {
  isLoading,
  setIsLoadingFalse,
  setErrorMessage,
  getDropdownSuccess,
  setOrgTypeSuccess,
  setGenderDropDown,
  setBeaconType,
  setLocation,
  setLocationLegendD,
  setActivityLegend,
  setPrecautionLegend,
} = DropdownSlice.actions;

export default DropdownSlice.reducer;