import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "primereact/button";
import { Autocomplete, TextField } from "@mui/material";
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { toast } from 'react-toastify';

interface GatewayCreationProps {
  isOpen: boolean;
  CloseModal: () => void;
  orgID: string;
  gateWayData?: any;
  dropdownData: { value: string; id: string }[];
  gatewayGet: boolean;
  setGateWayGet: any
}

interface registerInputData {
  gatewayId: string | null;
  gatewayName: string;
  location: string | null;
  id: string;
}

const GatewayCreation: React.FC<GatewayCreationProps> = ({ isOpen, CloseModal, orgID, dropdownData, gateWayData, gatewayGet, setGateWayGet }) => {
  const registerData: registerInputData = {
    gatewayId: null,
    gatewayName: '',
    location: null,
    id: ''
  };

  const [inputData, setInputData] = useState<registerInputData>(registerData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (gateWayData) {
      setInputData({
        gatewayId: gateWayData.gatewayId,
        gatewayName: gateWayData.gatewayName,
        id: gateWayData.id,
        location: gateWayData.location
      });

      const selectedOption = dropdownData.find((item) => item.value === gateWayData.location);
      setValue(selectedOption || null);
    } else {
      setInputData(registerData);
      setValue(null);
      setNameError({});
    }
    return () => {
      setInputData(registerData)
      setValue(null)
      setNameError({});
    }
  }, [gateWayData, dropdownData, isOpen]);

  const handleSubmit = async () => {
    try {
      let newErrors = {
        gatewayName: !inputData.gatewayName,
        gatewayId: !inputData.gatewayId,
        location: !inputData.location
      };
      setNameError(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      if (hasErrors) {
        toast.error("Please Fill Required Field");
        return;
      }

      const body = {
        gatewayId: inputData.gatewayId,
        gatewayName: inputData.gatewayName,
        id: gateWayData ? gateWayData.id : "",
        location: inputData.location,
        organization: orgID
      };

      const response = await axios.post(`${baseURL}/gateway/add`, body);
      if (response && response.data.message && response.data.message.code === successCode) {
        toast.success(response.data.message.description)
        setNameError({ gatewayId: false, location: false, gatewayName: false });
        CloseModal()
        setInputData(registerData)
        setValue(null)
        setGateWayGet(!gatewayGet)
      }else{
        toast.error(response.data.message.description)
      }
    } catch (error) {
      console.error("API Error: ", error);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} size="lg" centered style={{ width: "580px" }}>
        <div className="d-flex align-items-center justify-content-center m-20">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader toggle={() => {
                CloseModal();
                setInputData(registerData);
                setValue(null); 
                setNameError({ gatewayId: false, location: false, gatewayName: false });
              }}>GateWay Register</ModalHeader>
              <ModalBody>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                  <div className="col-md-12 mb-2">
                    <TextField
                      label="Gateway ID"
                      variant="outlined"
                      fullWidth
                      value={inputData.gatewayId || ''}
                      onChange={(e) => {
                        setInputData({ ...inputData, gatewayId: e.target.value });
                        setNameError({ ...nameError, gatewayId: false });
                      }}
                      error={!!nameError.gatewayId}
                    />
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                  <div className="col-md-6 mb-2">
                    <TextField
                      label="Gateway Name"
                      variant="outlined"
                      fullWidth
                      value={inputData.gatewayName}
                      onChange={(e) => {
                        setInputData({ ...inputData, gatewayName: e.target.value });
                        setNameError({ ...nameError, gatewayName: false });
                      }}
                      error={!!nameError.gatewayName}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Autocomplete
                      id="location-autocomplete"
                      options={dropdownData}
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(e, v) => {
                        if (v) {
                          setValue(v);
                          setInputData({ ...inputData, location: v.value });
                          setNameError({ ...nameError, location: false });
                        } else {
                          setValue(null);
                          setInputData({ ...inputData, location: null });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Location"
                          fullWidth
                          error={!!nameError.location}
                        />
                      )}
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
            <ModalFooter>
              <div className="d-flex gap-3 justify-content-center">
                <Button
                  label="Cancel"
                  severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
                  onClick={() => {
                    CloseModal();
                    setNameError({ gatewayId: false, location: false, gatewayName: false });
                    setInputData(registerData); 
                    setValue(null);       
                  }}
                />
                <Button
                  label="Save Changes"
                  style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}
                  onClick={handleSubmit}
                />
              </div>
            </ModalFooter>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GatewayCreation;