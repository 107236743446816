import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import React, { useEffect, useState } from "react";
import ShiftListTable from "./shiftListTable";
import { Button } from "reactstrap";
import { primarybg } from "../../common/primary";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllRNIncharge, getStaffDropdown } from "../../slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";
import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { toast } from "react-toastify";
import DateBox from "../../components/calendar/dateBox";
import moment from "moment";
import { IoMdArrowBack } from "react-icons/io";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f2f5f9",
        borderLeft: "1px solid #0303031f",
        height: '70vh',
      }}
      className="overflow-hidden"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "14px 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const TeamAllocation = () => {
  const [value, setValue] = React.useState(0);
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [allTeamData, setAllTeamData] = useState<any>([]);
  const { header1 } = authorize();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedDate1, setSelectedDate1] = useState<any>();
  const [selectedDate2, setSelectedDate2] = useState<any>();
  const [noOfShift, setNoOfShift] = useState<any>(0);
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const [staffDropdown, setStaffDropdown] = useState<any>([]);
  const org = useSelector((state: any) => state.Login.organization);
  const { orgData, shiftStartTime } = useSelector((state: any) => state.Org);
  let [incharge, setIncharge] = useState<any>('');
  const [previousCheck, setPreviousCheck] = useState<boolean>(false)
  const [datechangecheck, setdatechangecheck] = useState<boolean>(false)
  const [checkdate, setCheckdate] = useState<boolean>(false)
  const [nextDaydate, setNextDayDate] = useState<any>('')
  let [count, setcount] = useState<number>(0);
  const handleNewClose = () => {
    setOpenAddStaff(false)
  };
  useEffect(() => {
    if (renderTabContent() !== "General" && renderTabContent() !== "No Content Available" && renderTabContent() !== "Previous" && checkdate) {
      setCheckdate(false)
    }
  }, [previousCheck, value])
  useEffect(() => {
    if (!selectedDate) return
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
    const tabSlotTime = previousCheck ? moment(previousDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
    if (count <= 1) {
      setSelectedDate(moment(tabSlotTime).format('YYYY-MM-DD'))
    } else {
      return
    }
  }, [datechangecheck])

  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      setNextDayDate(moment(nextDay)?.format('YYYY-MM-DD'));
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate, previousCheck]);

  const handleChange = (newValue: number) => {
    if (newValue === 7) {
      setcount((prev) => prev + 1);
      if (count > 0) {
        setCheckdate(false);
        setPreviousCheck(false);
      } else {
        setCheckdate(true);
        setPreviousCheck(true);
      }
    } else {
      // Reset previous check when switching back from previous tab
      setPreviousCheck(false);
      if (count > 0) {
        setCheckdate(true);
      } else {
        setCheckdate(false);
      }
      setcount(0);
    }
    setValue(newValue);
    setdatechangecheck(!datechangecheck);
  };

  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectTeam, setSelectTeam] = useState("");
  const [staffError, setStaffError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [previousDate, setPreviousDate] = useState<any>('')

  const isAllSelected =
    staffDropdown?.length > 0 &&
    selectedValues.length === staffDropdown?.length;
  const handleselectChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = staffDropdown?.map((data: any) => data);
      setSelectedValues(
        selectedValues.length === staffDropdown?.length ? [] : allData
      );
      return;
    }
    if (value.length === 0) {
      setStaffError(true);
    } else {
      setStaffError(false);
    }
    setSelectedValues(value);
  };



  const handlestartTime = () => {
    const crtime: any = localStorage.getItem("LTime");
    if (crtime) {
      const formattedDate = moment(crtime).format("YYYY-MM-DD");
      const currentTime = moment(crtime).format('HH:mm')
      const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
      const CurrTime: any = moment(`1970-01-01T${currentTime}`)
      if (shiftStartTime !== CurrTime) {
        if (CurrTime.isAfter(OrgStartTime)) {
          setSelectedDate(formattedDate);
          setSelectedDate1(formattedDate)
          setSelectedDate2(formattedDate)
        } else {
          if (!formattedDate) return;
          const validDate = moment(formattedDate);
          if (validDate.isValid()) {
            const nextDay = moment(formattedDate).subtract(1, "days").format('YYYY-MM-DD');
            setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
            setSelectedDate1(formattedDate);
            setSelectedDate2(nextDay);
          } else {
            console.error("Invalid date:", formattedDate);
          }
        }
      } else {
        setSelectedDate(formattedDate);
        setSelectedDate1(formattedDate);
        setSelectedDate2(formattedDate);
      }
    }
  };
  useEffect(() => {
    // const handlestartTime = () => {
    //   const crtime: any = localStorage.getItem("LTime");
    //   if (crtime) {
    //     const formattedDate = moment(crtime).format("YYYY-MM-DD");
    //     setSelectedDate(formattedDate);
    //   }
    // };
    handlestartTime();
    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    setNoOfShift(numShift);
  }, []);
  useEffect(() => {
    getAllRNIncharge(dispatch, 'Registered Nurse', organization)
  }, [organization])
  const handleSelectTeam = (event: any) => {
    setSelectTeam(event.target.value);
    if (event.target.value === 0) {
      setTeamError(true);
    } else {
      setTeamError(false);
    }
  };
  const renderShiftTab = () => {
    switch (noOfShift - 1) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  }

  useEffect(() => {
    if (!previousCheck) return
    if (!previousDate) return;
    const validDate = moment(previousDate);
    if (validDate.isValid()) {
      if (count <= 1) {
        setSelectedDate(moment(previousDate).format('YYYY-MM-DD'))
      } else {
        return
      }
      // getAllTeam()
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [value, previousCheck])
  useEffect(() => {
    if (!selectedDate) return;
    const validDate = moment(selectedDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().subtract(1, 'days');
      setPreviousDate(moment(nextDay)?.format('MMM DD, YYYY'))
    } else {
      console.error("Invalid date:", selectedDate);
    }
  }, [selectedDate]);
  const getAllStaffByTeam = async () => {
    if (count > 1) return; // Only allow one previous click

    try {
      setLoading(true);
      const ShiftName = renderTabContent();
      const tabShiftName = previousCheck ? renderShiftTab() : ShiftName;
      const tabSlotTime = previousCheck
        ? moment(previousDate).format("YYYYMMDD")
        : moment(selectedDate).format("YYYYMMDD");
      const response = await axios.get(
        `${baseURL}/shift/getshiftStaffDropdowns?organization=${org}&date=${tabSlotTime}&shiftName=${tabShiftName}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        setStaffDropdown(response.data.data.data);
        setIncharge(response.data.data.shiftIncharge);
        setCheckdate(false);
        setPreviousCheck(false);
      } else {
        setStaffDropdown([]);
        setIncharge('');
        setCheckdate(false);
        setPreviousCheck(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setCheckdate(false);
      setPreviousCheck(false);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 as months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0"); // Padding day to ensure 2 digits

    return `${year}${month}${day}`;
  }
  const getAllTeam = async () => {
    if (count > 1) return;
    const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
    const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
    try {
      setLoading(true);
      const ShiftName = renderTabContent()
      const tabShiftName = ShiftName === "Previous" ? renderShiftTab() : ShiftName
      const tabSlotTime = previousCheck ? moment(previousDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
      const response = await axios.get(
        `${baseURL}/shift/getshiftStaffDropdowns?organization=${org}&date=${tabSlotTime}&shiftName=${tabShiftName}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        const sortedData = [...response.data.data].sort(
          (a, b) => b?.checkIn - a?.checkIn
        );
        setAllTeamData(sortedData);
        setValue(value);
        setLoading(false);
        setPreviousCheck(false)
      } else {
        setLoading(false);
        setPreviousCheck(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setPreviousCheck(false)
    }
  };

  const handleCreateTeam = async () => {
    setLoading(true);
    let valid = true;

    if (selectTeam === "") {
      setTeamError(true);
      valid = false;
    }

    if (selectedValues.length === 0) {
      setStaffError(true);
      valid = false;
    }

    if (valid) {
      try {
        setLoading(true);

        const staffIds = selectedValues.map((staff: any) => ({
          staffId: staff.id, // Renaming 'id' to 'staffId'
        }));

        let formData = {
          organization: organization,
          staff: staffIds,
          teamName: selectTeam,
        };

        const response = await axios.post(
          `${baseURL}/shift/register`,
          formData,
          { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          handleNewClose();
          setLoading(false);
          setTeamError(false);
          setStaffError(false);
          setSelectedValues([]);
          setSelectTeam("");
          getAllTeam();
          getAllStaffByTeam();
        } else {
          console.error("Error:", response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.error("API Request Error:", error);
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }

    return 24 / duration;
  };

  useEffect(() => {
    if (!selectedDate) return
    getAllStaffByTeam();
    getAllTeam();
  }, [selectedDate, value]);

  const handleDateChange = (e: any) => {
    // const newDate = e.target.value;
    if (count >= 0) {
      setcount(0)
      setValue(0)
    }
    setSelectedDate(e);

    // if (!newDate) {
    //   if (!newDate) {
    //     return;
    //   }

    //   try {
    //     getAllTeam();
    //     getAllStaffByTeam();
    //   } catch (error) {
    //     console.error("API Error : ", error);
    //     console.error("API Error : ", error);
    //   }
    // }
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 7:
        return "Previous";
      default:
        return "No Content Available";
    }
  };

  const [shifts, setShifts] = useState<any>([]);
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    for (let i = 0; i < totalShifts; i++) {
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      );
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);
      const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });
      startTime = endTime;
    }
    setShifts(shifts);
  };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
  }, [orgData, noOfShift])
  const [dateRange, setDateRange] = useState<any>()
  useEffect(() => {
    if (!shifts) return
    if (previousCheck) {
      setDateRange(shifts[noOfShift - 1])
    } else if (renderTabContent() === "General") {
      setDateRange(shifts[0])
    } else {
      setDateRange(shifts[value])
    }
  }, [shifts, value, previousCheck])
  return (
    <div style={{ height: '91vh' }}>
      {Loading && <Loader />}
      <div style={{ height: '91vh' }}>
        <div className="" style={{ height: '5vh' }}>
          <h5 style={{}}>Staff Allocation</h5>
        </div>
        <div style={{ height: '13vh', paddingTop: '5px' }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "0px",
              width: "90%",
              position: "relative",
              left: "66px",
            }}
          >
            <DateBox
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleDateChange={handleDateChange}
              countCheck={count}
              dateRange={dateRange}
            />
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            // display: "flex",
            backgroundColor: "#eaf2fa",
            borderRight: "1px groove #ccd2d8",
            height: '73vh',
            // border: '1px solid black'
          }}
        >
          <div
            className="col-md-2"
            style={{
              // width: "15%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <button
              className={`nav-link ${value === 7 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls=""
              aria-selected={value === 7}
              onClick={() => {
                if (count <= 1) {
                  handleChange(7);
                }
              }}
              style={{
                backgroundColor: value === 7 ? "#cddcfa" : "",
                color: value === 7 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              <IoMdArrowBack style={{ fontSize: '18px', paddingBottom: '2px' }} />
              Previous
            </button>
            {Array.from({ length: noOfShift }).map((_, index) => (
              <button
                className={`nav-link ${value === index ? "active" : ""}`}
                id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                type="button"
                role="tab"
                aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                aria-selected={value === index}
                onClick={() => handleChange(index)}
                style={{
                  backgroundColor: value === index ? "#cddcfa" : value === 7 ? `Shift-${String.fromCharCode(65 + index)}` === renderShiftTab() ? "#cddcfa" : "" : "",
                  color: value === index ? "" : "black",
                  width: "91%",
                  height: "40px",
                  borderRadius: "4px",
                }}
              >
                {`Shift-${String.fromCharCode(65 + index)}`}
              </button>
            ))}
            <button
              className={`nav-link ${value === 6 ? "active" : ""}`}
              id="General"
              type="button"
              role="tab"
              aria-controls="Shift-A"
              aria-selected={value === 6}
              onClick={() => handleChange(6)}
              style={{
                backgroundColor: value === 6 ? "#cddcfa" : "",
                color: value === 6 ? "" : "black",
                width: "91%",
                height: "40px",
                borderRadius: "4px",
              }}
            >
              General
            </button>
          </div>
          <div className="col-md-10 overflow-auto" style={{ height: '71vh' }}>
            {Array.from({ length: noOfShift }).map((_, index) => (
              <TabPanel value={value} index={index}>
                <ShiftListTable
                  value={renderTabContent()}
                  allTeamData={allTeamData}
                  selectedDate={selectedDate}
                  selectedDate1={selectedDate2}
                  staffDropdown={staffDropdown}
                  getAllStaffByTeam={getAllStaffByTeam}
                  getAllTeam={getAllTeam}
                  noOfShift={noOfShift}
                  incharge={incharge}
                  setIncharge={setIncharge}
                />
              </TabPanel>
            ))}
            <TabPanel value={value} index={6}>
              <ShiftListTable
                value={renderTabContent()}
                allTeamData={allTeamData}
                selectedDate={selectedDate}
                selectedDate1={selectedDate2}
                staffDropdown={staffDropdown}
                getAllStaffByTeam={getAllStaffByTeam}
                getAllTeam={getAllTeam}
                noOfShift={noOfShift}
                incharge={incharge}
                setIncharge={setIncharge}
              />
            </TabPanel>

            <TabPanel value={value} index={7}>
              <ShiftListTable
                value={renderTabContent()}
                allTeamData={allTeamData}
                selectedDate={selectedDate}
                selectedDate1={selectedDate2}
                staffDropdown={staffDropdown}
                getAllStaffByTeam={getAllStaffByTeam}
                getAllTeam={getAllTeam}
                noOfShift={noOfShift}
                incharge={incharge}
                setIncharge={setIncharge}
              />
            </TabPanel>

          </div>
        </div>
        <Dialog
          maxWidth={"lg"}
          PaperProps={{
            sx: {
              width: "595px",
              maxHeight: "calc(100% - 40px)",
              top: 25,
            },
          }}
          className="p-5"
          open={openAddStaff}
          onClose={handleNewClose}
        >
          <DialogTitle>Add Shift</DialogTitle>
          <DialogContent style={{ overflowY: "auto" }} className="p-4">
            <DialogContentText style={{ paddingLeft: "15px" }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Staff Name</InputLabel>
                    <Select
                      error={staffError}
                      placeholder="Select staff"
                      multiple
                      value={selectedValues} // This should be an array of selected values
                      onChange={handleselectChange}
                      color="primary"
                      size="medium"
                      label="Staff Name"
                      name="staff_name"
                      renderValue={(selectedValues) =>
                        selectedValues
                          .map((value: any) => value?.name)
                          .join(", ")
                      }
                    >
                      {/* Select All / Unselect All MenuItem */}
                      <MenuItem value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={
                              selectedValues.length > 0 &&
                              selectedValues.length < staffDropdown?.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>

                      {/* Render other staff options */}
                      {staffDropdown?.map((newData: any, i: number) => (
                        <MenuItem key={i} value={newData}>
                          <Checkbox
                            checked={selectedValues?.indexOf(newData) > -1} // Check if this item is selectedValues
                          />
                          <ListItemText primary={newData?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {staffError && (
                    <span className="form-error">Please select staff</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Shift Name</InputLabel>
                    <Select
                      error={teamError}
                      value={selectTeam} // This should be an array of selected values
                      onChange={handleSelectTeam}
                      placeholder="Select staff"
                      color="primary"
                      size="medium"
                      label="Shift Name"
                      name="team_name"
                    >
                      <MenuItem value="Shift-A">Shift-A</MenuItem>
                      <MenuItem value="Shift-B">Shift-B</MenuItem>
                      <MenuItem value="Shift-C">Shift-C</MenuItem>
                      <MenuItem value="General">General</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {teamError && (
                    <span className="form-error">Please select shift</span>
                  )}
                </Grid>
                {/* <Grid item md={6}>
                <DatePicker label="Start Date" />
              </Grid>
              <Grid item md={6}>
                <DatePicker label="End date" />
              </Grid> */}
              </Grid>
              {/* <Grid item md={12} sx={{ marginY: "15px" }}>
              {selectedValues?.length > 0 && (
                <>
                  {selectedValues?.map((data: any) => {
                    const firstLetter = data.charAt(0).toUpperCase();
                    return (
                      <>
                        <Chip
                          style={{ marginLeft: "5px" }}
                          variant="outlined"
                          label={`${data}`}
                          color="primary"
                          size="small"
                          avatar={<Avatar>{`${firstLetter}`}</Avatar>}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </Grid> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "10px",
                  paddingBottom: "15px",
                  paddingRight: "15px",
                }}
                className="mt-4"
              >
                <Button style={{ cursor: "pointer" }} onClick={handleNewClose}>
                  Cancel
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: primarybg,
                    borderColor: "transparent",
                  }}
                  onClick={() => handleCreateTeam()}
                >
                  Save
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default TeamAllocation;