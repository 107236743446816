import React, { useEffect, useState } from 'react'
import { grey, primarybg, primarytext } from '../../common/primary';
import moment from 'moment';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { authorize } from '../../helpers/common';
import { HttpLogin } from '../../utils/Http';
import { baseURL, successCode } from '../../configuration/url';
import { Tooltip } from "@mui/material";
import { fetchPatientData } from '../../slices/thunk';
import { Paginator } from 'primereact/paginator';
import {
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import AddOneToOne from './AddOneToOne';
import EditOneToOneRoutineModal from './editOneToOneRoutineModal';

interface TabViewOneToOneProps {
    patientDropdown: any
    value: any
    setLoading: any
    renderTabContent: any
    selectedDate: any
    selectedDate1: any
    setPreviousCheck: any
    noOfShift: any
}
const TabViewOneToOne: React.FC<TabViewOneToOneProps> = ({ selectedDate, selectedDate1, renderTabContent, setLoading, value, patientDropdown, setPreviousCheck, noOfShift }) => {
    const [modal, setModal] = useState(false);
    const [Fecthcheck, setFecthcheck] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [editModal1, setEditModal1] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const { oneData, oneDataElement } = useSelector((state: any) => state.OneToOne)
    const { organization } = useSelector((state: any) => state.Login);
    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });
    const selectedDateObj = moment(selectedDate1);
    const select1 = moment(selectedDate);

    const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');
    const renderShiftTab = () => {
        switch (noOfShift - 1) {
            case 0:
                return "Shift-A";
            case 1:
                return "Shift-B";
            case 2:
                return "Shift-C";
            case 3:
                return "Shift-D";
            case 4:
                return "Shift-E";
            case 5:
                return "Shift-F";
            case 6:
                return "General";
            case 7:
                return "Previous";
            default:
                return "No Content Available";
        }
    }
    const initialState = {
        date: "",
        id: "",
        organization: "",
        pid: "",
        shift: [{
            shiftName: "",
            shiftTime: "",
            shiftIncharge: "",
            record: [{
                distance: "",
                endTime: "",
                note: [{ note: "", time: "" }],
                staffId: "",
                staffName: "",
                startTime: "",
            }]
        }]
    }
    const [shifts, setShifts] = useState([initialState]);
    const dispatch = useDispatch<any>()
    const toggle = () => {
        setModal(!modal);
        // setFecthcheck(!Fecthcheck)
        setErrors({});
    };
    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };
    const oneToOneByPidAndDate = (patient: any) => {
        const { header1 } = authorize();
        setLoading(true);
        const shiftName = renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()
        try {
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&patientId=${patient.patientId}&shiftName=${shiftName}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setLoading(false);
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: moment(selectedDate).format('YYYYMMDD'),
                                id: responseData.id,
                                organization: organization,
                                pid: responseData.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData?.record?.length > 0 ? responseData.record : [initialState]
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setLoading(false);
                        setShifts([initialState]);
                    }
                });
        } catch (error: any) {
            console.error('API Error:', error)
            setLoading(false);
        }
    }
    const editChange = async (patient: any) => {
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModal1(true);
            setLoading(true);
            oneToOneByPidAndDate(patient)
        }
    };
    const Viewstaff = (patient: any) => {
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModalview(true);
        }
        setLoading(true);
        oneToOneByPidAndDate(patient)
    }
    useEffect(() => {
        const tabs = renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()
        fetchPatientData(dispatch, organization, currentPage, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), tabs, setPreviousCheck)
    }, [value, Fecthcheck, searchQuery, selectedDate]);
    return (
        <div>
            <div style={{ width: "100%", backgroundColor: "#f2f5f9" }} >
                <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "19px", fontWeight: 600, fontFamily: "poppins" }}>
                        {/* {moment(selectedDate).format("ddd DD") + " "} */}
                        {moment(selectedDate).format("MMM DD, YYYY")} {" "}
                        {renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()} 1:1 Assignment
                    </span>
                    <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                        <div aria-disabled={!isButtonEnabled} style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: isButtonEnabled ? "pointer" : "not-allowed", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                            <i style={{ fontSize: "36px", fontWeight: "lighter" }} className="material-icons" onClick={isButtonEnabled ? toggle : () => { }}>add</i>
                        </div>
                        <div className="mx-0 search-container d-flex align-items-center">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="search form-control"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <FaSearch className="search-icon mt-1" />
                        </div>
                    </div>
                </div>
                <table className="table table-bordered" style={{ fontSize: '13px', fontFamily: "poppins" }}>
                    <thead style={{ backgroundColor: grey }}>
                        <tr>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Prescribed by</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Technician</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Device Id</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oneData && oneData.length > 0 ? (
                            oneData.map((patient: any, index: number) => (
                                <tr key={index}>
                                    <td className="text text-center">{currentPage * 5 + index + 1}</td>
                                    <td
                                        style={{ cursor: "pointer", textAlign: "center" }}
                                        onClick={() => editChange(patient)}
                                        className="text"
                                    >
                                        {patient.patientName}
                                    </td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.startTime}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.endTime}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.shiftInchargeName}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.staffName}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient?.deviceId}</td>
                                    <td className="text" style={{ display: "flex", justifyContent: "space-around", height: "35px", alignItems: "center" }}>
                                        <Tooltip title="Edit" arrow>
                                            <div className="text-primary">
                                                <i
                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                    onClick={() => { setEditModal1(true); editChange(patient); }}
                                                    className="material-icons">create</i>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="View" arrow>
                                            <div className="text-primary">
                                                <i
                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                    onClick={() => { setEditModalview(true); Viewstaff(patient); }}
                                                    className="material-icons">preview</i>
                                            </div>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center" style={{ fontFamily: "poppins" }}>There are no records....</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="pagination-container">
                    <div className="d-flex justify-content-center">
                        <Paginator
                            first={currentPage * 5}
                            rows={5}
                            totalRecords={oneDataElement}
                            onPageChange={(e: any) => {
                                setCurrentPage(e.page);
                                const shiftTab = renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()
                                fetchPatientData(dispatch, organization, e.page, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), shiftTab, setPreviousCheck)
                            }}
                            currentPageReportTemplate={`Page ${currentPage} of ${oneDataElement / 5}`}
                        />
                    </div>
                </div>
                <Modal isOpen={editModalview} centered size='lg'>
                    <div className="d-flex align-items-center justify-content-center vh-90">
                        <div className='row' style={{ width: "100%" }}>
                            <div className='container col-md-12'>
                                <ModalHeader toggle={() => setEditModalview(false)}>
                                    <span>{formValues.patientName}</span>&nbsp;&nbsp;&nbsp;
                                </ModalHeader>
                                <ModalBody style={{ maxHeight: '55vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                    <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                        <thead style={{ backgroundColor: grey }}>
                                            <tr>
                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Assigned Technician</th>
                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Device Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                    <>
                                                        <tr >
                                                            <td className="text text-center">{currentPage * 5 + recordIndex + 1}</td>
                                                            <td style={{ cursor: "pointer", textAlign: "center" }} className="text" >
                                                                {record.staffName}
                                                            </td>
                                                            <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                {record.startTime}
                                                            </td>
                                                            <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.endTime}</td>
                                                            <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.deviceId}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={4} className="text-center">There are no records...</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </ModalBody>
                            </div>
                        </div>
                    </div >
                </Modal >
                <AddOneToOne
                    IsOpen={modal}
                    Close={toggle}
                    errors={errors}
                    setErrors={setErrors}
                    selectedDate={selectedDate}
                    selectedTab={renderTabContent}
                    shifts={shifts}
                    patientDropdown={patientDropdown}
                    setFecthcheck={setFecthcheck}
                    Fecthcheck={Fecthcheck}
                    noOfShift={noOfShift}
                    value={value}
                // setLoading={setLoading}
                />
                <EditOneToOneRoutineModal IsOpen={editModal1} selectedDate={selectedDate} selectedDate1={selectedDate1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={moment(selectedDate).format('YYYYMMDD')} editChange={editChange} selectedTab={renderTabContent} Fecthcheck={Fecthcheck} setFecthcheck={setFecthcheck} noOfShift={noOfShift} value={value} />
            </div>
        </div>
    )
}

export default TabViewOneToOne