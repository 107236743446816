export const TimeSlotData = {
  slotFirst: [
    {
      q15Slot: "A00",
      timeFormat: "00:01-00:15",
      displayTime: "00.00",
    },
    {
      q15Slot: "B00",
      timeFormat: "00:16-00:30",
      displayTime: "00.15",
    },
    {
      q15Slot: "C00",
      timeFormat: "00:31-00:45",
      displayTime: "00.30",
    },
    {
      q15Slot: "D00",
      timeFormat: "00:46-01:00",
      displayTime: "00.45",
    },
    {
      q15Slot: "A01",
      timeFormat: "01:01-01:15",
      displayTime: "01.00",
    },
    {
      q15Slot: "B01",
      timeFormat: "01:16-01:30",
      displayTime: "01.15",
    },
    {
      q15Slot: "C01",
      timeFormat: "01:31-01:45",
      displayTime: "01.30",
    },
    {
      q15Slot: "D01",
      timeFormat: "01:46-02:00",
      displayTime: "01.45",
    },
    {
      q15Slot: "A02",
      timeFormat: "02:01-02:15",
      displayTime: "02.00",
    },
    {
      q15Slot: "B02",
      timeFormat: "02:16-02:30",
      displayTime: "02.15",
    },
    {
      q15Slot: "C02",
      timeFormat: "02:31-02:45",
      displayTime: "02.30",
    },
    {
      q15Slot: "D02",
      timeFormat: "02:46-03:00",
      displayTime: "02.45",
    },
    {
      q15Slot: "A03",
      timeFormat: "03:01-03:15",
      displayTime: "03.00",
    },
    {
      q15Slot: "B03",
      timeFormat: "03:16-03:30",
      displayTime: "03.15",
    },
    {
      q15Slot: "C03",
      timeFormat: "03:31-03:45",
      displayTime: "03.30",
    },
    {
      q15Slot: "D03",
      timeFormat: "03:46-04:00",
      displayTime: "03.45",
    },
    {
      q15Slot: "A04",
      timeFormat: "04:01-04:15",
      displayTime: "04.00",
    },
    {
      q15Slot: "B04",
      timeFormat: "04:16-04:30",
      displayTime: "04.15",
    },
    {
      q15Slot: "C04",
      timeFormat: "04:31-04:45",
      displayTime: "04.30",
    },
    {
      q15Slot: "D04",
      timeFormat: "04:46-05:00",
      displayTime: "04.45",
    },
    {
      q15Slot: "A05",
      timeFormat: "05:01-05:15",
      displayTime: "05.00",
    },
    {
      q15Slot: "B05",
      timeFormat: "05:16-05:30",
      displayTime: "05.15",
    },
    {
      q15Slot: "C05",
      timeFormat: "05:31-05:45",
      displayTime: "05.30",
    },
    {
      q15Slot: "D05",
      timeFormat: "05:46-06:00",
      displayTime: "05.45",
    },
  ],
  slotTwo: [
    {
      q15Slot: "A06",
      timeFormat: "06:01-06:15",
      displayTime: "06.00",
    },
    {
      q15Slot: "B06",
      timeFormat: "06:16-06:30",
      displayTime: "06.15",
    },
    {
      q15Slot: "C06",
      timeFormat: "06:31-06:45",
      displayTime: "06.30",
    },
    {
      q15Slot: "D06",
      timeFormat: "06:46-07:00",
      displayTime: "06.45",
    },
    {
      q15Slot: "A07",
      timeFormat: "07:01-07:15",
      displayTime: "07.00",
    },
    {
      q15Slot: "B07",
      timeFormat: "07:16-07:30",
      displayTime: "07.15",
    },
    {
      q15Slot: "C07",
      timeFormat: "07:31-07:45",
      displayTime: "07.30",
    },
    {
      q15Slot: "D07",
      timeFormat: "07:46-08:00",
      displayTime: "07.45",
    },
    {
      q15Slot: "A08",
      timeFormat: "08:01-08:15",
      displayTime: "08.00",
    },
    {
      q15Slot: "B08",
      timeFormat: "08:16-08:30",
      displayTime: "08.15",
    },
    {
      q15Slot: "C08",
      timeFormat: "08:31-08:45",
      displayTime: "08.30",
    },
    {
      q15Slot: "D08",
      timeFormat: "08:46-09:00",
      displayTime: "08.45",
    },
    {
      q15Slot: "A09",
      timeFormat: "09:01-09:15",
      displayTime: "09.00",
    },
    {
      q15Slot: "B09",
      timeFormat: "09:16-09:30",
      displayTime: "09.15",
    },
    {
      q15Slot: "C09",
      timeFormat: "09:31-09:45",
      displayTime: "09.30",
    },
    {
      q15Slot: "D09",
      timeFormat: "09:46-10:00",
      displayTime: "09.45",
    },
    {
      q15Slot: "A10",
      timeFormat: "10:01-10:15",
      displayTime: "10.00",
    },
    {
      q15Slot: "B10",
      timeFormat: "10:16-10:30",
      displayTime: "10.15",
    },
    {
      q15Slot: "C10",
      timeFormat: "10:31-10:45",
      displayTime: "10.30",
    },
    {
      q15Slot: "D10",
      timeFormat: "10:46-11:00",
      displayTime: "10.45",
    },
    {
      q15Slot: "A11",
      timeFormat: "11:01-11:15",
      displayTime: "11.00",
    },
    {
      q15Slot: "B11",
      timeFormat: "11:16-11:30",
      displayTime: "11.15",
    },
    {
      q15Slot: "C11",
      timeFormat: "11:31-11:45",
      displayTime: "11.30",
    },
    {
      q15Slot: "D11",
      timeFormat: "11:46-12:00",
      displayTime: "11.45",
    },
  ],
  slotThree: [
    {
      q15Slot: "A12",
      timeFormat: "12:01-12:15",
      displayTime: "12.00",
    },
    {
      q15Slot: "B12",
      timeFormat: "12:16-12:30",
      displayTime: "12.15",
    },
    {
      q15Slot: "C12",
      timeFormat: "12:31-12:45",
      displayTime: "12.30",
    },
    {
      q15Slot: "D12",
      timeFormat: "12:46-13:00",
      displayTime: "12.45",
    },
    {
      q15Slot: "A13",
      timeFormat: "13:01-13:15",
      displayTime: "13.00",
    },
    {
      q15Slot: "B13",
      timeFormat: "13:16-13:30",
      displayTime: "13.15",
    },
    {
      q15Slot: "C13",
      timeFormat: "13:31-13:45",
      displayTime: "13.30",
    },
    {
      q15Slot: "D13",
      timeFormat: "13:46-14:00",
      displayTime: "13.45",
    },
    {
      q15Slot: "A14",
      timeFormat: "14:01-14:15",
      displayTime: "14.00",
    },
    {
      q15Slot: "B14",
      timeFormat: "14:16-14:30",
      displayTime: "14.15",
    },
    {
      q15Slot: "C14",
      timeFormat: "14:31-14:45",
      displayTime: "14.30",
    },
    {
      q15Slot: "D14",
      timeFormat: "14:46-15:00",
      displayTime: "14.45",
    },
    {
      q15Slot: "A15",
      timeFormat: "15:01-15:15",
      displayTime: "15.00",
    },
    {
      q15Slot: "B15",
      timeFormat: "15:16-15:30",
      displayTime: "15.15",
    },
    {
      q15Slot: "C15",
      timeFormat: "15:31-15:45",
      displayTime: "15.30",
    },
    {
      q15Slot: "D15",
      timeFormat: "15:46-16:00",
      displayTime: "15.45",
    },
    {
      q15Slot: "A16",
      timeFormat: "16:01-16:15",
      displayTime: "16.00",
    },
    {
      q15Slot: "B16",
      timeFormat: "16:16-16:30",
      displayTime: "16.15",
    },
    {
      q15Slot: "C16",
      timeFormat: "16:31-16:45",
      displayTime: "16.30",
    },
    {
      q15Slot: "D16",
      timeFormat: "16:46-17:00",
      displayTime: "16.45",
    },
    {
      q15Slot: "A17",
      timeFormat: "17:01-17:15",
      displayTime: "17.00",
    },
    {
      q15Slot: "B17",
      timeFormat: "17:16-17:30",
      displayTime: "17.15",
    },
    {
      q15Slot: "C17",
      timeFormat: "17:31-17:45",
      displayTime: "17.30",
    },
    {
      q15Slot: "D17",
      timeFormat: "17:46-18:00",
      displayTime: "17.45",
    },
  ],
  slotFour: [
    {
      q15Slot: "A18",
      timeFormat: "18:01-18:15",
      displayTime: "18.00",
    },
    {
      q15Slot: "B18",
      timeFormat: "18:16-18:30",
      displayTime: "18.15",
    },
    {
      q15Slot: "C18",
      timeFormat: "18:31-18:45",
      displayTime: "18.30",
    },
    {
      q15Slot: "D18",
      timeFormat: "18:46-19:00",
      displayTime: "18.45",
    },
    {
      q15Slot: "A19",
      timeFormat: "19:01-19:15",
      displayTime: "19.00",
    },
    {
      q15Slot: "B19",
      timeFormat: "19:16-19:30",
      displayTime: "19.15",
    },
    {
      q15Slot: "C19",
      timeFormat: "19:31-19:45",
      displayTime: "19.30",
    },
    {
      q15Slot: "D19",
      timeFormat: "19:46-20:00",
      displayTime: "19.45",
    },
    {
      q15Slot: "A20",
      timeFormat: "20:01-20:15",
      displayTime: "20.00",
    },
    {
      q15Slot: "B20",
      timeFormat: "20:16-20:30",
      displayTime: "20.15",
    },
    {
      q15Slot: "C20",
      timeFormat: "20:31-20:45",
      displayTime: "20.30",
    },
    {
      q15Slot: "D20",
      timeFormat: "20:46-21:00",
      displayTime: "20.45",
    },
    {
      q15Slot: "A21",
      timeFormat: "21:01-21:15",
      displayTime: "21.00",
    },
    {
      q15Slot: "B21",
      timeFormat: "21:16-21:30",
      displayTime: "21.15",
    },
    {
      q15Slot: "C21",
      timeFormat: "21:31-21:45",
      displayTime: "21.30",
    },
    {
      q15Slot: "D21",
      timeFormat: "21:46-22:00",
      displayTime: "21.45",
    },
    {
      q15Slot: "A22",
      timeFormat: "22:01-22:15",
      displayTime: "22.00",
    },
    {
      q15Slot: "B22",
      timeFormat: "22:16-22:30",
      displayTime: "22.15",
    },
    {
      q15Slot: "C22",
      timeFormat: "22:31-22:45",
      displayTime: "22.30",
    },
    {
      q15Slot: "D22",
      timeFormat: "22:46-22:00",
      displayTime: "22.45",
    },
    {
      q15Slot: "A23",
      timeFormat: "23:01-23:15",
      displayTime: "23.00",
    },
    {
      q15Slot: "B23",
      timeFormat: "23:16-23:30",
      displayTime: "23.15",
    },
    {
      q15Slot: "C23",
      timeFormat: "23:31-23:45",
      displayTime: "23.30",
    },
    {
      q15Slot: "D23",
      timeFormat: "23:46-23:00",
      displayTime: "23.45",
    },
  ],
};
