import React, { useEffect, useState } from "react";
import DateBox from '../../components/calendar/dateBox'
import moment from 'moment';
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";
import { baseURL, successCode } from "../../configuration/url";
import { fetchStaffDropdownDataEyeSight, getPatientDropdown, getStaffDropdown, getStaffDropdown1 } from "../../slices/thunk";
import { IoMdArrowBack } from "react-icons/io";
import TabView from "./TabView";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%", backgroundColor: '#f2f5f9', borderLeft: '1px solid #0303031f', height: '70vh' }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "14px 24px 24px 24px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Eyesight = () => {
    let [count, setcount] = useState<number>(0);
    const { organization } = useSelector((state: any) => state.Login);
    const { loading: thunkLoading } = useSelector((state: any) => state.OneToOne)
    const [selectedDate, setSelectedDate] = useState<any>();
    const [Loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const [editModal1, setEditModal1] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const dispatch = useDispatch<any>();
    const [noOfShift, setNoOfShift] = useState<any>(0);
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    // const [Fecthcheck, setFecthcheck] = useState<boolean>(false)
    const [checkdate, setCheckdate] = useState<boolean>(false)
    const [previousCheck, setPreviousCheck] = useState<boolean>(false)
    const [selectedDate1, setSelectedDate1] = useState<any>();
    const [datechangecheck, setdatechangecheck] = useState<boolean>(false)
    const [previousDate, setPreviousDate] = useState<any>('')
    const [nextDate, setNextDate] = useState<any>('')
    const [nextDaydate, setNextDayDate] = useState<any>('')
    // const handleSearchChange = (e: any) => {
    //     setSearchQuery(e.target.value);
    // };
    const [value, setValue] = React.useState(0);

    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });

    const handleChange = (newValue: number) => {
        if (newValue === 7) {
            setcount((prev) => prev + 1);
            if (count > 0) {
                setCheckdate(false);
                setPreviousCheck(false);
            } else {
                setCheckdate(true);
                setPreviousCheck(true);
            }
        } else {
            // Reset previous check when switching back from previous tab
            setPreviousCheck(false);
            if (count > 0) {
                setCheckdate(true);
            } else {
                setCheckdate(false);
            }
            setcount(0);
        }
        setValue(newValue);
        setdatechangecheck(!datechangecheck);
    };
    useEffect(() => {
        if (!selectedDate) return
        const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
        const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
        const tabSlotTime = previousCheck ? moment(previousDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
        if (count <= 1) {
            setSelectedDate(moment(tabSlotTime).format('YYYY-MM-DD'))
        } else {
            return
        }
    }, [datechangecheck])
    useEffect(() => {
        if (!selectedDate) return;
        const validDate = moment(selectedDate);
        if (validDate.isValid()) {
            const nextDay = validDate.clone().add(1, 'days');
            setNextDayDate(moment(nextDay)?.format('YYYY-MM-DD'))
        } else {
            console.error("Invalid date:", selectedDate);
        }
    }, [selectedDate, previousCheck]);
    useEffect(() => {
        if (!selectedDate) return;
        const validDate = moment(selectedDate);
        if (validDate.isValid()) {
            const nextDay = validDate.clone().subtract(1, 'days');
            setPreviousDate(moment(nextDay)?.format('MMM DD, YYYY'))
        } else {
            console.error("Invalid date:", selectedDate);
        }
    }, [selectedDate]);
    useEffect(() => {
        if (renderTabContent() !== "General" && renderTabContent() !== "No Content Available" && renderTabContent() !== "Previous" && checkdate) {
            setCheckdate(false)
        }
    }, [previousCheck, value])



    useEffect(() => {
        if (renderTabContent() !== "General" && renderTabContent() !== "No Content Available" && renderTabContent() !== "Previous" && checkdate) {
            setCheckdate(false)
        }
    }, [previousCheck, value])
    const renderTabContent = () => {
        switch (value) {
            case 0:
                return "Shift-A";
            case 1:
                return "Shift-B";
            case 2:
                return "Shift-C";
            case 3:
                return "Shift-D";
            case 4:
                return "Shift-E";
            case 5:
                return "Shift-F";
            case 6:
                return "General";
            case 7:
                return "Previous";
            default:
                return "No Content Available";
        }
    };
    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return 24 / duration;
    };
    const handlestartTime = () => {
        const crtime: any = localStorage.getItem("LTime");
        if (crtime) {
            const formattedDate = moment(crtime).format("YYYY-MM-DD");
            const currentTime = moment(crtime).format('HH:mm')
            const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
            const CurrTime: any = moment(`1970-01-01T${currentTime}`)
            if (shiftStartTime !== CurrTime) {
                if (CurrTime.isAfter(OrgStartTime)) {
                    setSelectedDate(formattedDate);
                    setSelectedDate1(formattedDate)
                } else {
                    if (!formattedDate) return;
                    const validDate = moment(formattedDate);
                    if (validDate.isValid()) {
                        const nextDay = validDate.clone().subtract(1, "days");
                        setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
                        setSelectedDate1(moment(nextDay)?.format('YYYY-MM-DD'));
                    } else {
                        console.error("Invalid date:", formattedDate);
                    }
                }
            } else {
                setSelectedDate(formattedDate);
                setSelectedDate1(formattedDate);
            }
        }
    };
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
        handlestartTime();
        handlestartTime();
    }, []);
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const handleDateChange = (event: any) => {
        if (count >= 0) {
            setcount(0)
            setValue(0)
        }
        const newDate = event;
        if (newDate) {
            setSelectedDate(newDate);
        }
    };
    useEffect(() => {
        getPatientDropdown(dispatch, organization);
        getStaffDropdown1(dispatch, organization);
        // getStaffDropdown(dispatch, organization);
    }, [dispatch, organization]);

    const renderShiftTab = () => {
        switch (noOfShift - 1) {
            case 0:
                return "Shift-A";
            case 1:
                return "Shift-B";
            case 2:
                return "Shift-C";
            case 3:
                return "Shift-D";
            case 4:
                return "Shift-E";
            case 5:
                return "Shift-F";
            case 6:
                return "General";
            case 7:
                return "Previous";
            default:
                return "No Content Available";
        }
    }
    useEffect(() => {
        const tabs = renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()
        const dateCheck1 = moment(selectedDate1).format('YYYYMMDD')
        const dateCheck2 = moment(selectedDate).format('YYYYMMDD')
        const tabSlotTime = previousCheck ? moment(previousDate).format("YYYYMMDD") : dateCheck1 === dateCheck2 ? moment(selectedDate).format("YYYYMMDD") : (checkdate) ? moment(nextDaydate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD")
        fetchStaffDropdownDataEyeSight(dispatch, tabSlotTime, organization, tabs, setPreviousCheck, setCheckdate)
    }, [value, selectedDate])

    const [shiftsTime, setShiftsTime] = useState<any>([]);
    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        for (let i = 0; i < totalShifts; i++) {
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            );
            const startTimeFormatted = startTime.toTimeString().slice(0, 5);
            const endTimeFormatted = endTime.toTimeString().slice(0, 5);
            const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
            });
            startTime = endTime;
        }
        setShiftsTime(shifts);
    };
    useEffect(() => {
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [orgData, noOfShift])
    const [dateRange, setDateRange] = useState<any>()
    useEffect(() => {
        if (!shiftsTime) return
        if (previousCheck) {
            setDateRange(shiftsTime[noOfShift - 1])
        } else if (renderTabContent() === "General") {
            setDateRange(shiftsTime[0])
        } else {
            setDateRange(shiftsTime[value])
        }
    }, [shiftsTime, value, previousCheck])
    return (
        <div style={{ height: '91vh' }}>
            {Loading && <Loader />}
            {thunkLoading && <Loader />}
            <div style={{ height: '6vh' }}>
                <h5 style={{}}>Eye-Sight Staff Assignment</h5>
            </div>
            <div style={{ height: '13vh' }}>
                <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "0px", width: "90%", position: "relative", left: "66px" }}>
                    <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={count} dateRange={dateRange} />
                </div>
            </div>
            {/* <div> */}
            <div className="d-flex" style={{ height: '70vh' }}>
                <div
                    className="col-md-2"
                    style={{
                        // width: "15%",
                        backgroundColor: "#eaf2fa",
                        borderRight: "1px groove #ccd2d8",
                        height: '70vh',
                    }}>
                    <div style={{ backgroundColor: "#eaf2fa", display: "flex", flexDirection: "column", paddingTop: "20px", alignItems: "center", height: '70vh' }}>
                        <button
                            className={`nav-link ${value === 7 ? "active" : ""}`}
                            id="General"
                            type="button"
                            role="tab"
                            aria-controls=""
                            aria-selected={value === 7}
                            onClick={() => {
                                if (count <= 1) {
                                    handleChange(7);
                                }
                            }}
                            style={{
                                backgroundColor: value === 7 ? "#cddcfa" : "",
                                color: value === 7 ? "" : "black",
                                width: "91%",
                                height: "40px",
                                borderRadius: "4px",
                            }}
                        >
                            <IoMdArrowBack style={{ fontSize: '18px', paddingBottom: '2px' }} />
                            Previous
                        </button>
                        {
                            Array.from({ length: noOfShift }).map((_, index: number) => (
                                <button
                                    className={`nav-link ${value === index ? 'active' : ''}`}
                                    id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                                    aria-selected={value === index}
                                    onClick={() => handleChange(index)}
                                    style={{ backgroundColor: value === index ? '#cddcfa' : value === 7 ? `Shift-${String.fromCharCode(65 + index)}` === renderShiftTab() ? "#cddcfa" : "" : '', width: "91%", height: "40px", borderRadius: "4px" }}
                                >
                                    {`Shift-${String.fromCharCode(65 + index)}`}
                                </button>
                            ))
                        }
                        <button
                            className={`nav-link ${value === 6 ? "active" : ""}`}
                            id="General"
                            type="button"
                            role="tab"
                            aria-controls="Shift-A"
                            aria-selected={value === 6}
                            onClick={() => handleChange(6)}
                            style={{
                                backgroundColor: value === 6 ? "#cddcfa" : "",
                                color: value === 6 ? "" : "black",
                                width: "91%",
                                height: "40px",
                                borderRadius: "4px",
                            }}
                        >
                            General
                        </button>
                    </div>
                </div>
                <div className="col-md-10" style={{ height: '70vh' }} >
                    {Array.from({ length: noOfShift }).map((_, index) => (
                        <TabPanel value={value} index={index}>
                            <TabView selectedDate={selectedDate} selectedDate1={selectedDate1} patientDropdown={patientDropdown} formValues={formValues} setLoading={setLoading} setFormValues={setFormValues} value={value} renderTabContent={renderTabContent} setPreviousCheck={setPreviousCheck} noOfShift={noOfShift} />
                        </TabPanel>
                    ))}
                    <TabPanel value={value} index={6}>
                        <TabView selectedDate={selectedDate} selectedDate1={selectedDate1} patientDropdown={patientDropdown} formValues={formValues} setLoading={setLoading} setFormValues={setFormValues} value={value} renderTabContent={renderTabContent} setPreviousCheck={setPreviousCheck} noOfShift={noOfShift} />
                    </TabPanel>

                    <TabPanel value={value} index={7}>
                        <TabView selectedDate={selectedDate} selectedDate1={selectedDate1} patientDropdown={patientDropdown} formValues={formValues} setLoading={setLoading} setFormValues={setFormValues} value={value} renderTabContent={renderTabContent} setPreviousCheck={setPreviousCheck} noOfShift={noOfShift} />
                    </TabPanel>
                </div>
            </div>
            {/* </div> */}
        </div >
    )
}

export default Eyesight