import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from "@mui/material/Typography";
import moment from 'moment'
import '../Reports.css'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { getActivityLegend, getLocationLegend } from '../../../slices/thunk';
import { setActivityLegend } from '../../../slices/dropdown/reducer';
import { setLocationLegend } from '../../../slices/Reports/reducer';
import DetailReportView from './DetailReportView';
import { primarybg, secondarybt } from '../../../common/primary';
interface OneToOneViewProps {
    onetooneReportData: any
    selectShiftView: any
    selectShift: any
    nextDate: any
    checkPrecaution: any
    checkBox: any
    legendcontrol: any
    setLoading: any
    timeFormat: any
}
const OneToOneView: React.FC<OneToOneViewProps> = ({ checkBox, checkPrecaution, legendcontrol, nextDate, onetooneReportData, selectShift, selectShiftView, setLoading, timeFormat }) => {
    const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
    const org = useSelector((state: any) => state.Login.organization)
    const [onetoOnedata, setOnetoOneData] = useState<any>([])
    const [expanded, setExpanded] = useState<string | false>('panel1');

    // const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const dispatch = useDispatch<any>()
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (onetooneReportData !== null && onetooneReportData.length > 0) {
            const filterData = onetooneReportData && onetooneReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime)
            setOnetoOneData(filterData)
        } else {
            setOnetoOneData([])
        }
    }, [onetooneReportData])


    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
        return () => {
            dispatch(setActivityLegend([]))
            dispatch(setLocationLegend([]))
        }
    }, [org])

    const handleSingltDownload = async (str: any) => {
        if (onetooneReportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < onetooneReportData.length) {
                        const currentIndex = index;
                        setTimeout(() => {
                            if (moment(onetooneReportData[currentIndex].date).format("DD-MM-YYYY") === str) {
                                timeDealys(onetooneReportData[currentIndex]);
                            }
                        }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);
                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    return (
        <div >
          {
            onetoOnedata !== null && onetoOnedata !== undefined && onetoOnedata.length > 0 && onetoOnedata?.map((data: any, inx: number) => (
              <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)} style={{ width: '100%' }}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: expanded === 'panelmain' + data.date ? secondarybt : '' }} expandIcon={<ArrowDropDownIcon />} >
                  <Typography>
                    <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                      <div className='' style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                        {moment(data.date).format("MMM DD, YYYY")}
                      </div>
                    </div>
                  </Typography>
                  <div>
                    <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                      handleSingltDownload(moment(data.date).format("DD-MM-YYYY"))
                    }}>Download</button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }} >
                    {
                      onetoOnedata !== null && onetoOnedata !== undefined &&
                      <DetailReportView reportData={onetoOnedata} selectShiftView={selectShiftView} checkBox={checkBox} selectShift={selectShift} nextDate={nextDate} dateID={undefined} checkPrecaution={checkPrecaution} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={legendcontrol} IndexNum={inx} timeFormat={timeFormat} />
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          }
    
        </div>
    )
}

export default OneToOneView