import React, { useEffect, useState } from 'react'
import { Autocomplete, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import { fetchPatientData1 } from '../../slices/thunk';
import Loader from '../../components/loader/Loader';
interface EditOneToOneRoutineModalProps {
    IsOpen: any
    closeModal: any
    formValues: any
    setFormValues: any
    errors: any
    setErrors: any
    shifts: any
    setShifts: any
    forDate: any
    editChange: any
    selectedTab: any
    Fecthcheck: any
    setFecthcheck: any
    se: any
    selectedDate1: any
    ta: any
    setPreviousCheck: any
    renderShiftTab: any
    noOfShift: any
    value: any
}

interface Shift {
    shift_name: string;
    start_time: string;
    end_time: string;
    dayShift: string;
}

const EditOneToOneRoutineModal: React.FC<EditOneToOneRoutineModalProps> = ({ IsOpen, closeModal, formValues, setFormValues, errors, setErrors, shifts, setShifts, forDate, editChange, selectedTab, Fecthcheck, setFecthcheck, se, ta, setPreviousCheck, renderShiftTab, noOfShift, value, selectedDate1 }) => {

    const { organization } = useSelector((state: any) => state.Login)
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const { staffDropdown, staffDropdown1, staffDropDownEyeSight } = useSelector((state: any) => state.Staff);
    const dispatch = useDispatch<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const handleRecordChange = (e: any, recordIndex: any) => {
        if (e) {
            setShifts((prevShifts: any) => {
                const updatedShifts = [...prevShifts];
                updatedShifts[0].shift[0].record[recordIndex].staffId = e;
                return updatedShifts;
            });
        } else {
            setShifts((prevShifts: any) => {
                const updatedShifts = [...prevShifts];
                updatedShifts[0].shift[0].record[recordIndex].staffId = "";
                return updatedShifts;
            });
        }
    };

    const selectedDateObj = moment(selectedDate1);
    const select1 = moment(se);

    const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');
    const handleInputChange = (
        e: any,
        recordIndex: number,
        field: string
    ) => {
        const value = e?.$d ? moment(e.$d).format('MMM DD, YYYY hh:mm A') : null;
        const updatedShifts = [...shifts];
        updatedShifts[0].shift[0].record[recordIndex][field] = value || null;
        setShifts(updatedShifts);
    };
    const addRecord = () => {
        setShifts((prevShifts: any) => {
            const newRecord = {
                distance: "",
                endTime: "",
                note: [
                    {
                        note: "",
                        time: ""
                    }
                ],
                staffId: "",
                staffName: "",
                startTime: "",
            };
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record.push(newRecord);
            return updatedShifts;
        });
    };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [record, setRecord] = useState<any>(null);

    const confirmDeleteRecord = (index: number, record: any) => {
        setDeleteIndex(index);
        setRecord(record)
        setConfirmDelete(true);
    };

    const removeRecord = async (index: any) => {

        if (record.staffId !== "" && record.startTime !== "" && record.endTime !== "") {
            if (deleteIndex !== null) {
                setLoading(true)
                try {
                    const url = `${baseURL}/eyesight/deleteStaff?date=${forDate}&endTime=${record?.endTime.split(" ")[3]?.slice(0, 5)}&organization=${organization}&pid=${formValues.patientId}&staffId=${record?.staffId}&startTime=${record?.startTime.split(" ")[3]?.slice(0, 5)}`;
                    const response = await axios.delete(url)
                    if (response.data.message.code === successCode) {
                        setLoading(false)
                        setShifts((prevShifts: any) => {
                            const updatedShifts = [...prevShifts];
                            updatedShifts[0].shift[0].record.splice(deleteIndex, 1);
                            return updatedShifts;
                        });
                        setFormValues({ ...formValues, endTime: '', startTime: '', prescribedby: '', prescribedbyName: '' })
                        setConfirmDelete(false);
                        setDeleteIndex(null);
                    } else {
                        setLoading(false)
                        setConfirmDelete(false);
                        setDeleteIndex(null);
                        toast.error(response.data.message.description)
                    }
                } catch (error: any) {
                    setLoading(false)
                    setConfirmDelete(false);
                    setDeleteIndex(null);
                    console.error('There was an error deleting the record:', error);
                }
            }
        } else {
            setLoading(false)
            setShifts((prevShifts: any) => {
                const updatedShifts = [...prevShifts];
                updatedShifts[0].shift[0].record.splice(deleteIndex, 1);
                return updatedShifts;
            });
            setConfirmDelete(false);
            setDeleteIndex(null);
        }
    };
    const cancelDelete = () => {
        setConfirmDelete(false);
        setDeleteIndex(null);
    };

    // const ShiftTimeRange = (values: any) => {
    //     const isGeneralTab = selectedTab() === "General";
    //     const starTime = isGeneralTab
    //         ? '09:00 AM'
    //         : formatTimeTo12Hour(shift[value]?.start_time)
    //     const endTime = isGeneralTab
    //         ? '05:00 PM'
    //         : formatTimeTo12Hour(shift[value]?.end_time)
    //     const testFormat = moment(values, ["MMM D, YYYY hh:mm A"]).format("HH:mm")
    //     const shiftstartTime = moment(starTime, ["h:mm A"]).format("HH:mm")
    //     const shiftEndTime = moment(endTime, ["h:mm A"]).format("HH:mm")
    //     const shiftTimeStart = moment(`2025-01-08T${shiftstartTime}`)
    //     const shiftTimeEnd = moment(`2025-01-08T${shiftEndTime}`)
    //     const date1 = moment(`2025-01-08T${testFormat}`)
    //     if (date1.format("HH:mm") !== shiftTimeStart.format("HH:mm") && date1.format("HH:mm") !== shiftTimeEnd.format("HH:mm")) {
    //         if (date1.isAfter(shiftTimeStart) && shiftTimeEnd.isAfter(date1)) {
    //             return false
    //         } else {
    //             return true
    //         }
    //     } else {
    //         return false
    //     }
    // }
    const ShiftTimeRange = (values: any) => {
        const isGeneralTab = selectedTab() === "General";
        const starTime = isGeneralTab
            ? '09:00 AM'
            : formatTimeTo12Hour(shift[value]?.start_time);
        const endTime = isGeneralTab
            ? '05:00 PM'
            : formatTimeTo12Hour(shift[value]?.end_time);

        const testFormat = moment(values, ["MMM D, YYYY h:mm A"]);

        // Check if the moment object is valid
        if (!testFormat.isValid()) {
            console.log("Invalid time format:", values);
            return true; // If time is invalid, consider it outside the shift range
        }
        // Format the shift start and end times to 24-hour format
        const shiftstartTime = moment(starTime, ["h:mm A"]).format("HH:mm");
        const shiftEndTime = moment(endTime, ["h:mm A"]).format("HH:mm");
        const shiftTimeStart = moment(`2025-01-08T${shiftstartTime}`);
        let shiftTimeEnd = moment(`2025-01-08T${shiftEndTime}`);
        let shiftTimeEnd1 = moment(`2025-01-08T${shiftEndTime}`);
        const zeroTime = moment("2025-01-08T00:00");
        const date1 = moment(`2025-01-08T${testFormat.format("HH:mm")}`);
        const isShiftCrossingMidnight = shiftTimeEnd.isBefore(shiftTimeStart);
        if (isShiftCrossingMidnight) {
            if (
                date1.isBefore(shiftTimeStart)
            ) {
                if (date1.isAfter(shiftTimeEnd)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                console.log("Date is inside the shift time range.");
                return false;
            }
        } else {
            // Normal shift logic
            if (date1.format("HH:mm") !== shiftTimeStart.format("HH:mm") && date1.format("HH:mm") !== shiftTimeEnd.format("HH:mm")) {
                if (date1.isAfter(shiftTimeStart) && shiftTimeEnd.isAfter(date1)) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    };
    const [checkindex, setcheckindex] = useState<number>(-1)
    const [checkindex1, setcheckindex1] = useState<number>(0)
    const savePatients = async (patient: any) => {
        const { header1 } = authorize();
        setLoading(true)
        try {
            let message: string = '';
            let newErrors = {
                patientIncharge: !formValues.patientIncharge,
                technician: false,
                startTime: false,
                endTime: false
            };
            shifts[0].shift[0].record.map((item: any, index: number) => {
                if (item?.staffId === "" || item?.endTime === "" || item?.startTime === "") {
                    message = item?.staffId === "" ? "Technician is Reqiured" :
                        item?.startTime === "" ? "Start Time is Required" :
                            "Fill the End Time is Required";
                    setcheckindex(index)
                    newErrors.technician = !item?.staffId
                    newErrors.startTime = !item?.startTime
                    newErrors.endTime = !item?.endTime
                } else {
                    newErrors.technician = !item?.staffId
                    newErrors.startTime = !item?.startTime
                    newErrors.endTime = !item?.endTime
                }
            });
            setErrors(newErrors);
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                // if (formValues.patientIncharge === null || formValues.patientIncharge === "") {
                //     toast.error("Prescribed By is Reqiured")
                //     setLoading(false)
                //     return
                // } else {
                //     toast.error(message);
                //     setLoading(false)
                //     return;
                // }
                setLoading(false)
                toast.error("Please Fill the Required Field")
                return
            }

            if (message !== "") {
                setLoading(false)
                toast.error(message);
                return
            } else {
                setcheckindex(-1)
            }

            const isInvalidShiftTime = (startTime: any, endTime: any, item: any) => {
                const checkTime = ShiftTimeRange(startTime);
                const checkTime1 = ShiftTimeRange(endTime);

                if (checkTime) {
                    const nameofStaff = staffDropDownEyeSight?.find((staff: any) => staff.staffId === item.staffId)
                    toast.error(`${nameofStaff.staffName} Start time Not in Shift Time Range`);
                    return true; // Invalid time
                }

                if (checkTime1) {
                    const nameofStaff = staffDropDownEyeSight?.find((staff: any) => staff.staffId === item.staffId)
                    toast.error(`${nameofStaff.staffName} End time Not in Shift Time Range`);
                    return true; // Invalid time
                }

                return false; // Valid time
            };

            const isInvalid = shifts[0].shift[0].record.some((item: any, inx: number) => {
                if (isInvalidShiftTime(item?.startTime, item?.endTime, item)) {
                    setLoading(false);
                    return true; // Breaks the loop
                }
                return false; // Continue the loop
            });

            if (isInvalid) return;
            let bodyData = {
                date: forDate,
                id: "",
                organization,
                pid: formValues.patientId,
                shift: shifts?.map((k: any) => ({
                    record: k.shift[0].record?.map((l: any) => {
                        const startTime = l.startTime ? dayjs(l.startTime, 'MMM DD, YYYY hh:mm A').format('HH:mm') : '';
                        const endTime = l.endTime ? dayjs(l.endTime, 'MMM DD, YYYY hh:mm A').format('HH:mm') : '';
                        return {
                            distance: "",
                            endTime: endTime || "",
                            note: [{ note: "", time: "" }],
                            staffId: l.staffId,
                            startTime: startTime || "",
                        }
                    }),
                    shiftIncharge: formValues.patientIncharge,
                    shiftName: "",
                    shiftTime: k.shift[0].shiftTime,
                })),
            };
            bodyData.shift.forEach((shift: any) => shift.shiftName = selectedTab() === "Previous" ? renderShiftTab() : selectedTab());
            const response = await axios.post(`${baseURL}/eyesight/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                setLoading(false)
                toast.success(response.data.message.description)
                editChange(patient);
                closeModal(false);
                setFecthcheck(!Fecthcheck)
                setErrors({})
                fetchPatientData1(dispatch, organization, 0, '', moment(se).format('YYYYMMDD'), ta, setPreviousCheck)
            } else {
                setLoading(false)
                toast.error(response.data.message.description);
            }

        } catch (error) {
            setLoading(false)
            toast.error("An error occurred during register.");
        }
    }

    const handlePrescribedchange = (e: any, value: any) => {
        setFormValues({
            ...formValues,
            patientIncharge: value ? value.id : null,
            patientInchargeName: value ? value.name : ''

        });

    }
    const [shift, setShift] = useState<any>([]);
    const { shiftStartTime } = useSelector((state: any) => state.Org);
    const { orgData } = useSelector((state: any) => state.Org);

    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        // Convert the start time string to a Date object
        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        // Function to convert 24-hour time to 12-hour time with AM/PM
        const to12HourFormat = (date: any) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? "PM" : "AM";

            // Convert hour to 12-hour format
            hours = hours % 24;
            if (hours === 0) {
                hours = '00'; // Midnight or noon case
            }

            // Add leading zero for minutes if needed
            minutes = minutes < 10 ? "0" + minutes : minutes;

            // Return formatted time with AM/PM
            return `${hours}:${minutes} `;
        };

        for (let i = 0; i < totalShifts; i++) {
            // Calculate the end time by adding the duration to the start time
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            ); // duration in hours

            // Format start and end times into 12-hour format with AM/PM
            const startTimeFormatted = to12HourFormat(startTime);
            const endTimeFormatted = to12HourFormat(endTime);

            // Assign shift name (A, B, C, etc.)
            const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

            // Push shift details into the array
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                dayShift: "",
            });

            // Update the start time for the next shift
            startTime = endTime;
        }
        const convertTo24Hour = (time: string) => {
            const [hour, minuteWithPeriod] = time.split(":");
            const [minute, period] = minuteWithPeriod.split(" ");
            let hour24 = parseInt(hour);

            // Handle AM/PM conversion
            if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
            if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

            return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
        };

        const addDayShift = (shift: Shift, addShift: boolean): Shift => {
            const startInMinutes = convertTo24Hour(shift.start_time);
            const endInMinutes = convertTo24Hour(shift.end_time);
            if (endInMinutes < startInMinutes) {
                return { ...shift, dayShift: "+1" };
            }
            if (addShift) {
                return { ...shift, dayShift: "+1" };
            }
            return { ...shift, dayShift: "" };
        };
        let addShift = false;

        // Apply the dayShift logic to each shift
        const updatedShifts = shifts.map((shift: any) => {
            // If we encounter a shift that crosses midnight, set addShift to true
            if (
                !addShift &&
                convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
            ) {
                addShift = true;
            }
            return addDayShift(shift, addShift);
        });
        setShift(updatedShifts);
    };
    useEffect(() => {
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [noOfShift, shiftStartTime, orgData])
    const formatTimeTo12Hour = (time: string) => {
        return moment(time, 'HH:mm').format('hh:mm A');
    };
    return (
        <div>
            {loading && <Loader />}
            <Modal isOpen={IsOpen} centered size='lg'>
                <div className="d-flex align-items-center justify-content-center vh-90">
                    <div className='row'>
                        <div className='container col-md-12'>
                            <ModalHeader toggle={() => { closeModal(false); setFecthcheck(!Fecthcheck); setErrors({}) }}>
                                <div className='d-flex ' style={{ width: '500px' }}>
                                    <div className='col-md-6'>
                                        Edit Eye Sight Routine
                                    </div>
                                    <div className='col-md-12'>
                                        <div
                                            className="d-flex align-items-center gap-2"
                                            style={{ fontSize: "18px", marginTop: "0.5%" }}
                                        >
                                            <div>
                                                {selectedTab() === "General"
                                                    ? 'Shift - General'
                                                    : selectedTab() === "Previous" ? 'Shift - Previous' : `Shift - ${shift[value]?.shift_name} `}{" "}
                                                {" "}
                                            </div>
                                            <div>
                                                {selectedTab() === "General"
                                                    ? '09:00 AM'
                                                    : selectedTab() === "Previous" ? formatTimeTo12Hour(shift[noOfShift - 1]?.start_time) : formatTimeTo12Hour(shift[value]?.start_time)}{" "}
                                                {""} -
                                            </div>
                                            <div style={{ marginLeft: "5px" }}>
                                                {selectedTab() === "General"
                                                    ? '05:00 PM'
                                                    : selectedTab() === "Previous" ? formatTimeTo12Hour(shift[noOfShift - 1]?.end_time) : formatTimeTo12Hour(shift[value]?.end_time)}{" "}
                                                {""}
                                                {/* {shifts[selectedTab()]?.dayShift} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                <div className="row w-100 " style={{ marginTop: '0px' }}>
                                    <div className='col-md-6 mb-2' >
                                        <label htmlFor="">Patient Name</label>
                                        <Autocomplete
                                            id='PatientName'
                                            options={patientDropdown}
                                            value={formValues.patientName}
                                            onChange={(e, v: any) => { formValues.patientId = v; setFormValues({ ...formValues }); }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" />
                                            )}
                                            disabled
                                        />
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <label htmlFor="">Prescribed By</label>
                                        <Autocomplete
                                            id='Prescribedby'
                                            options={staffDropdown1?.length > 0 ? staffDropdown1 : []}
                                            disabled={!isButtonEnabled}
                                            getOptionLabel={(option) => `${option.name} - ${option.role}`}
                                            value={staffDropdown1?.find((staff: any) => staff.id === formValues.patientIncharge)}
                                            onChange={(event, value) => { handlePrescribedchange(event, value); setErrors({ ...errors, patientIncharge: false }) }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={staffDropdown1?.length > 0 ? "Select Prescribed By" : "No Options"}
                                                    error={!!errors.patientIncharge}
                                                />
                                            )}
                                        />
                                    </div>
                                    {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                        shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                            <>
                                                <div className='col-md-6 mb-2' >
                                                    <label htmlFor="">Technician</label>
                                                    <Autocomplete
                                                        id='Technician'
                                                        options={staffDropDownEyeSight?.length > 0 ? staffDropDownEyeSight : []}
                                                        disabled={!isButtonEnabled}
                                                        getOptionLabel={(option) => `${option.staffName} - ${option.jobTitle}`}
                                                        value={staffDropDownEyeSight?.find((staff: any) => staff.staffId === record.staffId) ?? null}
                                                        onChange={(e, v) => { handleRecordChange(v?.staffId, recordIndex); setcheckindex(-1); setErrors({ ...errors, technician: false }) }}
                                                        sx={{
                                                            // border: checkindex === recordIndex && errors.technician ? "1px solid red" : "none"
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder='Technician'
                                                                error={checkindex === recordIndex && errors.technician}
                                                            />
                                                        )}
                                                    />
                                                    {/* 
                                                    <Select
                                                        style={{ height: "57px", paddingRight: '8px' }}
                                                        className="form-select"
                                                        fullWidth
                                                        value={record.staffId}
                                                        onChange={(e) => handleRecordChange(e, recordIndex)}
                                                    >
                                                        {staffDropDownEyeSight?.length > 0 ? staffDropDownEyeSight?.map((staff: any) => (
                                                            <MenuItem key={staff.staffId} value={staff.staffId}>
                                                                {staff.staffName} - {staff.jobTitle}

                                                            </MenuItem>
                                                        )) :
                                                            <MenuItem>
                                                                No Data Found
                                                            </MenuItem>
                                                        }
                                                    </Select> */}
                                                </div>

                                                <div className="col-md-6 d-flex">
                                                    <div className='px-2'>
                                                        <label htmlFor="">Start Time</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={record.startTime === "" ? null : dayjs(record.startTime, 'MMM DD, YYYY hh:mm A')}
                                                                onChange={(e: any) => { handleInputChange(e, recordIndex, 'startTime'); setErrors({ ...errors, startTime: false }) }}
                                                                ampm={true}
                                                                format='hh:mm A'
                                                                name='startTime'
                                                                disabled={!isButtonEnabled}
                                                                slotProps={{
                                                                    textField: {
                                                                        id: "startTime",
                                                                        placeholder: "hh:mm A",
                                                                        style: { height: "57px" },
                                                                        error: checkindex === recordIndex && errors.startTime,
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='px-2'>
                                                        <label htmlFor="">End Time</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={record.endTime === "" ? null : dayjs(record.endTime, 'MMM DD, YYYY hh:mm A')}
                                                                onChange={(e: any) => { handleInputChange(e, recordIndex, 'endTime'); setErrors({ ...errors, endTime: false }) }}
                                                                ampm={true}
                                                                format='hh:mm A'
                                                                disabled={!isButtonEnabled}
                                                                name='endTime'
                                                                slotProps={{
                                                                    textField: {
                                                                        id: "endTime",
                                                                        placeholder: "hh:mm A",
                                                                        style: { height: "57px" },
                                                                        error: checkindex === recordIndex && errors.endTime,
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className='d-flex align-items-center'>
                                                        {shifts[0].shift[0].record.length - 1 === recordIndex && (
                                                            <div>
                                                                <i
                                                                    style={{ backgroundColor: "#d7dee5", borderRadius: "4px", marginRight: '8px', cursor: isButtonEnabled ? 'pointer' : 'not-allowed' }}
                                                                    onClick={isButtonEnabled ? addRecord : () => { }}
                                                                    className="material-icons"
                                                                >
                                                                    add
                                                                </i>
                                                            </div>
                                                        )}
                                                        {shifts[0].shift[0].record.length !== 1 && (
                                                            <div>
                                                                <i
                                                                    style={{ backgroundColor: "#d7dee5", borderRadius: "4px", cursor: isButtonEnabled ? 'pointer' : 'not-allowed' }}
                                                                    onClick={isButtonEnabled ? () => confirmDeleteRecord(recordIndex, record) : () => { }}
                                                                    className="material-icons"
                                                                >
                                                                    remove
                                                                </i>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <Dialog open={confirmDelete} onClose={cancelDelete} style={{ backgroundColor: "#ffffff36" }}>
                                                    <DialogTitle>Confirm Delete</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Are you sure you want to delete this Staff?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <div className="d-flex gap-3 justify-content-center">
                                                            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={cancelDelete} ></Button>
                                                            <Button label="Delete Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => removeRecord(recordIndex)}></Button>
                                                        </div>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                        ))
                                    ) : (
                                        <div>No records available</div>
                                    )}
                                </div>
                            </ModalBody>
                        </div>
                        <ModalFooter>
                            <div className="d-flex gap-3 justify-content-center">
                                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => { closeModal(false); setFecthcheck(!Fecthcheck); setErrors({}) }} ></Button>
                                <Button disabled={!isButtonEnabled} label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={(e: any) => {
                                    savePatients(e)
                                }}></Button>
                            </div>
                        </ModalFooter>
                    </div>
                </div>
            </Modal >
        </div>
    )
}

export default EditOneToOneRoutineModal