import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { baseURL, successCode } from "../../configuration/url";
import { DatePicker } from "@mui/x-date-pickers";
import { authorize, formatDateToYYYYMMDD, formatTimeRange, formatTo12HourNoAmPm, formatTo12HourWithAmPm } from "../../helpers/common";
import { CloseTwoTone } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { primarybg, primarytext } from "../../common/primary";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FaInbox } from "react-icons/fa";
import "./Timeline.css";
import { fetchActivityLegend, fetchLocationLegend } from "../../slices/thunk";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanels(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{
        maxHeight: "70vh",
        // overflowY: "auto",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "14px 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ReportView = () => {
  const [data, setData] = useState<any>([]);
  const [date, setDate] = useState("");
  const [Loading, setLoading] = useState<boolean>(false);
  const { header1 } = authorize();
  const [value, setValue] = React.useState(0);
  const [formValid, setFormValid] = useState({
    location: false,
    activity: false,
  });

  const { locationLegend, activityLegend } = useSelector((state: any) => state.Dropdown);
  const { userType } = useSelector((state: any) => state.Login);
  const dispatch = useDispatch()
  const { shiftStartTime } = useSelector((state: any) => state.Org);

  const { loading } = useSelector(
    (state: any) => state.PSConfig
  );

  const [openModal, setOpenModal] = useState(false);
  const org = useSelector((state: any) => state.Login.organization);
  const handleSubmit = async () => {
    if (!date) {
      toast.warning("Please select a date.", {});
      return;
    }
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/config/getByDateShow/${date}/${org}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        setData(response.data.data);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let Q15RegistryData = {
    location: "",
    activity: "",
    remarks: "",
    reason: "",
    q15Date: "",
    pid: "",
    organization: org,
  };

  const [selectedSlotData, setSelectedSlotData] = useState<any>(null);
  const { orgData } = useSelector((state: any) => state.Org)
  let [inputQ15Data, setInputQ15Data] = useState<any>(Q15RegistryData);
  const [noOfShift, setNoOfShift] = useState<any>(0);
  let [lo, setlo] = useState<any>('');
  let [ac, setac] = useState<any>('');
  const [patient, setPatient] = useState("");
  const [item1, setitem1] = useState<any>('')

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleIconClick = (
    iconId: any,
    patient: any,
    item: any,
    location: any
  ) => {
    setInputQ15Data(() => ({
      q15Date: item?.q15Date,
      pid: item.patientId
    }))
    setitem1(item)

    const iconData: any = data.find((items: any) => items.patient === patient);
    setPatient(patient);
    console.log(iconData.slotData.find((k: any) => k.q15Slot === iconId))
    setSelectedSlotData(
      iconData.slotData.find((k: any) => k.q15Slot === iconId)
    );
    setInputQ15Data(iconData.slotData.find((k: any) => k.q15Slot === iconId));
    setOpenModal(true);

    let afterSelected = iconData.slotData.find(
      (k: any) => k.q15Slot === iconId
    );
    const savedUsername = localStorage.getItem("userDetailUsername");
    const filterLocation = locationLegend?.find(
      (data: any) => data?.value === afterSelected?.location
    );
    setlo(filterLocation)

    let filterActivity = activityLegend?.find(
      (data: any) => data?.value === afterSelected?.activity
    );
    setac(filterActivity)
    setInputQ15Data(() => ({
      remarks: afterSelected?.remarks ? afterSelected?.remarks : '',
      enteredBy: savedUsername,
      reason: afterSelected?.reason ? afterSelected.reason : "",
      activity: filterActivity?.value ? filterActivity.value : "",
      location: filterLocation?.value ? filterLocation.value : "",
    }))
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValid(() => ({
      location: false,
      activity: false,
    }));
  };

  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }
    return 24 / duration; // Return the number of shifts
  };

  useEffect(() => {
    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    setNoOfShift(numShift);
  }, []);

  useEffect(() => {
    fetchLocationLegend(dispatch, org)
    fetchActivityLegend(dispatch, org)
  }, [org]);

  const handleTimeSlotChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeInputs = (e: any, identifyer: any) => {
    let value = e.target.value;
    if (identifyer === "location") {
      if (value === "") {
        inputQ15Data.location = value;

        setFormValid((prev) => ({
          ...prev,
          location: true,
        }));
      } else {
        inputQ15Data.location = value;

        setFormValid((prev) => ({
          ...prev,
          location: false,
        }));
      }
      const filterLocation = locationLegend?.find(
        (data: any) => data?.value === value
      );
      setlo(filterLocation)
      setInputQ15Data({ ...inputQ15Data });
    }

    if (identifyer === "activity") {
      inputQ15Data.activity = value;
      if (value === "") {
        inputQ15Data.activity = value;
        setFormValid((prev) => ({
          ...prev,
          activity: true,
        }));
      } else {
        inputQ15Data.activity = value;
        setFormValid((prev) => ({
          ...prev,
          activity: false,
        }));
      }
      let filterActivity = activityLegend?.find(
        (data: any) => data?.value === value
      );
      setac(filterActivity)
      setInputQ15Data({ ...inputQ15Data });
    }
  };

  const handleUpdateConfig = async () => {
    let valid = true;
    let formData = {
      ...inputQ15Data,
      q15Time: formatTimeRange(selectedSlotData?.q15Time),
      q15Slot: selectedSlotData?.q15Slot,
      shiftIncharge: selectedSlotData?.shiftIncharge,
      q15Date: item1?.q15Date,
      pid: item1.patientId,
      organization: org,
      shift: `Shift-${String.fromCharCode(65 + value)}`,
      location: lo.key,
      activity: ac.key,
    };
    if (inputQ15Data.location === "") {
      valid = false;
      setFormValid((prev) => ({
        ...prev,
        location: true,
      }));
    }

    if (inputQ15Data.activity === "") {
      valid = false;
      setFormValid((prev) => ({
        ...prev,
        activity: true,
      }));
    }

    if (valid) {
      try {
        setLoading(true);

        const { header1 } = authorize();
        const response = await axios.post(
          `${baseURL}/config/register`,
          formData,
          { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          handleCloseModal();
          handleSubmit();
          setLoading(false);

          setFormValid(() => ({
            location: false,
            activity: false,
          }));
        } else {
          console.error("Error:", response.data.message);
          toast.error(response.data.message.description);
          setLoading(false);

        }
      } catch (error: any) {
        console.error("API Request Error:", error);
        toast.error(error);
        setLoading(false);

      }
    } else {
      toast.error("Please enter the data !");
      setLoading(false);

    }
  };

  const generateTimeSlots = (shiftIndex: number) => {
    const startHourInt = parseInt(shiftStartTime.split(":")[0], 10);
    const duration = Number(orgData?.shift?.duration);
    const slots = [];

    for (let i = 0; i < duration; i++) {
      const currentHour = (startHourInt + (shiftIndex * duration) + i) % 24;
      const startHour = formatTo12HourNoAmPm(currentHour);
      const endHour = formatTo12HourWithAmPm((currentHour + 1) % 24);
      slots.push(`${startHour}-${endHour}`);
    }
    return slots;
  };

  return (
    <>
      {Loading && <Loader />}
      {loading && <Loader />}

      <div className="p-2" style={{ height: '91vh', overflow: 'hidden', }} >
        <div className="row summary-page_row d-flex mt-1 justify-content-center align-items-center pl-5 " style={{ height: '12vh' }} >
          <div className="col-md-3">
            <DatePicker
              label={"Enter Date"}
              format="MM-DD-YYYY"
              onChange={(date: any) => {
                setDate(formatDateToYYYYMMDD(date));
              }}
            />
          </div>
          <div className="col-md-2">
            <Button className="calender_submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          <div className="col-md-2 d-flex align-items-center">
            <h5 className="mb-0">Q15 Summary</h5>
          </div>
          <div className="col-md-5 d-flex justify-content-end gap-3 align-items-center">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#355c7d52", marginRight: "5px" }}
              />
              <span>Done</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#426cc3", marginRight: "5px" }}
              />
              <span>No Proximity</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#ff7171", marginRight: "5px" }}
              />
              <span>Missing</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#edb405", marginRight: "5px" }}
              />
              <span>Late</span>
            </div>
          </div>
        </div>
        <div style={{ height: '77vh', overflowY: 'hidden' }}>
          <TabContext value={value}>
            <Box sx={{
              borderBottom: 1, borderColor: "divider", display: "flex",
              justifyContent: "center"
            }}>
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTimeSlotChange}
                aria-label="lab API tabs example"
                sx={{
                  maxWidth: "80%", // Adjust the width as needed
                  justifyContent: "center", // Center the tabs inside the TabList
                }}
              >
                {
                  Array.from({ length: noOfShift }).map((_, index: number) => (
                    <Tab label={`Shift-${String.fromCharCode(65 + index)}`} value={index}
                      onClick={() => handleChange(index)}
                    />
                  ))}
              </TabList>
            </Box>
            <div style={{ height: '70vh', overflow: 'hidden' }}>
              {
                Array.from({ length: noOfShift }).map((_, index: number) => (
                  <TabPanels value={value} index={index} >
                    {data?.length > 0 ? (
                      <TableContainer component={Paper} sx={{ width: "99%" }}>
                        <Table size="small" sx={{ borderSpacing: 0 }}>
                          <TableHead
                            sx={{
                              backgroundColor: "#f6f5f599",
                              borderTop: "1px groove #c0c1c2",
                              borderBottom: "1px groove #c0c1c2",
                              height: '5vh'
                            }}
                          >
                            <TableRow
                              sx={{
                                backgroundColor: "#f6f5f599",
                                borderTop: "1px groove #c0c1c2",
                                borderBottom: "1px groove #c0c1c2",
                              }}
                            >
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  color: primarytext,
                                  borderRight: "1px groove",
                                }}
                              >
                                Patient
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  color: primarytext,
                                  borderRight: "1px groove",
                                }}
                              >
                                Bed Number
                              </TableCell>
                              {generateTimeSlots(index).map((slot, i) => (
                                <TableCell
                                  sx={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    padding: 0,
                                    color: primarytext,
                                    whiteSpace: "nowrap",
                                    borderRight: "1px groove",
                                  }}
                                  key={i}
                                >
                                  {slot}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody className='hidden-scrollbar' style={{ height: '60vh', overflowY: 'scroll'}}>
                            {data?.map((item: any) => (
                              <TableRow sx={{ fontSize: "9px" }} key={item.id}>
                                <TableCell
                                  sx={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    lineHeight: "1.2",
                                    borderRight: "1px groove",
                                    
                                  }}
                                >
                                  <div className="d-flex justify-content-center">
                                    <p style={{ paddingTop: "12px" }}>{item.patient}</p>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    lineHeight: "1.2",
                                    borderRight: "1px groove",
                                  }}
                                >
                                  <div className="d-flex justify-content-center">
                                    <p style={{ color: "#000", paddingTop: "12px" }}>
                                      {item.bed}
                                    </p>
                                  </div>
                                </TableCell>
                                {generateTimeSlots(index).map((slot, i) => {
                                  const currentHour = (parseInt(shiftStartTime.split(":")[0], 10) + (index * Number(orgData?.shift?.duration)) + i) % 24;
                                  const slotId = `${currentHour < 10 ? '0' + currentHour : currentHour}`; // Ensure leading zero

                                  const iconData: any = data.find(
                                    (items: any) =>
                                      items.patient === item.patient
                                  );
                                  const slotData = iconData.slotData.find(
                                    (k: any) => k.q15Slot === slotId
                                  );
                                  const color = slotData?.proximityStatus
                                    ? "#426cc3"
                                    : slotData?.slotLateEntry
                                      ? "#edb405"
                                      : slotData?.slotMissed
                                        ? "#ff7171"
                                        : !slotData
                                          ? "#ff7171"
                                          : "#355c7d52";
                                  return (
                                    <TableCell
                                      sx={{
                                        fontSize: "9px",
                                        padding: 0,
                                        borderRight: "1px groove",
                                      }}
                                      key={i}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ justifyContent: "center", gap: "4px" }}
                                      >
                                        {["A", "B", "C", "D"].map((iconPrefix) => {
                                          const iconId = `${iconPrefix}${slotId}`;
                                          const iconData: any = data.find(
                                            (items: any) =>
                                              items.patient === item.patient
                                          );
                                          const slotData = iconData.slotData.find(
                                            (k: any) => k.q15Slot === iconId
                                          );
                                          const color = slotData?.proximityStatus
                                            ? "#426cc3"
                                            : slotData?.slotLateEntry
                                              ? "#edb405"
                                              : slotData?.slotMissed
                                                ? "#ff7171"
                                                : !slotData
                                                  ? "#ff7171"
                                                  : "#355c7d52";
                                          return (
                                            <FontAwesomeIcon
                                              key={iconId}
                                              icon={faCircle}
                                              style={{
                                                marginRight: 1,
                                                color: color,
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                handleIconClick(
                                                  iconId,
                                                  iconData.patient,
                                                  item,
                                                  iconData.location || ""
                                                );
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body1" className="table_empty">
                        <FaInbox className="empty_svg" />
                        No Data found for the selected Date
                      </Typography>
                    )}
                  </TabPanels>
                ))}
            </div>

          </TabContext>
        </div>

        <Dialog
          maxWidth={"lg"}
          PaperProps={{
            sx: {
              width: "595px",
              paddingBottom: "20px",
              zIndex: 1,
            },
          }}
          className="p-5"
          open={openModal}
          onClose={handleCloseModal}
        >
          <div>
            <DialogTitle sx={{ height: "45px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p style={{ fontWeight: "bold", color: "#717171" }}>
                    {patient}
                  </p>
                </div>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseTwoTone />
                </IconButton>
              </div>
            </DialogTitle>
            <hr />
            <DialogContent>
              <DialogContentText>
                <div>
                  {selectedSlotData ? (
                    <>
                      <div className="row ">
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.q15Time}
                              disabled={true}
                              style={{ width: "103.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Q15 Time"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.incharge}
                              disabled={true}
                              style={{ width: "96.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Incharge"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.enteredBy}
                              disabled={true}
                              style={{ width: "90.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Entered By"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 pt-3">
                          <div>
                            <FormControl
                              fullWidth
                              style={{ width: "95%" }}
                              variant="outlined"
                            >
                              <InputLabel color="primary">
                                Location Legend
                              </InputLabel>
                              <Select
                                error={formValid.location}
                                color="primary"
                                size="medium"
                                label="Location Legend"
                                name="q15Location"
                                value={inputQ15Data?.location}
                                disabled={userType === "Staff"}
                                onChange={(e) => {
                                  handleChangeInputs(e, "location");
                                }}
                              >
                                {locationLegend.map(
                                  (newData: any, i: number) => {
                                    return (
                                      <MenuItem key={i} value={newData.value}>
                                        {newData.value}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              <FormHelperText />
                              {formValid.location && (
                                <span className="form-error">
                                  Please enter location
                                </span>
                              )}
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-7 pt-3">
                          <FormControl
                            style={{ width: "94.5%" }}
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel color="primary">
                              Activity Legend
                            </InputLabel>
                            <Select
                              color="primary"
                              error={formValid.activity}
                              size="medium"
                              label="Activity Legend"
                              name="q15Activity"
                              value={inputQ15Data.activity}
                              disabled={userType === "Staff"}
                              onChange={(e) => {
                                handleChangeInputs(e, "activity");
                              }}
                            >
                              {activityLegend.map(
                                (newData: any, i: number) => {
                                  return (
                                    <MenuItem key={i} value={newData.value}>
                                      {newData.value}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                            <FormHelperText />
                            {formValid.activity && (
                              <span className="form-error">
                                Please enter activity
                              </span>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-md-12  pt-3">
                          <TextField
                            value={inputQ15Data.remarks}
                            onChange={(e) => {
                              inputQ15Data.remarks = e.target.value;
                              setInputQ15Data({ ...inputQ15Data });
                            }}
                            style={{ width: "96.5%" }}
                            multiline
                            rows={2}
                            color="primary"
                            variant="outlined"
                            type="text"
                            label="Notes/remarks"
                            disabled={userType === "Staff"}
                            size="medium"
                            margin="none"
                            required
                          />
                        </div>
                        {selectedSlotData?.reason ? (
                          <div className="col-md-12  pt-3">
                            <TextField
                              value={inputQ15Data.reason}
                              onChange={(e) => {
                                inputQ15Data.reason = e.target.value;
                                setInputQ15Data({ ...inputQ15Data });
                              }}
                              style={{ width: "96.5%" }}
                              multiline
                              rows={2}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Beacon Scanning Override"
                              size="medium"
                              disabled={userType === "Staff"}
                              margin="none"
                              required
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <p>No Data found for the slot</p>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {userType !== "Staff" && <div
                className="d-flex gap-3 justify-content-center align-items-center"
                style={{ height: "5px" }}
              >
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: primarybg,
                    borderColor: "transparent",
                  }}
                  onClick={handleUpdateConfig}
                >
                  Save
                </Button>
              </div>}
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
};
export default ReportView;