import React, { useEffect, useState } from 'react'
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { primarybg, secondarybt } from '../../common/primary';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatient, getAllStaff } from '../../slices/thunk';
import { toast } from 'react-toastify';
import moment from 'moment';
import { authorize } from '../../helpers/common';
import Loader from '../../components/loader/Loader';
import './Reports.css'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getActivityLegend, getLocationLegend } from '../../slices/Reports/thunk';
import printImage from '../../assets/images/printer.svg'
import ReportSheetView from '../q15Report/ReportSheetView';
import EyeSightVIew from './DetailReportView/EyeSightVIew';
import OneToOneView from './DetailReportView/OneToOneView';
import DetailReportView from './DetailReportView/DetailReportView';
import Q15DetailView from './DetailReportView/Q15DetailView';
import Q15DetailReportView from './DetailReportView/Q15DetailReportView';

interface addpatient {
    patientid?: any
    startDate?: any
    endDate?: any
    staffid?: any
}

const DetailReport = () => {
    const org = useSelector((state: any) => state.Login.organization)
    const [Q15tabcontrol, setQ15Tabcontrol] = useState<boolean>(false)
    const [onetoonetabcontrol, setOnetooneTabcontrol] = useState<boolean>(false)
    const [Eyetabcontrol, setEyeTabcontrol] = useState<boolean>(false)
    const [oneHourControl, setOneHourControl] = useState<boolean>(false)
    const [value, setValue] = useState<any>('1');
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    };
    const dispatch = useDispatch<any>()
    const { staffData } = useSelector((state: any) => state.Staff);
    const { patientData } = useSelector((state: any) => state.Patient);
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    let [getStaffData, setStaffData] = useState(new Array<any>());
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    const [addPatientName, setAddPatientName] = useState<any>('')
    let [data, setData] = useState(new Array<any>());
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [newInputData, setNewInputData] = useState<addpatient>();
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const [Q15ReportData, setQ15ReportData] = useState<any>('')
    const [onetooneReportData, setonetooneReportData] = useState<any>([])
    const [EyesightReportData, setEyesightReportData] = useState<any>([])
    const [OneHourReportData, setOneHourReportData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false);
    const [legendcontrol, setLegendControl] = useState<boolean>(false)
    const [OrgName, setOrgName] = useState<any>('')
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    const [viewContent, setViewContent] = useState<boolean>(true)
    const [checkBox, setCheckBox] = useState<boolean>(false);
    const [checkLegend, setCheckLegend] = useState<boolean>(false)
    const [reportViewContent, setReportViewContent] = useState<any>('')
    const [addStaffName, setAddStaffName] = useState<any>('')
    const [loginStaffName, setLoginStaffName] = useState<any>('')

    const [timeFormat, setTimeFormat] = useState<boolean>(false)

    const handleTimeFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeFormat(e.target.checked)
    }
    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            setLegendControl(e.target.checked)
        }
    }
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(e.target.checked);
    };
    useEffect(() => {
        if (disabled) {
            setLegendControl(disabled)
        }
    }, [disabled])

    const { useDetailProfile, userData } = useSelector((state: any) => state.Login);
    const decreaseOneMinute = (timeString: any) => {
        // Split the time string to extract hours and minutes
        if (!timeString) return
        const [hours, minutes] = timeString?.split(':').map(Number);

        // Create a new Date object for today with the given time
        let date = new Date();
        date.setHours(hours, minutes, 0); // Set the time (HH:MM:SS)

        // Subtract 1 minute
        date.setMinutes(date?.getMinutes() - 1);

        // Format the new time back to HH:mm format
        const newHours = String(date.getHours())?.padStart(2, '0');
        const newMinutes = String(date.getMinutes())?.padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    }
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d?.getHours()))?.padStart(2, '0');
        let mins = String((d?.getMinutes()))?.padStart(2, '0');
        return `${hours}:${mins}`;
    }

    useEffect(() => {
        const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
            let slotStart = new Date(fromTime)?.valueOf();
            let slotEnd = new Date(fromTime)?.valueOf() + slotLength * 1000;
            let endEpoch = new Date(toTime)?.valueOf();
            let ob = [];
            for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                ob?.push(formatDate(slotStart));
                slotStart = slotEnd;
            }
            return ob;
        }

        const formatDate = (timestamp: number) => {
            const date = new Date(timestamp);
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        }

        const from = "2022-05-25 " + shiftStartTime;
        const to = "2022-05-26 " + shiftStartTime;
        const slotLength = 15 * 60;
        var r = createTimeSlots(from, to, slotLength);
        setNewTimeSlot(r);
    }, [shiftStartTime]);
    useEffect(() => {
        if (!orgData) return
        setOrgName(orgData.organizationdetails[0].name)
    }, [orgData])
    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
    }, [org])
    useEffect(() => {
        getAllStaff(dispatch, org, -1, '')
        getAllPatient(dispatch, org, -1, '');
    }, [org])
    useEffect(() => {
        if (staffData) {
            setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
            setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k?.name[0]?.given + " " + k?.name[0]?.family }))
        } else {
            // setAddNewPatient([])
            setAddNewStaff([])
            setStaffData([])
        }
    }, [staffData, addNewPatient])

    useEffect(() => {
        if (!Array.isArray(patientData) && useDetailProfile.userType === "Admin") {
            setAddNewPatient(patientData?.content);
            setData(
                patientData?.content?.map((k: any) => {
                    return k?.basicDetails[0]?.name[0]?.given + " " + k?.basicDetails[0]?.name[0]?.family;
                })
            );
        } else if (useDetailProfile.userType === "Staff") {
            setLoginStaffName(useDetailProfile.name[0].given + " " + useDetailProfile.name[0].family)
            setAddStaffName(useDetailProfile.name[0].given + " " + useDetailProfile.name[0].family)
            const handelPatientDropDown = async () => {
                try {
                    const response = await axios.get(`${baseURL}/report/patients?organization=${org}&staffId=${useDetailProfile.id}`)
                    if (response.data.message.code === successCode) {
                        setAddNewPatient(response?.data?.data);
                        setData(response.data.data.map((k: any) => {
                            return k.patientName
                        }));
                    } else {
                        setAddNewPatient([])
                        setData([])
                    }
                } catch (error: any) {
                    console.error("API ERROR: ", error)
                }
            }
            handelPatientDropDown()
        } else {
            setAddNewPatient([])
            setData([])
        }
    }, [patientData]);
    const handleSubmit = async () => {
        setLoading(true)
        let newErrors = {
            patientid: !newInputData?.patientid,
            startDate: !newInputData?.startDate,
        };
        setNameError(newErrors);
        const hasErrors = Object.values(newErrors)?.some(error => error);
        if (hasErrors) {
            setLoading(false)
            toast.error("Please Fill Required Field")
            return;
        }
        const newDate = new Date()
        // const staffId:string = newInputData?.staffid !== '' ? newInputData?.staffid : "";
        const staffId = newInputData?.staffid
            ? newInputData.staffid
            : useDetailProfile.userType === "Staff"
                ? useDetailProfile.id
                : "";

        // const endDateRange = newInputData?.endDate ? moment(newInputData?.endDate).format("YYYYMMDD") : '' 
        //  /report/patientReports?endDate=20250129&organization=pxP7lcDGgG&patientId=sdfgsdg&staffId=dhdf&startDate=20250101
        //  /report/patientReports?endDate=20250129&organization=pxP7lcDGgG&patientId=sdfgsdg&startDate=20250101
        // `${baseURL}/report/patientReports?endDate=${moment(newDate).format("YYYYMMDD")}&organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`
        const apiDomian = staffId
            ? `${baseURL}/report/patientReports?Details=no&endDate=${moment(newInputData?.endDate ? newInputData?.endDate : newDate).format("YYYYMMDD")}&organization=${org}&patientId=${newInputData?.patientid}&staffId=${staffId}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`
            : `${baseURL}/report/patientReports?Details=no&endDate=${moment(newInputData?.endDate ? newInputData?.endDate : newDate).format("YYYYMMDD")}&organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`

        try {
            const { header1 } = authorize()
            const response = await axios.get(apiDomian, { headers: header1 })
            if (response.data.message.code === successCode) {
                setLoading(false)
                setCheckBox(disabled ? true : false)
                setCheckLegend(legendcontrol ? true : false)
                if (response.data.OneHour) {
                    setOneHourControl(response.data.OneHour ? true : false)
                    setValue('4')
                    setOneHourReportData(response.data.OneHourData)
                } else {
                    setOneHourReportData([])
                }
                if (response.data.eyeSight) {
                    setEyeTabcontrol(response.data.eyeSight ? true : false)
                    setValue('3')
                    setEyesightReportData(response.data.eyeSightData)
                } else {
                    setEyesightReportData([])
                    setEyeTabcontrol(false)
                }

                if (response.data.oneToOne) {
                    setOnetooneTabcontrol(response.data.oneToOne ? true : false)
                    setValue('2')
                    setonetooneReportData(response.data.oneToOneData)
                } else {
                    setonetooneReportData([])
                    setOnetooneTabcontrol(false)
                }

                if (response.data.q15) {
                    setQ15Tabcontrol(response.data.q15 ? true : false)
                    setQ15ReportData(response.data.q15Data)
                    setValue('1');
                } else {
                    setQ15ReportData([])
                    setQ15Tabcontrol(false)
                }
            } else {
                setCheckBox(false)
                setCheckLegend(false)
                setLoading(false)
                setQ15Tabcontrol(false)
                setOnetooneTabcontrol(false)
                setEyeTabcontrol(false)
                setQ15ReportData([])
                setonetooneReportData([])
                setonetooneReportData([])
                setValue('0')
            }
        } catch (error: any) {
            setLoading(false)
            console.error("API ERROR:", error)
        }

    }
    const handleQ15AllDownload = async (ReportData: any) => {
        if (ReportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < ReportData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(ReportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        setLoading(false);
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.q15Date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientName}-${moment(reports.q15Date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    const handleAllDownload = async (reportData: any) => {
        if (!reportData) return;

        try {
            setLoading(true);
            for (let i = 0; i < reportData.length; i++) {
                await generatePDF(reportData[i]);
            }
        } catch (error) {
            console.error("Download Error: ", error);
        } finally {
            setLoading(false);
        }
    };

    async function generatePDF(reports: any) {
        const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);
        if (sections.length === 0) {
            console.error('No sections found for the given selector');
            return;
        }

        const pdf = new jsPDF('p', 'px', 'a4', true);
        const margin = 20;
        const pageHeight = pdf.internal.pageSize.height;
        const images: any = [];

        const loadImages = async (section: any) => {
            return new Promise((resolve: any) => {
                html2canvas(section, {
                    useCORS: true,
                    logging: true,
                    scale: 2,
                    backgroundColor: null
                }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    if (imgData && imgData !== 'data:,') {
                        images.push(imgData);
                    } else {
                        console.error('Invalid image data generated');
                    }
                    resolve();
                }).catch((error) => {
                    console.error('Error rendering section:', error);
                    resolve();
                });
            });
        };

        await Promise.all(Array.from(sections).map(loadImages));

        if (images.length === 0) {
            console.error('No valid images captured for PDF');
            return;
        }

        images.forEach((imgData: any, idx: any) => {
            if (idx > 0) pdf.addPage();
            pdf.addImage(imgData, 'PNG', margin, margin, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
        });

        pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
    }

    const handlePrint = (elementId: any) => {
        const printElement = document.getElementById(elementId);
        if (!printElement) {
            console.error("Element not found for printing:", elementId);
            return;
        }

        const printWindow = window.open('', '_blank');
        if (printWindow) {
            printWindow.document.write('<html><head><title>Print</title>');
            printWindow.document.write('<style>img{max-width:100%;height:auto;}</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(printElement.innerHTML);
            printWindow.document.write('</body></html>');

            printWindow.document.close();
            printWindow.focus();

            printWindow.onload = () => {
                printWindow.print();
                printWindow.close();
            };
        } else {
            console.error("Failed to open print window");
        }
    };
    return (
        <div className='hidden-scrollbar px-2' style={{ overflow: 'hidden', height: '91vh' }}>
            {loading && <Loader />}
            <div style={{ height: '5vh' }}>
                <h5 className='' style={{  }}>Detail Report's</h5>
            </div>

            <div hidden={!viewContent} style={{ height: '86vh' }}>
                <div className='mt-3 d-flex '  >
                    <div className='col-md-2' >
                        { /* Select Patient section start */}
                        <header>
                            <div className='d-flex flex-column gap-3 px-1' >
                                <div className='pt-2'>
                                    <Autocomplete
                                        options={data}
                                        getOptionLabel={(option) => option}
                                        value={addPatientName}
                                        onChange={(e, v) => {
                                            setAddPatientName(v);
                                            if (useDetailProfile?.userType !== "Staff") {
                                                let newPatientData = addNewPatient && addNewPatient?.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                                                setNewInputData({ ...newInputData, patientid: newPatientData[0] });
                                                setNameError({ ...nameError, patientid: false })
                                            } else {
                                                let newPatientData = addNewPatient && addNewPatient?.filter((m: any) => m.patientName === v);
                                                setNewInputData({ ...newInputData, patientid: newPatientData[0]?.patientId });
                                                setNameError({ ...nameError, patientid: false })
                                            }
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (

                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                title={addPatientName}
                                                label="Patient Name"
                                                placeholder="Patient Name"
                                                margin="none"
                                                fullWidth
                                                error={!!nameError.patientid}
                                            // helperText={nameError.patientid ? 'sad' : '' }
                                            />
                                        )}
                                    />
                                </div>
                                <div className=''>
                                    <Autocomplete
                                        options={getStaffData}
                                        getOptionLabel={(option) => option}
                                        value={addStaffName}
                                        disabled={useDetailProfile.userType === "Staff"}
                                        defaultValue={useDetailProfile.userType === "Staff" ? loginStaffName : ""}
                                        onChange={(e, v) => {
                                            setAddStaffName(v);
                                            const selectedStaff = addNewStaff.find(
                                                (staff: any) =>
                                                    staff.name[0].given + " " + staff.name[0].family === v
                                            );
                                            setNewInputData({
                                                ...newInputData,
                                                staffid: selectedStaff ? selectedStaff.id : "",
                                            });
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Staff Name"
                                                placeholder="Staff Name"
                                                margin="none"
                                                fullWidth
                                            />
                                        )}
                                    />

                                </div>
                                <div className=''>
                                    <DatePicker
                                        className=""
                                        label="Start Date"
                                        // value={newInputData?.startDate ? newInputData?.startDate : null}
                                        onChange={(event: any) => {
                                            // const d = new Date(event)
                                            // let w = d.getFullYear().toString()
                                            // if (w.length !== 4) {
                                            setNewInputData({ ...newInputData, startDate: event?.$d })
                                            setNameError({ ...nameError, startDate: false })
                                            // }
                                        }}
                                        sx={{
                                            border: nameError.startDate ? "1px solid red" : "",
                                            borderRadius: "5px",
                                            width: '100%'
                                        }}

                                    />
                                </div>
                                <div className=''>
                                    <DatePicker
                                        className=""
                                        label="End Date"
                                        onChange={(event: any) => {
                                            setNewInputData({ ...newInputData, endDate: event?.$d })
                                        }}
                                        sx={{
                                            width: '100%'
                                        }}
                                    />
                                </div>
                                <div className=''>
                                    <FormControlLabel
                                        control={<Checkbox checked={disabled} onChange={handleCheck} />}
                                        label="Proximity"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                                        label={'Legends Key'}
                                    />
                                    <FormControlLabel
                                        // style={{ float: 'right', marginLeft: '5px', width: '200px' }}
                                        control={<Checkbox checked={timeFormat} onChange={handleTimeFormat} />}
                                        label={'24Hour Time Format'}
                                    />
                                </div>
                                <div className=' d-flex align-items-center justify-content-center '>
                                    <button className="btn" style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px', color: 'white' }} onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </header>
                        { /* Select Patient section End */}
                    </div>
                    {
                        (onetoonetabcontrol || Eyetabcontrol || Q15tabcontrol || oneHourControl) ?
                            <div className=' col-md-10 '>
                                {
                                    (onetoonetabcontrol || Eyetabcontrol || Q15tabcontrol || oneHourControl) && (
                                        <>
                                            <section>
                                                <Box sx={{ height: '84vh' }}>
                                                    <TabContext value={value}>
                                                        <Box>
                                                            <div className='row'>
                                                                <div className="col-md-8">
                                                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                                        {Q15tabcontrol && <Tab label="Q15-Report" value="1" />}
                                                                        {onetoonetabcontrol && <Tab label="One-to-One" value="2" />}
                                                                        {Eyetabcontrol && <Tab label="EyeSight" value="3" />}
                                                                        {oneHourControl && <Tab label="One Hour" value="4" />}
                                                                    </TabList>
                                                                </div>
                                                                <div className="col-md-4 p-2">
                                                                    {/* <div className=''> */}
                                                                    <button className='btn ' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right' }} onClick={() => {
                                                                        //  handleAllDownload(Q15ReportData)
                                                                        setReportViewContent(value === '2' ? onetooneReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime) : value === '3' ? EyesightReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime) : [])
                                                                        setViewContent(false)
                                                                    }}>View</button>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                        </Box>
                                                        <div className='hidden-scrollbar' style={{ height: '76vh', overflowY: 'scroll' }}>
                                                            <TabPanel value='1'>
                                                                <>
                                                                    {(Q15ReportData !== null && Q15ReportData.length > 0) && <Q15DetailView ReportData={Q15ReportData} setLoading={setLoading} legendcontrol={legendcontrol} checkPrecaution={'Q15'} checkBox={disabled} timeFormat={timeFormat} />}
                                                                </>
                                                            </TabPanel>
                                                            <TabPanel value='2'>
                                                                <>
                                                                    {onetooneReportData !== null && onetooneReportData.length > 0 && <OneToOneView onetooneReportData={onetooneReportData} selectShiftView={undefined} selectShift={undefined} nextDate={undefined} checkPrecaution={'1:1'} checkBox={disabled} legendcontrol={legendcontrol} setLoading={setLoading} timeFormat={timeFormat} />}
                                                                </>
                                                            </TabPanel>
                                                            <TabPanel value='3'>
                                                                <>
                                                                    {(EyesightReportData !== null && EyesightReportData.length > 0) && <EyeSightVIew EyesightReportData={EyesightReportData} selectShiftView={undefined} selectShift={undefined} nextDate={undefined} checkPrecaution={'Eyesight'} setLoading={setLoading} checkBox={disabled} legendcontrol={legendcontrol} timeFormat={timeFormat} />}
                                                                </>
                                                            </TabPanel>
                                                            <TabPanel value='4'>
                                                                <>
                                                                    {(OneHourReportData !== null && OneHourReportData.length > 0) && <Q15DetailView ReportData={OneHourReportData} setLoading={setLoading} legendcontrol={legendcontrol} checkPrecaution={'One-Hour'} checkBox={checkBox} timeFormat={timeFormat} />}
                                                                </>
                                                            </TabPanel>
                                                        </div>
                                                    </TabContext>
                                                </Box>
                                            </section>
                                        </>
                                    )
                                }
                            </div>
                            :
                            <div className='col-md-10 d-flex justify-content-center align-items-center ' >
                                <h5 style={{ fontSize: '16px', fontWeight: '600' }}>
                                    No Reports Found
                                </h5>
                            </div>
                    }
                </div>
            </div>
            <div className='' hidden={viewContent}>
                <div className='row gap-2 px-3 justify-content-end '>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => {
                        if (value === '1') {
                            handleQ15AllDownload(Q15ReportData)
                        } else {
                            handleAllDownload(reportViewContent)
                        }
                    }} >Download</button>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => { handlePrint(value === '1' ? 'Q15Report' : value === '2' ? 'OneToOneReport' : value === '3' ? 'EyeSightReport' : 'oneHourReports') }} ><img src={printImage} />Print</button>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => { setViewContent(true) }} >Close</button>
                </div>
                {
                    (value === '1' || value === '4') &&
                    <div className='px-4 hidden-scrollbar' id={`${value === "1" ? 'Q15Report' : 'oneHourReports'}`} style={{ overflow: 'auto', height: '75vh' }}  >
                        {
                            Q15ReportData !== null && Q15ReportData !== undefined && Q15ReportData.length > 0 && <Q15DetailReportView reportData={value === "1" ? Q15ReportData : OneHourReportData} checkPrecaution={value === "1" ? "Q15" : "One-Hour"} locationLegend={locationLegend} activityLegend={activityLegend} checkBox={checkBox} legendcontrol={legendcontrol} InxNum={undefined} oneHour={value === "1" ? false : true} timeFormat={timeFormat} logo={orgData.logo} />
                        }
                    </div>
                }
                {
                    reportViewContent.length > 0 &&
                    <div className='container-fluid px-2' id={`${value === '2' ? 'OneToOneReport' : value === '3' ? 'EyeSightReport' : ''}`}>
                        <DetailReportView reportData={reportViewContent} selectShiftView={undefined} checkBox={disabled} selectShift={undefined} nextDate={undefined} dateID={undefined} checkPrecaution={value === '2' ? "1:1" : "Eyesight"} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={legendcontrol} IndexNum={undefined} timeFormat={timeFormat} />
                    </div>
                }
            </div>

        </div>
    )
}

export default DetailReport