import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
interface DetailReportViewProps {
  reportData: any
  selectShiftView: any
  checkBox: any
  selectShift: any
  nextDate: any
  dateID: any
  checkPrecaution: string
  activityLegend: any
  locationLegend: any
  legendcontrol: boolean
  IndexNum: any
  timeFormat: any
}
const DetailReportView: React.FC<DetailReportViewProps> = ({ IndexNum, activityLegend, checkBox, checkPrecaution, dateID, legendcontrol, locationLegend, nextDate, reportData, selectShift, selectShiftView, timeFormat }) => {
  const [OrgName, setOrgName] = useState<any>('')
  const [reportDataFilter, setReportDataFilter] = useState<any>([])
  const { orgData } = useSelector((state: any) => state.Org)
  const { precaution } = useSelector((state: any) => state.Dropdown);
  useEffect(() => {
    if (!orgData) return
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])
  useEffect(() => {
    if (!reportData) return
    if (IndexNum !== undefined) {
      const filterData: any = reportData?.filter((j: any, inx: number) => inx === IndexNum)
      setReportDataFilter(filterData)
    } else {
      setReportDataFilter(reportData)
    }
  }, [reportData])

  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const formatChange = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    if (shiftTimeStart.isAfter(date2)) {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm") + " +1"
    } else {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm");
    }
  }

  const formatChange1 = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    const date1 = moment(`2025-01-08T00:00`)
    if (date2.isAfter(shiftTimeStart)) {
      return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    }
    else {
      if (date2.isAfter(date1)) {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3] + " +1"
      } else {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
      }
    }
  }
  const GetNextDate = (date: any) => {
    const validDate = moment(date);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      return moment(nextDay)?.format('MMM DD, YYYY')
    } else {
      console.error("");
    }
  }
  return (
    <div>
      {reportDataFilter !== null && reportDataFilter !== undefined && Array.isArray(reportDataFilter) && reportDataFilter?.map((data: any, inx: number) => {
        return (
          <>
            <div className='container-fluid overflow-auto' >
              {/* Sheet 1 */}
              <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                {data && <>
                  <div id="removePadding" style={{ textAlign: 'center' }} className="">
                    {orgData.logo && orgData.logo !== '' ? (
                      <img
                        src={orgData.logo}
                        alt=""
                        style={{ height: '60px', width: '60px' }}
                      />
                    ) : null}
                  </div>
                  <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                    {OrgName && OrgName}
                  </div>
                  <div id="removePadding" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '60%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                        <div id="removePadding" >
                          <strong>Date: </strong>
                          {data.date && moment(data.date).format("MMM DD, YYYY")}
                          {selectShiftView ? (selectShift === "Shift-C" && `, ${nextDate}`) : ''}
                        </div>
                        {selectShiftView && (
                          <div id="removePadding" >
                            <strong>Shift: </strong>
                            {selectShift}
                          </div>
                        )}
                      </div>
                      <div id="removePadding" className='' >
                        <strong>Patient Name: </strong>
                        {data.patientId}
                      </div>
                    </div>
                    <div className='d-flex row'>
                      <div id="removePadding" className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <strong>Room No: </strong>
                          {data.assignedBed && data.assignedBed.split('-')[0]}
                        </div>
                        <div>
                          <strong style={{ paddingRight: '12px' }}>Assigned Bed:</strong>
                          {data.assignedBed && data.assignedBed.split('-')[1]}
                        </div>
                      </div>
                      <div>
                        {
                          data.suicideRisk !== "" && data.suicideRisk !== null ? <><strong>SuicideRisk:</strong><span>{" "}{data?.suicideRisk}</span></> : <div></div>
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ border: '1px solid #C9C9C9' }}>
                    <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 0px', fontSize: '14px' }}>
                        <div>
                          CHECK PRECAUTIONS:
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Every 15 Minutes
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            1:1
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            One Hour
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Eyesight
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          width: '100%',
                          padding: '0px 10px',
                        }}
                      >
                        {precaution.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                          <div
                            className="form-check"
                            style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              disabled
                              checked={data?.precaution?.includes(item.value) || false}
                              style={{ padding: '7px', border: '1.2px solid black' }}
                            />
                            <label
                              className=""
                              style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                            >
                              {item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* ))} */}

                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>LOCATION CODES</div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                        {locationLegend && locationLegend?.map((item: any, inx: number) => (
                          <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                            {/* <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} /> */}
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              {item.key}{" - " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>ACTIVITY CODES</div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                          <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                              {inx + 1}.{" " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                  <>
                    <div className='' style={{ fontSize: '14px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                        <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                      <div style={{ width: '100%', fontSize: '14px' }}>
                        {data && data.configResponses?.map((t: any, ind: number) => {
                          return ind >= 0 && ind <= 31 && (<>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                              <div style={{ width: '29.2%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                {!timeFormat
                                  ? formatChange1(t?.timeSlot)
                                  : formatChange(t?.timeSlot)
                                }
                              </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{(t.activity && t.location) && t.activity + "/" + t.location}</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                            </div>
                          </>);
                        })}
                      </div>
                    </div>
                  </>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                    </div>
                    {
                      data?.shiftIncharge !== null ?
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>
                                {indx === 0 && (data?.[`shift${String.fromCharCode(65)}Incharge`] ?? "")}
                              </div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                        :
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                    }

                  </div>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                    </div>
                  </div>
                </>}
              </section>
              {/* Sheet 2 */}
              <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                {data && <>
                  <div id="removePadding" style={{ textAlign: 'center' }} className="">
                    {orgData.logo && orgData.logo !== '' ? (
                      <img
                        src={orgData.logo}
                        alt=""
                        style={{ height: '60px', width: '60px' }}
                      />
                    ) : null}
                  </div>
                  <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                    {OrgName && OrgName}
                  </div>
                  <div id="removePadding" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '60%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                        <div id="removePadding" >
                          <strong>Date: </strong>
                          {data.date && moment(data.date).format("MMM DD, YYYY")}
                          {selectShiftView ? (selectShift === "Shift-C" && `, ${nextDate}`) : ''}
                        </div>
                        {selectShiftView && (
                          <div id="removePadding" >
                            <strong>Shift: </strong>
                            {selectShift}
                          </div>
                        )}
                      </div>
                      <div id="removePadding" className='' >
                        <strong>Patient Name: </strong>
                        {data.patientId}
                      </div>
                    </div>
                    <div className='d-flex row'>
                      <div id="removePadding" className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <strong>Room No: </strong>
                          {data.assignedBed && data.assignedBed.split('-')[0]}
                        </div>
                        <div>
                          <strong style={{ paddingRight: '12px' }}>Assigned Bed:</strong>
                          {data.assignedBed && data.assignedBed.split('-')[1]}
                        </div>
                      </div>
                      <div>
                        {
                          data.suicideRisk !== "" && data.suicideRisk !== null ? <><strong>SuicideRisk:</strong><span>{" "}{data?.suicideRisk}</span></> : <div></div>
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ border: '1px solid #C9C9C9' }}>
                    <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 0px', fontSize: '14px' }}>
                        <div>
                          CHECK PRECAUTIONS:
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Every 15 Minutes
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            1:1
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            One Hour
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Eyesight
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          width: '100%',
                          padding: '0px 10px',
                        }}
                      >
                        {precaution.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                          <div
                            className="form-check"
                            style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              disabled
                              checked={data?.precaution?.includes(item.value) || false}
                              style={{ padding: '7px', border: '1.2px solid black' }}
                            />
                            <label
                              className=""
                              style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                            >
                              {item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* ))} */}

                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>LOCATION CODES</div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                        {locationLegend && locationLegend?.map((item: any, inx: number) => (
                          <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                            {/* <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} /> */}
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              {item.key}{" - " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>ACTIVITY CODES</div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                          <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                              {inx + 1}.{" " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                  <>
                    <div className='' style={{ fontSize: '14px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                        <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                      <div style={{ width: '100%', fontSize: '14px' }}>
                        {data && data.configResponses?.map((t: any, ind: number) => {
                          return ind >= 32 && ind <= 63 && (<>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                              <div style={{ width: '29.2%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                {!timeFormat
                                  ? formatChange1(t?.timeSlot)
                                  : formatChange(t?.timeSlot)
                                }
                              </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{(t.activity && t.location) && t.activity + "/" + t.location}</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                            </div>
                          </>);
                        })}
                      </div>
                    </div>
                  </>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                    </div>
                    {
                      data?.shiftIncharge !== null ?
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>
                                {/* {data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + indx)}`] ?? ""} */}
                                {t === 1 && (data?.[`shift${String.fromCharCode(66)}Incharge`] ?? "")}
                              </div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                        :
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                    }

                  </div>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                    </div>
                  </div>
                </>}
              </section>
              {/* Sheet 3 */}
              <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}3`}>
                {data && <>
                  <div id="removePadding" style={{ textAlign: 'center' }} className="">
                    {orgData.logo && orgData.logo !== '' ? (
                      <img
                        src={orgData.logo}
                        alt=""
                        style={{ height: '60px', width: '60px' }}
                      />
                    ) : null}
                  </div>
                  <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                    {OrgName && OrgName}
                  </div>
                  <div id="removePadding" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '60%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                        <div id="removePadding" >
                          <strong>Date: </strong>
                          {data.date && moment(data.date).format("MMM DD, YYYY")}
                          {selectShiftView ? (selectShift === "Shift-C" && `, ${nextDate}`) : ''}
                        </div>
                        {selectShiftView && (
                          <div id="removePadding" >
                            <strong>Shift: </strong>
                            {selectShift}
                          </div>
                        )}
                      </div>
                      <div id="removePadding" className='' >
                        <strong>Patient Name: </strong>
                        {data.patientId}
                      </div>
                    </div>
                    <div className='d-flex row'>
                      <div id="removePadding" className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <strong>Room No: </strong>
                          {data.assignedBed && data.assignedBed.split('-')[0]}
                        </div>
                        <div>
                          <strong style={{ paddingRight: '12px' }}>Assigned Bed:</strong>
                          {data.assignedBed && data.assignedBed.split('-')[1]}
                        </div>
                      </div>
                      <div>
                        {
                          data.suicideRisk !== "" && data.suicideRisk !== null ? <><strong>SuicideRisk:</strong><span>{" "}{data?.suicideRisk}</span></> : <div></div>
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ border: '1px solid #C9C9C9' }}>
                    <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 0px', fontSize: '14px' }}>
                        <div>
                          CHECK PRECAUTIONS:
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Every 15 Minutes
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            1:1
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            One Hour
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                            Eyesight
                          </label>
                          <div className="form-check" style={{ height: '15px' }}>
                            <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          width: '100%',
                          padding: '0px 10px',
                        }}
                      >
                        {precaution.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                          <div
                            className="form-check"
                            style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              disabled
                              checked={data?.precaution?.includes(item.value) || false}
                              style={{ padding: '7px', border: '1.2px solid black' }}
                            />
                            <label
                              className=""
                              style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                            >
                              {item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* ))} */}

                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>LOCATION CODES</div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                        {locationLegend && locationLegend?.map((item: any, inx: number) => (
                          <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                            {/* <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} /> */}
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              {item.key}{" - " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                      <div>ACTIVITY CODES</div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                          <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                              {inx + 1}.{" " + item.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                  <>
                    <div className='' style={{ fontSize: '14px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                        <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                      <div style={{ width: '100%', fontSize: '14px' }}>
                        {data && data.configResponses?.map((t: any, ind: number) => {
                          return ind >= 64 && ind <= 96 && (<>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                              <div style={{ width: '29.2%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                {!timeFormat
                                  ? formatChange1(t?.timeSlot)
                                  : formatChange(t?.timeSlot)
                                }
                              </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{(t.activity && t.location) && t.activity + "/" + t.location}</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                            </div>
                          </>);
                        })}
                      </div>
                    </div>
                  </>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                    </div>
                    {
                      data?.shiftIncharge !== null ?
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>
                                {/* {data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + indx)}`] ?? ""} */}
                                {data?.[`shift${String.fromCharCode(67 + indx)}Incharge`] ?? ""}
                              </div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                        :
                        <>
                          {[1, 2, 3, 4].map((t: any, indx: number) => (
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            </div>
                          ))}
                        </>
                    }

                  </div>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                      <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                      <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                    </div>
                  </div>
                </>}
              </section>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default DetailReportView