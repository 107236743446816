import React, { useEffect, useState } from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { primarybg, secondarybt } from '../../../common/primary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DetailReportView from './DetailReportView';
import { getActivityLegend, getLocationLegend } from '../../../slices/thunk';

interface EyeSightVIewProps {
    EyesightReportData: any
    setLoading: any
    checkBox: any
    legendcontrol: any
    checkPrecaution: any
    nextDate: any
    selectShift: any
    selectShiftView: any
    timeFormat: any
}
const EyeSightVIew: React.FC<EyeSightVIewProps> = ({ EyesightReportData, checkBox, checkPrecaution, legendcontrol, nextDate, selectShift, selectShiftView, setLoading, timeFormat }) => {
    const [eyeSightdata, setEyesightData] = useState<any>([])
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const org = useSelector((state: any) => state.Login.organization)
    const dispatch = useDispatch<any>()
    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
    }, [org])
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        if (EyesightReportData) {
            const filterData = EyesightReportData?.filter((data: any) => (data.shiftAIncharge !== "" || data.shiftBIncharge !== "" || data.shiftCIncharge !== "" || data.shiftDIncharge !== "") && data.configResponses.length > 0)
            setEyesightData(filterData)
        } else {
            setEyesightData([])
        }
    }, [EyesightReportData])

    return (
        <div>
            {
                eyeSightdata !== null && eyeSightdata !== undefined && eyeSightdata.length > 0 && eyeSightdata?.map((data: any, inx: number) => (
                    <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)} style={{ width: '100%' }}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: expanded === 'panelmain' + data.date ? secondarybt : '' }} expandIcon={<ArrowDropDownIcon />}>
                            <Typography >
                                <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                                    <div className='' style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                        {moment(data.date).format("MMM DD, YYYY")}
                                    </div>
                                </div>
                            </Typography>
                            <div>
                                <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                                    // handleSingltDownload(moment(data.date).format("DD-MM-YYYY"))
                                }}>Download</button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }} >
                                {
                                    eyeSightdata !== null && eyeSightdata !== undefined &&
                                    <DetailReportView reportData={eyeSightdata} selectShiftView={selectShiftView} checkBox={checkBox} selectShift={selectShift} nextDate={nextDate} dateID={undefined} checkPrecaution={checkPrecaution} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={legendcontrol} IndexNum={inx} timeFormat={timeFormat} />
                                }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
            }

        </div>
    )
}

export default EyeSightVIew