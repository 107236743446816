import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { primarybg } from '../common/primary';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'primereact/button';

interface AuthRoutesProps {
  children?: React.ReactNode;
  onAccess?: () => void; // Callback to notify parent component about access
}

const ProtectedAuth = ({ children, onAccess }: AuthRoutesProps) => {
  const { jwt } = useSelector((state: any) => state.Login);
  const location = useLocation();
  useEffect(() => {
    if (onAccess) {
      onAccess(); // Notify parent component about access
    }
  }, [onAccess]);
  const patient = location.pathname === '/patient-view';
  const staffView = location.pathname === '/sos-view';
  const view = patient || staffView
  const navigate = useNavigate()


  // else if (!localStorage.getItem('authStaff')) {
  //   return <Navigate to={{ pathname: '/login' }} />;
  // }

  useEffect(() => {
    const handlecheck = setInterval(() => {
      const usetype = localStorage.getItem('userType')
      if (!usetype) {
        sessionStorage.clear()
        clearInterval(handlecheck)
        navigate('/')
      }
    }, 1000)

  }, [])

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const idleLimit = 5 * 60 * 1000;

  const resetIdleTimer = () => {
    sessionStorage.setItem('idleTime', '0');
  };

  useEffect(() => {
    // if (view) return
    // alert('l')
    const storedIdleTime = parseInt(sessionStorage.getItem('idleTime') || '0', 10);
    if (storedIdleTime >= idleLimit) {
      setShowSessionExpiredModal(true);
    }

    const handleInteraction = () => {
      resetIdleTimer();
      sessionStorage.setItem('idleTime', '0'); // Reset idle time on interaction
    };

    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    const handleInterval = () => {
      const currentIdleTime = parseInt(sessionStorage.getItem('idleTime') || '0', 10);
      const newIdleTime = !view ? currentIdleTime + 1000 : 0; // Increment idle time by 1 second
      sessionStorage.setItem('idleTime', newIdleTime.toString());

      if (newIdleTime >= idleLimit) {
        setShowSessionExpiredModal(true);
      }
    }

    const idleInterval = setInterval(() => {
      handleInterval()
    }, 1000);

    return () => {
      clearInterval(idleInterval);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
    };
  }, []);

  useEffect(() => {
    const disableReload = (e: any) => {
      if (showSessionExpiredModal) {
        if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };

    const disableBeforeUnload = (e: any) => {
      if (showSessionExpiredModal) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('keydown', disableReload); // Disable F5
    window.addEventListener('beforeunload', disableBeforeUnload); // Disable reload button

    return () => {
      window.removeEventListener('keydown', disableReload); // Clean up F5 listener
      window.removeEventListener('beforeunload', disableBeforeUnload); // Clean up reload button listener
    };
  }, [showSessionExpiredModal]);

  const handleCloseModal = () => {
    setShowSessionExpiredModal(false);
    sessionStorage.setItem('idleTime', '0')
    // sessionStorage.clear()
  };
  const [count, setCount] = useState<number>(60)
  useEffect(() => {

    if (!showSessionExpiredModal) {
      setCount(60)
      return;
    }
    const countFun = setInterval(() => {
      setCount(prevCount => {
        if (prevCount <= 1) {
          clearInterval(countFun)
          sessionStorage.clear()
          handleCloseModal()
          return 0
        }
        return prevCount - 1
      });
    }, 1000);
    return () => clearInterval(countFun)
  }, [showSessionExpiredModal,])

  if (!sessionStorage.getItem('authStaff')) {
    if (view && localStorage.getItem('userType')) {
      sessionStorage.setItem('jwt', jwt)
      sessionStorage.setItem('authStaff', 'verified')
      return <Navigate to={{ pathname: location.pathname }} />;
    } else {
      return <Navigate to={{ pathname: '/login' }} />;
    }
  }

  return <React.Fragment>
    {children}
    <Dialog
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          fontFamily: "calibri", fontSize: "20px", zIndex: 9999
        },
      }}
      open={showSessionExpiredModal}
    >
      <div>
        <DialogTitle sx={{ height: "45px",  fontFamily: "poppins", fontStyle: "normal", fontWeight: 600  }}>
        {/* Session Expired */}
        Mettler Health Care
        </DialogTitle>
        <div className='mt-3 mx-2' style={{ borderBottom: '1px solid #cdcfd1'}}></div>
        <DialogContent className='mt-3' sx={{
          height: '50px', overflow: 'hidden',  padding: '0px 18px'
        }}>
          <DialogContentText sx={{
            fontFamily: "poppins", fontSize: "14px", fontStyle: "normal",  color: 'black', overflow: 'hidden'
          }}>
          You've been inactive for a while. Your session will expire in {count} seconds. Would you like to continue or log out
          </DialogContentText>
        </DialogContent>
        {/* <div  className='mt-2 mx-2' style={{ borderBottom: '1px solid #cdcfd1'}}></div> */}
        <DialogActions>
          <div
            className="d-flex gap-3 justify-content-center align-items-center mt-3 pb-3"
            style={{ height: "35px" }}
          >
            <Button label='Continue with this session' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => { handleCloseModal() }}></Button>
            <Button label='Go to Login' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => {
          handleCloseModal()
          sessionStorage.clear()
          navigate('/')
        }}></Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  </React.Fragment>;
};

export default ProtectedAuth;
