import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Box, DialogContent, DialogContentText, Grid, Tab, Tabs } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DateCalendar, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { HttpLogin } from "../../utils/Http";
import MaskGroupImage from '../../assets/images/Mask group.svg';
import patientImage from './../../assets/images/patientImage.png'
import '../../pages/Q15SlotAssign/q15Slot.css';
import { toast } from "react-toastify";
import moment from "moment";
import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Select,
    Stack,
    AlertTitle,
    Alert
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { successCode } from "../../configuration/url";
import { primarybg } from "../../common/primary";
import { Button } from "reactstrap";
import DateBox from "../../components/calendar/dateBox";
import dayjs from "dayjs";
import Loader from "../../components/loader/Loader";
import TimeSlot from "./TimeSlot";
import { TimeSlotData } from "./slotAssign";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { fetchActivityLegend, fetchLocationLegend } from "../../slices/thunk";
const Q15SlotAssign: React.FC = () => {

    let Q15RegistryData = {
        "id": "",
        "q15Date": "",
        "q15Slot": "",
        "q15Time": "",
        "enteredBy": "",
        "timestampCreatedAt": "",
        "timestampUpdatedAt": "",
        "breathing": true,
        "remarks": "",
        "reason": "",
        "shift": "",
        "shiftIncharge": "",
        "timeStamp": "",
        "location": "",
        "activity": "",
        "pid": "",
        "skippedScanning": true,
        "organization": ""
    }
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    let [displayData, setDisplayData] = useState(new Array<any>());
    const [inputQ15Date, setInputQ15Date] = useState<any>('');
    let [inputUserName, setInputUserName] = useState<any>('');
    let [staffEnteredBy, setStaffEnteredBy] = useState<any>('');
    const { organization, userData } = useSelector((state: any) => state.Login);
    let [timeSlotChange, setTimeSlotChange] = useState('1');
    let [calendarChange, setCalendarChange] = useState(4);
    const [displayDialogQ15Data, setDisplayDialogQ15Data] = useState(false);
    let [inputQ15Data, setInputQ15Data] = useState<any>(Q15RegistryData);
    let [calendarDate, setCalendarDate] = useState<any>(new Date());
    let [alertMessage, setAlertMessage] = useState("");
    let [addPatientName, setAddPatientName] = useState("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [patientNewData, setPatientData] = useState(new Array<any>());
    const [selectedDate, setSelectedDate] = useState<any>();
    const { locationLegend, activityLegend } = useSelector((state: any) => state.Dropdown);

    useEffect(() => {
        const handlestartTime = () => {
            const crtime: any = localStorage.getItem('LTime');
            if (crtime) {
                const formattedDate = moment(crtime).format('YYYY-MM-DD');
                setSelectedDate(formattedDate);
            }
        };
        handlestartTime();
    }, []);
    useEffect(() => {
        let newStaffName = userData.userDetail.name[0].given + " " + userData.userDetail.name[0].family;
        setStaffEnteredBy(newStaffName);
        setInputUserName(userData.session.username);
        setLoading(true)
        HttpLogin.axios().get("/api/patient/get/activePatientMob/" + organization)
            .then((newInData) => {
                if (newInData.data.message.code === successCode) {
                    setAddNewPatient(newInData.data.data !== null && newInData.data.data !== undefined && newInData.data.data);
                    setPatientData(newInData.data.data !== null && newInData.data.data !== undefined && newInData.data.data.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
                    setLoading(false)
                } else {
                    setAddNewPatient([]);
                    setPatientData([]);
                    setLoading(false)
                }
            })
        fetchActivityLegend(dispatch, organization)
        fetchLocationLegend(dispatch, organization)
    }, [organization]);

    const handleCalendarChange = useCallback((event: any) => {
        let newDate
        if (event === undefined || event === null || event === '') {
            newDate = event
            setSelectedDate(event)
        } else {
            setSelectedDate(event)
            newDate = event
        }
        // inputQ15Date = moment(newDate).format("YYYY-MM-DDTHH:mm:ss.000Z");
        setInputQ15Date(moment(newDate).format("MM/DD/YYYY"));
        setInputQ15Data({ ...inputQ15Data });
        let newDependDate = inputQ15Date !== "" ? moment(newDate, "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD") : moment(new Date(), "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD");
        if (inputQ15Data.pid !== "" && newDependDate !== null && newDependDate !== "" && newDependDate !== undefined) {
            setLoading(true)
            HttpLogin.axios().get(`/api/config/getById/${inputQ15Data.pid}/date/${newDependDate}`)
                .then((response) => {
                    if (response.data.data !== undefined) {
                        setDisplayData(response.data.data);
                        if (localStorage.getItem("CREATEQ15") !== null && localStorage.getItem("CREATEQ15") !== "" && localStorage.getItem("CREATEQ15") !== "No") {
                            let timeAddSlotChange;
                            let newCalendarChange;
                            let newCalendarDate;
                            timeAddSlotChange = localStorage.getItem("TIMESLOTCHANGE");
                            setTimeSlotChange(timeAddSlotChange !== "" && timeAddSlotChange !== null ? timeAddSlotChange : "1");
                            newCalendarChange = localStorage.getItem("Q15CALENDARCHANGE");
                            setCalendarChange(newCalendarChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 4);
                            newCalendarDate = localStorage.getItem("Q15DATE");
                            setCalendarDate(newCalendarDate !== "" && newCalendarDate !== null ? new Date(newCalendarDate) : calendarDate);
                            // inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
                            setInputQ15Date(moment(inputQ15Date).format('MM/DD/YYYY'));
                            inputQ15Data.q15Date = inputQ15Date;
                            setInputQ15Data({ ...inputQ15Data });
                            localStorage.setItem("CREATEQ15", "No");
                            setLoading(false)
                        }
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                })
            setLoading(false)
        }
    }, [selectedDate]);

    const handleTimeSlotChange = (event: React.SyntheticEvent, newValue: string) => {
        // timeSlotChange = event;
        setTimeSlotChange(newValue);
    }

    const handleNewClose = () => {
        localStorage.setItem("CREATEQ15", "No");
        setDisplayDialogQ15Data(false);
    }

    let [timeSlotSectionChange, setTimeSlotSectionChange] = useState(null);
    const handleDisplayDialogQ15Data = (event: any, newString: any, addData: any) => {
        if (addPatientName !== "") {
            if (addData != "") {
                inputQ15Data.id = addData.id;
                setInputQ15Date(new Date(moment(addData?.q15Date, "YYYYMMDD").format("YYYY-MM-DDTHH:mm:ss.000Z")));
                inputQ15Data.q15Slot = addData.q15Slot;
                inputQ15Data.enteredBy = inputUserName;
                inputQ15Data.q15Time = addData.q15Time;
                inputQ15Data.location = addData.location;
                inputQ15Data.activity = addData.activity;
                inputQ15Data.remarks = addData.remarks;
                inputQ15Data.reason = addData.reason;
                inputQ15Data.q15Date = addData.q15Date;
                setInputQ15Date(moment(inputQ15Date).format('MM/DD/YYYY'));
                setInputQ15Data({ ...inputQ15Data });
            } else {
                // alert(selectedDate)

                inputQ15Data.q15Date = moment(selectedDate, "YYYY-MM-DD").format("MM/DD/YYYY");
                setInputQ15Date(moment(selectedDate, "YYYY-MM-DD").format('YYYYMMDD'));
                inputQ15Data.q15Slot = event;
                inputQ15Data.q15Time = newString;
                inputQ15Data.location = "";
                inputQ15Data.activity = "";
                inputQ15Data.remarks = "";
                inputQ15Data.reason = "";
                inputQ15Data.enteredBy = inputUserName;
                timeSlotSectionChange = event;
                setTimeSlotSectionChange(event);
                setInputQ15Data({ ...inputQ15Data });
            }
            // alert(inputQ15Data.q15Date)
            // alert(moment(inputQ15Data.q15Date, "YYYY-MM-DDTHH:mm:ss.000Z").format("YYYYMMDD"))
            inputQ15Data.shift = ""
            inputQ15Data.q15Date = moment(selectedDate, "YYYY-MM-DD").format("MM/DD/YYYY");
            setLoading(true)
            HttpLogin.axios().get("/api/PSConfig/getByDateAndOrganization/" + moment(selectedDate, "YYYY-MM-DD").format("YYYYMMDD") + `/${organization}`)
                .then((res) => {
                    if (res?.data?.mesasge?.code === successCode) {
                        let newShiftIncharge = res.data.data.configList.shift.filter((l: any) => (l.shiftName === inputQ15Data.shift)).map((k: any) => { return k.rnIncharge })
                        inputQ15Data.shiftIncharge = newShiftIncharge !== null ? newShiftIncharge[0] : "";
                        setInputQ15Data({ ...inputQ15Data });
                        setLoading(false)
                    } else {
                        setInputQ15Data({ ...inputQ15Data });
                        setLoading(false)
                    }
                })
            setLoading(false)
            localStorage.setItem("TIMESLOTCHANGE", timeSlotChange.toString());
            localStorage.setItem("Q15CALENDARCHANGE", calendarChange.toString());
            localStorage.setItem("Q15DATE", inputQ15Date);
            setDisplayDialogQ15Data(true);

        } else {
            toast.error("Please select a Patient");
            setDisplayDialogQ15Data(false);
            setLoading(false)
        }
    }
    const [showAlertcr, setShowAlertcr] = useState(false);
    const [showAlerter, setShowAlerter] = useState(false);
    const handleQ15ClickChange = () => {
        localStorage.setItem("CREATEQ15", "Yes");
        if (inputQ15Data.q15Date) {
            inputQ15Data.q15Date = moment(inputQ15Data.q15Date).format("YYYYMMDD");
        }
        inputQ15Data.timeStamp = moment().format("YYYYMMDDHHmmss");
        inputQ15Data.skippedScanning = true
        inputQ15Data.organization = organization
        setInputQ15Data({ ...inputQ15Data });
        if (inputQ15Data.activity === "" || inputQ15Data.location === "") {
            setShowAlerter(true);
            setTimeout(() => {
                setShowAlerter(false);
            }, 1000);
            setAlertMessage("Some data is missing");
        } else {
            setLoading(true)
            const url = "/api/config/register";
            HttpLogin.axios().post(url, inputQ15Data, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            })
                .then(res => {

                    if (res.data.message.code === successCode) {
                        toast.success(res.data.message.description);
                        handleNewClose();
                        setLoading(false)
                    } else {
                        toast.error(res.data.message.description)
                        handleNewClose();
                        setLoading(false)
                    }
                    setLoading(true)
                    let newDependDate = moment(inputQ15Data.q15Date).format("YYYYMMDD");
                    HttpLogin.axios().get(`/api/config/getById/${inputQ15Data.pid}/date/${newDependDate}`)
                        .then((response) => {

                            if (response.data.data) {

                                setDisplayData(response.data.data);
                                setTimeSlotSectionChange(null);
                                setLoading(false)
                            }
                        })
                        .catch((e) => {
                            console.error("Error fetching updated data:", e);
                            setLoading(false)
                        });
                    setLoading(false)
                    return res;
                })
                .catch((e) => {
                    toast.error(e);
                    setLoading(false)
                    return e;
                });
        }
    }

    useEffect(() => {
        const handlestartTime = () => {
            const crtime: any = localStorage.getItem('LTime');
            if (crtime) {
                const formattedDate = moment(crtime).format('YYYY-MM-DD');
                setSelectedDate(formattedDate);
                setInputQ15Date(moment(formattedDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss.000+05:30"))
            }
        };
        handlestartTime();
    }, []);

    return (
        <div className='row' style={{ padding: '28px' }}>
            {loading && <Loader />}
            <div className="row">
                <div className="col-sm-3">
                    <h5>Q15 Routine</h5>
                </div>
                <div className="col-sm-6" style={{ justifyContent: 'end', paddingLeft: '30px' }} >
                    <Autocomplete
                        className=""
                        id="patientName"
                        options={patientNewData}
                        getOptionLabel={(option: any) => option}
                        value={addPatientName}
                        onChange={(e, v) => {
                            setAddPatientName(v);
                            let newPatientData = addNewPatient.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                            inputQ15Data.pid = newPatientData[0]
                            let newDependDate: any = inputQ15Date !== "" ? moment(inputQ15Date, "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD") : moment(new Date(), "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD");
                            if (newPatientData[0] !== "" && newDependDate !== null && newDependDate !== "" && newDependDate !== undefined) {
                                setInputQ15Date(moment(newDependDate).format('MM/DD/YYYY'));
                                setLoading(true)
                                HttpLogin.axios().get(`/api/config/getById/${newPatientData[0]}/date/${newDependDate}`)
                                    .then((response) => {
                                        if (response.data.data !== undefined) {
                                            setDisplayData(response.data.data);
                                            if (localStorage.getItem("CREATEQ15") !== null && localStorage.getItem("CREATEQ15") !== "" && localStorage.getItem("CREATEQ15") !== "No") {
                                                let timeAddSlotChange;
                                                let newCalendarChange;
                                                let newCalendarDate;

                                                timeAddSlotChange = localStorage.getItem("TIMESLOTCHANGE");
                                                setTimeSlotChange(timeAddSlotChange !== "" && timeAddSlotChange !== null ? timeAddSlotChange : "1");
                                                newCalendarChange = localStorage.getItem("Q15CALENDARCHANGE");
                                                setCalendarChange(newCalendarChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 4);
                                                newCalendarDate = localStorage.getItem("Q15DATE");
                                                setCalendarDate(newCalendarDate !== "" && newCalendarDate !== null ? new Date(newCalendarDate) : calendarDate);

                                                inputQ15Data.q15Date = inputQ15Date;
                                                setInputQ15Data({ ...inputQ15Data });
                                                localStorage.setItem("CREATEQ15", "No");
                                                setLoading(false)
                                            }
                                            setLoading(false)
                                        } else {
                                            setLoading(false)
                                        }
                                    })
                            } else {
                                inputQ15Data.pid = newPatientData[0];
                            }
                            setInputQ15Data({ ...inputQ15Data });
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Patient"
                                placeholder="Select Patient"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div className="col-sm-2"></div>
            </div>
            <div className="total_time_seaction">
                <div style={{ display: 'flex', justifyContent: "center", padding: "10px", gap: '25px', }}>
                    <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleCalendarChange} countCheck={0} dateRange={undefined} />
                </div>
            </div>


            <TabContext value={timeSlotChange}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList scrollButtons onChange={handleTimeSlotChange} aria-label="lab API tabs example" centered
                    >
                        <Tab label="0:00 - 5:45" value="1" />
                        <Tab label="6:00 - 11:45" value="2" />
                        <Tab label="12:00 - 17:45" value="3" />
                        <Tab label="18:00 - 23:45" value="4" />

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TimeSlot displayData={displayData} inputQ15Data={inputQ15Data} inputQ15Date={inputQ15Date} timeSlotSectionChange={timeSlotSectionChange} TimeSlotData={TimeSlotData?.slotFirst} handleDisplayDialogQ15Data={handleDisplayDialogQ15Data} />
                </TabPanel>
                <TabPanel value="2">
                    <TimeSlot displayData={displayData} inputQ15Data={inputQ15Data} inputQ15Date={inputQ15Date} timeSlotSectionChange={timeSlotSectionChange} TimeSlotData={TimeSlotData?.slotTwo} handleDisplayDialogQ15Data={handleDisplayDialogQ15Data} />

                </TabPanel>
                <TabPanel value="3">
                    <TimeSlot displayData={displayData} inputQ15Data={inputQ15Data} inputQ15Date={inputQ15Date} timeSlotSectionChange={timeSlotSectionChange} TimeSlotData={TimeSlotData?.slotThree} handleDisplayDialogQ15Data={handleDisplayDialogQ15Data} />

                </TabPanel>
                <TabPanel value="4">
                    <TimeSlot displayData={displayData} inputQ15Data={inputQ15Data} inputQ15Date={inputQ15Date} timeSlotSectionChange={timeSlotSectionChange} TimeSlotData={TimeSlotData?.slotFour} handleDisplayDialogQ15Data={handleDisplayDialogQ15Data} />

                </TabPanel>

            </TabContext>


            <Dialog maxWidth={'lg'} PaperProps={{
                sx: {
                    width: '595px', maxHeight: 'calc(100% - 40px)', top: 25,
                },
            }}
                className="p-5"
                open={displayDialogQ15Data}
                onClose={handleNewClose}
            >
                <DialogContent style={{ overflowY: 'auto' }} className="p-4">
                    <DialogContentText style={{ paddingLeft: '15px' }}>
                        <div style={{ display: 'flex', width: '96%', justifyContent: 'end' }}>
                            {showAlertcr && (
                                <Stack style={{ height: '83px', width: '380px' }} spacing={2}>
                                    <Alert severity="success">
                                        <AlertTitle>{alertMessage}</AlertTitle>
                                    </Alert>
                                </Stack>
                            )}
                            {showAlerter && (
                                <Stack style={{ height: '83px', width: '332px' }} spacing={2}>
                                    <Alert severity="error">
                                        <AlertTitle>{alertMessage}</AlertTitle>
                                    </Alert>
                                </Stack>
                            )}
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div>
                                {addPatientName !== '' ? (
                                    <div style={{ display: 'flex', alignItems: 'center', opacity: 0.6, gap: '10px' }}>
                                        <img
                                            src={patientImage}
                                            style={{ width: '37px', height: '37px', borderRadius: '50%' }}
                                            alt="Patient"
                                        />
                                        {/* <span style={{ fontSize: '16px', fontWeight: '500' }}>{addPatientName}</span> */}
                                        <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                                            {addPatientName}
                                        </span>

                                    </div>
                                ) : (
                                    <span></span>
                                )}
                                {/* <div
            style={{ fontSize: '16px' }}
            className="patient-Q15-dialogTitle"
          >
            Enter Date and Time
          </div> */}
                                <div
                                    style={{ fontSize: '16px', opacity: '0.5' }}
                                    className="patient-Q15-dialogTitle"
                                >
                                    {/* {inputQ15Data.q15Time} */}
                                </div>
                                <div
                                    style={{ width: '95%' }}
                                    className="d-flex flex-row gap-2 pt-2"
                                >
                                    <div className="col-6">
                                        <TextField
                                            id="q15Date"
                                            type="date"
                                            value={selectedDate}
                                            color="primary"
                                            variant="outlined"
                                            label="Date"
                                            size="medium"
                                            margin="none"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex flex-row gap-2">
                                            <TextField
                                                id="q15TimePeriod"
                                                value={inputQ15Data.q15Time.slice(0, 5)}
                                                color="primary"
                                                variant="outlined"
                                                type="text"
                                                label="Start Time"
                                                size="medium"
                                                margin="none"
                                            />
                                            <TextField
                                                id="q15TimePeriod1"
                                                value={inputQ15Data.q15Time.slice(6, 11)}
                                                color="primary"
                                                variant="outlined"
                                                type="text"
                                                label="End Time"
                                                size="medium"
                                                margin="none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <TextField
                                        id="q15EnteredBy"
                                        value={staffEnteredBy}
                                        disabled={true}
                                        style={{ width: '96.5%' }}
                                        color="primary"
                                        variant="outlined"
                                        type="text"
                                        label="Enter Staff Name"
                                        size="medium"
                                        margin="none"
                                        required
                                    />
                                </div>
                                <div className="pt-3 d-flex flex-row">
                                    <div className="col-5">
                                        <FormControl
                                            style={{ width: '95%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel color="primary">Location Legend</InputLabel>
                                            <Select
                                                color="primary"
                                                size="medium"
                                                label="Location Legend"
                                                name="q15Location"
                                                value={inputQ15Data.location}
                                                onChange={(e) => {
                                                    inputQ15Data.location = e.target.value;
                                                    setInputQ15Data({ ...inputQ15Data });
                                                }}
                                            >
                                                {locationLegend?.map((newData: any, i: number) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={newData.key}
                                                        >
                                                            {newData.value}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText />
                                        </FormControl>
                                    </div>
                                    <div className="col-7">
                                        <FormControl
                                            style={{ width: '94.5%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel color="primary">Condition Legend</InputLabel>
                                            <Select
                                                color="primary"
                                                size="medium"
                                                label="Condition Legend"
                                                name="q15Activity"
                                                value={inputQ15Data.activity}
                                                onChange={(e) => {
                                                    inputQ15Data.activity = e.target.value;
                                                    setInputQ15Data({ ...inputQ15Data });
                                                }}
                                            >
                                                {activityLegend?.map((newData: any, i: number) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={newData.key}
                                                        >
                                                            {newData.value}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <TextField
                                        id="q15EnteredBy"
                                        value={inputQ15Data.remarks}
                                        onChange={(e) => {
                                            inputQ15Data.remarks = e.target.value;
                                            setInputQ15Data({ ...inputQ15Data });
                                        }}
                                        style={{ width: '96.5%' }}
                                        multiline
                                        rows={2}
                                        color="primary"
                                        variant="outlined"
                                        type="text"
                                        label="Notes/remarks"
                                        size="medium"
                                        margin="none"
                                        required
                                    />
                                </div>
                                <div className="mt-2">
                                    <TextField
                                        id="q15EnteredBy"
                                        value={inputQ15Data.reason}
                                        onChange={(e) => {
                                            inputQ15Data.reason = e.target.value;
                                            setInputQ15Data({ ...inputQ15Data });
                                        }}
                                        style={{ width: '96.5%' }}
                                        multiline
                                        rows={2}
                                        color="primary"
                                        variant="outlined"
                                        type="text"
                                        label="Beacon Scanning Override"
                                        size="medium"
                                        margin="none"
                                        required
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        gap: '10px',
                                        paddingBottom: '15px',
                                        paddingRight: '15px',
                                    }}
                                    className="mt-4"
                                >
                                    <Button
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleNewClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: primarybg,
                                            borderColor: 'transparent',
                                        }}
                                        onClick={handleQ15ClickChange}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </LocalizationProvider>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Q15SlotAssign