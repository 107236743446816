import { Bed } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogContentText, IconButton, MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { FaQrcode } from 'react-icons/fa';
import { Button } from "primereact/button";
import PatientBed from './patientBed';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { authorize, formatDateToYYYYMMDD } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import { getAllOrgPatient, getAllRole, getDeviceDropdown } from '../../slices/thunk';
import { toast } from 'react-toastify';
import axios from 'axios';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../components/loader/Loader';

interface PatientModalProps {
  bedAssignDialog: any
  handleDioCancel: () => void
  selectedPatientName: any
  error: any
  setError: any
  admitDate: any
  setAdmitDate: any
  dateFieldError: string
  setDateFieldError: any
  reason: any
  setReason: any
  deviceId: any
  setDeviceId: any
  handleQrClick: any
  options: any
  bedSelected: any
  admitD: any
  setAdmitD: any
  bedId: any
  handleBedClick: any
  bedsByNumber: any
  selectPatientId: any
  currentPage: any
  setBedId: any
}
const PatientAdmitModal: React.FC<PatientModalProps> = ({ bedAssignDialog, handleDioCancel, selectedPatientName, error, setError, admitDate, setAdmitDate, dateFieldError, setDateFieldError, reason, setReason, handleQrClick, deviceId, setDeviceId, options, bedSelected, admitD, setAdmitD, bedId, handleBedClick, bedsByNumber, selectPatientId, currentPage, setBedId }) => {
  const dispatch = useDispatch<any>()
  const { organization, userData } = useSelector((state: any) => state.Login);
  const userId = userData?.userDetail.id;
  const [selectedStaff, setSelectedStaff] = useState("");
  const { roleList } = useSelector((state: any) => state.PSConfig)
  const { loading } = useSelector((state: any) => state.PSConfig)
  const [Loading1, setLoading] = useState<boolean>(false)
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    setNameError({ ...nameError, bedSelected: false, reason: false, deviceId: false, admitDate: false, selectedStaff: false })
  }, [bedAssignDialog])

  const handleSave = async () => {
    setLoading(true)
    let nameErrors = {
      selectedStaff: !selectedStaff,
      admitDate: !admitDate,
      deviceId: !deviceId,
      reason: !reason,
      bedSelected: !bedSelected
    }
    setNameError(nameErrors)
    const hasErrors = Object.values(nameErrors).some(error => error);
    if (hasErrors) {
      toast.error("Please Fill Admit Required Field")
      setLoading(false)
      return;
    }
    try {
      const formattedDate = formatDateToYYYYMMDD(admitDate);
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        assignedBy: userId,
        admitDate: formattedDate,
        admitReason: reason,
        deviceId: deviceId,
        doctorName: selectedStaff,
      };
      const { header1 } = authorize();
      const response = await axios.post(
        `${baseURL}/Q15Bed/assign`,
        requestBody, { headers: header1 }
      );
      if (
        response?.data?.message &&
        response?.data?.message?.code === successCode
      ) {
        getAllOrgPatient(dispatch, organization, currentPage, '');
        toast.success(response.data.message.description)
        getDeviceDropdown(dispatch, organization)
        handleDioCancel()
        handleCLS()
        setLoading(false)

      } else {
        console.error("Error:", response.data.message);
        toast.error(response.data.message.description)
        setLoading(false)

      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false)

    }
  }
  useEffect(() => {
    getAllRole(dispatch, 'Physician', organization)
  }, [dispatch, organization])

  useEffect(() => {
    if (bedSelected) {
      setNameError({ ...nameError, bedSelected: false })
    }
    return () => {
      setNameError({ ...nameError, bedSelected: false, reason: false, deviceId: false, admitDate: false, selectedStaff: false })
    }
  }, [bedSelected])
  const handleCLS = () => {
    setNameError({ ...nameError, bedSelected: false, reason: false, deviceId: false, admitDate: false })
    setSelectedStaff('')
    setBedId('')
    handleDioCancel()
  }

  return (
    <div>
      {Loading1 && <Loader />}
      {loading && <Loader />}
      {/* {loading && <Loader />} */}
      <Dialog maxWidth={'md'} PaperProps={{ sx: { width: '72%', maxWidth: '72%', position: 'absolute', height: '500px', top: '40px', overflow: 'hidden', right: "40px" } }}
        open={bedAssignDialog}
        onClose={handleCLS}

      >
        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 10px", alignItems: "center", gap: "10px", top: 0 }}>
          <div style={{ fontWeight: 600 }}>
            <h5>Admit</h5>
          </div>
          <div>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleCLS}></button>
          </div>
        </div>

        <DialogContentText >
          <DialogContent style={{ padding: '20px', background: '#F8FAFB', overflowY: 'auto', height: '400px' }}>
            <div>
              <>
                <div className="row w-100 mb-3">
                  <div className="col-md-6">
                    <TextField
                      id="PatientName"
                      label="Patient Name"
                      variant="outlined"
                      value={selectedPatientName}
                      fullWidth
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      label="Select Physician"
                      value={selectedStaff}
                      onChange={(e) => { setSelectedStaff(e.target.value) }}
                      fullWidth
                      select
                    >
                      {roleList?.length > 0 ? (
                        roleList?.map((staff: any) => (
                          <MenuItem
                            key={staff.id}
                            value={`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                          >
                            {`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No Staff Available</MenuItem>
                      )}
                    </TextField>
                  </div>


                </div>

                <div className="row w-100 ">
                  <div className='col-md-6 mb-2' >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box >
                        <DatePicker
                          sx={{ width: "100%" }}
                          onError={(newError) => setError(newError)}
                          label={'Admit Date'}
                          value={admitDate}
                          onChange={(newValue) => {
                            setAdmitDate(newValue);
                            setNameError({ ...nameError, admitDate: false })
                          }}
                          slotProps={{
                            textField: {
                              helperText: nameError.admitDate ? "Date field Required " : "",
                              error: !!nameError.admitDate,
                            },
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="Reason"
                      label="Admit Reason"
                      variant="outlined"
                      value={reason}
                      fullWidth
                      onChange={(e) => { setReason(e.target.value); setNameError({ ...nameError, reason: false }) }}
                      error={!!nameError.reason}
                      helperText={nameError.reason ? "Reason field is Required" : ""}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100 mt-2">
                  <div className='col-md-6 mb-2'>
                    <TextField
                      label='Device-Id'
                      value={deviceId}
                      onChange={(event) => { setDeviceId(event.target.value); setNameError({ ...nameError, deviceId: false }) }}
                      required
                      error={!!nameError.deviceId}
                      helperText={nameError.deviceId ? 'DeviceId is Required' : ''}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <FaQrcode
                            className="position-absolute bottom-0 m-3"
                            onClick={handleQrClick}
                            role="button"
                            style={{
                              fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0
                            }}
                          />
                        ),

                      }}
                      select
                      SelectProps={{
                        IconComponent: () => null,
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: '300px',
                            },
                          },
                        },
                      }}
                    >
                      {options?.length > 0 ? options?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.deviceId}>
                          {option?.deviceId}
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: "12px",
                              color: !option?.status ? '#5FB477' : '#EF6868',
                              display: "inline-block",
                              textAlign: "center",
                              marginLeft: '10px',
                              marginBottom: '1px'
                            }}
                          />
                        </MenuItem>
                      )) :
                        <MenuItem>
                          No Devices Data
                        </MenuItem>
                      }
                    </TextField>
                  </div>
                  <div className="col-md-6" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <p style={{ paddingRight: '18px', marginTop: '10px', marginLeft: '10px', color: nameError.bedSelected ? "red" : "" }}>{bedSelected ? bedSelected : "Select Room & Bed"}</p>
                    <IconButton style={{ color: nameError.bedSelected ? 'red' : "#0f3995", marginTop: '-5px', fontSize: '30px' }}
                      onClick={() => {
                        if (admitD === false) {
                          setAdmitD(true);
                        } else {
                          setAdmitD(false);
                        }
                      }}
                      edge="start">
                      <Bed style={{ fontSize: '30px' }} />
                    </IconButton>
                  </div>
                </div>
              </>
              {admitD && <> <PatientBed admitD={admitD} bedId={bedId} handleBedClick={handleBedClick} bedsByNumber={bedsByNumber} /> </>}
            </div>
          </DialogContent>
        </DialogContentText>
        <div className="d-flex gap-3 pt-2 mb-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
            onClick={() => {
              handleCLS()
            }}></Button>
          <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
        </div>
      </Dialog>
    </div>
  )
}

export default PatientAdmitModal