import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { getDropdownSuccess, isLoading, setActivityLegend, setBeaconType, setGenderDropDown, setIsLoadingFalse, setLocation, setLocationLegendD, setOrgTypeSuccess, setPrecautionLegend } from "./reducer";
import { authorize } from "../../helpers/common";



export const fetchDropdownData = async (dispatch:any) => {
  const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/dropdowns/get-all`,{headers: header1});
      if (response.data.message && response.data.message.code === successCode) {
        dispatch(getDropdownSuccess(response.data.data))
      } else {
        dispatch(getDropdownSuccess([]))
        console.error('Error fetching dropdown data:', response.data.message.description);
      }
    } catch (error) {
      dispatch(getDropdownSuccess([]))
      console.error('Error fetching dropdown data:', error);
    }
  };

  export const getOrgType = async (dispatch: any) => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/dropdown/ehr/getByDropdown?dropdown=OrganizationType`, { headers: header1 })
      if (response?.data.message.code === successCode) {
        dispatch(setOrgTypeSuccess(response.data.data[0].list));
      }
      else {
        console.error('Error fetching dropdown data:', response?.data.message.description);
        dispatch(setOrgTypeSuccess([]));
      }
    } catch (error) {
      dispatch(setOrgTypeSuccess([]));
      console.error('Error fetching dropdown data:', error);
    }
  };

  export const fetchGenderDropdown = async (dispatch: any, org: string) => {
    isLoading()
    const { header1 } = authorize();
    const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Gender&organizationId=${org}&page=-1`, { headers: header1 })
    if (response.data.message.code === successCode) {
      dispatch(setGenderDropDown(response.data.data[0].list));
      setIsLoadingFalse()
    } else {
      dispatch(setGenderDropDown([]));
      setIsLoadingFalse()
    }
  }

  export const fetchBeaconType = async (dispatch: any, orgId: string) => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Beacontype&organizationId=${orgId}&page=-1`, { headers: header1 });
      if (response.data.message.code === 'MHC - 0200') {
        dispatch(setBeaconType(response.data.data[0].list));
      } else {
        dispatch(setBeaconType([]))
        console.error('Error fetching dropdown data:', response.data.message.description);
      }
    } catch (error) {
      dispatch(setBeaconType([]))
      console.error('Error fetching dropdown data:', error);
    }
  };

export const fetchLocationDropdown = async (dispatch: any, orgId: string) => {
    const { header1 } = authorize();
    try {
      
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Location&organizationId=${orgId}&page=-1`, { headers: header1 });
      if (response && response.data.message && response.data.message.code === successCode) {
        dispatch(setLocation(response.data.data[0].list));
      } else {
        dispatch(setLocation([]));
        console.error(
          "Error fetching dropdown data:",
          response.data.message.description
        );
      }
    } catch (error) {
      dispatch(setLocation([]));
      console.error("Error fetching dropdown data:", error);
    }
  };

  export const fetchLocationLegend = async (dispatch: any, org: string) => {
    try {
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Location%20Legend&organizationId=${org}&page=-1`)
      if (response.data.message.code === successCode) {
        dispatch(setLocationLegendD(response.data.data[0].list))
      } else {
        dispatch(setLocationLegendD([]))
      }
    } catch (error: any) {
      dispatch(setLocationLegendD([]))
      console.error("API ERROR:", error)
    }
  }
export  const fetchActivityLegend = async (dispatch: any, org: string) => {
    try {
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Activity%20Legend&organizationId=${org}&page=-1`)
      if (response.data.message.code === successCode) {
        dispatch(setActivityLegend(response.data.data[0].list))
      } else {
        dispatch(setActivityLegend([]))
      }
    } catch (error: any) {
      dispatch(setActivityLegend([]))
      console.error("API ERROR :", error)
    }
  }

export const fetchPrecautionLegend = async (dispatch: any, org: string) => {
  try {
    const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Precaution&organizationId=${org}&page=-1`)
    if (response.data.message.code === successCode) {
      dispatch(setPrecautionLegend(response.data.data[0].list))
    } else {
      dispatch(setPrecautionLegend([]))
    }
  } catch (error: any) {
    dispatch(setPrecautionLegend([]))
    console.error("API ERROR:", error)
  }
}