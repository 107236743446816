import { Button } from 'primereact/button';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface LogoutConfirmationModalProps {
    open: boolean;
    handleConfirm: () => void;
    handleClose: () => void;
    message: string;
}

const LogoutConfirmationModal: React.FC<LogoutConfirmationModalProps> = ({ open, handleConfirm, handleClose, message }) => {

    return (
        <Modal
            isOpen={open}
            toggle={handleClose}
            centered
            style={{
                fontFamily: "calibri",
                fontSize: "20px",
                maxWidth: "500px", 
                width: "80%", 
                height: "300px", 
            }}
        >
            <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
                Mettler Health Care
            </ModalHeader>
            <ModalBody
                style={{
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center", 
                }}
            >
                <div
                    style={{
                        marginBottom: "10px",
                        position: "relative",
                         top: "-5px",
                    }}
                >
                    {message}
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                    <Button label='Yes' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleConfirm}></Button>
                    <Button label='No' severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose}></Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default LogoutConfirmationModal;
