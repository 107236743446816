import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import Loader from "../../components/loader/Loader";
import Calendar from "../../components/calendar";
import { primarybg, primarytext } from "../../common/primary";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { baseURL } from "../../configuration/url";
import { useDispatch, useSelector } from "react-redux";
import DateBox from '../../components/calendar/dateBox';
import moment, { duration } from 'moment';
import { FaSearch } from "react-icons/fa";

interface AlarmRecord {
  id: string;
  triggeredStaffId: string;
  visitorName: string;
  triggeredTime: string;
  triggeredDate: string;
  location: string;
}

const AlarmRecordTable: React.FC = () => {
  const [alarmData, setAlarmData] = useState<AlarmRecord[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [date, setDate] = useState<string>('');
  const organizationId = useSelector((state: any) => state.Login.organization);
  const [searchTerm, setSearchTerm] = useState('');
  const { shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org);
  const recordsPerPage = 8;
  const formatDate = (date: any) => {
    const options = { day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };


  const fetchAlarmRecords = async (page: number, selectedDate: Date, searchTerm: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${baseURL}/alarm/getAlarmRecordByTriggeredDate/${moment(selectedDate).format("YYYYMMDD")}?organization=${organizationId}&page=${page}&pageSize=${recordsPerPage}&search=${searchTerm}`
      );

      const data = response.data.data || {};
      const alarmRecords = data.content || [];

      setTotalRecords(data.totalElements || 0);
      setAlarmData(alarmRecords);
    } catch (err: any) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };
  const dispatch = useDispatch<any>()
  useEffect(() => {
    fetchAlarmRecords(currentPage, selectedDate, searchTerm);
  }, [currentPage, selectedDate, searchTerm]);
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const handlestartTime = () => {
    const crtime: any = localStorage.getItem("LTime");
    if (crtime) {
      const formattedDates = moment(crtime).format("YYYY-MM-DD");
      const currentTime = moment(crtime).format('HH:mm')
      const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
      const CurrTime: any = moment(`1970-01-01T${currentTime}`)
      if (shiftStartTime !== CurrTime) {
        if (CurrTime.isAfter(OrgStartTime)) {
          setSelectedDate(formattedDates);
        } else {
          if (!formattedDates) return;
          const validDate = moment(formattedDates);
          if (validDate.isValid()) {
            const nextDay = validDate.clone().subtract(1, "days");
            setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
          } else {
            console.error("Invalid date:", formattedDates);
          }
        }
      } else {
        setSelectedDate(formattedDates);
      }
    }
  };
  useEffect(() => {
    handlestartTime()
  }, [])
  const handleDate = (date: Date) => {
    setSelectedDate(date);
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    setDate(formattedDate);
  };

  const getDate = (dateTime: string) => {
    if (!dateTime) return 'N/A';

    const date = new Date(
      `${dateTime.slice(0, 4)}-${dateTime.slice(4, 6)}-${dateTime.slice(6, 8)}`
    );

    if (isNaN(date.getTime())) return 'Invalid date';

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };
  const handleDateChange = (event: any) => {
    const newDate = event;
    // if (newDate.isValid()) {
    setSelectedDate(newDate);
    setDate('');
    // } else {
    //   const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    //   const formattedDate = newDate.toLocaleDateString('en-US', options);
    //   setDate(formattedDate);
    //   setSelectedDate(newDate);
    // }
  };
  const getTime = (dateTime: string) => {
    if (!dateTime) return 'N/A';
    const hours24 = parseInt(dateTime.slice(8, 10), 10);
    const minutes = dateTime.slice(10, 12);
    const hours12 = hours24 % 12 || 12;
    const period = hours24 < 12 ? 'AM' : 'PM';
    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <div className="" style={{ width: '99%', marginLeft: '5px', height: '91vh' }}>
      {isLoading && (<Loader />)}
      <div className="" style={{ height: '5vh' }}>
        <h5>SOS Call</h5>
      </div>
      <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
        <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} dateRange={undefined} />
      </div>
      <div className='d-flex justify-content-between my-2' style={{ fontSize: "19px", fontWeight: 600, fontFamily: "poppins", height: '7vh' }}>
        <div className='d-flex align-items-center'>
          {moment(selectedDate).format("MMM DD, YYYY")}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={handleSearch}
            />
            <FaSearch className="search-icon" />
          </div>

        </div>
      </div>
      <div style={{ height: '62vh'  }}>
        <Table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" className="text-center table-data" style={{ width: '13px', color: primarytext }}>S.No</th>
              <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Date</th>
              <th scope="col" className="text-center table-data" style={{ color: primarytext }}> Trigered Time</th>
              <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
              <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff / Guest Name</th>
            </tr>
          </thead>
          <tbody>
            {alarmData.length > 0 ? (
              alarmData.map((record, index) => (
                <tr key={record.id}>
                  <td className="text-center">{currentPage * recordsPerPage + index + 1}</td>
                  <td className="text-center">{getDate(record.triggeredDate)}</td>
                  <td className="text-center">{getTime(record.triggeredTime)}</td>
                  <td className="text-center">{record.location}</td>
                  <td className="text-center">  {record.triggeredStaffId ? record.triggeredStaffId : record.visitorName}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">No records found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="pagination-container">
          <div className="d-flex justify-content-center">
            <Paginator
              first={currentPage * recordsPerPage}
              rows={recordsPerPage}
              totalRecords={totalRecords}
              onPageChange={(e: any) => {
                setCurrentPage(e.page);
              }}
              currentPageReportTemplate={`Page ${currentPage + 1} of ${Math.ceil(totalRecords / recordsPerPage)}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmRecordTable;
