import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Rectangle6215 from './../../assets/images/Rectangle 6215.png';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, SelectChangeEvent, FormControlLabel, Checkbox, Avatar, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchActivityLegend, fetchLocationDropdown, fetchPrecautionLegend, getAllRNIncharge, getAllStaff, getPatientDropdown } from "../../slices/thunk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { HttpLogin } from "../../utils/Http";
import moment from 'moment';
import { baseURL, successCode } from "../../configuration/url";
import { secondarybt } from "../../common/primary";
import { IoPrintOutline } from "react-icons/io5";
import { primarybg, white } from "../../common/primary";
import { useLocation } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReportView from "../Reports/ReportView";
import ReportSheetView from "./ReportSheetView";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";

interface Shift {
    shift_name: string;
    start_time: string;
    end_time: string;
    dayShift: string;
}

export default function ControlledAccordions() {

    const dispatch = useDispatch()
    const org = useSelector((state: any) => state.Login.organization)
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    const { userData } = useSelector((state: any) => state.Login);
    const userId = userData.userDetail.id;
    const [isHidden, setIsHidden] = useState(false);
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const { staffData } = useSelector((state: any) => state.Staff);
    let [addCalendarDate, setAddCalendarDate] = useState(new Array<any>());
    let [FilteraddCalendarDate, setFilterAddCalendarDate] = useState(new Array<any>());
    let [tableData, setTableData] = useState(new Array<any>());
    let [getStaffData, setStaffData] = useState(new Array<any>());
    let [getPatientData, setPatientData] = useState(new Array<any>());
    let [calendarDate,] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<any>();
    const { userType } = useSelector(
        (state: any) => state.Login
    );
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    let [newTimeSlot1, setNewTimeSlot1] = useState(new Array<any>());
    const [noOfShift, setNoOfShift] = useState<any>(0);
    const [noOfSlot, setNoOfSlot] = useState<any>()
    const addInputData = {
        shiftIncharge: "",
        criteria: "",
        patientName: "",
        slot: "",
        shift: [""],
        startDate: null,
        endDate: null
    }

    const [selectShift, setSelectShift] = useState<any>('');
    const [option, setOption] = useState<any>([])
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
    }, [orgData])

    useEffect(() => {
        console.log(tableData, FilteraddCalendarDate, 'tableData')
    }, [tableData])

    useEffect(() => {
        if (noOfShift) {
            setOption(Array.from({ length: noOfShift }, (_, i) => `Shift-${String.fromCharCode(65 + i)}`));
        }
    }, [noOfShift]);
    function removeDuplicates(arr: any[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }
    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }

        return 24 / duration;
    };
    const getNumberofSlot = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return duration / orgData.slotDuration;
    }
    const getReturnValue = (index1: any, index2: any) => {
        let returnValue = index2 * noOfSlot === 0 ? index1 : index2 * noOfSlot === noOfSlot ? index1 + noOfSlot : index2 * noOfSlot === (noOfSlot + noOfSlot) ? index1 + noOfSlot + noOfSlot : index2 * noOfSlot === (noOfSlot + noOfSlot + noOfSlot) && index1 + noOfSlot + noOfSlot + noOfSlot
        switch (returnValue) {
            case 0:
                return 0;
            case 1:
                return 8;
            case 2:
                return 16;
            case 3:
                return 24;
            case 4:
                return 32;
            case 5:
                return 40;
            case 6:
                return 48;
            case 7:
                return 56;
            case 8:
                return 64;
            case 9:
                return 72;
            case 10:
                return 80;
            case 11:
                return 88;
            case 12:
                return 96;
            case 13:
                return 104;
            default:
                return 0;
        }
    }
    const getReturnValue1 = (index1: any, index2: any) => {
        let returnValue = index2 * noOfSlot === 0 ? index1 : index2 * noOfSlot === noOfSlot ? index1 + noOfSlot : index2 * noOfSlot === (noOfSlot + noOfSlot) ? index1 + noOfSlot + noOfSlot : index2 * noOfSlot === (noOfSlot + noOfSlot + noOfSlot) && index1 + noOfSlot + noOfSlot + noOfSlot
        switch (returnValue) {
            case 0:
                return 0;
            case 1:
                return 7;
            case 2:
                return 15;
            case 3:
                return 23;
            case 4:
                return 31;
            case 5:
                return 39;
            case 6:
                return 47;
            case 7:
                return 55;
            case 8:
                return 63;
            case 9:
                return 71;
            case 10:
                return 79;
            case 11:
                return 87;
            case 12:
                return 95;
            case 13:
                return 103;
            default:
                return 0;
        }
    }
    const getAccordionDate = (inx: any, index: any) => {
        let returnValue = index * 7 === 0 ? inx : index * 7 === 7 ? inx + noOfSlot : index * 7 === (7 + 7) ? inx + noOfSlot + noOfSlot : index * 7 === (7 + 7 + 7) && inx + noOfSlot + noOfSlot + noOfSlot
        switch (returnValue) {
            case 0:
                return 0;
            case 1:
                return 8;
            case 2:
                return 16;
            case 3:
                return 24;
            case 4:
                return 32;
            case 5:
                return 40;
            case 6:
                return 48;
            case 7:
                return 56;
            case 8:
                return 64;
            case 9:
                return 72;
            case 10:
                return 80;
            case 11:
                return 88;
            case 12:
                return 96;
            case 13:
                return 104;
            default:
                return 0;
        }
    }
    const getAccordionDate1 = (inx: any, index: any) => {
        let returnValue = index * 7 === 0 ? inx : index * 7 === 7 ? inx + noOfSlot : index * 7 === (7 + 7) ? inx + noOfSlot + noOfSlot : index * 7 === (7 + 7 + 7) && inx + noOfSlot + noOfSlot + noOfSlot
        switch (returnValue) {
            case 0:
                return 0;
            case 1:
                return 7;
            case 2:
                return 15;
            case 3:
                return 23;
            case 4:
                return 31;
            case 5:
                return 39;
            case 6:
                return 47;
            case 7:
                return 55;
            case 8:
                return 63;
            case 9:
                return 71;
            case 10:
                return 79;
            case 11:
                return 87;
            case 12:
                return 95;
            case 13:
                return 103;
            default:
                return 0;
        }
    }
    let [newInputData, setNewInputData] = useState(addInputData);
    let [addNurseIncharge, setAddNurseIncharge] = useState<any>("");
    let [addEnteredBy, setAddEnteredBy] = useState("");
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    let [addPatientName, setAddPatientName] = useState<any>("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [loginStaff, setLoginStaff] = useState("");
    const { locationLegend, activityLegend, precaution } = useSelector((state: any) => state.Dropdown);
    const { rnInchargeList } = useSelector((state: any) => state.PSConfig)

    const location = useLocation();
    const path = location.pathname === "/q15-report"
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
        let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
        setNoOfSlot(numSlot);
    }, [orgData])
    const [legendcontrol, setLegendControl] = useState<boolean>(false)
    const [timeFormat, setTimeFormat] = useState<boolean>(false)

    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegendControl(e.target.checked)
    }
    const handleTimeFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeFormat(e.target.checked)
    }
    const handleStartTime = async () => {
        let crtime: any = localStorage.getItem("LTime");

        if (crtime instanceof Promise) {
            crtime = await crtime;
        }

        if (crtime) {
            const formattedDate = moment(crtime).format("YYYY-MM-DD");
            const currentTime = moment(crtime).format("HH:mm");
            const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`);
            const CurrTime: any = moment(`1970-01-01T${currentTime}`);

            if (shiftStartTime !== CurrTime) {
                if (CurrTime.isAfter(OrgStartTime)) {
                    setSelectedDate(formattedDate);
                } else {
                    if (!formattedDate) return;
                    const validDate = moment(formattedDate);
                    if (validDate.isValid()) {
                        const nextDay = validDate.clone().subtract(1, "days");
                        setSelectedDate(moment(nextDay).format("YYYY-MM-DD"));
                    } else {
                        console.error("Invalid date:", formattedDate);
                    }
                }
            } else {
                setSelectedDate(formattedDate);
            }
        }
    };
    useEffect(() => {
        handleStartTime();
    }, []);
    useEffect(() => {
        getPatientDropdown(dispatch, org)
        getAllStaff(dispatch, org, -1, '');
        getAllRNIncharge(dispatch, 'Registered Nurse', org)
        return () => {
            setViewContent(false);
        }
    }, [dispatch, org]);

    // location , activity legend & Precaution
    useEffect(() => {
        fetchLocationDropdown(dispatch, org)
        fetchActivityLegend(dispatch, org)
        fetchPrecautionLegend(dispatch, org)
    }, [org])

    useEffect(() => {
        if (!noOfShift) return
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [noOfShift])
    useEffect(() => {
        HttpLogin.axios().get("/api/org/getById/" + org)
            .then((res) => {
                if (res.data.message.code === successCode) {
                    var newResult = res.data.data.shift.startTime !== "" ? res.data.data.shift.startTime : "08:00";
                    const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
                        let slotStart = new Date(fromTime).getTime();
                        let endEpoch = new Date(toTime).getTime();
                        let ob = [];

                        while (slotStart < endEpoch) {
                            ob.push(formatDate(slotStart));
                            slotStart += slotLength * 1000;
                        }

                        return ob;
                    };

                    const from = "2022-05-25 " + newResult;
                    const to = "2022-05-26 " + newResult;
                    const slotLength = 15 * 60;
                    var r = createTimeSlots(from, to, slotLength);
                    setNewTimeSlot(r);
                }
            })
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null
            ? moment(newInputData.startDate)
            : moment(selectedDate);

        var dayCalendar = [];
        dayCalendar.push(from.format("YYYYMMDD"));
        setAddCalendarDate(dayCalendar);
    }, [org, selectedDate]);
    useEffect(() => {
        if (selectShift) {
            var newResult = tableData[0]?.[`shift${selectShift.split('-')[1].trim()}Time`].split(' ')[0] + " " + tableData[0]?.[`shift${selectShift.split('-')[1].trim()}Time`].split(' ')[1];
            var newResult1 = tableData[0]?.[`shift${selectShift.split('-')[1].trim()}Time`].split(' ')[3] + " " + tableData[0]?.[`shift${selectShift.split('-')[1].trim()}Time`].split(' ')[4];
            const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
                let slotStart = new Date(fromTime).getTime();
                let endEpoch = new Date(toTime).getTime();
                let ob = [];

                while (slotStart < endEpoch) {
                    ob.push(formatDate(slotStart));
                    slotStart += slotLength * 1000;
                }

                return ob;
            };

            const from = "2022-05-25 " + newResult;
            const to = "2022-05-26 " + newResult1;
            const slotLength = 15 * 60;
            var r = createTimeSlots(from, to, slotLength);
            setNewTimeSlot1(r);
        }
    }, [selectShift])

    useEffect(() => {
        let newLoginstaff = staffData !== null && staffData !== undefined && staffData?.filter((t: any) => ((t.role === "Social Worker" || t.role === "Registered Nurse") && (t.id === userId)))
        if (newLoginstaff.length > 0) {
            setLoginStaff(newLoginstaff[0].name[0].given + " " + newLoginstaff[0].name[0].family);
        }
        // setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse"));
        // setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))
        setAddNewStaff(staffData !== null && staffData !== undefined ? staffData : []);
        setStaffData(staffData !== null && staffData !== undefined && staffData?.map((k: any) => { return k.name[0].given + " " + k.name[0].family }))
        
    }, [staffData, userId])

    useEffect(() => {
        if (!rnInchargeList) return
        setPatientData(rnInchargeList !== null && rnInchargeList !== undefined && rnInchargeList?.filter((t: any) => t.role === "Registered Nurse").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))
    }, [rnInchargeList])

    useEffect(() => {
        if (!selectedDate) return
        const handleGetQ15ReportData = async () => {
            if (!selectedDate) return
            var newFromDate = newInputData.startDate !== null ? moment(newInputData.startDate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD");
            var newToDate = newInputData.endDate !== null ? moment(newInputData.endDate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD");
            let enterBy = newInputData.criteria !== null ? newInputData.criteria : ""
            let PatientName = addPatientName?.name !== null ? addPatientName?.name : ""
            let NurseIncharge = addNurseIncharge !== null ? addNurseIncharge : ""
            // let apiUrl = `/api/config/getByDateRange?startDate=${newFromDate}&endDate=${newToDate}&organization=${org}`;
            let apiUrl: any = `${baseURL}/config/getByDateRange?endDate=${newToDate}&organization=${org}&startDate=${newFromDate}`

            if (newInputData?.criteria) {
                apiUrl += `&enteredBy=${newInputData?.criteria}`
            } else if (userType === "Staff") {
                let newStaffData = addNewStaff
                    .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === loginStaff)
                    .map((k) => k.id);
                apiUrl += `&enteredBy=${newStaffData[0]}`
            }
            if (addPatientName?.name) {
                apiUrl += `&patientName=${addPatientName.name}`;
            }
            if (addNurseIncharge) {
                apiUrl += `&shiftInchargeName=${addNurseIncharge}`;
            }
            if (selectShift) {
                apiUrl += `&shiftName=${selectShift}`
            }
            const { header1 } = authorize();
            setLoading1(true)
            try {
                const response = await axios.get(apiUrl, { headers: header1 })
                if (response.data.message.code === successCode) {
                    const uniqueBdates = new Set(response?.data?.data?.map((item: any) => item.date)); // Unique dates from b
                    const filtered = Array.from(new Set(addCalendarDate?.filter((date: any) => uniqueBdates.has(date))));
                    setFilterAddCalendarDate(filtered)
                    setTableData(response.data.data ?? []);
                    setLoading1(false)
                } else {
                    setFilterAddCalendarDate([])
                    setTableData([]);
                    setLoading1(false);
                }
            } catch (error: any) {
                console.error("API ERROR:", error)
                setLoading1(false)
            }
        }
        handleGetQ15ReportData()
    }, [addCalendarDate, newInputData, org, addPatientName, selectedDate, selectShift]);

    const decreaseOneMinute = (timeString: any) => {
        // Split the time string to extract hours and minutes
        if (!timeString) return
        const [hours, minutes] = timeString.split(':').map(Number);

        // Create a new Date object for today with the given time
        let date = new Date();
        date.setHours(hours, minutes, 0); // Set the time (HH:MM:SS)

        // Subtract 1 minute
        date.setMinutes(date.getMinutes() + 1);

        // Format the new time back to HH:mm format
        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    }
    const formatTimeTo12Hour = (time: any) => {
        let addTime = decreaseOneMinute(time)
        return moment(addTime, 'HH:mm').format('hh:mm A');
    };

    const formatTimeTo12Hour1 = (time: any) => {
        return moment(time, 'HH:mm').format('hh:mm A');
    };
    const handleDateChange = (event: any) => {
        const d = moment(event); // Use Moment.js directly
        let w = d.year().toString();

        if (w.length !== 4) return;

        newInputData.startDate = event;
        setNewInputData({ ...newInputData });

        let from = newInputData.startDate ? moment(newInputData.startDate) : moment(selectedDate);
        let to = newInputData.endDate ? moment(newInputData.endDate) : moment(selectedDate);

        let dayCalendar: string[] = [];

        for (let day = from.clone(); day.isSameOrBefore(to, "day"); day.add(1, "day")) {
            dayCalendar.push(day.format("YYYYMMDD"));
        }

        setAddCalendarDate(removeDuplicates(dayCalendar));
    };
    const handleNewDateChange = (event: any) => {
        const d = moment(event);
        let w = d.year().toString();

        if (w.length !== 4) return;

        newInputData.endDate = event;
        setNewInputData({ ...newInputData });

        let from = newInputData.startDate ? moment(newInputData.startDate) : moment();
        let to = newInputData.endDate ? moment(newInputData.endDate) : moment(calendarDate);

        let dayCalendar: string[] = [];

        for (let day = from.clone(); day.isSameOrBefore(to, "day"); day.add(1, "day")) {
            dayCalendar.push(day.format("YYYYMMDD"));
        }

        setAddCalendarDate(removeDuplicates(dayCalendar));
    };


    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [newExpanded, setNewExpanded] = useState<string | false>('panel11');
    const [newAddExpandeds, setNewAddExpandeds] = useState<string | false>('');
    const [newAddExpanded, setNewAddExpanded] = useState<string | false>('');
    const [loading, setLoading] = useState<boolean>(false)
    const [loading1, setLoading1] = useState<boolean>(false)
    const [shifts, setShifts] = useState<any>([]);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleNewChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setNewExpanded(newExpanded ? panel : false);
        };

    // const handleNewAddChanges = (panel: string, access: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    //     setNewAddExpandeds(isExpanded ? panel + "/" + access : false);
    // };
    const handleNewAddChanges = (panel: string, y: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setNewAddExpandeds(isExpanded ? `${panel}/${y}` : false);
    };
    // const handleNewAddChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    //     setNewAddExpanded(isExpanded ? panel : false);
    // };
    const handleNewAddChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setNewAddExpanded(isExpanded ? panel : false);
    };
    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        // Convert the start time string to a Date object
        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        // Function to convert 24-hour time to 12-hour time with AM/PM
        const to12HourFormat = (date: any) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? "PM" : "AM";

            // Convert hour to 12-hour format
            hours = hours % 24;
            if (hours === 0) {
                hours = '00'; // Midnight or noon case
            }

            // Add leading zero for minutes if needed
            minutes = minutes < 10 ? "0" + minutes : minutes;

            // Return formatted time with AM/PM
            return `${hours}:${minutes} `;
        };

        for (let i = 0; i < totalShifts; i++) {
            // Calculate the end time by adding the duration to the start time
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            ); // duration in hours

            // Format start and end times into 12-hour format with AM/PM
            const startTimeFormatted = to12HourFormat(startTime);
            const endTimeFormatted = to12HourFormat(endTime);

            // Assign shift name (A, B, C, etc.)
            const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

            // Push shift details into the array
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                dayShift: "",
            });

            // Update the start time for the next shift
            startTime = endTime;
        }
        const convertTo24Hour = (time: string) => {
            const [hour, minuteWithPeriod] = time.split(":");
            const [minute, period] = minuteWithPeriod.split(" ");
            let hour24 = parseInt(hour);

            // Handle AM/PM conversion
            if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
            if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

            return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
        };

        const addDayShift = (shift: Shift, addShift: boolean): Shift => {
            const startInMinutes = convertTo24Hour(shift.start_time);
            const endInMinutes = convertTo24Hour(shift.end_time);
            if (endInMinutes < startInMinutes) {
                return { ...shift, dayShift: "+1" };
            }
            if (addShift) {
                return { ...shift, dayShift: "+1" };
            }
            return { ...shift, dayShift: "" };
        };
        let addShift = false;

        // Apply the dayShift logic to each shift
        const updatedShifts = shifts.map((shift: any) => {
            // If we encounter a shift that crosses midnight, set addShift to true
            if (
                !addShift &&
                convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
            ) {
                addShift = true;
            }
            return addDayShift(shift, addShift);
        });
        setShifts(updatedShifts);
    };

    let [viewContentData, setViewContent] = useState(false);
    const [hiddenBox, setHiddenBox] = useState<boolean>(false)

    const handlePrint = () => {
        setIsHidden(true);
        setTimeout(() => {
            const printContents = document.getElementById('q15Reports')!.innerHTML;
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.open();
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Q15 Report</title>
                            <style>
                                /* General styles for printing */
                                @media print {
                                    body {
                                        font-family: Arial, sans-serif;
                                        margin: 0;
                                        padding: 0;
                                    }
                                    #q15Reports {
                                        width: 100%;
                                        margin: 0;
                                        padding: 0;
                                        page-break-inside: avoid; /* Prevent content from breaking within elements */
                                    }
                                    * {
                                        box-sizing: border-box;
                                    }
                                    /* Remove unwanted margins or padding */
                                    html, body {
                                        width: 100%;
                                        height: auto;
                                    }
                                    /* Ensure no blank space at the end */
                                    div {
                                        page-break-after: avoid;
                                    }
                                }
                            </style>
                        </head>
                        <body>
                            <div id="q15Reports">${printContents}</div>
                        </body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.onload = () => {
                    printWindow.print();
                    printWindow.close();
                    setIsHidden(false);
                };
            }
        }, 0);
    };

    const handleDownload = async () => {
        if (tableData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < tableData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(tableData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);


                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${reports.id}`);
                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height;
                    const images: any[] = [];
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                allowTaint: true,
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                                imageTimeout: 35000,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientName}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }

    return (
        <div className="row d-flex flex-column" style={{ width: "100%" }}>
            {loading && <Loader />}
            {loading1 && <Loader />}
            <div hidden={viewContentData} className="row" style={{ display: 'flex', marginLeft: '10px', gap: "10px", justifyContent: "flex-end" }}>
                <div className="col-md-3">
                    {path && <h5 style={{ float: 'left' }}>Q15-Report</h5>}
                </div>
                <div className="col-md-8">
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><IoPrintOutline style={{ color: 'black', fontSize: '20px' }} />Print</button>
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={() => setViewContent(true)}>View</button>
                    <FormControlLabel
                        style={{ float: 'right', marginLeft: '5px', width: '180px' }}
                        control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                        label={'Legends Key'}
                    />
                </div>
            </div>
            <div hidden={viewContentData} className="row" style={{ height: '83vh' }} >
                <div className="col-md-3 d-flex flex-column pe-1 pt-3" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker className="m-3 " label="Start Date" value={newInputData.startDate}
                            onChange={handleDateChange} />
                        <DatePicker className="m-3" label="End Date" value={newInputData.endDate}
                            onChange={(e) => { handleNewDateChange(e) }} />
                    </LocalizationProvider>
                    <Autocomplete
                        className="m-3"
                        options={option}
                        getOptionLabel={(option) => option}
                        value={selectShift}
                        onChange={(e, v) => {
                            // if (reportData.length > 0) {
                            setSelectShift(v)
                            // setViewContent(!viewContentData)
                            // handlesumbit()
                            // setCheckSubmit(true)
                            // } else {
                            //     setSelectShift(v)
                            // }
                        }}
                        sx={{}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Shift"
                                placeholder="Shift"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={patientDropdown}
                        getOptionLabel={(option: any) => option.name || ""}
                        value={addPatientName !== null ? addPatientName : ''}
                        // onChange={(e, v: any) => {
                        //     setAddPatientName(v);
                        //     const matchedPatient = patientDropdown?.find(
                        //         (patient: any) => patient.name === v.name
                        //     );
                        //     console.log(v,matchedPatient, "ofthevalue")
                        //     if (matchedPatient) {
                        //         newInputData.patientName = matchedPatient.id;
                        //         setNewInputData({ ...newInputData });
                        //     }
                        // }}
                        onChange={(e, v: any) => {
                            setAddPatientName(v);
                            if (v && v.name) {
                                const matchedPatient = patientDropdown?.find(
                                    (patient: any) => patient.name === v.name
                                );
                                console.log(v, matchedPatient, "ofthevalue");
                                if (matchedPatient) {
                                    newInputData.patientName = matchedPatient.id;
                                    setNewInputData({ ...newInputData });
                                }
                            } else {
                                newInputData.patientName = "";
                                setNewInputData({ ...newInputData });
                            }
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Patient Name"
                                placeholder="Patient Name"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getPatientData}
                        getOptionLabel={(option: any) => option}
                        value={addNurseIncharge || ""}
                        onChange={(e, v) => {
                            // addNurseIncharge = v;
                            if (v) {
                                setAddNurseIncharge(v);
                                let newStaffData = rnInchargeList.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k: any) => { return k.id });
                                newInputData.shiftIncharge = newStaffData[0];
                                setNewInputData({ ...newInputData });
                            } else {
                                setAddNurseIncharge("");
                                newInputData.shiftIncharge = "";
                                setNewInputData({ ...newInputData });
                            }
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Nurse Incharge"
                                placeholder="Nurse Incharge"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getStaffData}
                        disabled={userType === 'Staff' && loginStaff.length > 0}
                        getOptionLabel={(option) => option}
                        value={userType === "Staff" && loginStaff.length > 0 ? loginStaff : addEnteredBy || ""}
                        onChange={(e, v) => {
                            // addEnteredBy = v;
                            if (v) {
                                setAddEnteredBy(v);
                                let newStaffData = addNewStaff
                                    .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === v)
                                    .map((k) => k.id);
                                newInputData.criteria = newStaffData[0];
                                console.log(v, newStaffData[0], 'enterByValue')
                                setNewInputData({ ...newInputData });
                            } else {
                                setAddEnteredBy("");
                                newInputData.criteria = "";
                                setNewInputData({ ...newInputData });
                            }
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Entered By"
                                placeholder="Entered By"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />

                </div>
                <div className="col-md-9" style={{ overflow: 'auto', height: '83vh' }}>
                    {FilteraddCalendarDate !== undefined && FilteraddCalendarDate !== null && FilteraddCalendarDate.length !== 0 ? FilteraddCalendarDate.map((u: any) => (
                        <Accordion expanded={expanded === 'panelmain' + u} onChange={handleChange('panelmain' + u)}>
                            <AccordionSummary style={{ backgroundColor: "#FFF" }} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDropDownIcon />}>
                                <Typography style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <span style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                        {moment(u).format("MMM DD, YYYY")}
                                    </span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography >
                                    {tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.date).format("YYYYMMDD")).length > 0 ? (tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.date).format("YYYYMMDD")).map((l: any, y: any) => (
                                                <Accordion style={{ borderStyle: "none" }} expanded={newExpanded === 'panel11'} onChange={handleNewChange('panel11')}>
                                                    <AccordionSummary style={{ backgroundColor: "#FFF" }} expandIcon={false} aria-controls="panel11d-content" id="panel11d-header">
                                                        <Typography style={{ width: "-webkit-fill-available" }}>
                                                            <div>
                                                                <div style={{ position: 'relative', width: '100%', top: '18px', left: '-10px' }}>
                                                                    {
                                                                        Array.from({ length: !selectShift ? noOfShift : 1 }).map((_, index) => (
                                                                            <Accordion
                                                                                style={{ borderStyle: "none", top: '-12px' }}
                                                                                expanded={newAddExpanded === `Shift ${String.fromCharCode(65 + index)}: ${l.patientName}` || newAddExpandeds === `Shift ${String.fromCharCode(65 + index)}` + "/" + y}
                                                                                onChange={(event, isExpanded) => {
                                                                                    if (newAddExpandeds === `Shift ${String.fromCharCode(65 + index)}` + "/" + y) {
                                                                                        handleNewAddChanges(`Shift ${String.fromCharCode(65 + index)}`, y)(event, isExpanded);
                                                                                    } else {
                                                                                        handleNewAddChange(`Shift ${String.fromCharCode(65 + index)}: ${l.patientName}`)(event, isExpanded);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <AccordionSummary style={{ backgroundColor: "#F7FAFE", borderRadius: '6px' }} aria-controls="panel11Ad-content" id="panel11Ad-header">
                                                                                    <Typography>
                                                                                        <div className="d-flex">
                                                                                            <span className="" style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", position: "relative", top: "0px", width: '240px' }}>
                                                                                                {selectShift ? selectShift : `Shift ${String.fromCharCode(65 + index)}:`}
                                                                                                <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal" }}>
                                                                                                    {' '}
                                                                                                    {
                                                                                                        selectShift !== "" && selectShift !== null && selectShift !== undefined
                                                                                                            ? l?.[`shift${selectShift.split('-')[1].trim()}Time`]
                                                                                                            : shifts.length > 0
                                                                                                                ? `${formatTimeTo12Hour(shifts[index]?.start_time)} to ${formatTimeTo12Hour1(shifts[index]?.end_time)}`
                                                                                                                : null
                                                                                                    }
                                                                                                    {/* {' '}{selectShift ? l?.[`shift${selectShift.split('-')[1].trim()}Time`] : shifts.length > 0 && formatTimeTo12Hour(shifts[index]?.start_time)} to {shifts.length > 0 && formatTimeTo12Hour1(shifts[index]?.end_time)} */}
                                                                                                </span>
                                                                                            </span>
                                                                                            <span className="" style={{ fontFamily: "poppins", color: "#000", fontSize: "13px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", display: 'flex', alignItems: 'center' }} >
                                                                                                {
                                                                                                    selectShift ? l?.shiftIncharge?.[`shiftInchargeName${selectShift.split('-')[1].trim()}`] !== null && `Shift Incharge - ${l?.shiftIncharge?.[`shiftInchargeName${selectShift.split('-')[1].trim()}`] ?? ""}` : l?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + index)}`] !== null && (`Shift Incharge - ` + l?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + index)}`])
                                                                                                }
                                                                                                {/* {l?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + index)}`] !== null && `Shift Incharge - ` + l?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + index)}`]} */}
                                                                                            </span>
                                                                                            <div style={{ position: "absolute", left: "unset", whiteSpace: "nowrap", right: "90px", width: "131px", display: "flex", gap: "7px" }}>
                                                                                                {/* <img alt="" src={l?.patientLogo} ></img> */}
                                                                                                {
                                                                                                    l?.patientLogo
                                                                                                        ?
                                                                                                        <Avatar
                                                                                                            sx={{ width: 30, height: 30, backgroundColor: '#9F9FA2', marginRight: '0px' }}
                                                                                                            src={l?.patientLogo}
                                                                                                        >
                                                                                                        </Avatar>
                                                                                                        :
                                                                                                        <Avatar
                                                                                                            sx={{ width: 30, height: 30, backgroundColor: '#9F9FA2', marginRight: '0px' }}
                                                                                                        >
                                                                                                        </Avatar>
                                                                                                }
                                                                                                <div style={{ fontFamily: "poppins", color: "#000", fontSize: "12px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal", display: 'flex', alignItems: 'center' }}>Patient: {l.patientName}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Typography>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails style={{ backgroundColor: "#F7FAFE", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                                                                                    <Typography style={{ height: selectShift ? "380px" : "448px", backgroundColor: "#F7FAFE", cursor: 'auto' }}>
                                                                                        {
                                                                                            Array.from({ length: noOfSlot }).map((_, ind) => (

                                                                                                <div className="my-3" style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <div className="" style={{ width: "200px", fontFamily: "poppins", fontSize: "12px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>
                                                                                                        <div>
                                                                                                            {
                                                                                                                selectShift
                                                                                                                    ? `${formatTimeTo12Hour(newTimeSlot1[getAccordionDate(ind, index)])} to ${newTimeSlot1.length > 0 && formatTimeTo12Hour(newTimeSlot1[getAccordionDate1(ind + 1, index)])}`
                                                                                                                    : `${newTimeSlot.length > 0 && formatTimeTo12Hour(newTimeSlot[getAccordionDate(ind, index)])} to ${newTimeSlot.length > 0 && formatTimeTo12Hour(newTimeSlot[getAccordionDate1(ind + 1, index)])}`
                                                                                                            }
                                                                                                            {/* {newTimeSlot.length > 0 && formatTimeTo12Hour(newTimeSlot[getAccordionDate(ind, index)])} to {newTimeSlot.length > 0 && formatTimeTo12Hour(newTimeSlot[getAccordionDate1(ind + 1, index)])}: */}
                                                                                                        </div>
                                                                                                        <div className="d-flex gap-1  my-4" style={{}}>
                                                                                                            <div className=" d-flex align-self-center" style={{ width: "fit-contant" }}>Entered By: </div>
                                                                                                            {/* <img alt="" src={Rectangle6215}></img> */}
                                                                                                            <div className=" d-flex align-self-center" style={{ fontFamily: "poppins", fontSize: "10px", fontWeight: 600, color: "#000", lineHeight: "normal" }}>
                                                                                                                {
                                                                                                                    selectShift ? l?.enteredBy?.[`shift${selectShift.split('-')[1].trim()}`]?.[`slotName${ind + 1}`] : l?.enteredBy?.[`shift${String.fromCharCode(65 + index)}`]?.[`slotName${ind + 1}`] ?? "--"
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {l.data !== null && l.data !== undefined && l.data.length > 0 && l?.data?.map((s: any, t: number) => {
                                                                                                        return t >= Number(getReturnValue(ind, index)) && t <= Number(getReturnValue1(ind + 1, index)) && (
                                                                                                            <div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                                                <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "12px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{s.time && s.time.split(" ")[0] + " " + s.time.split(' ')[3]} </span>
                                                                                                                <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                                                <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{s.location !== null && s.location !== "" ? s.location : "NA"}{"-"}{s.activity !== null && s.activity !== "" ? s.activity : "NA"}</span>
                                                                                                            </div>)
                                                                                                    })}
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </Typography>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    </AccordionSummary>
                                                </Accordion>))) : (
                                        <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                                    )
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )) : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>}
                </div>
            </div>
            <div id="q15Reports" hidden={!viewContentData}>
                {viewContentData && !isHidden && (
                    <div className="d-flex flex-row justify-content-end gap-2" hidden={hiddenBox} >
                        <FormControlLabel
                            style={{ float: 'right', marginLeft: '5px', width: '150px' }}
                            control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                            label={'Legends Key'}
                        />
                        <FormControlLabel
                            style={{ float: 'right', marginLeft: '5px', width: '200px' }}
                            control={<Checkbox checked={timeFormat} onChange={handleTimeFormat} />}
                            label={'24Hour Time Format'}
                        />
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => { setHiddenBox(true); handleDownload(); }}>Download</button>
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => { setHiddenBox(true); handlePrint(); }}>Print</button>
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => setViewContent(false)}>Close</button>
                    </div>
                )}
                {
                    tableData.length > 0 && Array.isArray(tableData) ?
                        <ReportSheetView reportData={tableData} checkPrecaution={'Q15'} locationLegend={locationLegend} activityLegend={activityLegend} checkBox={undefined} legendcontrol={legendcontrol} InxNum={undefined} oneHour={false} logo={orgData.logo} timeFormat={timeFormat} selectShift={selectShift} />
                        : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                }
            </div>
        </div>
    );
};