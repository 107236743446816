import React, { useEffect, useState } from 'react'
import Calendar from '../calendar/index';
import moment from 'moment';
import { useSelector } from 'react-redux';
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';


interface DateBoxProps {
  selectedDate: any
  setSelectedDate: any
  handleDateChange: any
  countCheck: any
  dateRange: any
}
const DateBox: React.FC<DateBoxProps> = ({ selectedDate, setSelectedDate, handleDateChange, countCheck, dateRange }) => {
  const { orgTimeZone } = useSelector((state: any) => state.LargeScreen)
  const { shiftStartTime,shiftDuration } = useSelector((state: any) => state.Org);
  const [checkPreDate, setCheckPreDate] = useState<any>()

  const handlecount = 4
  const checkDate = () => {
    // dateRange.start_time
    // dateRange.end_time
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date1 = moment(`2025-01-08T${dateRange?.start_time}`)
    const date2 = moment(`2025-01-08T${dateRange?.end_time}`)
    const zero = moment(`2025-01-08T00:00`)
    if (dateRange.start_time !== shiftStartTime) {
      if (date1.isAfter(shiftTimeStart)) {
        if (date2.isAfter(shiftTimeStart)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!dateRange) return
    setCheckPreDate(checkDate())
  }, [dateRange])

  const renderDateBoxes = () => {
    const dateBoxes = [];
    let selectedMoment = moment.tz(selectedDate, orgTimeZone);

    for (let i = -3; i < handlecount; i++) {
      let currentMoment = selectedMoment.clone().add(i, 'days');
      const date = currentMoment.date();
      const year = currentMoment.year();
      const month = (currentMoment.month() + 1).toString().padStart(2, '0');

      window.localStorage.setItem("getByDate", `${year}${month}${date}`);

      dateBoxes.push(
        <Calendar
          key={currentMoment.format('YYYY-MM-DD')}
          day={currentMoment.format('ddd')}
          date={date}
          onClick={() => {
            // setSelectedDate(currentMoment.format('YYYY-MM-DD'))
            if (countCheck >= 0) {
              handleDateChange(currentMoment.format('YYYY-MM-DD'))
            } else {
              setSelectedDate(currentMoment.format('YYYY-MM-DD'))
            }
          }}
          isSelected={selectedMoment?.isSame(currentMoment, 'day')}
          isPreSelected={checkPreDate ? selectedMoment?.isSame((currentMoment.clone().add(-1, 'days')) , 'day')  : false}
          selectedShift={checkPreDate}
        />
      );
    }
    return dateBoxes;
  };
  return (
    <>
      <>
        {renderDateBoxes()}
        <div className="inpMain">
          <input
            type="date"
            value={selectedDate}
            onChange={(e: any) => {
              if (e.target.value) {
                // alert(e.target.value)
                if (countCheck >= 0) {
                  const datePicker = moment(e.target.value).format('YYYY-MM-DD')
                  handleDateChange(datePicker)
                } else {
                  handleDateChange(e.target.value)
                }
              }
            }}
          />
          <div style={{ border: "1px groove" }} />
          <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
        </div>
      </>
    </>
  )
}


export default DateBox