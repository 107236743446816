import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OneToOneReportView from '../OneToOneReport/OneToOneReport';
import ControlledAccordions from '../q15Report';
import { grey, primarytext, secondarybt } from '../../common/primary';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivityLegend, fetchLocationLegend, fetchPrecautionLegend, getAllStaff, getPatientDropdown } from '../../slices/thunk';
import moment from 'moment';
import { Button, Table } from 'reactstrap';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import MultipleReportView from '../OneToOneReport/MultipleReportView';
import printImage from '../../assets/images/printer.svg'
import ReportView from './ReportView';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Loader from '../../components/loader/Loader';
import OneHourReport from '../OnehourReport';

const AllReport = () => {
    const addInputData = {
        date: '',
        patientName: '',
        staffName: ''
    }
    const { orgData } = useSelector((state: any) => state.Org);
    const [values, setValues] = useState<any>('')
    const [value, setValue] = React.useState('1');
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    };
    const org = useSelector((state: any) => state.Login.organization)

    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const dispatch = useDispatch<any>()
    // const option = ['Shift-A', 'Shift-B', 'Shift-C']
    const [option, setOption] = useState<any>([])
    let [addPatientName, setAddPatientName] = useState<any>("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [multiReportData, setMultiReportData] = useState<any>()
    const [reportData, setReportData] = useState<any>([])
    const [newInputData, setNewInputData] = useState(addInputData)
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    let [outputSave, setOutputSave] = useState(false);
    const [checkSubmit, setCheckSubmit] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkSelect, setCheckSelect] = useState<number>(1)
    const [viewContentData, setViewContent] = useState<boolean>(true);
    const [accordionView, setAccordionView] = useState<boolean>(false)
    let [dates, setDates] = useState<any>();
    let [selectdate, setSelectDate] = useState<any>(new Array<any>());
    const [singleDate, setSingleDate] = useState<any>();
    const [selectShift, setSelectShift] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [selectShiftView, setSelectShiftView] = useState<boolean>(false);
    const [checkBox, setCheckBox] = useState<boolean>(false);
    const [checkPrecaution, setCheckPrecaution] = useState<any>('')
    const { locationLegend, activityLegend } = useSelector((state: any) => state.Dropdown);

    const [legendcontrol, setLegendControl] = useState<boolean>(false)
    const [timeFormat, setTimeFormat] = useState<boolean>(false)

    const handleTimeFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeFormat(e.target.checked)
    }
    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegendControl(e.target.checked)
    }

    const [noOfShift, setNoOfShift] = useState<any>(0);

    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return 24 / duration;
    };
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
    }, [orgData])

    useEffect(() => {
        if (noOfShift) {
            setOption(Array.from({ length: noOfShift }, (_, i) => `Shift-${String.fromCharCode(65 + i)}`));
        }
    }, [noOfShift]);

    // EyeSight Tab click to initial value
    const handleEyesight = () => {
        setCheckPrecaution("Eyesight")
        setSelectedRows([])
        setReportData([])
        setReportData([])
        setNewInputData(addInputData)
        setCheckSelect(1)
        setAddPatientName('')
        setValues('')
        setSelectShift('')
        setDisabled(false)
        setSelectShiftView(false)
        setViewContent(true)
    }

    // location , activity & precaution legend 
    useEffect(() => {
        fetchLocationLegend(dispatch, org)
        fetchActivityLegend(dispatch, org)
        fetchPrecautionLegend(dispatch, org)
    }, [org])

    useEffect(() => {
        getPatientDropdown(dispatch, org)
    }, [org])

    useEffect(() => {
        setDates(selectdate ? selectdate?.map((item: any, inx: number) => { return item.date }) : []);
        if (selectdate === null || selectdate === undefined) {
            setValues('')
        }
    }, [selectdate, addPatientName, newInputData]);

    const handleCheckSelect = (n: any) => {
        setCheckSelect(n)
        if (n === 1) {
            setAccordionView(false)
            setSelectedRows([])
            setReportData([])
        } else {
            setOutputSave(false)
            setSelectedRows([])
            setReportData([])
        }
    }

    // Proxmity select checkbox function
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(e.target.checked);
    };

    // Patient Report Date UseEffect
    useEffect(() => {
        const getDropDownDate = async () => {
            try {
                setLoading(true)

                // let ApiUrl: any = `${baseURL}/register1to1/getDateList?patientId=${newInputData.patientName}`
                // if (newInputData.staffName) {
                //     ApiUrl += `&staffId=${newInputData.staffName}`
                // } else if (userType === "Staff") {
                //     ApiUrl += `&staffId=${loginStaff}`
                // }
                let ApiUrL: any = `${baseURL}/registereyesight/getDateList?patientId=${addPatientName?.id}`
                if (newInputData.staffName) {
                    ApiUrL += `&staffId=${newInputData.staffName}`
                } else if (userType === "Staff") {
                    ApiUrL += `&staffId=${loginStaff}`
                }
                const response = await axios.get(ApiUrL)
                if (response) {
                    setSelectDate(response.data.data)
                    setLoading(false)
                } else {
                    console.error("Error");
                    setSelectDate([]);
                    setLoading(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSelectDate([]);
                setLoading(false)
            }
        }
        getDropDownDate();
    }, [newInputData, addPatientName]);
    // Tomorrow Date 
    const [nextDate, setNextDate] = useState<any>('')
    useEffect(() => {
        if (!singleDate) return;

        const validDate = moment(singleDate[0]);
        // console.log(validDate, 'validDate')
        if (validDate.isValid()) {
            const nextDay = validDate.clone().add(1, 'days');
            setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
        } else {
            console.error("Invalid date:", singleDate);
        }
    }, [singleDate]);

    useEffect(() => {
        if (!values) return
        setNewInputData({ ...newInputData, date: moment(value).format("YYYYMMDD") });
    }, [values])

    // select Mulit Date
    const handleRowSelected = (item: string) => {
        const index = selectedRows.findIndex(selectedItem => selectedItem === moment(item).format("YYYYMMDD"));
        if (index > -1) {
            setSelectedRows(selectedRows.filter((_, i) => i !== index));
        } else {
            setSelectedRows([...selectedRows, moment(item).format("YYYYMMDD")]);
        }
    };

    const handlesumbit = async () => {

        if (checkSelect === 1) {
            let newErrors = {
                patientName: !addPatientName?.id,
                date: !newInputData.date
            };
            setNameError(newErrors);
            const hasErrors = Object.values(newErrors)?.some(error => error);
            if (hasErrors) {
                setCheckSubmit(true)
                toast.error("Please Fill Required Field")
                return;
            }
        } else {
            let newErrors = {
                patientName: !addPatientName?.id,
                selectedRows: !selectedRows?.length
            };
            setNameError(newErrors);
            const hasErrors = Object.values(newErrors)?.some(error => error);
            if (hasErrors) {
                setCheckSubmit(true)
                toast.error("Please Fill Required Field")
                return;
            }
        }
        try {
            setLoading(true)
            setMultiReportData([])
            setReportData([])
            let bodyDate: any[];
            if (checkSelect === 1) {
                bodyDate = singleDate
            } else {
                bodyDate = selectedRows
            }
            let ApiURL = `${baseURL}/registereyesight/geteyesightReportForMultipleDate?organization=${org}&patientId=${addPatientName?.id}`
            if (selectShift !== null && selectShift !== undefined && selectShift !== "") {
                ApiURL += `&shiftName=${selectShift}`
            }
            const response = await axios.post(ApiURL, { dates: bodyDate })
            if (response?.data.message && response?.data.message.code === successCode) {
                if (checkSelect === 1) {
                    setReportData(response.data.data)
                    setSelectShiftView(selectShift ? true : false)
                    setCheckBox(disabled)
                    setViewContent(false)
                } else {
                    setAccordionView(true)
                    setMultiReportData(response.data.data)
                    setReportData(response.data.data)
                    setSelectShiftView(selectShift ? true : false)
                    setCheckBox(disabled)
                    // setViewContent(!viewContentData)
                    if (checkSubmit === false) {
                        setViewContent(!viewContentData)
                    }
                }
            } else {
                console.error("Error:", response.data.message);
            }
            bodyDate = []
            setLoading(false)
        } catch (error: any) {
            console.error("API Request Error", error)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (selectShift === null) {
            handlesumbit()
        }
    }, [selectShift])
    //print page
    const handlePrint = () => {
        let printContents = document.getElementById('1to1AllReport')!.innerHTML;
        let printWindow = window.open('', '_blank');
        printWindow!.document.body.innerHTML = printContents;
        printWindow!.print();
        printWindow!.close();
    }
    // Pdf genrate to download
    const handleDownload = async () => {
        if (reportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < reportData?.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(reportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);


                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);
                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height;
                    const images: any[] = [];
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                allowTaint: true,
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                                imageTimeout: 35000,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    };

    // For Eye sight enterBy Staff dropwon and select state
    const { userData, userType } = useSelector((state: any) => state.Login);
    const userId = userData.userDetail.id;
    let [getStaffData, setStaffData] = useState(new Array<any>());
    const { staffData } = useSelector((state: any) => state.Staff);
    let [loginStaff, setLoginStaff] = useState<any>("");
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    let [addEnteredBy, setAddEnteredBy] = useState<any>("");
    useEffect(() => {
        getAllStaff(dispatch, org, -1, '');
    }, [dispatch, org]);
    useEffect(() => {
        let newLoginstaff = staffData !== null && staffData !== undefined && staffData?.filter((t: any) => ((t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner") && (t.id === userId)))
        if (newLoginstaff.length > 0) {
            setLoginStaff(newLoginstaff[0].name[0].given + " " + newLoginstaff[0].name[0].family);
        }
        setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
        setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))

    }, [staffData, userId])
    return (
        <div className='m-1' style={{ height: '91vh', overflowY: 'hidden' }}>
            <div style={{ height: '5vh' }} className=''>
                <h5 >REPORTS</h5>
            </div>
            <Box sx={{ height: '86vh' }}>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {
                                orgData?.q15Access === 'Yes' &&
                                <Tab label="Q15-Report" value="1" onClick={() => { setCheckPrecaution("Q15") }} />
                            }
                            {
                                orgData?.q15OneToOne &&
                                <Tab label="One-To-One" value="2" onClick={() => { setCheckPrecaution("1:1") }} />
                            }
                            {
                                orgData?.eyeSight &&
                                <Tab label="Eyesight" value="3" onClick={() => { handleEyesight() }} />
                            }
                            {
                                orgData?.q15OneHour &&
                                <Tab label="One-Hour" value="4" onClick={() => { setCheckPrecaution("One-hour") }} />
                            }
                        </TabList>
                    </Box>
                    <Box sx={{ height: '78vh' }}>
                        <div className='hidden-scrollbar' style={{ height: '78vh', overflowY: 'scroll' }} >
                            {
                                orgData?.q15Access === 'Yes' &&
                                <TabPanel value="1" style={{ padding: '5px' }}>
                                    <div>
                                        <ControlledAccordions />
                                    </div>
                                </TabPanel>
                            }
                            {
                                orgData?.q15OneToOne &&
                                <TabPanel value="2" style={{ padding: '5px' }}>
                                    <div>
                                        <OneToOneReportView checkPrecaution={checkPrecaution} />
                                    </div>
                                </TabPanel>
                            }
                            {
                                orgData?.eyeSight &&
                                <TabPanel value="3" style={{ padding: '5px' }}>
                                    <div className='container-fluid overflow-y-auto pb-5'>
                                        {loading && <Loader />}
                                        <div hidden={checkSelect === 2 && !viewContentData ? true : false}>
                                            <div className='pt-2'>
                                                <div className="d-flex justify-content-between">
                                                    <div className='col-md-3' ></div>
                                                    <div className="col-md-8 d-flex justify-content-end ">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={disabled} onChange={handleCheck} />}
                                                            label="Proximity"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                                                            label={'Legends Key'}
                                                        />
                                                        <FormControlLabel
                                                            style={{ width: '200px' }}
                                                            control={<Checkbox checked={timeFormat} onChange={handleTimeFormat} />}
                                                            label={'24Hour Time Format'}
                                                        />
                                                        <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => {
                                                            if (checkSelect === 1) {
                                                                if (reportData?.length === 0) {
                                                                    handlesumbit()
                                                                    handleDownload()
                                                                }
                                                                handleDownload()
                                                            } else if (checkSelect === 2) {
                                                                if (!reportData.length) {
                                                                    handlesumbit()
                                                                    setCheckSubmit(false)
                                                                } else {
                                                                    setViewContent(!viewContentData)
                                                                }
                                                            }
                                                        }}
                                                        // disabled={!viewContentData}
                                                        >
                                                            {checkSelect === 1 ? 'Download' : 'View'}
                                                        </button>
                                                        {
                                                            checkSelect === 1 &&
                                                            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><img src={printImage} />Print</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className=" d-flex flex-row mt-2" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px", height: '100px', width: '100%' }}>
                                                        <div className='col-2  d-flex justify-content-center align-items-center '>
                                                            <div className='' >
                                                                <div className=''>
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={checkSelect === 1 ? true : false} onChange={() => { handleCheckSelect(1) }} />}
                                                                        label="Single Report"
                                                                    />
                                                                </div>
                                                                <div className=''>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox checked={checkSelect === 2 ? true : false}
                                                                                onChange={() => {
                                                                                    if (!viewContentData) {
                                                                                        setViewContent(true)
                                                                                    }
                                                                                    handleCheckSelect(2)
                                                                                }
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Multi Report"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3  d-flex justify-content-center align-items-center'>
                                                            <Autocomplete
                                                                options={patientDropdown}
                                                                getOptionLabel={(option: any) => option.name || ""}
                                                                value={addPatientName}
                                                                onChange={(e, v: any) => {
                                                                    setAddPatientName(v);
                                                                    if (v) {
                                                                        setSelectedRows([]);
                                                                        setMultiReportData([]);
                                                                        setReportData([]);
                                                                        const matchedPatient = addNewPatient?.find(
                                                                            (m: any) =>
                                                                                `${m.basicDetails?.[0]?.name?.[0]?.given || ""} ${m.basicDetails?.[0]?.name?.[0]?.family || ""}` === v
                                                                        );
                                                                        if (matchedPatient) {
                                                                            newInputData.patientName = matchedPatient.id;
                                                                            setNewInputData({ ...newInputData, date: "" });
                                                                        }
                                                                        setNameError({ ...nameError, patientName: false });
                                                                        setOutputSave(false);
                                                                        setCheckSubmit(true);
                                                                        if (checkSelect === 1) {
                                                                            setViewContent(true);
                                                                        }
                                                                    }
                                                                }}
                                                                sx={{ width: "90%" }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Patient Name"
                                                                        margin="none"
                                                                        fullWidth
                                                                        error={!!nameError.patientName}
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className='col-2 d-flex justify-content-center align-items-center '>
                                                            <Autocomplete
                                                                className="my-3"
                                                                id="criteria"
                                                                options={getStaffData}
                                                                disabled={userType === 'Staff' && loginStaff.length > 0}
                                                                getOptionLabel={(option) => option}
                                                                value={userType === "Staff" && loginStaff.length > 0 ? loginStaff : addEnteredBy || ""}
                                                                onChange={(e, v) => {
                                                                    addEnteredBy = v;
                                                                    setAddEnteredBy(v);
                                                                    let newStaffData = addNewStaff
                                                                        .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === v)
                                                                        .map((k: any) => k.id);
                                                                    // newInputData.criteria = newStaffData[0];
                                                                    // setNewInputData({ ...newInputData });
                                                                    setNewInputData({ ...newInputData, staffName: newStaffData[0] })
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Entered By"
                                                                        placeholder="Entered By"
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className='col-2  d-flex justify-content-center align-items-center  ' >
                                                            <Autocomplete
                                                                options={dates}
                                                                getOptionLabel={(option) => option}
                                                                value={outputSave === true ? values : ''}
                                                                onChange={(e, v: any) => {
                                                                    setValues(v);
                                                                    setSingleDate([moment(v).format("YYYYMMDD")])
                                                                    // handleRowSelected(v)
                                                                    // setSelectedRows([...selectedRows, moment(v).format("YYYYMMDD")])
                                                                    setNameError({ ...nameError, date: false });
                                                                    setOutputSave(true);
                                                                    if (checkSelect === 1) {
                                                                        setViewContent(true)
                                                                    }
                                                                }}
                                                                freeSolo
                                                                sx={{ width: "82%" }}
                                                                disabled={checkSelect === 2 ? true : false}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Select Date"
                                                                        error={!!nameError.date}
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        <div className='col-2  d-flex justify-content-center align-items-center  '>
                                                            <Autocomplete
                                                                options={option}
                                                                getOptionLabel={(option) => option}
                                                                value={selectShift}
                                                                onChange={(e, v) => {
                                                                    if (reportData.length > 0) {
                                                                        setSelectShift(v)
                                                                        // setViewContent(!viewContentData)
                                                                        // handlesumbit()
                                                                        // setCheckSubmit(true)
                                                                    } else {
                                                                        setSelectShift(v)
                                                                    }
                                                                }}
                                                                sx={{ width: "82%" }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Shift"
                                                                        placeholder="Shift"
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className='col-1  d-flex justify-content-center align-items-center     '>
                                                            <Button label='submit' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => {
                                                                handlesumbit()
                                                                setCheckSubmit(true)
                                                            }}>Submit</Button>
                                                        </div>
                                                    </div>
                                                    {
                                                        checkSelect === 2 &&
                                                        <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }} className=''>
                                                            {(addPatientName && dates && dates.length > 0) && (
                                                                // {(addPatientName && dates) && (
                                                                <Table hover style={{ width: '100%', textAlign: 'center' }} >
                                                                    <thead >
                                                                        <tr className='' style={{ top: 0, position: 'sticky' }}>
                                                                            <th style={{ color: primarytext, backgroundColor: grey }} >Date</th>
                                                                            <th style={{ color: primarytext, backgroundColor: grey }} >Patient Name</th>
                                                                            <th style={{ color: primarytext, backgroundColor: grey }} >Shift</th>
                                                                            <th style={{ color: primarytext, backgroundColor: grey }} >Proximity</th>
                                                                            <th style={{ color: primarytext, backgroundColor: grey }} >Select</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        {dates && dates.map((item: any, inx: number) => {
                                                                            return (
                                                                                <tr key={inx} className='' style={{}}>
                                                                                    <td>{item && moment(item).format("MM-DD-YYYY")}</td>
                                                                                    <td>{addPatientName?.name}</td>
                                                                                    <td>{selectShift ? selectShift : 'Full Shift'}</td>
                                                                                    <td>{disabled ? disabled && "Proximity" : '-'}</td>
                                                                                    <td>
                                                                                        {/* <Checkbox onChange={() => { handleRowSelected(item) }} /> */}
                                                                                        <input type='checkbox' checked={selectedRows.includes(moment(item).format("YYYYMMDD"))} onChange={() => {
                                                                                            handleRowSelected(item)
                                                                                        }} />
                                                                                    </td>
                                                                                </tr>)
                                                                        })}
                                                                    </tbody>
                                                                </Table >)}
                                                        </div>
                                                    }
                                                    {/* Multi Report Summary&Details View Start */}
                                                    <div className='mt-2'>
                                                        {
                                                            checkSelect === 2 &&
                                                            <MultipleReportView
                                                                multiReportData={multiReportData}
                                                                View={accordionView}
                                                                selectShift={selectShift}
                                                                selectShiftView={selectShiftView}
                                                                checkBox={checkBox}
                                                                locationLegend={locationLegend}
                                                                activityLegend={activityLegend}
                                                                checkPrecaution={checkPrecaution}
                                                                legendcontrol={legendcontrol}
                                                                timeFormat={timeFormat}
                                                            />
                                                        }
                                                    </div>
                                                    {/* Multi Report Summary&Details View End */}
                                                </div>

                                            </div>
                                        </div>
                                        <div hidden={viewContentData} id='1to1AllReport' style={{ paddingLeft: '100px', paddingRight: '100px' }} >
                                            {
                                                checkSelect === 2 && !viewContentData &&
                                                <>
                                                    <div className='d-flex justify-content-end gap-2'>
                                                        <div>
                                                            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => {
                                                                handleDownload()
                                                            }}>Download</button>
                                                        </div>
                                                        <div>
                                                            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px', }} onClick={() => {
                                                                handlePrint()
                                                            }}><img src={printImage} />Print</button>
                                                        </div>
                                                        <div>
                                                            <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => { setViewContent(!viewContentData) }}>Close</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                reportData !== null && reportData !== undefined && <ReportView reportData={reportData} selectShiftView={selectShiftView} checkBox={checkBox} selectShift={selectShift} nextDate={nextDate} dateID={undefined} checkPrecaution={checkPrecaution} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={legendcontrol} IndexNum={undefined} timeFormat={timeFormat} />
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                            }
                            {
                                orgData?.q15OneHour &&
                                <TabPanel value='4' style={{ padding: '5px' }}>
                                    <div>
                                        <OneHourReport />
                                    </div>
                                </TabPanel>
                            }

                        </div>
                    </Box>
                </TabContext>
            </Box>
        </div>
    )
}

export default AllReport