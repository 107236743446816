import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaSearch } from "react-icons/fa";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FaInbox } from "react-icons/fa6";

const TeamListTable = ({ allTeamData }:any) => {

  return (
    <div>
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2"></div>
        <div className="row">
          <div className="col-md-3"></div>
        </div>
        {allTeamData?.length === 0 || allTeamData === undefined ? (<>
              <Typography variant="body1" className="table_empty">
                <FaInbox className="empty_svg" />
                No Data found for this team
              </Typography>
            </>):(
        <table
          className="table table-bordered"
          style={{ fontSize: "13px", marginTop: "10px" }}
        >
          <thead>
            <tr>
              <th scope="col" className="text-center table-data">
                Staff Name
              </th>
              <th scope="col" className="text-center table-data">
                Staff ID
              </th>
              <th scope="col" className="text-center table-data">
               Role
              </th>
              <th scope="col" className="text-center table-data">
              Job Title
              </th>
              <th scope="col" className="text-center table-data">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
           <>
              {allTeamData[0]?.staff?.map((staff: any, index: number) => (
              <tr key={index}>
                <td className="text">{staff?.staffame}</td>
                <td className="text">{staff?.staffId}</td>
                <td className="text">{staff?.staffRole}</td>
                <td className="text">{staff?.jobTitle}</td>
                <td className="text d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
            </>
          </tbody>
        </table>)}
      </div>
    </div>
  );
};

export default TeamListTable;