import React, { useState, useEffect, useRef } from "react";
import Image5 from '../../assets/images/image5.png';
import mettler from '../../assets/images/mettler.jpg';
import { Button } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";

interface Data {
  email: string;
  otp: string;
}

const VerifyOtp = () => {
  const [data, setData] = useState<Data>({
    email: "",
    otp: "",
  });
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const savedEmail = localStorage.getItem('savedEmail');
    if (savedEmail) {
      setData((prevData) => ({ ...prevData, email: savedEmail }));
    } else {
      console.warn('No Mail Found in Local Storage');
    }
  }, []);
  const handleInputChange = (index: number, value: string) => {
    if (index >= 6) return;
    setData((prevData) => {
      const otpArray = prevData.otp.split("");
      otpArray[index] = value;
      const updatedOtp = otpArray.join("").slice(0, 6);
      return { ...prevData, otp: updatedOtp };
    });
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  

  const handleRequest = async () => {
    const { header1 } = authorize();
    console.log(data, 'firstData')
    if (data.otp === "") {
      toast.error('Enter the OTP to verify your identity.')
      return
    }
    if (data.otp.length !== 6) {
      toast.error('Enter the OTP to verify your identitysgds.')
      return
    }
    try {
      setLoading(true)
      const response = await axios.post(`${baseURL}/user/verify-otp`, data, { headers: header1 });
      if (response.data.message && response.data.message.code === 'MHC - 0200') {
        setLoading(false)
        toast.success(response.data.message.description)
        navigate('/change-password');
      } else {
        setLoading(false)
        toast.error(response.data.message.description)
      }
    } catch (error) {
      setLoading(false)
      console.warn('Error:', error);
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, [])
  const handleKeyDown = (e: any, index: any) => {
    if (e.key === 'Enter') {
      handleRequest();
      inputRefs.current[0]?.focus();
    }

    if (e.key === 'Backspace') {
      const value = e.target.value;
      if (value !== '') {
        setData((prevData) => {
          const newOtp = prevData.otp.split("");
          newOtp[index] = value;
          return { ...prevData, otp: newOtp.join("") };
        });
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
  return (
    <div className="row passcode-section p-0 m-0" >
      {loading && <Loader />}
      <div className="col-md-6" style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', marginLeft: '-6px', height: '101%' }}>
        <img src={Image5} className="img-fluid"
          style={{ objectFit: "cover", height: '100vh' }} alt="Image"></img>
      </div>
      <div className="col-md-6 d-flex flex-column align-items-md-center justify-content-md-center">
        <div className="d-flex justify-content-center mb-2">
          <img className="p-0 "
            src={mettler}
            style={{ height: "-webkit-fill-available" }}
            alt="Image"
          ></img>
        </div>
        <form className="rounded col-md-8" style={{ padding: '50px' }} >
          <div className="d-flex flex-column gap-4">
            <h4 className="text-center">OTP Verification</h4>
            <div className="d-flex gap-1" >
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  maxLength={1}
                  className=""
                  value={data.otp[index] || ""}
                  type="number"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{
                    width: '40px',
                    height: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #0f3995',
                    textAlign: 'center',
                    borderRadius: '8px',
                    marginRight: '15px',
                    left: '7px'
                  }}
                  ref={(el) => inputRefs.current[index] = el}
                />
              ))}
            </div>
            <Button color="info" style={{ fontSize: '20px' }} onClick={handleRequest}>
              Verify OTP
            </Button>
            <div className="d-flex align-items-center justify-content-center">
              <p style={{ cursor: 'pointer', color: '#2D70F4' }} onClick={() => navigate('/')}>Back to Login</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;