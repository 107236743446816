import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./beacon.css";
import { baseURL, successCode } from "../../configuration/url";
import { TbDeviceWatchPlus } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllBeacon, getAllGateWayByOrg, getAllSirenByOrg, updatedSensorDetails } from "../../slices/beaconDevices/thunk";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Tooltip,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button } from 'primereact/button';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowAltCircleLeft, FaQrcode, FaSearch } from "react-icons/fa";
import BeaconCreation from "./beaconCreation";
import { BrowserMultiFormatReader } from "@zxing/library";
import Trashimg from "./../../assets/images/VscTrash.png"
import editimg from "./../../assets/images/Vectorpencil.png"
import { authorize, formatMacAddress } from "../../helpers/common";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { grey, primarytext } from "../../common/primary";
import SirenCreation from "./sirenCreation";
import GatewayCreation from "./gatewayCreation";
import { getBeaconSuccess, getGateWaySuccess, getSirenSuccess } from "../../slices/beaconDevices/reducer";
import Loader from "../../components/loader/Loader";
import EditSettingModal from "./editSettingModal";
import { FaMobileScreenButton } from "react-icons/fa6";
import csv from "csvtojson";
import { fetchBeaconType, fetchLocationDropdown } from "../../slices/thunk";

interface TabletDetail {
  TabletId: any;
  TabletModel: any;
  Type: any;
  assestid: any
}

const QRCodeScanner: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [addData, setAddData] = useState<any>([{ value: '', key: '' }]);
  const [sirenCurrentPage, setSirenCurrentPage] = useState(0)
  const [dropdowns, setDropdowns] = useState<any>([])
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});
  const [gateWayCurrentPage, setGateWayCurrentPage] = useState(0)
  const [handheld, setHandheld] = useState(0)
  const [EVCurrentPage, setEVCurrentPage] = useState(0)
  const [EVTotalElements, setEVTotalElements] = useState(0)
  const [HHTotalElements, setHHTotalElements] = useState(0)
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const { beaconTypeDropdown, locationDropdown } = useSelector((state: any) => state.Dropdown);
  const { orgData } = useSelector((state: any) => state.Org);
  const orgId = params?.id ? params.id : organization;
  const { loading, beaconData, totalElements, sirenData, sirenTotalElements, gateWayData, gateWayTotalElements } = useSelector((state: any) => state.Beacon);
  const [modal, setModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const videoRef = useRef(null);
  const [deviceName, setDeviceName] = useState("");
  let [deviceType, setDeviceType] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [uuid, setUuid] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const codeReader = new BrowserMultiFormatReader();
  const [options, setOptions] = useState<any>([]);
  const [scanning, setScanning] = useState(false);
  const orgName = window.localStorage.getItem("OrgName");
  const [searchText, SetsearchText] = useState("");
  const [searchTab, SetsearchTab] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [qrReader, setQrReader] = useState<any>('')
  const [test, setTest] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null);
  const [sirenRegisterModal, setSirenRegisterModal] = useState<boolean>(false)
  const [gateWayRegisterModal, setGateWayRegisterModal] = useState<boolean>(false)
  const [settingEditModal, setSettingEditModal] = useState<boolean>(false)
  const [settingEditData, setSettingEditData] = useState<any>([])
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sirenGet, setSirenGet] = useState<boolean>(false)
  const [gatewayGet, setGateWayGet] = useState<boolean>(false)
  let [sensorData, setSensorData] = useState({
    id: "",
    uuid: "",
    deviceId: "",
    deviceName: "",
    BeaconType: "",
    modelNumber: '',
    orgId: orgId,
  });
  const [sirenEditData, setSirenEditData] = useState<any>()
  const [gateWayEditData, setGateWayEditData] = useState<any>()
  const [sirenDeleteModal, setSirenDeleteModal] = useState<boolean>(false)
  const [gateWayDeleteModal, setgateWayDeleteModal] = useState<boolean>(false)
  const [sirenDeviceID, setSirenDeviceID] = useState<any>()
  const [gateWayDeviceID, setgateWayDeviceID] = useState<any>()
  const [types, setTypes] = useState<any>('');
  const [sirenSerachText, setSirenSerachText] = useState<string>('')
  const [settingSerachText, setSettingSerachText] = useState<string>('')
  const [gatewaySerachText, setGateWaySerachText] = useState<string>('')
  const [settingData, setSettingData] = useState<any>()
  const [Loading, setLoading] = useState<boolean>(false)
  const [tabletCreation, setTabletCreation] = useState<boolean>(false)
  const [tabletEditModal, setTableEditModal] = useState<boolean>(false)
  const [tabletGetData, setTabletGetData] = useState<any>('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [tabletData, setTabletData] = useState<TabletDetail>()
  const [tabletEditData, setTabletEditData] = useState<any>('')
  const [confirm, setConfirm] = useState(false)
  const [tabletDeleteModal, setTabletDeleteModal] = useState<boolean>(false)
  const [tabletDeleteData, setTabletDeleteData] = useState<any>('')
  const [settingDeleted, setSettingDelete] = useState<any>('')
  const [variableName, setVariableName] = useState<any>('')
  const [settingDeletedModal, setSettingDeletedModal] = useState<any>('')
  const [value, setValue] = React.useState<any>('1');
  useEffect(() => {
    const handleDorpDown = async () => {
      try {
        const { header1 } = authorize();
        const response = await axios.get(`${baseURL}/dropdowns/getAllDropdownsByOrganization?organizationId=organizationId`, { headers: header1 })
        setDropdowns(response.data?.data)
      } catch (error: any) {
        console.error('API Error :', error)
      }
    }
    handleDorpDown();
  }, [])

  useEffect(() => {
    fetchBeaconType(dispatch, orgId)
  }, [orgId, value])
  const handleInputChange = (e: any) => {
    const value = e.target.value.trim();
    setSettingSerachText(value);
    setAddData((k: any) => ({ ...k, value }));
    const isDuplicate = settingData?.some((item: any) => item.value.toLowerCase() === value.toLowerCase());
    setIsSaveDisabled(!value || isDuplicate);
  };


  const handleSubmit = async () => {
    if (!types) {
      toast.error('Please select a type');
      handleCsvCancel()
      return;
    }
    const filteredJsonData = jsonData ? jsonData.filter((row: any) => row.value) : [];
    const requestBody = {
      id: '',
      organizationId: orgId,
      dropdown: types,
      q15: true,
      list: filteredJsonData.length > 0
        ? filteredJsonData.map((row: any) => ({
          id: "",
          key: row.key || "",
          value: row.value,
          type: types,
        }))
        : [
          {
            id: '',
            key: addData.key,
            value: addData.value,
            type: types,
          },
        ],
    };

    const { header1 } = authorize();
    try {
      const response = await axios.post(`${baseURL}/dropdowns/addByOrgId`, requestBody, { headers: header1 });
      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description);
        handleSetting(types, EVCurrentPage)
        setSettingSerachText('')
        setAddData({ key: '', value: '' });
      } else {
        toast.error(response.data.message.description);
      }
      handleCsvCancel()
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('An error occurred while saving the data.');
    }
  };
  const handleQrReaderClick = () => {
    setShowTextField(true);
  };
  useEffect(() => {
    if (qrReader.length < 12) {
      setQrReader('')
      return
    };
    handleQrValueChange()
  }, [qrReader])
  const handleQrValueChange = () => {
    if (qrReader.length >= 12) {
      let newDeviceId = '';
      if (qrReader.length < 15) {
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId;
      } else {
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId.slice(6, 23);
        setDeviceName(qrReader.slice(24, 30));
      }

      setDeviceId(newDeviceId);
      handleShow1Close();
      setNewModal(true);
    }
  };

  useEffect(() => {
    if (showTextField && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showTextField]);

  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            const result1 = result.getText();
            let newDeviceId = "";
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
                setDeviceName(result1.slice(24, 30));
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption === undefined) {
              setNewModal(true);
              setDeviceId(newDeviceId);
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Given Beacon Device was registered.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };


  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };
  const handleShow1Close = () => {
    setShow1(false)
    setQrReader('')
    setShowTextField(false)
  }

  const handleDeviceNameChange = (e: any) => {
    setDeviceName(e.target.value);
  };

  const handleUUIDChange = (e: any) => {
    setUuid(e.target.value);
  };

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    setShow1(false)
    setShow(true);
    setDeviceId("")
    setDeviceName("")
    setScanning(!scanning);
    setShowTextField(false);

  }
  const handleQrClick1 = () => {
    setShow1(true)
  }

  const postAPI = async () => {
    const { header1 } = authorize();
    try {
      if (!uuid || !deviceId || !deviceName) {
        toast.error("Please fill the required fields");
        return;
      }
      const res = await axios.post(`${baseURL}/sensor/register`, {
        deviceId,
        deviceName,
        uuid,
        orgId,
        deviceType,
        modelNumber
      }, { headers: header1 });
      if (res.data.message.code === successCode) {
        handleClose1();
        setNewModal(false)
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        getAllBeacon(dispatch, orgId, currentPage, '');
      } else {
        handleClose1();
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        toast.warn(res.data.message.description)
      }
    } catch (error) {
      console.warn(error);
      handleClose1();
      setDeviceId("");
      setDeviceName("");
    }
  };

  const ScanModalClose = () => {
    setNewModal(false)
    handleClose1();
    setDeviceId("");
    setDeviceName("");
    setUuid("");
    setDeviceType("");
    setModelNumber("");
  }

  const toggle = () => {
    setModal(!modal);
  }

  useEffect(() => {
    fetchLocationDropdown(dispatch, orgId);
  }, [orgId, value]);

  useEffect(() => {

    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getAllByorgId/${orgId}`, { headers: header1 });
        setOptions(response.data.data.content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const [open, setOpen] = useState(false)

  const handleDelete = (deviceName: any) => {
    setOpen(true);
    setDeviceName(deviceName);

  }
  const handleClo = () => {
    setOpen(false)
    setDeviceName('');
  }

  const handleSirenDelete = (item: any) => {
    setSirenDeleteModal(true)
    setSirenDeviceID(item)
  }
  const handleSirenClo = () => {
    setSirenDeleteModal(false)
    setSirenDeviceID('')
  }

  const handleGateWayDelete = (item: any) => {
    setgateWayDeleteModal(true)
    setgateWayDeviceID(item)
  }
  const handleGateWayClo = () => {
    setgateWayDeleteModal(false)
    setgateWayDeviceID('')
  }
  const deleteAPI = async (deviceName: any) => {
    const { header1 } = authorize();
    try {
      const res = await axios.delete(
        `${baseURL}/sensor/deleteByDeviceName/${deviceName}`,
        { headers: header1 }
      );
      if (res.data.message.code === successCode) {
        handleClo()
        toast.success(res.data.message.description);
        getAllBeacon(dispatch, orgId, currentPage, '');
      } else {
        handleClo()
        toast.error(res.data.message.description);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleDelete1 = async () => {
    const { header1 } = authorize();
    try {
      const body = [settingDeleted]
      const res = await axios.delete(
        `${baseURL}/dropdowns/deleteByOrganizationDropdownAndIds?dropdown=${types}&organizationId=${orgId}`,
        { headers: header1, data: body }
      );
      if (res.data.code === successCode) {
        toast.success(res.data.description)
        handleSetting(types, EVCurrentPage)
        handleSettingDeleteCls()
      } else {
        toast.error(res.data.description)
        handleSettingDeleteCls()
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to delete');
      handleSettingDeleteCls()
    }
  };
  const deleteSirenData = async (sirenID: string) => {
    try {
      const res = await axios.delete(`${baseURL}/siren/deleteSiren?id=${sirenID}`)
      if (res.data.message.code === successCode) {
        toast.success(res.data.message.description);
        setSirenGet(!sirenGet)
        handleSirenClo()
      } else {
        handleSirenClo()
        toast.error(res.data.message.description);
      }
    } catch (error) {
      console.warn('API Error: ', error)
    }
  }

  const deleteGateWay = async (gateWayID: string) => {
    try {
      const res = await axios.delete(`${baseURL}/gateway/deleteGateway?id=${gateWayID}`)
      if (res.data.message.code === successCode) {
        toast.success(res.data.message.description);
        setGateWayGet(!gatewayGet)
        handleGateWayClo()
      } else {
        toast.error(res.data.message.description);
      }

    } catch (error) {
      console.warn('API Error: ', error)
    }
  }

  useEffect(() => {
    getAllBeacon(dispatch, orgId, searchText ? 0 : currentPage, searchText ? searchText : '');
    return () => {
      dispatch(getBeaconSuccess([]))
      dispatch(getSirenSuccess([]))
      dispatch(getGateWaySuccess([]))
    }
  }, [dispatch, searchText]);

  useEffect(() => {
    getAllSirenByOrg(dispatch, orgId, sirenSerachText ? 0 : sirenCurrentPage, sirenSerachText ? sirenSerachText : '')
  }, [dispatch, sirenSerachText, sirenGet])
  useEffect(() => {
    getAllGateWayByOrg(dispatch, orgId, gatewaySerachText ? 0 : gateWayCurrentPage, gatewaySerachText ? gatewaySerachText : '')
  }, [dispatch, gatewaySerachText, gatewayGet])

  const handleSave = async () => {
    const updateFields = {
      id: sensorData.id,
      uuid: sensorData.uuid,
      deviceId: sensorData.deviceId,
      deviceName: sensorData.deviceName,
      deviceType: sensorData.BeaconType,
      modelNumber: sensorData.modelNumber,
      orgId: orgId,
    };

    setSensorData((prevData) => ({
      ...prevData,
      ...updateFields,
    }));

    try {
      await updatedSensorDetails(
        dispatch,
        sensorData.id,
        updateFields,
        orgId
      );

      setEditModal(false);
      getAllBeacon(dispatch, orgId, currentPage, '')
    } catch (error) {
      console.error("Error updating sensor details:", error);
    }
  };

  const handleClick = (selectSensor: any) => {
    if (selectSensor) {
      setSensorData({
        id: selectSensor?.id,
        uuid: selectSensor?.uuid || "",
        deviceId: selectSensor?.deviceId || "",
        deviceName: selectSensor?.deviceName || "",
        BeaconType: selectSensor?.deviceType || "",
        modelNumber: selectSensor?.modelNumber || "",
        orgId: orgId,
      });
      setEditModal(true);
    } else {
      console.warn("Invalid data", selectSensor);
    }
  };

  const handleSearchChange = (e: any) => {
    SetsearchText(e.target.value);
  }
  const handleSearchTab = (e: any) => {
    SetsearchTab(e.target.value);
  }


  const handleSirenSearchChange = (e: any) => {
    setSirenSerachText(e.target.value)
  }
  const handleGateWayChange = (e: any) => {
    setGateWaySerachText(e.target.value)
  }

  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSensorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModelChange = (event: any, newValue: any) => {
    let modelNumber = '';
    if (newValue === "W7") {
      modelNumber = "METTLER WRISTBAND BEACON";
    } else if (newValue === "W8") {
      modelNumber = "METTLER SOS BEACON";
    } else if (newValue.toUpperCase() === "KG02") {
      modelNumber = "METTLER BEACON GATEWAY";
    } else if (newValue.toUpperCase() === "WH15") {
      modelNumber = "METTLER HR WRISTBAND BEACON";
    }
    setDeviceType(modelNumber)
    setModelNumber(newValue)
  };
  const handleModelChange1 = (event: any, newValue: any) => {
    let modelNumber = '';
    if (newValue === "W7") {
      modelNumber = "METTLER WRISTBAND BEACON";
    } else if (newValue === "W8") {
      modelNumber = "METTLER SOS BEACON";
    } else if (newValue.toUpperCase() === "KG02") {
      modelNumber = "METTLER BEACON GATEWAY";
    } else if (newValue.toUpperCase() === "WH15") {
      modelNumber = "METTLER HR WRISTBAND BEACON";
    }

    setSensorData(prevState => ({
      ...prevState,
      BeaconType: modelNumber,
      modelNumber: newValue,
    }));
  };

  const handleDeviceTypeChange = (event: any) => {
    setDeviceType(event.target.value);
  };
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
    setAddData([{ value: '', key: '' }])
    SetsearchText("")
    setSirenSerachText("")
    setGateWaySerachText("")
    SetsearchTab("")
    setTypes("")
  };

  const handleSirenModal = () => {
    setSirenRegisterModal(!sirenRegisterModal)
  }
  const handleGatewayModal = () => {
    setGateWayRegisterModal(!gateWayRegisterModal)
  }
  const handleSetting = async (value: any, page: any) => {
    setLoading(true)
    try {
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=${value}&organizationId=${orgId}&page=${page}&pageSize=7&searchValue=${settingSerachText}`)
      if (response.data.message.code === successCode) {
        setSettingData(response.data.data.content[0].list)
        setEVTotalElements(response.data.data?.totalElements)
        setLoading(false)
      } else {
        setSettingData([])
        setLoading(false)
      }
      setLoading(false)
    } catch (error: any) {
      console.error(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (types) {
      handleSetting(types, settingSerachText ? 0 : EVCurrentPage)
    }
  }, [settingSerachText, types])

  const handleLocationClick = (item: any) => {
    setSettingEditData(item)
    setSettingEditModal(true)

  }
  const handleLocationEditModalCls = () => {
    setSettingEditModal(false)
    handleSetting(types, EVCurrentPage)
  }
  const handleTabletModal = () => {
    setTabletCreation(true)
  }
  const handleTabletCLS = () => {
    setTabletCreation(false)
    setNameError({});
    setTabletData((prevData: any) => ({
      ...prevData,
      TabletId: '',
      TabletModel: '',
      Type: '',
      assestid: '',
    }))
  }
  const getTabletData = async (page: any) => {
    setLoading(true)
    try {
      const response = await axios.get(`${baseURL}/tab/getbyorganizationid/{organizationId}?organizationId=${orgId}&page=${page}&pageSize=7&search=${searchTab}`)
      if (response.data.message.code === successCode) {
        setTabletGetData(response.data.data.content)
        setHHTotalElements(response.data.data?.totalElements)
        setLoading(false)
        handleTabletCLS()
      } else {
        setTabletGetData('')
      }
      setLoading(false)
    } catch (error: any) {
      console.error('API Error: ', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    getTabletData(0)
  }, [searchTab])
  const handletabletSubmit = async () => {
    setLoading(true)
    const newErrors = {
      uniqueId: !tabletData?.TabletId,
      type: !tabletData?.Type,
      assetId: !tabletData?.assestid,
    };
    setNameError(newErrors);
    try {
      const body = {
        id: "",
        organizationId: params?.id,
        uniqueId: tabletData?.TabletId,
        type: tabletData?.Type,
        assetId: tabletData?.assestid,
      }
      const { header1 } = authorize();
      const response = await axios.post(`${baseURL}/tab/register`, body, { headers: header1 })
      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description)
        setLoading(false)
        getTabletData(0)
        setNameError({});
      } else {
        toast.warning(response.data.message.description)
        setLoading(false)
      }
    } catch (error: any) {
      console.error('API Error: ', error)
      setLoading(false)
    }

  }
  const handleTableteditCLS = () => {
    setTableEditModal(false)
  }
  const handleEdittabletSubmit = async () => {
    setLoading(true)
    try {
      const body = {
        id: tabletEditData.id,
        macAddress: tabletEditData.macAddress,
        organizationId: tabletEditData.organizationId,
        uniqueId: tabletEditData.uniqueId,
        type: tabletEditData.type,
        assetId: tabletEditData.assetId,
      }
      const { header1 } = authorize();
      const response = await axios.put(`${baseURL}/tab/update/{id}?id=${tabletEditData.id}`, body, { headers: header1 })

      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description)
        getTabletData(0)
        setLoading(false)
        handleTableteditCLS()
      }
      setLoading(false)
    } catch (error: any) {
      console.error('API Error: ', error)
      setLoading(false)
    }
  }
  const handleTabDelete = (data: any) => {
    setTabletDeleteModal(true)
    setTabletDeleteData(data)

  }
  const hadleDeleteTabletData = async () => {
    try {
      const response = await axios.delete(`${baseURL}/tab/{id}?id=${tabletDeleteData?.id}`)
      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description)
        handleDeleteDataCls()
        getTabletData(0)
      } else {
        toast.error(response.data.message.description);
      }
    } catch (error: any) {
      console.error('API Error: ', error)
    }
  }
  const handleDeleteDataCls = () => {
    setTabletDeleteModal(false)
    setTabletDeleteData('')

  }

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileName = file.name.split('.').slice(0, -1).join('.');

    if (fileName !== types) {
      toast.error(`File name must match the selected type: ${types}`);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvString = e.target.result;
      const json: any = await csv().fromString(csvString);
      setJsonData(json);
      setConfirm(true)
    };
    reader.readAsText(file);
  };

  const handleCsvCancel = () => {
    setConfirm(false)
    setJsonData([])
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    }
  }

  const handleSettingDeleteCls = () => {
    setSettingDelete('')
    setSettingDeletedModal(false)
  }
  return (
    <div className="container " style={{ height: '91vh', overflow: 'hidden' }}>
      {loading && <Loader />}
      {Loading && <Loader />}
      <div className="d-flex justify-content-center" style={{ height: '6vh' }}>
        {params?.id ? (
          <div className="pt-1">
            <h5 style={{ fontSize: '25px' }}>{orgName}</h5>
          </div>
        ) : (
          <div className="col-md-4 d-flex align-items-center"></div>
        )
        }
      </div>
      <Box sx={{ height: '85vh' }}>
        <TabContext value={value} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {params?.id && (<FaArrowAltCircleLeft style={{ cursor: 'pointer', fontSize: '25px', marginTop: '15px', marginRight: '10px' }} onClick={() => navigate(-1)} />)}
              <Tab label="Settings" value="1" />
              <Tab label="Beacon" value="2" />
              {orgData?.sos !== false && (
                <Tab label="Siren" value="3" />
              )}
              {orgData?.sos !== false && (
                <Tab label="GateWay" value="4" />
              )}
              <Tab label="Handheld Device" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: '5px' }}>
            <div style={{ width: '100%' }}>
              <div className=" d-flex flex-column">
                <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                    <h5 style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>Environment Variable</h5>
                  </div>
                  <div className="col-md-8">
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', gap: '10px' }}>
                        <div className="mx-0 gap-3 px-2 search-container d-flex align-items-center">
                          {confirm &&
                            <LogoutConfirmationModal
                            open={confirm}
                            handleConfirm={() => handleSubmit()}
                            handleClose={() => handleCsvCancel()}
                            message={`Are you sure you want to add this in ${types}?`}
                            />
                          }
                          <div className="input-wrapper" style={{ position: 'relative', width: '200px' }}>
                            <Autocomplete
                              options={dropdowns}
                              getOptionLabel={(option) => option}
                              value={types || null}
                              onChange={(e, v) => {
                                setTypes(v);
                                setAddData({ value: '' });
                                // handleSetting(v, EVCurrentPage)
                                setSettingSerachText('')
                              }}
                              renderInput={(params) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                label="Select Your Type"
                                fullWidth
                                placeholder={types ? '' : 'Select a type...'}
                                required
                                />
                              )}
                              />
                          </div>
                              {params?.id && (<div >
                                <input
                                  type="file"
                                  accept=".csv"
                                  ref={fileInputRef}
                                  onChange={handleFileUpload}
                                  style={{ display: 'none' }}
                                />
                                <FontAwesomeIcon
                                  icon={faFileCsv}
                                  style={{ cursor: 'pointer', fontSize: '27px', color: '#007BFF' }}
                                  onClick={handleIconClick}
                                  title="Upload CSV"
                                />
                              </div>
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead style={{ backgroundColor: grey }}>
                  <tr>
                    <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638", width: '100px' }}>S.No</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Variable Data</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Key</th>
                    {params.id && (
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638", width: '100px' }}>
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="tbody text-center">
                  {params.id && (
                    <tr>
                      <td>-</td>
                      <td>
                        <input
                          type="text"
                          placeholder="Enter Variable Data"
                          className="form-control"
                          value={addData.value}
                          onChange={handleInputChange}
                          style={{ fontSize: '13px' }}
                        />
                      </td>
                      <td></td>
                      {params.id && (
                        <td className="text-center">
                          <Button
                            label="Save"
                            disabled={isSaveDisabled}
                            style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}
                            onClick={handleSubmit}
                          >
                          </Button>
                        </td>
                      )}
                    </tr>
                  )}

                  {types && settingData?.length > 0 ? (
                    settingData.map((item: any, inx: any) => (
                      <tr key={`${inx}`}>
                        <td>{EVCurrentPage * 8 + inx + 1}</td>
                        <td>{item?.value}</td>
                        <td>{item?.key}</td>
                        {params.id && (
                          <td className="text-center d-flex justify-content-around align-items-center">
                            <Tooltip title="Edit" arrow>
                              <div onClick={() => handleLocationClick(item)} style={{ cursor: 'pointer' }}>
                                <img style={{ height: '15px', width: '15px' }} src={editimg} />
                              </div>
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <div onClick={() => {
                                setSettingDeletedModal(true)
                                setSettingDelete(item?.id)
                                setVariableName(item?.value)
                              }} style={{ cursor: 'pointer' }}>
                                <img style={{ height: '15px', width: '15px' }} src={Trashimg} alt="delete" />
                              </div>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        <h5>{types ? 'No Environment Variable Data' : 'No Environment Variable Data'}</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={EVCurrentPage * 8}
                    rows={8}
                    totalRecords={EVTotalElements}
                    onPageChange={(e: any) => {
                      setEVCurrentPage(e.page)
                      handleSetting(types, e.page)
                    }}
                    currentPageReportTemplate={`Page ${EVCurrentPage} of ${EVTotalElements / 8}`}
                  />
                </div>
              </div>
              <LogoutConfirmationModal open={settingDeletedModal} handleConfirm={() => handleDelete1()} handleClose={handleSettingDeleteCls} message={`Do you want to delete "${variableName}"`} />
              <EditSettingModal isOpen={settingEditModal} handleModalColse={handleLocationEditModalCls} formValue={settingEditData} setFormValue={setSettingEditData} OrgId={orgId} />

            </div>
          </TabPanel>
          <TabPanel value="2" style={{ padding: '5px' }}>
            <div className="m-0 p-0">
              <div>
                <div className="row">
                  <div className="col-md-4 d-flex align-items-center ">
                    <h5 className="" style={{ fontSize: '20px' }}>Beacon/Device List</h5>
                  </div>
                  <div className="col-md-8 ">
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                      {params.id && (<div >
                        <button
                          className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                          style={{ fontSize: '10px', width: '100px', height: '30px' }}
                          onClick={handleQrClick1}
                        >
                          <FaQrcode
                            style={{ cursor: "pointer", fontSize: "17px" }}
                          />{" "}
                          Scan
                        </button>
                      </div>)}
                      {params?.id && (<div >
                        <button
                          className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                          style={{ fontSize: '10px', width: '160px', height: '30px' }}
                          onClick={toggle}
                        >
                          <TbDeviceWatchPlus
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />{" "}
                          Register a New Device
                        </button>
                      </div>)}
                      <div >
                        <div className="mx-0 search-container d-flex align-items-center">
                          <input
                            type="text"
                            placeholder="Search..."
                            className="search form-control"
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ fontSize: "10px", width: '170px', height: '30px' }}
                          />
                          <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div >
                <table className="table table-bordered" style={{ fontSize: '13px' }}>
                  <thead style={{ backgroundColor: grey }}>
                    <tr >
                      <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Serial Number</th>
                      {/* <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>MAC Address</th> */}
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Unique ID(UUID)</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Model</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device Type</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Status</th>
                      {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {beaconData.length > 0 ? beaconData.map((item: any, index: any) => (
                      <tr key={item.id}>
                        <td>{currentPage * 8 + index + 1}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={params.id ? () => handleClick(item) : () => { }}>
                          {item.deviceName}
                        </td>
                        {/* <td>{item.deviceId}</td> */}
                        <td>{item.uuid}</td>
                        <td>{item.modelNumber}</td>
                        <td>{(item.deviceType) ? item.deviceType : 'NA'}</td>
                        <td className="text-center">
                          <Tooltip title={!(item.status) ? "Available" : "Occupied"} arrow>
                            <FontAwesomeIcon
                              icon={faCircle}
                              style={{
                                fontSize: "13px",
                                cursor: "pointer",
                                color: !(item.status) ? '#5FB477' : '#EF6868',
                                display: "inline-block",
                                textAlign: "center",
                              }}
                            />
                          </Tooltip>
                        </td>
                        {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                          <Tooltip title="Edit" arrow>
                            <div onClick={() => handleClick(item)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <div onClick={() => handleDelete(item.deviceName)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                          </Tooltip>
                        </td>)}
                      </tr>
                    )) :
                      <tr>
                        <td colSpan={7} className="text-center">
                          <h5>No Beacons found</h5>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <BeaconCreation modal={modal} orgId1={params?.id ? orgId : null} toggle={toggle} currentPage={currentPage} />

              </div>
              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={currentPage * 8}
                    rows={8}
                    totalRecords={totalElements}
                    onPageChange={(e: any) => {
                      setCurrentPage(e.page)
                      getAllBeacon(dispatch, orgId, e.page, searchText ? searchText : '')
                    }}
                    currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 8}`}
                  />
                </div>
              </div>
              <Modal isOpen={editModal} centered size="lg" style={{ width: '600px' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <ModalHeader toggle={() => setEditModal(false)}>
                        Device Details
                      </ModalHeader>
                    </div>
                    <ModalBody>
                      <div className="d-flex align-items-center justify-content-center vh-90">
                        <div className="row w-100">
                          <div className="container col-md-12 ">
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-12 mb-2">
                                <TextField id="outlined-basic-1" label="Unique Id(UUID)" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.uuid} name="uuid" />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <TextField id="outlined-basic-1" label="MAC Address" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceId} name="deviceId" />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField id="outlined-basic-1" label="Serial Number" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceName} name="deviceName" />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  id='model'
                                  options={beaconTypeDropdown?.map((item: any) => item.value)}
                                  value={sensorData.modelNumber}
                                  onChange={handleModelChange1}
                                  renderInput={(params) => <TextField {...params} label="Model" variant="outlined" />}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-1"
                                  label="Device Type"
                                  variant="outlined"
                                  fullWidth
                                  value={sensorData.BeaconType ? sensorData.BeaconType : 'N/A'}
                                  name="modelNumber"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </div>
                </div>

                <ModalFooter className="">
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
                    <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <Modal centered isOpen={show} toggle={handleClose1}>
                <ModalHeader toggle={handleClose1}>Scanning</ModalHeader>
                <ModalBody>
                  <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
                    <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <Modal centered isOpen={show1} toggle={handleShow1Close} >
                <ModalHeader toggle={handleShow1Close}>Select Any Scanning Option</ModalHeader>
                <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      label="Start Scan"
                      style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold', marginRight: 10 }}
                      onClick={handleQrClick}
                    >
                    </Button>
                    <Button
                      label="QR Reader"
                      style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}
                      onClick={handleQrReaderClick}
                    >
                    </Button>
                  </div>
                  {showTextField && (
                    <div style={{ marginTop: 10 }}>
                      <TextField
                        id="qrInput"
                        label="Scan a QR using QR reader"
                        variant="outlined"
                        fullWidth
                        inputRef={inputRef}
                        value={test}
                        focused
                        onKeyDown={() => setTest('')}
                        onKeyUp={() => setTest('')}
                        onChange={(e: any) => {
                          setTest(e.target.value)
                          setQrReader(qrReader + e.target.value)
                        }}

                      />
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex gap-3 justify-content-center"></div>
                </ModalFooter>
              </Modal>
              <Modal isOpen={newModal} centered size="lg" style={{ width: '600px' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <ModalHeader toggle={() => setNewModal(false)}>
                        Add Device
                      </ModalHeader>
                    </div>
                    <ModalBody>
                      <div className="d-flex justify-content-center">
                        <div className="row w-100">
                          <div className="container col-md-12 ">
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-12 mb-2">
                                <TextField
                                  id="outlined-basic-1"
                                  label="Unique Id (UUID)"
                                  variant="outlined"
                                  fullWidth
                                  onChange={handleUUIDChange}
                                  value={uuid}
                                />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-2"
                                  label="MAC Address"
                                  variant="outlined"
                                  fullWidth
                                  value={deviceId}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-4"
                                  label="Serial Number"
                                  variant="outlined"
                                  fullWidth
                                  onChange={handleDeviceNameChange}
                                  value={deviceName}
                                />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  id="Model"
                                  options={beaconTypeDropdown?.map((item: any) => item.value)}
                                  value={modelNumber}
                                  onChange={handleModelChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Model"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-3"
                                  label="Device Type"
                                  variant="outlined"
                                  fullWidth
                                  value={deviceType}
                                  onChange={handleDeviceTypeChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </div>
                </div>

                <ModalFooter className="">
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={ScanModalClose}></Button>
                    <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={postAPI}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <LogoutConfirmationModal
                open={open}
                handleConfirm={() => deleteAPI(deviceName)}
                handleClose={() => handleClo()}
                message={`Are you sure you want to delete the siren?${deviceName}`}
              />
            </div>
          </TabPanel>
          {orgData?.sos !== false && (
            <TabPanel value="3" style={{ padding: '5px' }}>
              <div style={{ width: '100%' }} className="m-0 p-0">
                <div>
                  <div className="row ">
                    <div className="col-md-4 d-flex align-items-center"><h5 style={{ fontSize: '20px' }}>Siren Details</h5></div>
                    <div className="col-md-8">
                      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                        {params?.id && (<div >
                          <button
                            className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                            style={{ fontSize: '10px', width: '100px', height: '30px' }}
                            onClick={() => {
                              setSirenEditData(null)
                              handleSirenModal()
                            }}
                          >
                            <TbDeviceWatchPlus
                              style={{ cursor: "pointer", fontSize: "20px" }}
                            />{" "}
                            Register
                          </button>
                        </div>)}
                        <div >
                          <div className="mx-0 search-container d-flex align-items-center">
                            <input
                              type="text"
                              placeholder="Search..."
                              className="search form-control"
                              value={sirenSerachText}
                              onChange={handleSirenSearchChange}
                              style={{ fontSize: "10px", width: '170px', height: '30px' }}
                            />
                            <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <table className="table table-bordered" style={{ fontSize: '13px' }}>
                  <thead style={{ backgroundColor: grey }}>
                    <tr >
                      <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Virtual ID</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Siren Name</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Location</th>
                      {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                    </tr>
                  </thead>
                  <tbody className="tbody text-center">
                    {sirenData?.length > 0 ? sirenData?.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{sirenCurrentPage * 8 + index + 1}</td>

                        <td>{item.virtualId}</td>
                        <td>{item.sirenName}</td>
                        <td>{item.location}</td>
                        {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                          <Tooltip title="Edit" arrow onClick={() => {
                            handleSirenModal()
                            setSirenEditData(item)
                          }}
                          >
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                          </Tooltip>
                          <Tooltip title="Delete" arrow onClick={() => { handleSirenDelete(item) }}>
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                          </Tooltip>
                        </td>)}
                      </tr>
                    )) :
                      <tr>
                        <td colSpan={7} className="text-center">
                          <h5>No Siren found</h5>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <div className="pagination-container" >
                  <div className="d-flex justify-content-center">
                    <Paginator
                      first={sirenCurrentPage * 8}
                      rows={8}
                      totalRecords={sirenTotalElements}
                      onPageChange={(e: any) => {
                        setSirenCurrentPage(e.page)
                        getAllSirenByOrg(dispatch, orgId, e.page, sirenSerachText ? sirenSerachText : '')
                      }}
                      currentPageReportTemplate={`Page ${sirenCurrentPage} of ${sirenTotalElements / 8}`}
                    />
                  </div>
                </div>
                <SirenCreation isOpen={sirenRegisterModal} sirenData={sirenEditData} orgID={orgId} CloseModal={handleSirenModal} dropdownData={locationDropdown} sirenGet={sirenGet} setSirenGet={setSirenGet} />
                <LogoutConfirmationModal
                  open={sirenDeleteModal}
                  handleConfirm={() => deleteSirenData(sirenDeviceID?.id)}
                  handleClose={() => handleSirenClo()}
                  message={`Do you want to delete this data?  ${sirenDeviceID?.virtualId}`}
                />
              </div>
            </TabPanel>
          )}
          {orgData?.sos !== false && (
            <TabPanel value="4" style={{ padding: '5px' }}>
              <div style={{ width: '100%' }}>
                <div>
                  <div className="row">
                    <div className="col-md-4 d-flex align-items-center"><h5 style={{ fontSize: '20px' }}>GateWay Details</h5></div>
                    <div className="col-md-8">
                      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                        {params?.id && (<div >
                          <button
                            className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                            style={{ fontSize: '10px', width: '100px', height: '30px' }}
                            onClick={() => {
                              setGateWayEditData(null)
                              handleGatewayModal()
                            }}
                          >
                            <TbDeviceWatchPlus
                              style={{ cursor: "pointer", fontSize: "20px" }}
                            />{" "}
                            Register
                          </button>
                        </div>)}
                        <div >
                          <div className="mx-0 search-container d-flex align-items-center">
                            <input
                              type="text"
                              placeholder="Search..."
                              className="search form-control"
                              value={gatewaySerachText}
                              onChange={handleGateWayChange}
                              style={{ fontSize: "10px", width: '170px', height: '30px' }}
                            />
                            <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table table-bordered" style={{ fontSize: '13px' }}>
                  <thead style={{ backgroundColor: grey }}>
                    <tr >
                      <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Gateway ID</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Gateway Name</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Location</th>
                      {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                    </tr>
                  </thead>
                  <tbody className="tbody text-center">
                    {gateWayData?.length > 0 ? gateWayData?.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{gateWayCurrentPage * 8 + index + 1}</td>
                        <td>{item.gatewayId}</td>
                        <td>{item.gatewayName}</td>
                        <td>{item.location}</td>
                        {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                          <Tooltip title="Edit" arrow onClick={() => {
                            setGateWayEditData(item)
                            handleGatewayModal()
                          }}
                          >
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                          </Tooltip>
                          <Tooltip title="Delete" arrow onClick={() => { handleGateWayDelete(item) }}>
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                          </Tooltip>
                        </td>)}
                      </tr>
                    )) :
                      <tr>
                        <td colSpan={7} className="text-center">
                          <h5>No GateWay found</h5>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>

                <div className="pagination-container" >
                  <div className="d-flex justify-content-center">
                    <Paginator
                      first={gateWayCurrentPage * 8}
                      rows={8}
                      totalRecords={gateWayTotalElements}
                      onPageChange={(e: any) => {
                        setGateWayCurrentPage(e.page)
                        getAllGateWayByOrg(dispatch, orgId, e.page, gatewaySerachText ? gatewaySerachText : '')
                      }}
                      currentPageReportTemplate={`Page ${gateWayCurrentPage} of ${gateWayTotalElements / 8}`}
                    />
                  </div>
                </div>
                <GatewayCreation isOpen={gateWayRegisterModal} orgID={orgId} CloseModal={handleGatewayModal} dropdownData={locationDropdown} gateWayData={gateWayEditData} gatewayGet={gatewayGet} setGateWayGet={setGateWayGet} />
                <LogoutConfirmationModal
                  open={gateWayDeleteModal}
                  handleConfirm={() => deleteGateWay(gateWayDeviceID?.id)}
                  handleClose={() => handleGateWayClo()}
                  message={`Do you want to delete this data? ${gateWayDeviceID?.gatewayId}`}
                />
              </div>
            </TabPanel>
          )}
          <TabPanel value="5" style={{ padding: '5px' }}>
            <div style={{ width: '100%' }}>
              <div>
              </div>
              <div className="row">
                <div className="col-md-4 d-flex align-items-center"><h5 style={{ fontSize: '20px' }}>Handheld Device</h5></div>
                <div className="col-md-8">
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                    {params?.id && (<div >
                      <button
                        className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                        style={{ fontSize: '10px', width: '140px', height: '30px' }}
                        onClick={() => {
                          handleTabletModal()
                        }}
                      >
                        <FaMobileScreenButton
                          style={{ cursor: "pointer", fontSize: "13px" }}
                        />
                        Register New Device
                      </button>
                    </div>)}
                    <div >
                      <div className="mx-0 search-container d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="search form-control"
                          onChange={handleSearchTab}
                          style={{ fontSize: "10px", width: '170px', height: '30px' }}
                        />
                        <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead style={{ backgroundColor: grey }}>
                  <tr >
                    <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Tablet Unique ID</th>
                    {/* <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Tablet MAC Address</th> */}
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Type</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Tablet Asset ID</th>
                    {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                  </tr>
                </thead>
                <tbody className="tbody text-center">
                  {tabletGetData?.length > 0 ? tabletGetData?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td className="text text-center">{handheld * 8 + index + 1}</td>
                      <td>{item?.uniqueId}</td>
                      {/* <td>{item?.macAddress}</td> */}
                      <td>{item?.type}</td>
                      <td>{item?.assetId}</td>
                      {params.id && (
                        <td className="text-center d-flex justify-content-center align-items-center" style={{ gap: '20px' }}>
                          <Tooltip title="Edit" arrow onClick={() => {
                            setTableEditModal(true)
                            setTabletEditData(item)
                          }}
                          >
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                          </Tooltip>
                          <Tooltip title="Delete" arrow onClick={() => {
                            handleTabDelete(item)
                          }}>
                            <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                          </Tooltip>
                        </td>
                      )}
                    </tr>
                  )) :
                    <tr>
                      <td colSpan={6} className="text-center">
                        <h5>There's No Details For Tablets</h5>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>

              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={handheld * 8}
                    rows={8}
                    totalRecords={HHTotalElements}
                    onPageChange={(e: any) => {
                      setHandheld(e.page)
                      getTabletData(e.page)
                    }}
                    currentPageReportTemplate={`Page ${handheld} of ${HHTotalElements / 8}`}
                  />
                </div>
              </div>
              {
                tabletCreation && <>
                  <Modal isOpen={tabletCreation} size="lg" centered style={{ width: '580px' }}>
                    <ModalHeader toggle={() => handleTabletCLS()}>
                      Register
                    </ModalHeader>
                    <ModalBody>
                      <div className="row w-100" style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        <div className="col-md-12 mb-2 d-flex flex-row">
                          <TextField
                            label="Tablet Unique ID"
                            fullWidth
                            required
                            value={tabletData?.TabletId}
                            onChange={(e: any) => {
                              setTabletData((prevData: any) => ({
                                ...prevData,
                                TabletId: e.target.value
                              }));
                              setNameError({ ...nameError, uniqueId: false });
                            }}
                            error={!!nameError.uniqueId}

                          />
                        </div>
                        {/* <div className="col-md-6 mb-2 d-flex flex-row">
                          <TextField
                            label="Tablet MAC Address"
                            fullWidth
                            required
                            value={tabletData?.TabletModel}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const formattedMac = formatMacAddress(e.target.value);
                              setTabletData((prevData: any) => ({
                                ...prevData,
                                TabletModel: formattedMac,
                              }));
                            }}
                          />
                        </div> */}
                      </div>
                      <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                        <div className="col-md-6 mb-2 d-flex flex-row">
                          <FormControl style={{ width: "100%" }} required>
                            <InputLabel
                              style={{ color: nameError.type ? '#dc3545' : '' }}
                            >
                              Type
                            </InputLabel>                            <Select
                              value={tabletData?.Type || ""}
                              label="Type"
                              onChange={(e: any) => {
                                setTabletData((prevData: any) => ({
                                  ...prevData,
                                  Type: e.target.value,
                                }));
                                setNameError({ ...nameError, type: false });
                              }}
                              error={!!nameError.type}
                            >
                              <MenuItem value="iPad">IPad</MenuItem>
                              <MenuItem value="Android">Android</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 mb-2 d-flex flex-row">
                          <TextField
                            label="Tablet Asset Id"
                            fullWidth
                            required
                            value={tabletData?.assestid}
                            onChange={(e: any) => {
                              setTabletData((prevData: any) => ({
                                ...prevData,
                                assestid: e.target.value
                              }));
                              setNameError({ ...nameError, assetId: false });

                            }}
                            error={!!nameError.assetId}

                          />
                        </div>
                      </div>
                    </ModalBody>

                    <ModalFooter className="gap-2">
                      <Button
                        label="Cancel"
                        severity="secondary"
                        style={{
                          color: '#000',
                          backgroundColor: '#94a0b7',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}
                        onClick={() => handleTabletCLS()}
                      ></Button>
                      <Button
                        label="Save"
                        style={{
                          backgroundColor: '#0f3995',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}
                        onClick={() => { handletabletSubmit() }}
                      ></Button>
                    </ModalFooter>
                  </Modal>

                </>
              }
              {
                tabletEditModal &&
                <Modal isOpen={tabletEditModal} size="lg" centered style={{ width: '580px' }}>
                  <ModalHeader toggle={() => handleTableteditCLS()}>
                    Edit
                  </ModalHeader>
                  <ModalBody>
                    <div className="row w-100" style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                      <div className="col-md-12 mb-2 d-flex flex-row">
                        <TextField
                          label="Tablet Unique ID"
                          fullWidth
                          required
                          value={tabletEditData?.uniqueId}
                          name="uniqueId"
                          id="uniqueId"
                          onChange={(e: any) => {
                            setTabletEditData((prevData: any) => ({
                              ...prevData,
                              uniqueId: e.target.value
                            }));
                          }}
                        />
                      </div>
                      {/* <div className="col-md-6 mb-2 d-flex flex-row">
                        <TextField
                          label="Tablet MAC Address"
                          fullWidth
                          id="macAddress"
                          required
                          value={tabletEditData?.macAddress}
                          name="macAddress"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const formattedMac = formatMacAddress(e.target.value);
                            setTabletEditData((prevData: any) => ({
                              ...prevData,
                              macAddress: formattedMac,
                            }));
                          }}
                        />
                      </div> */}
                    </div>
                    <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                      <div className="col-md-6 mb-2 d-flex flex-row">
                        <FormControl style={{ width: "100%" }} required>
                          <InputLabel>Type</InputLabel>
                          <Select
                            label="Type"
                            value={tabletEditData?.type || ""}
                            onChange={(e: any) => {
                              setTabletEditData((prevData: any) => ({
                                ...prevData,
                                type: e.target.value,
                              }));
                            }}
                          >
                            <MenuItem value="iPad">IPad</MenuItem>
                            <MenuItem value="Android">Android</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6 mb-2 d-flex flex-row">
                        <TextField
                          label="Tablet Asset Id"
                          fullWidth
                          required
                          value={tabletEditData?.assetId}
                          onChange={(e: any) => {
                            setTabletEditData((prevData: any) => ({
                              ...prevData,
                              assetId: e.target.value
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="gap-2">
                    <Button
                      label="Cancel"
                      severity="secondary"
                      style={{
                        color: '#000',
                        backgroundColor: '#94a0b7',
                        fontSize: '12px',
                        fontWeight: 'bold'
                      }}
                      onClick={() => handleTableteditCLS()}
                    ></Button>
                    <Button
                      label="Save"
                      style={{
                        backgroundColor: '#0f3995',
                        fontSize: '12px',
                        fontWeight: 'bold'
                      }}
                      onClick={() => { handleEdittabletSubmit() }}
                    ></Button>
                  </ModalFooter>
                </Modal>
              }
            </div>
          </TabPanel>
        </TabContext>
      </Box>
      <LogoutConfirmationModal
        open={tabletDeleteModal}
        handleConfirm={() => hadleDeleteTabletData()}
        handleClose={() => handleDeleteDataCls()}
        message={`Do you want to delete this data? ${tabletDeleteData.assetId} `}
      />
    </div>
  );
};

export default QRCodeScanner;