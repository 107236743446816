import React, { useEffect, useState } from 'react'
import { primarybg, secondarybt, white } from '../../common/primary'
import { ArrowDropDownIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRNIncharge, getAllStaff, getPatientDropdown } from '../../slices/thunk'
import moment from 'moment'
import { HttpLogin } from '../../utils/Http'
import { baseURL, successCode } from '../../configuration/url'
import axios from 'axios'
import { authorize } from '../../helpers/common'
import ReportSheetView from '../q15Report/ReportSheetView'
import { IoPrintOutline } from 'react-icons/io5'
import Rectangle6215 from './../../assets/images/Rectangle 6215.png';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import Loader from '../../components/loader/Loader'

interface Shift {
    shift_name: string;
    start_time: string;
    end_time: string;
    dayShift: string;
}

const OneHourReport = () => {
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const org = useSelector((state: any) => state.Login.organization);
    const { rnInchargeList } = useSelector((state: any) => state.PSConfig)
    const { userData, userType } = useSelector((state: any) => state.Login);
    const userId = userData.userDetail.id;
    const { staffData } = useSelector((state: any) => state.Staff);
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    const { locationLegend, activityLegend } = useSelector((state: any) => state.Dropdown);
    const dispatch = useDispatch<any>();
    const [loading, setLoading] = useState<boolean>(false)
    let [loginStaff, setLoginStaff] = useState<any>("");
    let [addNewStaff, setAddNewStaff] = useState<any>([]);
    const [legendcontrol, setLegendControl] = useState<boolean>(false)
    const [isHidden, setIsHidden] = useState(false);
    let [viewContentData, setViewContent] = useState(false);
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [newExpanded, setNewExpanded] = useState<string | false>('panel11');
    const [noOfShift, setNoOfShift] = useState<any>(0);
    const [noOfSlot, setNoOfSlot] = useState<any>()
    const [newAddExpanded, setNewAddExpanded] = useState<string | false>('');
    const [newAddExpandeds, setNewAddExpandeds] = useState<string | false>('');
    const [shifts, setShifts] = useState<any>([]);
    const [hiddenBox, setHiddenBox] = useState<boolean>(false)
    const [timeFormat, setTimeFormat] = useState<boolean>(false)

    const [inchargeList, setInchargeList] = useState<any>([])
    let [getStaffData, setStaffData] = useState<any>([]);
    let [newTimeSlot, setNewTimeSlot] = useState<any>([]);
    let [addNurseIncharge, setAddNurseIncharge] = useState<any>("");
    let [addEnteredBy, setAddEnteredBy] = useState<any>("");
    const addInputData = {
        shiftIncharge: "",
        criteria: "",
        patientName: "",
        slot: "",
        shift: [""],
        startDate: null,
        endDate: null
    }

    let [newInputData, setNewInputData] = useState(addInputData);
    let [addCalendarDate, setAddCalendarDate] = useState<any>([]);
    let [FilteraddCalendarDate, setFilterAddCalendarDate] = useState(new Array<any>());
    let [calendarDate,] = useState(new Date());
    let [addPatientName, setAddPatientName] = useState<any>("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    const [selectedDate, setSelectedDate] = useState<any>();
    const [tableData, setTableData] = useState<any>([])

    const [selectShift, setSelectShift] = useState<any>('');
    const [option, setOption] = useState<any>([])
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
    }, [orgData])

    useEffect(() => {
        if (noOfShift) {
            setOption(Array.from({ length: noOfShift }, (_, i) => `Shift-${String.fromCharCode(65 + i)}`));
        }
    }, [noOfShift]);
    const handleTimeFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeFormat(e.target.checked)
    }
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    function removeDuplicates(arr: any[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    const handleStartTime = async () => {
        let crtime: any = localStorage.getItem("LTime");
        if (crtime instanceof Promise) {
            crtime = await crtime;
        }

        if (crtime) {
            const formattedDate = moment(crtime).format("YYYY-MM-DD");
            const currentTime = moment(crtime).format("HH:mm");
            const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`);
            const CurrTime: any = moment(`1970-01-01T${currentTime}`);

            if (shiftStartTime !== CurrTime) {
                if (CurrTime.isAfter(OrgStartTime)) {
                    setSelectedDate(formattedDate);
                } else {
                    if (!formattedDate) return;
                    const validDate = moment(formattedDate);
                    if (validDate.isValid()) {
                        const nextDay = validDate.clone().subtract(1, "days");
                        setSelectedDate(moment(nextDay).format("YYYY-MM-DD"));
                    } else {
                        console.error("Invalid date:", formattedDate);
                    }
                }
            } else {
                setSelectedDate(formattedDate);
            }
        }
    };
    useEffect(() => {
        handleStartTime();
    }, []);
    useEffect(() => {
        HttpLogin.axios().get("/api/org/getById/" + org)
            .then((res) => {
                if (res.data.message.code === successCode) {
                    var newResult = res.data.data.shift.startTime !== "" ? res.data.data.shift.startTime : "08:00";
                    const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
                        let slotStart = new Date(fromTime).getTime();
                        let endEpoch = new Date(toTime).getTime();
                        let ob = [];

                        while (slotStart < endEpoch) {
                            ob.push(formatDate(slotStart));
                            slotStart += slotLength * 1000;
                        }

                        return ob;
                    };

                    const from = "2022-05-25 " + newResult;
                    const to = "2022-05-26 " + newResult;
                    const slotLength = 60 * 60;
                    var r = createTimeSlots(from, to, slotLength);
                    setNewTimeSlot(r);
                }
            })
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null
            ? moment(newInputData.startDate)
            : moment(selectedDate);

        var dayCalendar = [];
        dayCalendar.push(from.format("YYYYMMDD"));
        setAddCalendarDate(dayCalendar);
    }, [org, selectedDate]);
    useEffect(() => {
        getPatientDropdown(dispatch, org)
        getAllRNIncharge(dispatch, 'Registered Nurse', org)
        getAllStaff(dispatch, org, -1, '');
    }, [org])
    useEffect(() => {
        if (rnInchargeList) {
            setInchargeList(rnInchargeList !== null && rnInchargeList !== undefined && rnInchargeList?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))
        } else {
            setInchargeList([])
        }
    }, [rnInchargeList])
    useEffect(() => {
        let newLoginstaff = staffData !== null && staffData !== undefined && staffData?.filter((t: any) => ((t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner") && (t.id === userId)))
        if (newLoginstaff?.length > 0) {
            setLoginStaff(newLoginstaff[0].name[0].given + " " + newLoginstaff[0].name[0].family);
        }
        setAddNewStaff(staffData !== null && staffData !== undefined ? staffData : []);
        setStaffData(staffData !== null && staffData !== undefined && staffData?.map((k: any) => { return k.name[0].given + " " + k.name[0].family }))

    }, [staffData, userId])
    const handleDateChange = (event: any) => {
        const d = moment(event); // Use Moment.js directly
        let w = d.year().toString();

        if (w.length !== 4) return;

        newInputData.startDate = event;
        setNewInputData({ ...newInputData });

        let from = newInputData.startDate ? moment(newInputData.startDate) : moment(selectedDate);
        let to = newInputData.endDate ? moment(newInputData.endDate) : moment(selectedDate);

        let dayCalendar: string[] = [];

        for (let day = from.clone(); day.isSameOrBefore(to, "day"); day.add(1, "day")) {
            dayCalendar.push(day.format("YYYYMMDD"));
        }

        setAddCalendarDate(removeDuplicates(dayCalendar));
    };
    const handleNewDateChange = (event: any) => {
        const d = moment(event);
        let w = d.year().toString();

        if (w.length !== 4) return;

        newInputData.endDate = event;
        setNewInputData({ ...newInputData });

        let from = newInputData.startDate ? moment(newInputData.startDate) : moment();
        let to = newInputData.endDate ? moment(newInputData.endDate) : moment(calendarDate);

        let dayCalendar: string[] = [];

        for (let day = from.clone(); day.isSameOrBefore(to, "day"); day.add(1, "day")) {
            dayCalendar.push(day.format("YYYYMMDD"));
        }

        setAddCalendarDate(removeDuplicates(dayCalendar));
    };

    useEffect(() => {
        const ohsReport = async () => {
            if (!selectedDate) return
            setLoading(true)
            try {
                var newFromDate = newInputData.startDate !== null ? moment(newInputData.startDate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD");
                var newToDate = newInputData.endDate !== null ? moment(newInputData.endDate).format("YYYYMMDD") : moment(selectedDate).format("YYYYMMDD");
                let apiURl = `/ohsreport/getByDateRange?endDate=${newToDate}&organization=${org}&startDate=${newFromDate}`
                
                if (addNurseIncharge) {
                    apiURl += `&shiftInchargeName=${addNurseIncharge.trim()}`;
                }
                if (addPatientName?.name) {
                    apiURl += `&patientName=${addPatientName?.name}`;
                }

                if (newInputData?.criteria) {
                    apiURl += `&enteredBy=${newInputData?.criteria}`
                } else if (userType === "Staff") {
                    let newStaffData = addNewStaff
                        .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === loginStaff)
                        .map((k:any) => k.id);
                    apiURl += `&enteredBy=${newStaffData[0]}`
                }
                if (selectShift) {
                    apiURl += `&shiftName=${selectShift}`
                }
                const { header1 } = authorize();
                const response = await axios.get(baseURL + apiURl, { headers: header1 })
                if (response.data.message.code === successCode) {
                    const uniqueBdates = new Set(response?.data?.ohsReports?.map((item: any) => item.date));
                    const filtered = Array.from(new Set(addCalendarDate?.filter((date: any) => uniqueBdates.has(date))));
                    setFilterAddCalendarDate(filtered)
                    setTableData(response.data.ohsReports)
                    setLoading(false)
                } else {
                    setTableData([])
                    setLoading(false)
                }
            } catch (error: any) {
                console.error("API ERROR :", error)
                setLoading(false)
            }
        }
        ohsReport();
        // console.log('addPatientName',addPatientName,"\n addNurseIncharge", addNurseIncharge,'\n newInputData.criteria', newInputData.criteria)
    }, [addCalendarDate, newInputData, org, addPatientName, selectedDate, selectShift])
    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegendControl(e.target.checked)
    }
    const handlePrint = () => {
        setIsHidden(true);
        setTimeout(() => {
            const printContents = document.getElementById('oneHourReports')!.innerHTML;
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.open();
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Q15 Report</title>
                            <style>
                                /* General styles for printing */
                                @media print {
                                    body {
                                        font-family: Arial, sans-serif;
                                        margin: 0;
                                        padding: 0;
                                    }
                                    #q15Reports {
                                        width: 100%;
                                        margin: 0;
                                        padding: 0;
                                        page-break-inside: avoid; /* Prevent content from breaking within elements */
                                    }
                                    * {
                                        box-sizing: border-box;
                                    }
                                    /* Remove unwanted margins or padding */
                                    html, body {
                                        width: 100%;
                                        height: auto;
                                    }
                                    /* Ensure no blank space at the end */
                                    div {
                                        page-break-after: avoid;
                                    }
                                }
                            </style>
                        </head>
                        <body>
                            <div id="q15Reports">${printContents}</div>
                        </body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.onload = () => {
                    printWindow.print();
                    printWindow.close();
                    setIsHidden(false);
                };
            }
        }, 0);
    };
    const handleDownload = async () => {
        if (tableData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < tableData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(tableData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);


                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${reports.id}`);
                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height;
                    const images: any[] = [];
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientName}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    const handleNewChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setNewExpanded(newExpanded ? panel : false);
        };
    const handleNewAddChanges = (panel: string, y: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        // console.log("Clicked shift panel:", panel, "Index:", y, "isExpanded:", isExpanded);
        setNewExpanded(isExpanded ? `${panel}/${y}` : false);
    };
    const handleNewAddChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        // console.log("Clicked panel:", panel, "isExpanded:", isExpanded);
        setNewAddExpanded(isExpanded ? panel : false);
    };
    const decreaseOneMinute = (timeString: any) => {
        // Split the time string to extract hours and minutes
        if (!timeString) return
        const [hours, minutes] = timeString.split(':').map(Number);

        // Create a new Date object for today with the given time
        let date = new Date();
        date.setHours(hours, minutes, 0); // Set the time (HH:MM:SS)

        // Subtract 1 minute
        date.setMinutes(date.getMinutes() + 1);

        // Format the new time back to HH:mm format
        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    }
    const formatTimeTo12Hour = (time: any) => {
        let addTime = decreaseOneMinute(time)
        return moment(addTime, 'HH:mm').format('hh:mm A');
    };

    const formatTimeTo12Hour1 = (time: any) => {
        return moment(time, 'HH:mm').format('hh:mm A');
    };
    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }

        return 24 / duration;
    };
    const getNumberofSlot = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return duration / 2;
    }
    useEffect(() => {
        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
        let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
        setNoOfSlot(numSlot);
    }, [orgData])
    useEffect(() => {
        if (!noOfShift) return
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [noOfShift])

    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        // Convert the start time string to a Date object
        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        // Function to convert 24-hour time to 12-hour time with AM/PM
        const to12HourFormat = (date: any) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? "PM" : "AM";

            // Convert hour to 12-hour format
            hours = hours % 24;
            if (hours === 0) {
                hours = '00'; // Midnight or noon case
            }

            // Add leading zero for minutes if needed
            minutes = minutes < 10 ? "0" + minutes : minutes;

            // Return formatted time with AM/PM
            return `${hours}:${minutes} `;
        };

        for (let i = 0; i < totalShifts; i++) {
            // Calculate the end time by adding the duration to the start time
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            ); // duration in hours

            // Format start and end times into 12-hour format with AM/PM
            const startTimeFormatted = to12HourFormat(startTime);
            const endTimeFormatted = to12HourFormat(endTime);

            // Assign shift name (A, B, C, etc.)
            const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

            // Push shift details into the array
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                dayShift: "",
            });

            // Update the start time for the next shift
            startTime = endTime;
        }
        const convertTo24Hour = (time: string) => {
            const [hour, minuteWithPeriod] = time.split(":");
            const [minute, period] = minuteWithPeriod.split(" ");
            let hour24 = parseInt(hour);

            // Handle AM/PM conversion
            if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
            if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

            return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
        };

        const addDayShift = (shift: Shift, addShift: boolean): Shift => {
            const startInMinutes = convertTo24Hour(shift.start_time);
            const endInMinutes = convertTo24Hour(shift.end_time);
            if (endInMinutes < startInMinutes) {
                return { ...shift, dayShift: "+1" };
            }
            if (addShift) {
                return { ...shift, dayShift: "+1" };
            }
            return { ...shift, dayShift: "" };
        };
        let addShift = false;

        // Apply the dayShift logic to each shift
        const updatedShifts = shifts.map((shift: any) => {
            // If we encounter a shift that crosses midnight, set addShift to true
            if (
                !addShift &&
                convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
            ) {
                addShift = true;
            }
            return addDayShift(shift, addShift);
        });
        setShifts(updatedShifts);
    };
    return (
        <div className=''>
            {loading && <Loader />}
            <div hidden={viewContentData} className='d-flex justify-content-end ' >
                <div hidden={viewContentData} className="col-md-8">
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><IoPrintOutline />Print</button>
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={() => setViewContent(true)}>View</button>
                    <FormControlLabel
                        style={{ float: 'right', marginLeft: '5px', width: '180px' }}
                        control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                        label={'Legends Key'}
                    />
                </div>
            </div>
            <div hidden={viewContentData} className='row' style={{ height: '83vh' }} >
                <div hidden={viewContentData} className="col-md-3 d-flex flex-column pt-3" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker className="m-3 " label="Start Date"
                            value={newInputData.startDate}
                            onChange={handleDateChange}
                        />
                        <DatePicker className="m-3" label="End Date"
                            value={newInputData.endDate}
                            onChange={(e) => { handleNewDateChange(e) }}
                        />
                    </LocalizationProvider>
                    <Autocomplete
                        className="m-3"
                        options={option}
                        getOptionLabel={(option) => option}
                        value={selectShift}
                        onChange={(e, v) => {
                            // if (reportData.length > 0) {
                            console.log(v, 'valuepf')
                            setSelectShift(v)
                            // setViewContent(!viewContentData)
                            // handlesumbit()
                            // setCheckSubmit(true)
                            // } else {
                            //     setSelectShift(v)
                            // }
                        }}
                        sx={{}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Shift"
                                placeholder="Shift"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />

                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={patientDropdown}
                        getOptionLabel={(option: any) => option.name || ""}
                        value={addPatientName !== null ? addPatientName : ""}
                        onChange={(e, v: any) => {
                            setAddPatientName(v);
                            if (v && v.name) {
                                const matchedPatient = patientDropdown?.find(
                                    (patient: any) => patient.name === v.name
                                );
                                console.log(v, matchedPatient, "ofthevalue");
                                if (matchedPatient) {
                                    newInputData.patientName = matchedPatient.id;
                                    setNewInputData({ ...newInputData });
                                }
                            } else {
                                newInputData.patientName = "";
                                setNewInputData({ ...newInputData });
                            }
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Patient Name"
                                placeholder="Patient Name"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={inchargeList}
                        getOptionLabel={(option: any) => option}
                        value={addNurseIncharge || ""}
                        onChange={(e, v) => {
                            // addNurseIncharge = v;
                            setAddNurseIncharge(v);
                            let newStaffData = rnInchargeList.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k: any) => { return k.id });
                            newInputData.shiftIncharge = newStaffData[0];
                            setNewInputData({ ...newInputData });
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Nurse Incharge"
                                placeholder="Nurse Incharge"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getStaffData}
                        disabled={userType === 'Staff' && loginStaff?.length > 0}
                        getOptionLabel={(option: any) => option}
                        value={userType === "Staff" && loginStaff?.length > 0 ? loginStaff : addEnteredBy || ""}
                        onChange={(e, v) => {
                            // addEnteredBy = v;
                            setAddEnteredBy(v);
                            // let newStaffData = addNewStaff
                            //     .filter((m: any) => `${m.name[0].given} ${m.name[0].family}` === v)
                            //     .map((k: any) => k.id);
                            // newInputData.criteria = newStaffData[0];
                            newInputData.criteria = v;
                            setNewInputData({ ...newInputData });
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Entered By"
                                placeholder="Entered By"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div hidden={viewContentData} className="col-md-9" style={{ overflow: 'auto', height: '83vh' }}>
                    {/* {
                        reportData.length > 0 && <ReportSheetView reportData={undefined} checkPrecaution={undefined} locationLegend={undefined} activityLegend={undefined} checkBox={undefined} legendcontrol={undefined} />
                    } */}
                    {
                        FilteraddCalendarDate !== undefined && FilteraddCalendarDate !== null && FilteraddCalendarDate.length !== 0 ? FilteraddCalendarDate.map((u: any) => (
                            <Accordion expanded={expanded === 'panelmain' + u} onChange={handleChange('panelmain' + u)}>
                                <AccordionSummary style={{ backgroundColor: "#FFF" }} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDropDownIcon />}>
                                    <Typography style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                        <span style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                            {moment(u).format("MMM DD, YYYY")}
                                        </span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {
                                            tableData !== null && tableData !== undefined && Array.isArray(tableData) && tableData?.filter((j: any) => u === moment(j.date).format("YYYYMMDD")).length > 0 ? (tableData !== null && tableData !== undefined && tableData?.filter((j: any) => u === moment(j.date).format("YYYYMMDD")).map((l: any, y: any) => (
                                                <Accordion style={{ borderStyle: "none" }} expanded={newExpanded === `panel11-${l.patientName}` || newExpanded === `panel11-${l.patientName}` + "/" + y} onChange={(event, isExpanded) => {
                                                    // handleNewChange('panel11')

                                                    if (newAddExpandeds === `panel11-${l.patientName}` + "/" + y) {
                                                        handleNewAddChanges(`panel11-${l.patientName}`, y)(event, isExpanded);
                                                    } else {
                                                        handleNewChange(`panel11-${l.patientName}`)(event, isExpanded);
                                                    }
                                                }
                                                }>
                                                    <AccordionSummary style={{ backgroundColor: "#FFF" }} expandIcon={false} aria-controls="panel11d-content" id="panel11d-header">
                                                        <Typography style={{ width: "-webkit-fill-available" }}>
                                                            <span style={{ fontFamily: "poppins", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                                                {l.patientName}
                                                            </span>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <ReportSheetView reportData={tableData?.filter((j: any) => l.patientName === j.patientName)} checkPrecaution={'One-Hour'} locationLegend={locationLegend} activityLegend={activityLegend} checkBox={undefined} legendcontrol={legendcontrol} InxNum={undefined} oneHour={true} logo={orgData.logo} timeFormat={timeFormat} selectShift={selectShift} />
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))) : (<div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>)
                                        }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )) : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                    }
                </div>
            </div>

            <div id="oneHourReports" hidden={!viewContentData}>
                {viewContentData && !isHidden && (
                    <div className="d-flex flex-row justify-content-end gap-2" hidden={hiddenBox} >
                        <FormControlLabel
                            style={{ float: 'right', marginLeft: '5px', width: '150px' }}
                            control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                            label={'Legends Key'}
                        />
                        <FormControlLabel
                            style={{ float: 'right', marginLeft: '5px', width: '200px' }}
                            control={<Checkbox checked={timeFormat} onChange={handleTimeFormat} />}
                            label={'24Hour Time Format'}
                        />
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => { setHiddenBox(true); handleDownload(); }}>Download</button>
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => { setHiddenBox(true); handlePrint(); }}>Print</button>
                        <button className="btn" style={{ backgroundColor: primarybg, color: white }} onClick={() => setViewContent(false)}>Close</button>
                    </div>
                )}
                {
                    tableData?.length > 0 && Array.isArray(tableData) ?
                        <div className='mx-3'>
                            <ReportSheetView reportData={tableData} checkPrecaution={'One-Hour'} locationLegend={locationLegend} activityLegend={activityLegend} checkBox={undefined} legendcontrol={legendcontrol} InxNum={undefined} oneHour={true} logo={orgData.logo} timeFormat={timeFormat} selectShift={selectShift} />
                        </div>
                        : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                }
            </div>
        </div>
    )
}

export default OneHourReport