import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Autocomplete,
  TextField,
} from "@mui/material";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "primereact/button";
import { getAllBeacon } from "../../slices/thunk";
import { authorize } from "../../helpers/common";

interface FormData {
  id: string;
  uuid: string;
  deviceName: string;
  deviceId: string;
  BeaconType: string;
  modelNumber: string;
  orgId: string;
}

interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

interface BeaconCreationFormProps {
  modal: boolean;
  toggle: () => void;
  orgId1: string;
  currentPage: number;
}

const initialFormData: FormData = {
  id: "",
  uuid: "",
  deviceName: "",
  deviceId: "",
  BeaconType: "",
  modelNumber: "",
  orgId: "",
};

const BeaconCreation: React.FC<BeaconCreationFormProps> = ({
  modal,
  toggle,
  orgId1,
  currentPage
}) => {
  const { organization } = useSelector((state: any) => state.Login);
  const { beaconTypeDropdown } = useSelector((state: any) => state.Dropdown);
  const orgId = orgId1 ? orgId1 : organization;
  const [formValues, setFormValues] = useState<FormData>(initialFormData);
  const dispatch = useDispatch<any>();
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});

  const resetForm = () => {
    setFormValues(initialFormData);
  };

  const handleCancelClick = () => {
    toggle();
    resetForm();
    setNameError({});
    setFormValues(initialFormData);
  };

  const handleSaveClick = async () => {
    const newErrors = {
      uuid: !formValues.uuid,
      deviceName: !formValues.deviceName,
      deviceId: !formValues.deviceId,
      deviceType: !formValues.BeaconType,
      modelNumber: !formValues.modelNumber,
    };
    setNameError(newErrors);
    const requestBody = {
      id: "",
      uuid: formValues.uuid,
      deviceName: formValues.deviceName,
      deviceId: formValues.deviceId,
      deviceType: formValues.BeaconType,
      modelNumber: formValues.modelNumber,
      orgId: orgId,
    };
    const { header1 } = authorize();
    try {

      if (!formValues.uuid || !formValues.deviceId || !formValues.deviceName || !formValues.modelNumber) {
        toast.error("Please fill the required fields");
        return;
      }
      const response = await axios.post(
        `${baseURL}/sensor/register`,
        requestBody, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        toast.success(response.data.message.description);
        toggle();
        resetForm();
        handleCancelClick();
        getAllBeacon(dispatch, orgId, currentPage, '')
      } else {
        toast.error(response.data.message.description);
      }
    } catch (error) {
      toast.error("Something went wrong")
      console.error("beaconCreate: ", error);
    }
  };

  return (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      style={{ width: "580px" }}
    >
      <div className="d-flex align-items-center justify-content-center m-20">
        <div className="row w-100">
          <div className="container col-md-12">
            <ModalHeader
              toggle={() => {
                resetForm();
                setFormValues(initialFormData);
                toggle();
              }}
            >
              Device/Beacon Register
            </ModalHeader>
            <ModalBody>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="col-md-12 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Unique ID (UUID)"
                    required
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setFormValues({ ...formValues, uuid: e.target.value });
                      setNameError({ ...nameError, uuid: false });
                    }}
                    error={!!nameError.uuid}
                  />
                </div>
              </div>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="MAC Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={formValues.deviceId || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const cleanedValue = inputValue.replace(/[^a-zA-Z0-9:]/g, "");
                      const withoutExtraColons = cleanedValue.replace(/(:{2,})/g, ":");
                      let rawInput = withoutExtraColons.replace(/:/g, "").toUpperCase();
                      rawInput = rawInput.slice(0, 12);
                      let formattedValue = "";
                      for (let i = 0; i < rawInput.length; i += 2) {
                        formattedValue += rawInput.substring(i, i + 2);
                        if (i + 2 < rawInput.length) {
                          formattedValue += ":";
                        }
                      }
                      setFormValues({ ...formValues, deviceId: formattedValue });
                      setNameError({ ...nameError, deviceId: false });
                    }}
                    error={!!nameError.deviceId}

                  />
                </div>

                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    type="Number"
                    label="Serial Number"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setFormValues({ ...formValues, deviceName: e.target.value })
                      setNameError({ ...nameError, deviceName: false });
                    }}
                    error={!!nameError.deviceName}
                  />
                </div>
              </div>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div className="col-md-6 mb-2">
                  <Autocomplete
                    id="Model"
                    options={beaconTypeDropdown.map((item: any) => item.value)}
                    value={formValues.modelNumber}
                    onChange={(event, value: any) => {
                      let modelNumber = "";
                      if (value === "W7") {
                        modelNumber = "METTLER WRISTBAND BEACON";
                      } else if (value === "W8") {
                        modelNumber = "METTLER SOS BEACON";
                      } else if (value?.toUpperCase() === "KG02") {
                        modelNumber = "METTLER BEACON GATEWAY";
                      } else if (value?.toUpperCase() === "WH15") {
                        modelNumber = "METTLER HR WRISTBAND BEACON";
                      }

                      setFormValues({ ...formValues, modelNumber: value, BeaconType: modelNumber });
                      setNameError({ ...nameError, modelNumber: false });

                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Model"
                        fullWidth
                        required
                        error={!!nameError.modelNumber}
                      />
                    )}
                  />
                </div>

                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Device Type"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!nameError.modelNumber}
                    value={formValues.BeaconType ? formValues.BeaconType : 'N/A'}
                    name="modelNumber"
                  />
                </div>
              </div>
            </ModalBody>
          </div>
          <ModalFooter className="">
            <div className="d-flex gap-3 justify-content-center">
              <Button
                label="Cancel"  severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
                onClick={handleCancelClick}
              ></Button>
              <Button
                label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}
                onClick={handleSaveClick}
              ></Button>
            </div>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
};

export default BeaconCreation;
