import { useNavigate, useParams } from "react-router-dom";
import {
    deleteOrganizationDetails,
    activeOrg,
    updateOrganizationDetails,
} from "../../slices/organizationDetails/thunk";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

import { baseURL, successCode } from "../../configuration/url";
import { formatPhoneNumber, formatEmail } from "../../helpers/common";
import { Button } from "primereact/button";
import { getOrgByID } from "../../slices/thunk";
import uploadImage from "../../assets/images/rectangle-59991.svg";
import { useTimezoneSelect, allTimezones } from "react-timezone-select"
import { Close } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import LogoutConfirmationModal from "../../components/LogoutModel";
import useZipcode from "../../hooks/usefetch";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const labelStyle = "original"
const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
}
interface FormData {
    id: string,
    active: any,
    name: string,
    email: string,
    mobileNumber: string,
    websiteUrl: string,
    type: string,
    hippaPrivacyOfficerFirstName: string,
    hippaPrivacyOfficerLastName: string,
    proximityVerification: string,
    geofencing: string,
    q15Access: string,
    duration: string,
    startTime: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    country: string,
    zip: string,
    cFPerson: string,
    cLPerson: string,
    cEmail: string,
    cPhone: string,
    officemobileNumber: string,
    npi: string,
    tin: string,
    timeZone: string,
    logo: any,
    sos: boolean,
    q15OneHour: boolean,
    q15OneToOne: boolean,
    eyeSight: boolean,
    q15Slot: string,
    updateRequest: boolean,
    patientsCountForStaff: Number,
    slotduration: string
}

const OrgUpdate: React.FC = () => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const { options } = useTimezoneSelect({ labelStyle, timezones })
    const params = useParams();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [activeDetail, setActiveDetail] = useState<any>()
    const [activeMessage, setActiveMessage] = useState<any>('')
    const [checkDuration, setCheckDuration] = useState<boolean>(false)
    const [slotOption, setSlotOption] = useState<any>([])


    const [zipCode, setZipCode] = useState("");
    const { data, zipLoading } = useZipcode(zipCode);
    const [formData, setFormData] = useState<FormData>({
        id: "",
        active: '',
        name: "",
        email: "",
        mobileNumber: "",
        websiteUrl: "",
        type: "",
        hippaPrivacyOfficerFirstName: "",
        hippaPrivacyOfficerLastName: "",
        proximityVerification: "",
        geofencing: "",
        q15Access: "",
        duration: "",
        startTime: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        cFPerson: "",
        cLPerson: "",
        cEmail: "",
        cPhone: "",
        officemobileNumber: "",
        npi: "",
        tin: "",
        timeZone: "",
        logo: "",
        sos: false,
        q15OneHour: false,
        q15OneToOne: false,
        eyeSight: false,
        q15Slot: "",
        updateRequest: false,
        patientsCountForStaff: 0,
        slotduration: ""
    })

    useEffect(() => {
        const getSlotDuration = () => {
            if (formData.duration === "") return;

            const Durationvalue = formData.duration as keyof typeof slotOptionsMap;

            const slotOptionsMap = {
                "04": [{ label: "01", value: "01" }, { label: "02", value: "02" }, { label: "04", value: "04" }],
                "06": [{ label: "01", value: "01" }, { label: "02", value: "02" }, { label: "03", value: "03" }],
                "08": [{ label: "01", value: "01" }, { label: "02", value: "02" }, { label: "04", value: "04" }],
                "12": [{ label: "01", value: "01" }, { label: "02", value: "02" }, { label: "03", value: "03" }, { label: "04", value: "04" }, { label: "06", value: "06" }]
            };
            setSlotOption(slotOptionsMap[Durationvalue] || []);
        };
        getSlotDuration();
    }, [formData, checkDuration])
    useEffect(() => {
        const locationData = data.data?.[0];

        if (data) {
            if (locationData) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    state: locationData?.state || "",
                    country: locationData?.countryISO || "",
                    city: locationData?.city || "",
                }));
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    state: "",
                    country: "",
                    city: "",
                }));
            }
        }
    }, [data]);

    const organization = params.id;
    const { orgData, loading } = useSelector(
        (state: any) => state.Org
    )
    const { loading: OrgLoading } = useSelector((state: any) => state.Organization)
    const { orgType } = useSelector((state: any) => state.Dropdown);
    const currentPage = 0;
    const [orgname, setOrgname] = useState(false);
    const [email, setEmail] = useState(false);
    const [npi, setnpi] = useState(false);
    const [tin, settin] = useState(false);
    const [mobilePhone, setMobilePhone] = useState(false);
    const [duration, setDuration] = useState(false);
    const [slotDuration, setSlotDuration] = useState(false);
    const [show, setShow] = useState(false);
    const [organizationId, setOrganizationId] = useState("");
    const [organizationName, setOrganizationName] = useState("")
    const [secretKey, setSecretKey] = useState({
        pinNumber: ['', '', '', '', '', '']
    });
    const secretcode = secretKey.pinNumber.join('')
    const inputRefs = useRef<any>([]);

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const DurationDropDown = [{ "id": "04" }, { "id": "06" }, { "id": "08" }, { "id": "12" }]
    const handleInputChangep = (e: any, index: any) => {
        const newValue = e.target.value;
        setSecretKey(prevState => {
            const newData = [...prevState.pinNumber];
            newData[index] = newValue;
            return {
                pinNumber: newData
            };
        });
        if (index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };
    const maskPassword = (index: any) => {
        const inputElement = document.getElementById(`pinNumber${index}`) as HTMLInputElement;
        inputElement.type = 'text';
        setTimeout(() => {
            inputElement.type = 'password';
        }, 400);
    };
    const userEmail = localStorage.getItem('userDetailEmail');
    const handleBackspaceAndEnter = (e: any, index: any) => {
        if (e.key === 'Backspace') {
            if (index > 0 && secretKey.pinNumber[index] === '') {
                const prevInput = document.getElementById(`pinNumber${index - 1}`) as HTMLInputElement;
                prevInput.focus();
                setSecretKey(prevState => {
                    const newData = [...prevState.pinNumber];
                    newData[index - 1] = '';
                    return {
                        pinNumber: newData
                    };
                });
            } else {
                setSecretKey(prevState => {
                    const newData = [...prevState.pinNumber];
                    newData[index] = '';
                    return {
                        pinNumber: newData
                    };
                });
            }
        }
    };
    const [orgdatabyId, setOrgdatabyId] = useState<any>(orgData);
    const handleClose1 = () => {
        secretKey.pinNumber = ["", "", "", "", "", ""]
        setShow(false)
    };
    console.log(orgdatabyId, 'orgdatabyId');
    useEffect(() => {
        if (!params?.id) return
        getOrgByID(dispatch, organization)
    }, [params?.id, dispatch, organization])

    useEffect(() => {
        setOrgdatabyId(orgData)
    }, [orgData])

    useEffect(() => {
        setFormData({
            id: orgdatabyId?.id,
            active: orgdatabyId?.active,
            name: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.name) || "",
            email: orgdatabyId?.email || "",
            mobileNumber: orgdatabyId?.mobileNumber || "",
            websiteUrl: orgdatabyId?.websiteUrl || "",
            type: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0].type) || "",
            hippaPrivacyOfficerFirstName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.firstName) || "",
            hippaPrivacyOfficerLastName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.lastName) || "",
            startTime: orgdatabyId?.shift?.startTime || "",
            duration: orgdatabyId?.shift?.duration || "",
            proximityVerification: orgdatabyId?.proximityVerification || "",
            q15Access: orgdatabyId?.q15Access || "",
            geofencing: orgdatabyId?.geofencing || "",
            addressLine1: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine1) || "",
            addressLine2: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine2) || "",
            officemobileNumber: (orgdatabyId?.contact && orgdatabyId.contact[0]?.officemobileNumber) || "",
            city: (orgdatabyId?.contact && orgdatabyId.contact[0]?.city) || "",
            state: (orgdatabyId?.contact && orgdatabyId.contact[0]?.state) || "",
            country: (orgdatabyId?.contact && orgdatabyId.contact[0]?.country) || "",
            zip: (orgdatabyId?.contact && orgdatabyId.contact[0]?.zip) || "",
            cFPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.firstName) || "",
            cLPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.lastName) || "",
            cEmail: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.email) || "",
            cPhone: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.phoneNumber) || "",
            npi: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.npi) || "",
            tin: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.tin) || "",
            timeZone: orgdatabyId?.timeZone || "",
            logo: orgdatabyId?.logo || "",
            sos: orgdatabyId?.sos || "",
            q15OneHour: orgdatabyId?.q15OneHour || "",
            q15OneToOne: orgdatabyId?.q15OneToOne || "",
            eyeSight: orgdatabyId?.eyeSight || false,
            q15Slot: orgdatabyId?.q15Slot || "",
            updateRequest: orgdatabyId?.updateRequest,
            patientsCountForStaff: orgdatabyId?.patientsCountForStaff,
            slotduration: orgdatabyId?.slotDuration
        })
    }, [orgdatabyId])
    const handleOrganizationTypeChange = (event: any, value: any) => {
        setFormData({
            ...formData,
            type: value
        });
    };

    const handleOrgChange = (organization: string, orgName: string) => {
        window.localStorage.setItem("OrgName", orgName)
        setOrganizationName(orgName);
        navigate(`/beacon-table/${organization}`);
    }
    const handleopen = (orgID: any, orgName: any) => {
        window.localStorage.setItem("OrgName", orgName)
        setShow(true);
        setOrganizationName(orgName);
        setOrganizationId(orgID);
    };

    const handleActiveModalOpen = (organization: any) => {
        setActiveModal(!activeModal)
        setActiveDetail(organization)
        if (organization?.active) {
            setActiveMessage(`Are you sure to inactive this "${organization?.name}"?`)
        } else {
            setActiveMessage(`Are you sure to activate "${organization?.name}"?`)
        }
    }

    const handleAvtiveModalClo = () => {
        setActiveModal(!activeModal)
        setActiveDetail('')
    }

    const handleActivatedModal = async (organization: any) => {
        if (organization?.active) {
            handleopen(organization.id, organization?.name)
            handleAvtiveModalClo()
        } else {
            await dispatch(activeOrg(organization.id, currentPage));
            getOrgByID(dispatch, organization.id)
            handleAvtiveModalClo()
        }
    }
    const get24HourFormat = (timeString: string): string => {
        if (!timeString) return '';

        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);

        let hours24 = period === 'PM' && hours !== 12 ? hours + 12 : hours;
        if (period === 'AM' && hours === 12) hours24 = 0;

        return `${String(hours24).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };
    const handleSaveChanges = (e: any) => {
        e.preventDefault()
        try {
            const isHighlighted = highlight();
            if (!isHighlighted) {
                if (!organization) {
                    console.error("Selected organization ID not found");
                    return;
                } else if (
                    formData.zip.length > 0 &&
                    formData.city === "" &&
                    formData.state === ""
                ) {
                    formData.city = "";
                    formData.state = "";
                    formData.country = "";
                    alert("Please Enter Valid Zip Code");
                    return;
                }
                const updatedFields = {
                    id: "",
                    organizationdetails: [
                        {
                            name: formData.name,
                            type: formData.type,
                            npi: formData.npi,
                            tin: formData.tin,
                        },
                    ],
                    contact: [
                        {
                            addressLine1: formData.addressLine1,
                            addressLine2: formData.addressLine2,
                            city: formData.city,
                            state: formData.state,
                            country: formData.country,
                            zip: formData.zip,
                            officemobileNumber: formData.officemobileNumber,
                        },
                    ],
                    email: formData.email,
                    websiteUrl: formData.websiteUrl,
                    shift: {
                        duration: formData.duration.replace(/^(\d)$/, '0$1'),
                        startTime: get24HourFormat(formData.startTime), // Convert before sending
                    },
                    hippaprivacyofficer: [
                        {
                            firstName: formData.hippaPrivacyOfficerFirstName,
                            lastName: formData.hippaPrivacyOfficerLastName,
                        },
                    ],
                    mobileNumber: formData.mobileNumber,
                    proximityVerification: formData.proximityVerification,
                    geofencing: formData.geofencing,
                    q15Access: formData.q15Access,
                    timeZone: formData.timeZone,
                    sos: formData.sos,
                    updateRequest: formData?.updateRequest,
                    patientsCountForStaff: formData.patientsCountForStaff,
                    q15OneHour: formData.q15OneHour,
                    q15OneToOne: formData.q15OneToOne,
                    eyeSight: formData.eyeSight,
                    q15Slot: formData.q15Slot === "Yes" ? "Yes" : "No",
                    slotDuration: formData.slotduration,
                    pointofcontact: [
                        {
                            firstName: formData.cFPerson,
                            lastName: formData.cLPerson,
                            email: formData.cEmail,
                            phoneNumber: formData.cPhone,
                        },
                    ],
                };
                const data = new FormData();

                // Append the JSON object as a string
                data.append("organization", JSON.stringify(updatedFields));

                // Append the logo file if it exists
                if (formData?.logo instanceof File) {
                    data.append("logo", formData?.logo);
                }

                dispatch(updateOrganizationDetails(organization, data, currentPage));
            }
        } catch (error) {
            console.error("Error registering:", error);
        }
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handlePostalCodeChange = async (e: any) => {
        const newZip = e.target.value;
        formData.zip = newZip;
        setZipCode(e.target.value);

        setFormData({ ...formData });
        if (formData.zip.length === 5) {
        } else {
            formData.state = "";
            formData.country = "";
            formData.city = "";
        }
        setFormData({ ...formData });
    };
    const handleAddress1 = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleAddress = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const highlight = () => {
        let highlighted = false;
        if (!formData.name.trim()) {
            setOrgname(true);
            toast.error("Please Enter organizationName");
            highlighted = true;
        } else {
            setOrgname(false);
        }
        if (!formData.email.trim()) {
            setEmail(true);
            toast.error("Please Enter email");
            highlighted = true;
        } else {
            setEmail(false);
        }
        if (!formData.npi.trim()) {
            setnpi(true);
            toast.error("Please Enter npi");
            highlighted = true;
        } else {
            setnpi(false);
        }
        if (!formData.tin.trim()) {
            settin(true);
            toast.error("Please Enter tin");
            highlighted = true;
        } else {
            settin(false);
        }
        if (!formData.mobileNumber.trim()) {
            setMobilePhone(true);
            toast.error("Please Enter mobileNumber");
            highlighted = true;
        } else {
            setMobilePhone(false);
        }
        if (!formData.duration.trim()) {
            setDuration(true);
            toast.error("Please Enter duration");
            highlighted = true;
        } else {
            setDuration(false);
        }
        if (!formData.slotduration.trim()) {
            setSlotDuration(true);
            toast.error("Please Enter Slot Duration");
            highlighted = true;
        } else {
            setDuration(false);
        }
        return highlighted;
    };

    const hiddenFileInput = useRef<any>(null);

    const handleProfileChange = () => {
        if (hiddenFileInput !== null) {
            hiddenFileInput.current.click();
        }
    }
    const [logo, setLogo] = useState<any>(null)
    const handleImageChange = (e: any) => {
        const file = e.target?.files[0];
        if (!file) return;
        const imageUrl = URL.createObjectURL(file);
        setLogo(imageUrl);
        setFormData({ ...formData, logo: file });
    };

    const handleinputchange = (event: any) => {
        if (event.target.id === "OrganizationName") {
            const updatedValue = event.target.value;
            formData.name = updatedValue.charAt(0).toUpperCase() + updatedValue.slice(1);
            setOrgname(false);
        } else if (event.target.id === "email") {
            setEmail(false);  // Reset the error state for email
            formData.email = event.target.value;


        } else if (event.target.id === "npi") {
            formData.npi = event.target.value;
            setnpi(false);
        } else if (event.target.id === "tin") {
            const inputValue = event.target.value;
            if (/^\d{0,9}$/.test(inputValue)) {
                formData.tin = inputValue
                settin(false);
            }
        } else if (event.target.id === "OrganizationType") {
            formData.type = event.target.value;
        } else if (event.target.id === "AddressLine1") {
            formData.addressLine1 = event.target.value;
        } else if (event.target.id === "AddressLine2") {
            formData.addressLine2 = event.target.value;
        } else if (event.target.id === "city") {
            formData.city = event.target.value;
        } else if (event.target.id === "state") {
            formData.state = event.target.value;
        } else if (event.target.id === "country") {
            formData.country = event.target.value;
        } else if (event.target.id === "mobileNumber") {
            formData.mobileNumber = event.target.value;
            setMobilePhone(false);
        } else if (event.target.id === "WebsiteURL") {
            formData.websiteUrl = event.target.value;
        } else if (event.target.id === "ContactpersonFirstName") {
            formData.cFPerson = event.target.value;
        } else if (event.target.id === 'ContactpersonLastName') {
            formData.cLPerson = event.target.value;
        } else if (event.target.id === "ContactMobile") {
            formData.cPhone = event.target.value;
        } else if (event.target.id === "ContactOffice") {
            formData.officemobileNumber = event.target.value;
        } else if (event.target.id === "cEmail") {
            formData.cEmail = event.target.value;
        } else if (event.target.id === 'duration') {
            formData.duration = event.target.value;
            setDuration(false);
        } else if (event.target.id === "outlined_success") {
            const time = event.target.value; // Assuming this is in 24-hour format
            const [hours, minutes] = time.split(':');
            const formattedTime = new Date(0, 0, 0, hours, minutes).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            setFormData((prevData) => ({
                ...prevData,
                startTime: formattedTime, // e.g., "06:00 AM"
            }));
        }
        setFormData({ ...formData });
    };
    const handleTimeChange = (newValue: Date | null) => {
        if (newValue) {
            const formattedTime = newValue.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });
            setFormData((prevData) => ({
                ...prevData,
                startTime: formattedTime,
            }));
        }
    };
    const handleInputChange = (field: keyof FormData, value: any) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSelectChange = (fieldName: string, value: any) => {
        setFormData({ ...formData, [fieldName]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        axios.post(`${baseURL}/org/checkSecretKey?email=${userEmail}&secretKey=${secretcode}`)
            .then(async response => {
                if (response.data.message && response.data.message.description) {
                    if (response.data.message.code === successCode) {
                        try {
                            await dispatch(deleteOrganizationDetails(organizationId, currentPage));
                            getOrgByID(dispatch, organizationId)
                            console.warn("Organization Inactivated successfully");
                            setShow(false);
                        } catch (error) {
                            console.warn("Failed to inActive organization");
                        }
                    } else {
                        handleClose1()
                        toast.error(response.data.message.description)
                    }
                } else {
                    toast.warn("Unexpected response structure");
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div style={{ overflow: 'hidden', height: '91vh' }}>
            <div className="d-flex align-items-center justify-content-center" style={{ overflow: 'hidden' }}>
                {(loading || OrgLoading) && <Loader />}
                <div className="" style={{ width: '65vw', height: '91vh' }}>
                    <div className="" style={{ height: '10vh' }}>
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-md-6 d-flex">
                                <h4 >
                                    {formData.name}
                                </h4>
                            </div>
                            <div className="col-md-2">
                                <div
                                    onClick={handleProfileChange}
                                    hidden={formData.logo !== null && formData.logo !== "" && formData.logo !== "string"}
                                    style={{ position: "relative", width: "140px", cursor: "pointer" }}
                                >
                                    <img className="frame-child4" style={{ width: '120px' }} alt="" src={uploadImage} />
                                    <div
                                        className="upload-logo-pic1"
                                        style={{ fontSize: '13px', color: 'blue', position: 'absolute', top: '16px', left: '13px', zIndex: 1 }}
                                    >
                                        Upload Logo
                                    </div>
                                </div>
                                <div
                                    hidden={formData.logo === null || formData.logo === "" || formData.logo === "string"}
                                    style={{ position: "relative", width: "182px", cursor: "pointer" }}
                                >
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            style={{ height: '50px', width: '50px', borderRadius: '60px', cursor: 'pointer' }}
                                            src={logo || formData.logo}
                                            onClick={handleProfileChange}
                                            alt="Uploaded Logo"
                                        />
                                        <input
                                            className="vector-group"
                                            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: '0', cursor: 'pointer' }}
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <div className="text-primary" style={{ position: 'relative', marginRight: '10px' }}>
                                    <Button label="Device List" onClick={() => handleOrgChange(formData.id, formData.name)}></Button>
                                </div>
                                <div className="text-primary">
                                    <Button label={formData.active ? "Inactive" : "Activate"} onClick={() => handleActiveModalOpen(formData)}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="" style={{ height: '71vh', overflowY: 'auto' }}>
                        <form onSubmit={handleSaveChanges}>
                            <div className="row w-100">
                                <div className="col-md-4">
                                    <TextField
                                        error={orgname}
                                        id="OrganizationName"
                                        name="organization Name"
                                        label="Organization Name"
                                        placeholder="Enter Organization Name"
                                        value={formData.name}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4 ">
                                    <TextField
                                        error={email}
                                        id="email"
                                        name="email"
                                        label="Organization Email"
                                        placeholder="Enter Organization Email"
                                        value={formData.email}

                                        onChange={handleinputchange}
                                        onBlur={() => {
                                            try {
                                                const formattedEmail = formatEmail(formData.email);
                                                setFormData({ ...formData, email: formattedEmail });
                                                setEmail(false);
                                            } catch (error) {
                                                setEmail(true);
                                            }
                                        }}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                {/* <div className="col-md-4">
                                            <TextField
                                                error={npi}
                                                id="npi"
                                                name="npi"
                                                label="NPI#"
                                                placeholder="Enter NPI"
                                                value={formData.npi}
                                                onChange={handleinputchange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </div> */}

                                <div className="col-md-4">
                                    <TextField
                                        error={npi}
                                        id="npi"
                                        type="text"
                                        name="npi"
                                        label="NPI#"
                                        placeholder="Enter NPI"
                                        value={formData.npi}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^\d{0,10}$/.test(inputValue)) {
                                                setFormData({ ...formData, npi: inputValue });
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>

                            </div>
                            <div className="row w-100 ">
                                <div className="col-md-4 ">
                                    <TextField
                                        error={tin}
                                        id="tin"
                                        name="tin"
                                        label="TIN"
                                        placeholder="Enter TIN"
                                        value={formData.tin}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <Autocomplete
                                        id={'organizationDetails'}
                                        options={orgType?.map((item: any) => item.value)}
                                        value={formData.type}
                                        onChange={handleOrganizationTypeChange}
                                        renderInput={(params) => <TextField {...params} label={'Organization Type'} variant="outlined" />}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        id="WebsiteURL"
                                        name="websiteUrl"
                                        label="Website URL"
                                        placeholder="Enter WebsiteUrl"
                                        value={formData.websiteUrl}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="row w-100 ">
                                <div className="col-md-4">
                                    <TextField
                                        id="hippaPrivacyOfficerFirstName"
                                        name="hippaPrivacyOfficerFirstName"
                                        label="HIPPA Privacy Officer First Name"
                                        placeholder="Enter HippaPrivacyOfficer First Name"
                                        value={formData.hippaPrivacyOfficerFirstName}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        id="hippaPrivacyOfficerLastName"
                                        name="hippaPrivacyOfficerLastName"
                                        label="HIPPA Privacy Officer Last Name"
                                        placeholder="Enter HippaPrivacyOfficer Last Name"
                                        value={formData.hippaPrivacyOfficerLastName}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        error={mobilePhone}
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        label="Mobile Number"
                                        placeholder="Enter Mobile Number"
                                        value={formatPhoneNumber(formData.mobileNumber)}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap w-100 mt-2">
                                <div className="col-md-4"
                                // style={{ position: 'relative', top: '16px', width: '258px', marginLeft: '4px' }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Start Time"
                                            value={
                                                formData.startTime
                                                    ? (() => {
                                                        const [time, period] = formData.startTime.split(' ');
                                                        const [hours, minutes] = time.split(':');
                                                        let hours24 = parseInt(hours, 10);
                                                        if (period === 'PM' && hours24 !== 12) hours24 += 12;
                                                        if (period === 'AM' && hours24 === 12) hours24 = 0;
                                                        const date = new Date();
                                                        date.setHours(hours24, parseInt(minutes, 10), 0);
                                                        return date;
                                                    })()
                                                    : null
                                            }
                                            sx={{
                                                width: '91%'
                                            }}
                                            onChange={handleTimeChange}
                                            ampm={true}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-4">
                                    <Autocomplete
                                        id="duration"
                                        options={DurationDropDown.map((item) => item.id)}
                                        value={formData.duration || ""}
                                        onChange={(event, newValue: any) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                duration: newValue,
                                            }));
                                        }}
                                        sx={{
                                            width: '92%'
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Shift Duration"
                                                variant="outlined"
                                                error={duration}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            // fullWidth
                                            // margin="normal"
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Autocomplete
                                        options={slotOption}
                                        getOptionLabel={(option) => option.label}
                                        value={formData.slotduration ? { label: `${formData.slotduration}`, value: formData.slotduration } : null}
                                        onChange={(event, newValue) => {
                                            console.log(event, newValue, "newValue")
                                            setFormData({ ...formData, slotduration: newValue ? newValue.value : "" });
                                        }}
                                        sx={{
                                            width: '92%'
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Slot Duration" variant="outlined" />
                                        )}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <TextField
                                        id="patientsCountForStaff"
                                        label="Patient Count For Staff"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.patientsCountForStaff || ""}
                                        sx={{
                                            width: '92%'
                                        }}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            setFormData({
                                                ...formData,
                                                patientsCountForStaff: value === "" ? "" : (value),
                                            });
                                        }}
                                        required
                                    />
                                </div>

                            </div>
                            <div className="mt-1">
                                <label
                                    htmlFor="OrganizationType"
                                    className="label "
                                >
                                    Contact Person
                                </label>
                            </div>
                            <div className="row w-100 ">
                                <div className="col-md-6">
                                    <TextField
                                        id="ContactpersonFirstName"
                                        name="cFPerson"
                                        label="First Name"
                                        placeholder="Enter First Name"
                                        value={formData.cFPerson}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        id="LastName"
                                        name="cLPerson"
                                        label="Last Name"
                                        placeholder="Enter LastName"
                                        value={formData.cLPerson}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-md-6">
                                    <TextField
                                        id="cEmail"
                                        name="cEmail"
                                        label="Email"
                                        placeholder="Enter Email"
                                        value={formData.cEmail}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        id="ContactMobile"
                                        name="cPhone"
                                        label="Mobile Number"
                                        placeholder="Enter Mobile Number"
                                        value={formatPhoneNumber(formData.cPhone)}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        id="ContactOffice"
                                        name="officemobileNumber"
                                        label="Office Number"
                                        placeholder="Enter Office Number"
                                        value={formatPhoneNumber(formData.officemobileNumber)}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="mt-1">
                                <label
                                    htmlFor="Address"
                                    className="label"
                                >
                                    Address
                                </label>
                            </div>
                            <div className="row w-100 ">
                                <div className="col-md-4 ">
                                    <TextField
                                        id="addressLine1"
                                        name="addressLine1"
                                        label="Address Line 1"
                                        placeholder="Enter Address"
                                        value={formData.addressLine1}
                                        onChange={handleAddress1}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        id="addressLine2"
                                        name="addressLine2"
                                        label="Address Line 2"
                                        placeholder="Enter Address"
                                        value={formData.addressLine2}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleAddress}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <TextField
                                        id="zipcode"
                                        label="Zip/Postal Code"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.zip}
                                        onChange={handlePostalCodeChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {(zipLoading && !formData.city) && <CircularProgress size={'25px'} />}
                                </div>
                            </div>
                            <div className="row w-100 ">
                                <div className="col-md-4 mt-3">
                                    <TextField
                                        id="city"
                                        label="City"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.city}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <TextField
                                        id="state"
                                        label="State"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.state}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <TextField
                                        id="country"
                                        label="Country"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.country}
                                        onChange={handleinputchange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <FormControl fullWidth>
                                        <InputLabel id="timeZone-label">Time Zone</InputLabel>
                                        <Select
                                            labelId="timeZone-label"
                                            id="timeZone"
                                            value={formData.timeZone}
                                            onChange={(e) => handleSelectChange("timeZone", e.target.value)}
                                            label="Time Zone"
                                            MenuProps={MenuProps}
                                        >
                                            {options?.length > 0 ? options.map((option) => (
                                                <MenuItem style={{ fontSize: '11px' }} key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )) :
                                                <MenuItem>
                                                    No Time Zone Data
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row w-100 mb-3">
                                <div className="mt-1">
                                    <label htmlFor="OrganizationType" className="label">
                                        Access Control
                                    </label>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="q15-access-label">Q15</InputLabel>
                                        <Select
                                            labelId="q15-access-label"
                                            id="q15Access"
                                            label="Q15"
                                            value={formData.q15Access}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                handleSelectChange("q15Access", value);
                                                if (value === "No") {
                                                    setFormData((prevData: any) => ({
                                                        ...prevData,
                                                        proximityVerification: "No",
                                                        q15OneToOne: "false",
                                                        q15OneHour: "false",
                                                        eyeSight: "false",
                                                        q15Slot: "No"
                                                    }));
                                                }
                                            }}
                                        >
                                            <MenuItem value="Yes">Yes</MenuItem>
                                            <MenuItem value="No">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {formData.q15Access === "Yes" && (

                                    <div className="col-md-4 mt-2">
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="q15Slot-label">Q15 Slot</InputLabel>
                                            <Select
                                                labelId="q15Slot-label"
                                                id="q15Slot"
                                                label="Q15 Slot"
                                                value={formData.q15Slot}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFormData((prevData) => ({ ...prevData, q15Slot: value }));
                                                }}
                                            >
                                                <MenuItem value="Yes">Q15 Slot</MenuItem>
                                                <MenuItem value="No">Q15 Shift</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}

                                <div className="col-md-4 mt-2">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="sos-label">SOS</InputLabel>
                                        <Select
                                            labelId="sos-label"
                                            id="sos"
                                            label="SOS"
                                            onChange={(event) => {
                                                const value = event.target.value === 'true';
                                                setFormData((prevData) => ({ ...prevData, sos: value }));
                                            }}
                                            value={formData.sos ? 'true' : 'false'}
                                            name="sos"
                                        >
                                            <MenuItem value="true">Yes</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="geofencing-label">Geo Fencing</InputLabel>
                                        <Select
                                            labelId="geofencing-label"
                                            id="geofencing"
                                            label="Geo Fencing"
                                            value={formData.geofencing}
                                            onChange={(e) => handleSelectChange("geofencing", e.target.value)}
                                        >
                                            <MenuItem value="Yes">Yes</MenuItem>
                                            <MenuItem value="No">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {formData.q15Access === "Yes" && (
                                    <>
                                        <div className="col-md-4 mt-2">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="proximity-label">Proximity</InputLabel>
                                                <Select
                                                    labelId="proximity-label"
                                                    id="proximity"
                                                    label="Proximity"
                                                    value={formData.proximityVerification}
                                                    onChange={(e) =>
                                                        handleInputChange("proximityVerification", e.target.value)
                                                    }
                                                >
                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                    <MenuItem value="No">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="q15OneToOne-label">One To One</InputLabel>
                                                <Select
                                                    labelId="q15OneToOne-label"
                                                    id="q15OneToOne"
                                                    label="One To One"
                                                    onChange={(event) => {
                                                        const value = event.target.value === 'true';
                                                        setFormData((prevData) => ({ ...prevData, q15OneToOne: value }));
                                                    }}
                                                    value={formData.q15OneToOne ? 'true' : 'false'}
                                                    name="q15OneToOne"
                                                >
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="q15OneHour-label">One Hour</InputLabel>
                                                <Select
                                                    labelId="q15OneHour-label"
                                                    id="q15OneHour"
                                                    label="One Hour"
                                                    onChange={(event) => {
                                                        const value = event.target.value === 'true';
                                                        setFormData((prevData) => ({ ...prevData, q15OneHour: value }));
                                                    }}
                                                    value={formData.q15OneHour ? 'true' : 'false'}
                                                    name="q15OneHour"
                                                >
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="eyeSight-label">Eye Sight</InputLabel>
                                                <Select
                                                    labelId="eyeSight-label"
                                                    id="eyeSight"
                                                    label="Eye Sight"
                                                    onChange={(event) => {
                                                        const value = event.target.value === 'true';
                                                        setFormData((prevData) => ({ ...prevData, eyeSight: value }));
                                                    }}
                                                    value={formData.eyeSight ? 'true' : 'false'}
                                                    name="eyeSight"
                                                >
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                                <div className="col-md-4 mt-2">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="client details edit option">Client Details Edit Option</InputLabel>
                                        <Select
                                            labelId="client details edit option"
                                            id="client details edit option"
                                            label="client details edit option"
                                            value={formData?.updateRequest ? 'true' : 'false'}
                                            onChange={(e) => {
                                                const value = e.target.value === 'true';
                                                handleSelectChange('updateRequest', value)
                                            }}
                                        >
                                            <MenuItem value="true">Yes</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>


                            </div>


                        </form>
                    </div>
                    <div className="" style={{ height: '10vh' }}>
                        <div className="d-flex gap-3 justify-content-end py-3">
                            <Button
                                label="Cancel"
                                severity="secondary"
                                style={{
                                    color: "#000",
                                    backgroundColor: "#94a0b7",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                                onClick={() => navigate(-1)}
                            ></Button>
                            <Button
                                label="Save Changes"
                                style={{
                                    backgroundColor: "#0f3995",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                                onClick={handleSaveChanges}
                            ></Button>
                        </div>
                    </div>
                </div>
                <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
                <Dialog
                    maxWidth={'sm'}
                    fullWidth
                    PaperProps={{
                        sx: {
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'absolute',
                            width: '35%',
                        }
                    }}
                    open={show}
                    onClose={handleClose1}
                >
                    <DialogTitle>
                        Organization: {organizationName}
                        <IconButton
                            aria-label="close"
                            onClick={() => handleClose1()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme: any) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div
                            className="px-1 py-4"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            }}
                        >
                            <TextField
                                disabled
                                id="email"
                                label="Email"
                                value={userEmail}
                                variant="outlined"
                                fullWidth
                            />
                            <label
                                htmlFor="code"
                                style={{
                                    fontSize: '14px',
                                    marginBottom: '4px',
                                    textAlign: 'center'
                                }}
                            >
                                Enter Your Passcode
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '10px',
                                }}
                            >
                                {secretKey.pinNumber.map((value: any, index: any) => (
                                    <input
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                        }}
                                        itemID="code"
                                        key={index}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        id={`pinNumber${index}`}
                                        // className={`passwordText${index + 1}`}
                                        name="pinNumber"
                                        value={value}
                                        onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
                                        required
                                        onChange={(e) => {
                                            handleInputChangep(e, index);
                                            maskPassword(index);
                                        }}
                                        maxLength={1}
                                    />
                                ))}
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button label="Inactive"
                                    style={{ backgroundColor: '#0f3995', fontSize: '12px', height: '30px', width: '70%', fontWeight: 'bold' }}
                                    onClick={handleSubmit}></Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}
export default OrgUpdate