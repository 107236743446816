import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getBedAssignSuccess } from "./reducer"
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllBed = async (dispatch: any, orgId: string) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.get(`${baseURL}/Q15Bed/getByOccupied/${orgId}`,{headers: header1});
      if (response.data.message.code === successCode) {
        dispatch(setIsLoadingFalse());
        dispatch(getBedAssignSuccess(response.data.data));
      } else {
        dispatch(setIsLoadingFalse());
        dispatch(setErrorMessage(response.data.message.description));
        dispatch(getBedAssignSuccess([]));
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
      dispatch(getBedAssignSuccess([]));
      console.error('API error:', error);
    }
  };

  export const deletePatientAssignDetails = (id:string,org: string) => async (dispatch: any) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.post(`${baseURL}/Q15Bed/dischargePatient/${id}`,{headers: header1});
      if (response.data.message.code === successCode) {
          getAllBed(dispatch,org)
          dispatch(setIsLoadingFalse());
          toast.success(response.data.message.description)
          } else {
        toast.error(response.data.message.description)
        dispatch(setIsLoadingFalse());
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
      toast.error("Error: something went wrong.")
      console.warn('API Error:', error);
    }
  };