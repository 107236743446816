import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, ListItem, MenuItem, IconButton, Select, TextField, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { List } from "reactstrap";
import { FaQrcode } from "react-icons/fa";
import { Bed } from "@mui/icons-material";
import PatientBed from "../AllPatient/patientBed";
import { Button } from "primereact/button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { authorize, formatDateToYYYYMMDD } from '../../helpers/common';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBed, getAllBedAssign, getAllPatient, getAllRole, getDeviceDropdown } from '../../slices/thunk';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from '../../components/loader/Loader';

interface TransferModalProps {
  IsOpen: boolean
  dischargeTransferId: any
  Close: () => void
  handleQrClick: any
  patientname: any
  error: any
  setError: any
  admitDate: any
  deviceId: any
  setDeviceId: any
  setAdmitDate: any
  dateFieldError: string
  setDateFieldError: any
  reason: any
  setReason: any
  options: any
  bedClick: any
  bedSelected: any
  roomNo: any
  admitD: any
  setAdmitD: any
  bedId: any
  handleBedClick: any
  bedsByNumber: any
  currentPage: number
  setBedAssignDialog: any
  setBedClick: any
  selectPatientId: any
  setBedId: any
  doctor: any
}
const TransferModal: React.FC<TransferModalProps> = ({ IsOpen, dischargeTransferId, Close, handleQrClick, patientname, setError, admitDate, deviceId, setDeviceId, setAdmitDate, dateFieldError, setDateFieldError, error, reason, setReason, options, bedClick, bedSelected, roomNo, admitD, setAdmitD, bedId, handleBedClick, bedsByNumber, currentPage, setBedAssignDialog, setBedClick, selectPatientId, setBedId, doctor }) => {
  const dispatch = useDispatch<any>();
  const { organization, userData } = useSelector((state: any) => state.Login);
  const { roleList } = useSelector((state: any) => state.PSConfig)
  const userId = userData?.userDetail.id;
  const [selectedStaff, setSelectedStaff] = useState("");
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [bedNumber, setBedNumber] = useState<any>('')
  const handleCLS = () => {
    setNameError({ ...nameError, bedId: false, admitDate: false, reason: false, selectedStaff: false, deviceId: false })
    setSelectedStaff('')
    setBedId('')
    setDeviceData('')
    setDeviceId('')
    Close()
  }
  const [deviceData, setDeviceData] = useState<any>('')
  // useEffect(() => {
  //   if () {

  //   }
  // }, [])
  useEffect(() => {
    if (deviceId) {
      const filterDevicedata = options.filter((data:any) => data.deviceId === deviceId)
      setDeviceData(filterDevicedata ? deviceId : "")
    } else {
      setDeviceData("")
    }
  }, [deviceId])
  const handleSave = async () => {
    setLoading(true)
    let newError = {
      ...(dischargeTransferId === "1" && { deviceId: !deviceId }),
      reason: !reason,
      admitDate: !admitDate,
      bedId: !roomNo,
      ...(dischargeTransferId === "1" && { selectedStaff: !selectedStaff })
    };
    setNameError(newError)
    const hasErrors = Object.values(newError).some(error => error);
    if (hasErrors) {
      toast.error('Please fill the required fields');
      setLoading(false)
      return;
    }
    const { header1 } = authorize();
    try {
      const formattedDate = formatDateToYYYYMMDD(admitDate);
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        transferedBy: userId,
        transferDate: formattedDate,
        transferReason: reason,
        deviceId: deviceId,
        doctorName: selectedStaff,
      };
      const requestBody1 = {
        roomBed: roomNo,
        pid: selectPatientId,
        orgId: organization,
        dischargeDate: formattedDate,
        dischargedBy: userId,
        dischargeReason: reason,
      }
      console.log(requestBody, 'requestBody')
      console.log(requestBody1, 'requestBody', '1234')
      const response = await axios.post(
        `${baseURL}/Q15Bed/${dischargeTransferId === '1' ? 'transfer' : 'dischargePatient'}`,
        dischargeTransferId === '1' ? requestBody : requestBody1, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        handleCLS()
        getAllPatient(dispatch, organization, currentPage, '');
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
        toast.success(response.data.message.description)
        getDeviceDropdown(dispatch, organization)
        setBedAssignDialog(false)
        setBedClick(false)
        setAdmitD(false)
        setLoading(false)
      } else {
        console.error("Error:", response.data.message);
        toast.error(response.data.message.description)
        setLoading(false)

      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false)
    }
  }


  const errorMessage = React.useMemo(() => {
    if (dateFieldError) return dateFieldError;
    switch (error) {
      case 'maxDate':
      case 'minDate':
        return 'Please select a date in the first quarter of 2022';
      case 'invalidDate':
        return 'Your date is not valid';
      default:
        return '';
    }
  }, [error, dateFieldError]);
  useEffect(() => {
    getAllRole(dispatch, 'Physician', organization)
  }, [dispatch, organization])
  useEffect(() => {
    if (IsOpen) {
      setSelectedStaff(doctor || '');
    }
  }, [IsOpen, doctor]);
  
  
  return (
    <>
      <div>
        {loading && <Loader/>}
        <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: dischargeTransferId === "1" ? '75%' : '40%', maxWidth: '72%', position: 'absolute', height: `${dischargeTransferId === "1" ? '525px' : '320px'}`, top: '30px', overflow: 'hidden' } }}
          open={IsOpen}
        >
          <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              {dischargeTransferId === "1" ? 'Transfer' : 'Discharge'}
            </div>
            <div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleCLS}></button>
            </div>
          </DialogTitle>
          <DialogContentText >
            <DialogContent style={{ padding: '20px', background: '#F8FAFB', overflowY: 'auto', height: '400px' }}>
              <div>
                <>
                  <div className="row w-100 mb-3">
                    <div className={dischargeTransferId === "1" ? 'col-md-6' : 'col-md-12'}>
                      <TextField id="Reason" label="Patient Name" variant="outlined" value={patientname} fullWidth />
                    </div>
                    {dischargeTransferId === "1" &&
                      <div className="col-md-6">
                        <TextField
                          label="Select Physician"
                          value={selectedStaff}
                          onChange={(e) => setSelectedStaff(e.target.value)}
                          fullWidth
                          select
                        >
                          {roleList.length > 0 ? (
                            roleList.map((staff: any) => (
                              <MenuItem
                                key={staff.id}
                                value={`${staff.name[0]?.given || ''} ${staff.name[0]?.family || ''}`}
                              >
                                {`${staff.name[0]?.given || ''} ${staff.name[0]?.family || ''}`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">No Staff Available</MenuItem>
                          )}
                        </TextField>
                      </div>
                    }
                  </div>
                  <div className="row w-100 ">
                    <div className='col-md-6 mb-2' >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box >
                          <DatePicker
                            sx={{ width: "100%" }}
                            onError={(newError) => setError(newError)}
                            label={dischargeTransferId === "1" ? 'Transfer Date' : 'Discharge Date'}
                            value={admitDate}
                            onChange={(newValue) => {
                              setAdmitDate(newValue);
                              setNameError({ ...nameError, admitDate: false })
                              // if (dateFieldError) setDateFieldError('')
                            }}

                            slotProps={{
                              textField: {
                                helperText: nameError.admitDate ? "Date field cannot be empty." : "",
                                error: !!nameError.admitDate,
                              },
                            }}
                          />
                        </Box>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-6 mb-2'>
                      <TextField
                        id="Reason"
                        label={dischargeTransferId === "1" ? 'Transfer Reason' : 'Discharge Reason'}
                        variant="outlined"
                        value={reason}
                        fullWidth
                        onChange={(e) => {
                          setReason(e.target.value)
                          setNameError({ ...nameError, reason: false })
                        }}
                        error={!!nameError.reason}
                        helperText={nameError.reason ? "Reason is Required" : ""}
                        required
                      />
                    </div>
                    {dischargeTransferId === "1" && <>
                      <div className='col-md-6 mb-2'>

                        <TextField
                          label='Device-Id'
                          value={deviceData}
                          onChange={(event) => { setDeviceData(event.target.value); setNameError({ ...nameError, deviceId: false }) }}
                          required
                          error={!!nameError.deviceId}
                          helperText={nameError.deviceId ? 'DeviceId is Required' : ''}
                          fullWidth
                          InputProps={{

                            endAdornment: (
                              <FaQrcode
                                className="position-absolute bottom-0 m-3"
                                onClick={handleQrClick}
                                role="button"
                                style={{
                                  fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0
                                }}
                              />
                            ),

                          }}
                          select
                          SelectProps={{
                            IconComponent: () => null,
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: '300px',
                                },
                              },
                            },
                          }}
                        >
                          {options?.length > 0 ? options?.map((option: any) => (
                            <MenuItem key={option?.id} value={option?.deviceId}>
                              {option?.deviceId}
                              <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                  fontSize: "12px",
                                  color: !option?.status ? '#5FB477' : '#EF6868',
                                  display: "inline-block",
                                  textAlign: "center",
                                  marginLeft: '10px',
                                  marginBottom: '1px'
                                }}
                              />
                            </MenuItem>
                          )) :
                            <MenuItem>
                              No Devices Data
                            </MenuItem>
                          }
                        </TextField>

                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'end', cursor: 'pointer' }}
                        onClick={() => setAdmitD(!admitD)}
                      >
                        <TextField
                          label="Room-Bed"
                          type="text"
                          placeholder=''
                          margin="none"
                          fullWidth
                          value={bedClick && bedSelected ? bedSelected : roomNo}
                          error={!!nameError.bedId}
                          helperText={nameError.bedId ? "Room-Bed is ReQuired" : ""}
                          required
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                style={{ color: "#0f3995" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAdmitD(!admitD);
                                  setNameError({ ...nameError, bedId: false })
                                }}
                                edge="end"
                              >
                                <Bed />
                              </IconButton>
                            )
                          }}
                          inputProps={{
                            style: { cursor: 'pointer' }
                          }}
                        />
                      </div>
                    </>}
                  </div>
                </>
                {admitD && <>
                  <PatientBed
                    admitD={admitD}
                    bedId={bedId}
                    handleBedClick={handleBedClick}
                    bedsByNumber={bedsByNumber}
                  />
                </>}
              </div>
              <div className="d-flex gap-3 pt-3 mb-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
                  onClick={handleCLS}></Button>
                <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
              </div>
            </DialogContent>
          </DialogContentText>

        </Dialog>
      </div>
    </>
  )
}

export default TransferModal