import React, { useEffect, useRef, useState } from "react";
import Sos from "../../assets/images/sos.png";
import siren from "../../assets/images/siren-on1.png";
import Triggered from "../../assets/images/sosTriggered.png";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Modal } from "@mui/material";
import Card from "../../components/card";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, successCode, webSocketClient, webSocketURL } from "../../configuration/url";
import { FaBed, FaTimesCircle } from "react-icons/fa";
import { RiAlarmWarningFill } from "react-icons/ri";
import "./patientDisplay.css";
import Slide from "./customslider";
import { getAllStaffLS } from "../../slices/thunk";
import { FiMinusCircle } from "react-icons/fi";
import { GoPlusCircle } from "react-icons/go";
import moment from "moment";
import { CloseTwoTone } from "@mui/icons-material";
import { toast } from "react-toastify";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";

const PatientDisplay = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const [alarmData, setAlarmData] = useState<any[""]>([]);
  const { organization, jwt } = useSelector((state: any) => state.Login);
  const { orgTimeZone, patientData } = useSelector((state: any) => state.LargeScreen)
  const { staffData1 } = useSelector((state: any) => state.Staff);
  const staffData = staffData1?.staffData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const handleTrigger = () => setOpen(true);
  const [modalopen, setModalopen] = useState<boolean>(false)
  const { currentDateTime } = useSelector((state: any) => state.LargeScreen)
  const formattedDate = moment(currentDateTime, 'MM/DD/YYYY, hh:mm:ss A').format('YYYYMMDD');
  const [stafHistory, setStaffHistory] = useState<any>()
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [LorgName, setLorgName] = useState('')
  const { orgData } = useSelector((state: any) => state.Org);
  useEffect(() => {
    const LorgName: any = localStorage.getItem('orgName')
    setLorgName(LorgName ? LorgName : '')
  }, [orgTimeZone])
  const handleOnclick = (staff: any) => {
    setStaffHistory(staff)
    setHistoryModal(true)
  }
  const handleCloseModal = () => {
    setStaffHistory('')
    setHistoryModal(false)
    setAlarmData('')
    setIsExpanded(false)
  }
  useEffect(() => {
    sessionStorage.setItem('jwt', jwt)
    sessionStorage.setItem('authStaff', 'verified')
  }, [])
  const handlePlusClick11 = async (staffId: any) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/alarm/getAlarmRecordforOne?identifier=${stafHistory?.triggeredStaffId ? stafHistory?.triggeredStaffId : stafHistory?.visitorName}&organization=${organization}`);
      if (response.data?.message?.code === 'MHC - 0200') {
        setAlarmData(response.data.data || []);
        setIsExpanded(true);
      } else {
        setAlarmData([]);
        setIsExpanded(false);
      }
    } catch (error) {
      console.error('Error fetching alarm data:', error);
      setAlarmData([]);
    }
    setLoading(false);
  };

  const handleMinusClick = () => {
    setAlarmData([]);
    setIsExpanded(false);
  };
  useEffect(() => {
    getAllStaffLS(dispatch, formattedDate, organization)
  }, [organization])

  const [alarmHistory, setAlarmHistory] = useState([]);
  const [alarm, setAlarm] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [fullAlarm, setFullAlaram] = useState<string>('0')

  const [devices, setDevices] = useState([])

  const [available, setAvailable] = useState<any>("");
  const [staffName, setStaffName] = useState<any>("");
  const [occupied, setOccupied] = useState<any>("");
  const dispatch = useDispatch<any>()

 

  useEffect(() => {
    const getbedCount = async () => {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByAvailable/${organization}`
      );
      const res = await axios.get(
        `${baseURL}/Q15Bed/getByOccupied/${organization}`
      );
      setAvailable(response.data.data.length);
      setOccupied(res.data.data.length);
    };
    getbedCount();
  }, [organization]);
  const handleDevices = () => {
    if (staffData && staffData.length > 0) {
      const Devices = staffData
        .map((staff: any) => staff.sosDevice)
        .filter((device: string | undefined) => device && device.trim() !== "");
      if (Devices.length > 0) {
        return `${Devices.join(",")}`;
      }
    }
    return []
  }
  const getGateway = async () => {
    if (staffData && staffData.length > 0) {
      const devicesFormatted = handleDevices()
      if (devicesFormatted.length > 0) {
        try {
          const response = await axios.post(`${baseURL}/siren/siren-on_singleAPI?deviceId=${devicesFormatted}&organization=${organization}`)
          if (response?.data?.message?.code === successCode) {
            if (response.data.data) {
              setStaffName(response.data.data || "");
              setToggleValue(true);
              setModalopen(true)
              getHistory();
              setOpen(true);
              setAlarm(true)
            }
          } else {
            setAlarm(false)
          }
        } catch (error) {
          console.error(`Error with device(s) ${devicesFormatted}:`, error);
        }
      } else {
        console.error('No valid devices found');
      }
    } else {
      console.error('staffData is empty or undefined');
    }
  }

  useEffect(() => {
    getAllStaffLS(dispatch, formattedDate, organization)
    const socket = new SockJS(webSocketURL);
    const client: any = Stomp.over(socket);
    client.connect({}, (frame: any) => {
      // setIsConnected(true);
      setLoading(false);
      client.subscribe(webSocketClient, (message: any) => {
        const receivedMessage = message.body;
        if (receivedMessage === "Beacon created:") {
          getGateway()
        } else if (receivedMessage === "sensor register") {
          getAllStaffLS(dispatch, formattedDate, organization)
        }
      });
    }, (error: any) => {
      console.error('Error connecting to WebSocket:', error);
      setLoading(false);
    });
    return () => {
      if (client && client.connected) {
        client.disconnect();
      }
    };
  }, []);
  const getHistory = async () => {
    const response = await axios.get(
      `${baseURL}/alarm/getAlarmRecord?full=${fullAlarm}&organization=${organization}`
    );
    if (response.data.message.code === successCode) {
      setAlarmHistory(response.data.data);
    } else {
      setAlarmHistory([]);
    }
  };
  useEffect(() => {
    getHistory();
  }, [organization, fullAlarm]);
  const highRate = patientData?.filter(
    (patient: any) =>
      parseInt(patient.heartRate) > 100 || parseInt(patient.heartRate) < 60
  );
  const lowRate = patientData?.filter(
    (patient: any) =>
      parseInt(patient.heartRate) >= 60 && parseInt(patient.heartRate) <= 100
  );

  const hlength = highRate?.length;
  const llength = lowRate?.length;
  const plength = hlength + llength;

  const handleToggleChange = async () => {
    setToggleValue(false);
    try {
      let devicesid: string[] = [];

      staffName?.forEach((item: any) => {
        if (Array.isArray(item.triggeredSirenIds)) {
          devicesid.push(...item.triggeredSirenIds);
        }
      });

      const devicesidString = devicesid.join(',')
      const response = await axios.post(`${baseURL}/siren/siren-off?sirenIds=${devicesidString}`)
      const resp = await axios.delete(`${baseURL}/beacon/deleteKBeaconDevice?deviceId=${devicesidString}`)
      if (response.status === 200 || resp.data.message.code === successCode) {
        setAlarm(false)
      } else {
        setAlarm(false)
        setToggleValue(true)
        toast.error(resp.data.message.description)
      }

    } catch (error) {
      console.error("Error toggling switch:", error);
    }
  };

  // Emergency Modal Close
  const handleClose = () => {
    setOpen(false);
    handleToggleChange();
    setStaffName([]);
    setModalopen(false);
  };
  useEffect(() => {
    const getDevices = async () => {
      const response = await axios.get(`${baseURL}/siren/getAllDevices?organization=${organization}`)
      if (response?.data?.devices) {
        setDevices(response.data.devices)
      } else (
        setDevices([])
      )
    }
    getDevices()
  }, [organization])

  const slides = [];
  const slideSize = 4; // Number of items per slide
  for (let i = 0; i < highRate?.length; i += slideSize) {
    const slideData = highRate?.slice(i, i + slideSize);
    const slide = (
      <div key={i}>
        <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", height: "160px", width: "100%" }}>
          {slideData.map((item: any, index: any) => (
            <div key={index} className="mb-4 g-2" style={{ display: "flex", justifyContent: "flex-end" }} >
              <Card
                patientName={item.patientName}
                room={item.roomNo}
                bed={item.bedNo}
                heartRate={item.heartRate}
              />
            </div>
          ))}
        </div>
      </div>
    );
    slides.push(slide);
  }
  const lslides = [];
  const itemsPerSlide = 12;
  for (let i = 0; i < lowRate?.length; i += itemsPerSlide) {
    const slideData = lowRate?.slice(i, i + itemsPerSlide);
    const slide = (
      <div key={i}>
        <div style={{ display: "flex", flexDirection: "column", height: "480px" }}>
          {[0, 1, 2].map((rowIndex) => (
            <div key={rowIndex} style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", height: "33%", width: "100%" }}>
              {slideData.slice(rowIndex * 4, (rowIndex + 1) * 4).map((item: any, index: any) => (
                <div key={index} className="mb-4 g-2" style={{ flex: "1", display: "flex", justifyContent: "flex-end" }} >
                  <Card
                    // style={{width:"69%"}}
                    patientName={item.patientName}
                    room={item.roomNo}
                    bed={item.bedNo}
                    heartRate={item.heartRate}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
    lslides.push(slide);
  }

  return (
    <div ref={fullscreenRef}>
      <div className="row mx-0" style={{ marginTop: '0px', height: '92vh' }}>
        <div className="col-md-9" style={{ backgroundColor: "#0A2A7C" }}>
          <div style={{ paddingTop: 5 }}>
            <p style={{ color: "#fff", marginLeft: "30px" }}>
              Showing 1 to {hlength} of {plength} Patients
            </p>
          </div>
          <div >
            <Slide staffData={null}>
              {slides}
            </Slide>
          </div>
          <div style={{ paddingTop: 5 }}>
            <p style={{ color: "#fff", marginLeft: "30px" }}>
              Showing 1 to {llength} of {plength} Patients
            </p>
          </div>
          <div >
            <Slide staffData={null}>
              {lslides}
            </Slide>
          </div>
        </div>
        <div className="col-md-3 d-flex flex-column align-items-center" style={{ backgroundColor: "#4E6BB2" }}>
          {orgData?.sos !== false && (

            <div className="row ">
              <div className="d-flex justify-content-center mt-2">
                {alarm ? (
                  <img
                    src={Triggered}
                    alt="Logo"
                    className="logo-img"
                    style={{ width: "120px" }}
                    onClick={handleTrigger}
                  />
                ) : (
                  <img
                    src={Sos}
                    alt="Logo"
                    className="logo-img"
                    style={{ width: "120px" }}
                  />
                )}
              </div>

            </div>
          )}
          {orgData?.sos !== false && (
            <div className="row mt-2" style={{
              width: "80%",
              borderRadius: "5px",
              background:
                "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
              borderColor: "white",
              borderWidth: 0.5,
              borderStyle: "solid",
            }}>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontStyle: "revert-layer",
                      borderRadius: "5px",
                      alignItems: 'center',
                      justifyContent: "start",
                      textAlign: "center",
                      paddingTop: "4px",
                      marginBottom: '2px',
                    }}
                  >
                    SOS calls
                  </p>
                  <hr
                    style={{
                      marginTop: '0px',
                      marginBottom: '8px',
                      borderTopColor: "#ffffff",
                      borderTopWidth: 0.5,
                    }}
                  />
                </div>
              </div>
              <div className="hidden-scrollbar" style={{ height: alarmHistory?.length > 0 ? (fullAlarm === '0' ? '118px' : '200px') : '118px', overflowY: fullAlarm === '1' ? 'scroll' : 'hidden', }}>              {/* {!loading && <img src="https://cdn.pixabay.com/animation/2023/11/30/10/11/10-11-02-622_512.gif" style={{}}/>} */}
                {alarmHistory?.length > 0 ?
                  alarmHistory?.map((item: any, index: any) => (
                    <div
                      key={item.id}
                      className="mb-2 justify-content-around align-items-center"
                      style={{
                        width: "100%",
                        height: "30px",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 5,
                        backgroundColor: "#fff",
                        cursor: 'pointer'

                      }}
                      onClick={() => {
                        handleOnclick(item)
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        {item?.triggeredStaffId ? item?.triggeredStaffId : item?.visitorName}
                      </span>
                      <span style={{
                        fontWeight: "bold",
                        fontSize: "10px"
                      }}>
                        {moment(item.triggeredTime, 'YYYYMMDDHHmmss').format('MMM DD, YYYY hh:mm A')}
                      </span>
                    </div>
                  )) :

                  <>
                    <div className="" style={{ color: 'white', display: 'flex', justifyContent: 'center' }}>
                      Loading ...
                    </div>
                  </>
                }
              </div>
              <div className="d-flex justify-content-end" style={{ paddingBottom: '5px' }} >
                {fullAlarm === '0'
                  ?
                  <GoPlusCircle style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => {
                    setAlarmHistory([])
                    setFullAlaram('1')
                  }}
                  />
                  :
                  <FiMinusCircle style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => {
                    setAlarmHistory([])
                    setFullAlaram('0')
                  }}
                  />
                }
              </div>
            </div>
          )}

            <div className="row mt-2" style={{
              width: "80%",
              borderRadius: "5px",
              background:
                "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
              borderColor: "white",
              borderWidth: 0.5,
              borderStyle: "solid",
            }}>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontStyle: "revert-layer",
                      borderRadius: "5px",
                      textAlign: "center",
                      alignItems: 'center',
                      justifyContent: "start",
                      paddingTop: "4px",
                      marginBottom: '2px',
                    }}
                  >
                    Alarm device status
                  </p><hr
                    style={{
                      marginTop: '0px',
                      marginBottom: '8px',
                      borderTopColor: "#ffffff",
                      borderTopWidth: 0.5,
                    }}
                  /></div>
                <div className="col-md-1"></div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", marginBottom: "15px", gap: "4px" }} >
                {devices?.length > 0 ? devices.map((item: any, index: any) => {
                  if (item.data.msg !== 'token invalid') {
                    return (
                      <div key={index} style={{ backgroundColor: '#fff', alignItems: "flex-start", height: "40px", display: "flex", justifyContent: "center", padding: "0px 0px 0px 4px", borderRadius: "4px", flexDirection: "column" }}>
                        <img
                          src={siren}
                          alt={`Siren ${index + 1}`}
                          className="logo-img"
                          style={{ width: "15px", height: "15px" }}
                        />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <p style={{ color: "#000", margin: 0, fontSize: '9px' }}>{item?.data?.result?.devices[0]?.name}</p>
                          <p style={{ color: item?.data?.result?.devices[0]?.online ? "green" : "red", margin: 0, fontSize: '9px' }}>{item?.data?.result && item?.data?.result?.devices[0]?.online ? 'Online' : 'Offline'}</p>
                        </div>
                      </div>
                    )

                  } else {
                    return (<div>
                      <p style={{ color: "white", margin: 0, fontSize: '14px' }}>
                        No devices
                      </p>
                    </div>)
                  }
                }

                )
                  : <div>
                    <p style={{ color: "white", margin: 0, fontSize: '14px' }}>
                      No devices
                    </p>
                  </div>

                }
              </div>
            </div>

          {/* <div className="row mt-2 mb-2"  */}
          <div className={`row mt-2 mb-2 ${orgData.sos === false ? 'mt-md-5' : ''}`}
            style={{
              width: "80%",
              borderRadius: "5px",
              background:
                "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
              borderColor: "white",
              borderWidth: 0.5,
              borderStyle: "solid",
            }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textAlign: "center",
                    alignItems: 'center',
                    justifyContent: "start",
                    paddingTop: "4px",
                    marginBottom: '2px',
                  }}
                >
                  Bed Inventory
                </p><hr
                  style={{
                    marginTop: '0px',
                    marginBottom: '8px',
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500",
                    fontSize: '14px'
                  }}
                >
                  Available Beds
                </p>
                <p style={{ color: "#2CDB72", fontWeight: "500", fontSize: '14px' }}>
                  {available}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaBed size={"25px"} color="#2CDB72" />
              </IconButton>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500",
                    fontSize: '14px'
                  }}
                >
                  Occupied Beds
                </p>
                <p style={{ color: "#CCD9A8", fontWeight: "500", fontSize: '14px' }}>
                  {occupied}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaBed size={"25px"} color="#CCD9A8" />
              </IconButton>
            </div>
          </div>
        </div>

      </div>
      {modalopen && staffName.length > 0 && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              width: 600,
              height: 350,
              borderRadius: 8,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              borderBottom: "30px solid #F04349",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <IconButton style={{ color: "white" }} onClick={handleClose}>
                <FaTimesCircle size={17} />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RiAlarmWarningFill style={{ color: "red", fontSize: 50 }} />
            </div>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Emergency
            </p>
            {Array.isArray(staffName) && staffName?.map((item: any) => (<p
              style={{
                fontFamily: "Poppins",
                fontSize: 14,
                textAlign: "center",
                color: "white",
              }}
            >
              {item?.staffName ? item?.staffName : item?.visitorName ? item?.visitorName : 'N/A'} called for help - Go to   Location: {item?.location}
            </p>))
            }

            <div
              style={{
                display: "flex",
                justifyContent: 'center',
              }}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={toggleValue}
                  onChange={handleToggleChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </Modal>
      )}

      <Dialog open={historyModal} maxWidth="md" fullWidth onClose={handleCloseModal} PaperProps={{
        style: {
          position: 'absolute', right: '20%'
        },
      }}
        style={{ padding: 0 }}
      >
        <div
          style={{
            height: isExpanded ? '471px' : '350px',
            width: '100%',
            fontFamily: 'Poppins',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogTitle sx={{ height: '45px' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>{LorgName}</p>
              </div>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseTwoTone />
              </IconButton>
            </div>
          </DialogTitle>
          <hr style={{}} />
          <DialogContent style={{ flex: '0 0 auto' }}>
            <DialogContentText>
              <div style={{ fontFamily: 'Poppins' }}>
                <p style={{}}>Staff SOS Call Details:</p>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                  <p style={{ marginRight: '15px' }}>
                    <strong style={{ color: 'gray' }}>
                      {stafHistory?.visitorName ? 'Visitor Name: ' : 'Staff Name: '}
                    </strong>
                    {stafHistory?.triggeredStaffId ? stafHistory?.triggeredStaffId : stafHistory?.visitorName}
                  </p>
                  <p style={{ marginRight: '15px' }}>
                    <strong style={{ color: 'gray' }}>Location: </strong>
                    {stafHistory?.location && stafHistory?.location}
                  </p>
                  <p>
                    <strong style={{ color: 'gray' }}>Date: </strong>
                    {stafHistory?.triggeredTime &&
                      moment(stafHistory?.triggeredTime, 'YYYYMMDDHHmmss').format('MMM DD, YYYY hh:mm A')}
                  </p>
                </div>

                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {isExpanded ? (
                    <FaAngleUp
                      style={{
                        color: '#000',
                        cursor: 'pointer',
                        marginLeft: '10px', // Adds a little spacing from the text
                      }}
                      onClick={() => {
                        setIsExpanded(false);
                        handleMinusClick();
                      }}
                    />
                  ) : (
                    <FaAngleDown
                      style={{
                        color: '#000',
                        cursor: 'pointer',
                        marginLeft: '10px', // Adds a little spacing from the text
                      }}
                      onClick={() => {
                        setIsExpanded(true);
                        handlePlusClick11(stafHistory?.staffId);
                      }}
                    />
                  )}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent style={{ flex: '1 1 auto', overflow: 'hidden', padding: '0 24px 24px 24px' }}>
            {loading ? (
              <p style={{}}>Loading...</p>
            ) : (
              alarmData.length > 0 && (
                <div
                  style={{
                    height: '230px',
                    overflowY: 'auto',
                  }}
                >
                  <Table className="table table-bordered" style={{ fontSize: '11px', height: ' 60%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Triggered Staff/Guest Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {alarmData.map((alarm: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell>{alarm.triggeredStaffId ? alarm.triggeredStaffId : alarm.visitorName}</TableCell>
                          <TableCell>{alarm.location}</TableCell>
                          <TableCell>
                            {moment(alarm.triggeredTime, 'YYYYMMDDHHmmss').format('MMM DD, YYYY hh:mm A')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )
            )}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default PatientDisplay;