import axios from "axios";
import { toast } from "react-toastify";
import {
    isLoading,
    setIsLoadingFalse,
    setFormValues,
    setOneDataSuccess,
    setOneDataElements,
    setOneData1Success,
    setOneData1Elements,
} from "./reducer";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";

export const savePatientData = (body: any, headers: any, successCallback: () => void, errorCallback: (error: string) => void) => {
    return async (dispatch: any) => {
        const { header1 } = authorize();

        try {
            const response = await axios.post(`${baseURL}/oneToOnePatient/register`, body, {
                headers: header1,
            });

            if (response.data.message.code === successCode) {
                dispatch(setFormValues(response.data.data));
                successCallback();
            } else {
                errorCallback(response.data.message.description);
            }
        } catch (error) {
            errorCallback("An error occurred during register.");
        }
    };
};
export const fetchPatientData = async (dispatch: any, org: string, pageNo: number, search: string, date: string, shiftName: string, setPreviousCheck: any) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
        dispatch(isLoading())
        const response = await axios.get(
            `${baseURL}/oneToOne/oneToOnePatientList?date=${date}&organization=${org}&page=${pageNo}&pageSize=5&search=${search}&shiftName=${shiftName}`,
            { headers: header1 }
        );
        if (response.data.message.code === successCode) {
            dispatch(setIsLoadingFalse())
            dispatch(setOneDataSuccess(response.data.data.content || []));
            dispatch(setOneDataElements(response.data.data.totalElements || 0))
            setPreviousCheck(false)
        } else {
            dispatch(setIsLoadingFalse())
            dispatch(setOneDataSuccess([]));
            dispatch(setOneDataElements(0))
            toast.error(response.data.message.description);
            setPreviousCheck(false)
        }
    } catch (error) {
        dispatch(setIsLoadingFalse())
        dispatch(setOneDataSuccess([]));
        toast.error("Failed to fetch patient data. Please try again.");
        console.error(error);
        setPreviousCheck(false)
    }
}; 
export const fetchPatientData1 = async (dispatch: any, org: string, pageNo: number, search: string, date: string, shiftName: string, setPreviousCheck: any) => {
    // return async (dispatch: any) => {
    dispatch(isLoading());
    const { header1 } = authorize();

    try {
        const response = await axios.get(
            `${baseURL}/eyesight/eyeSightPatientList?date=${date}&organization=${org}&page=${pageNo}&pageSize=5&search=${search}&shiftName=${shiftName}`,
            { headers: header1 }
        );
        if (response.data.message.code === successCode) {
            dispatch(setIsLoadingFalse())
            dispatch(setOneData1Success(response.data.data.content || []));
            dispatch(setOneData1Elements(response.data.data.totalElements || 0))
            setPreviousCheck(false)
        } else {
            dispatch(setIsLoadingFalse())
            dispatch(setOneData1Success([]))
            toast.error(response.data.message.description);
            setPreviousCheck(false)
        }
    } catch (error) {
        dispatch(setIsLoadingFalse())
        console.error(error);
        dispatch(setOneData1Success([]))
        setPreviousCheck(false)
    }
    // };
};
