import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import '../Reports.css'
import { getActivityLegend, getLocationLegend } from '../../../slices/thunk';
import { setActivityLegend } from '../../../slices/dropdown/reducer';
import { setLocationLegend } from '../../../slices/Reports/reducer';
import { primarybg, secondarybt } from '../../../common/primary';
import Q15DetailReportView from './Q15DetailReportView';
interface Q15DetailViewProps {
    ReportData: any
    setLoading: any
    legendcontrol: any
    checkPrecaution: any
    checkBox: any
    timeFormat: boolean
}
const Q15DetailView: React.FC<Q15DetailViewProps> = ({ ReportData, checkBox, checkPrecaution, legendcontrol, setLoading, timeFormat }) => {
    const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
    const org = useSelector((state: any) => state.Login.organization)
    const [OrgName, setOrgName] = useState<any>('')
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [singleReportDate, setSingleReportDate] = useState<any>('')
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const [reportData, setReportDate] = useState<any>([])
    const dispatch = useDispatch<any>()

    useEffect(() => {
        if (ReportData) {
            const filterData = ReportData.filter((data: any) => (data.shiftIncharge.shiftInchargeA !== null || data.shiftIncharge.shiftInchargeB !== null || data.shiftIncharge.shiftInchargeC !== null || data.shiftIncharge.shiftInchargeD !== null) && data.data.length > 0)
            setReportDate(filterData)
        } else {
            setReportDate([])
        }
    }, [ReportData])
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        if (!orgData) return
        setOrgName(orgData.organizationdetails[0].name)
    }, [orgData])
    // location & activity legend
    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
        return () => {
            dispatch(setActivityLegend([]))
            dispatch(setLocationLegend([]))
        }
    }, [org])
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    useEffect(() => {
        const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
            let slotStart = Date.parse(fromTime);
            let slotEnd = slotStart + slotLength * 1000;
            let endEpoch = Date.parse(toTime);
            let slots = [];

            while (slotEnd <= endEpoch) {
                slots.push(formatDate(slotStart)); // Ensure formatDate is defined
                slotStart = slotEnd;
                slotEnd += slotLength * 1000;
            }

            return slots;
        };

        const from = `2022-05-25 ${shiftStartTime}`;
        const to = `2022-05-26 ${shiftStartTime}`;
        const slotLength = 15 * 60;

        let timeSlots = createTimeSlots(from, to, slotLength);

        // Convert to 12-hour format with leading zeros
        const formattedSlots = timeSlots.map(time => convertTo12HourFormat(time));

        setNewTimeSlot(formattedSlots);
    }, [shiftStartTime]); // Include dependencies

    const decreaseOneMinute = (timeString: string) => {
        if (!timeString) return null;

        const [hours, minutes] = timeString.split(':').map(Number);

        let date = new Date();
        date.setHours(hours, minutes, 0);

        date.setMinutes(date.getMinutes() - 1);

        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    };

    const convertTo12HourFormat = (time: string) => {
        let [hours, minutes] = time.split(':').map(Number);
        let amPm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
        let formattedHours = String(hours).padStart(2, '0'); // Ensure leading zero

        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    };

    const calculateDisplayTime = (time: string, startTime: string, index: number) => {
        // Parse hours and minutes from startTime
        const [startHour, startMinute] = startTime?.split(":").map(Number);

        // Calculate if the current time crosses midnight
        const currentHour = (startHour + Math.floor((startMinute + index * 15) / 60)) % 24;
        const isNextDay = (startHour + Math.floor((startMinute + index * 15) / 60)) >= 24;

        return `${time}${isNextDay ? " +1" : ""}`;
    };

    async function timeDealys(reports: any) {
        const sections = document.querySelectorAll(`.report-section${reports.date}`);
        const pdf = new jsPDF('p', 'px', 'a4', true);
        const margin = 20;
        const pageHeight = pdf.internal.pageSize.height;
        const images: any[] = [];
        let currentY = margin;
        const patientName = reports.patientName

        const addSectionToPDF = (section: any) => {
            return new Promise((resolve: any) => {
                html2canvas(section, {
                    useCORS: true,
                    scale: 2,
                    logging: true,
                    backgroundColor: null,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    images.push(imgData);
                    resolve();
                }).catch((error) => {
                    console.error('Error rendering section:', error);
                    resolve();
                });
            });
        };


        // Add all sections to images array
        const promises = Array.from(sections).map(addSectionToPDF);
        await Promise.all(promises);
        // Add the first image to the first page
        pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
        pdf.addPage(); // Create a new page

        // Add the second image to the second page
        pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

        // Save the PDF with the swapped content
        pdf.save(`${patientName}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
    }
    const handleSingltDownload = async (str: string) => {
        if (ReportData) {
            try {
                setLoading(true);
                const report = ReportData.find((item: any) => item.id === str);
                if (report) {
                    await timeDealys(report);
                }
                setLoading(false);
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    };
    return (
        <div>
            {newTimeSlot.length > 0 && reportData.length > 0 &&
                reportData.map((data: any, inx: number) => (
                    <Accordion
                        key={data.date}
                        expanded={expanded === 'panelmain' + data.date}
                        onChange={handleChange('panelmain' + data.date)}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{ backgroundColor: expanded === 'panelmain' + data.date ? secondarybt : '' }}
                            expandIcon={<ArrowDropDownIcon />}
                        >
                            <Typography>
                                <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                                    <div
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "normal"
                                        }}
                                    >
                                        {moment(data.date).format("MMM DD, YYYY")}
                                    </div>
                                </div>
                            </Typography>
                            <div>
                                <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                                    // handleSingltDownload(moment(data.id).format("DD-MM-YYYY"))
                                    handleSingltDownload(data.id)
                                }} >Download</button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }}>
                                <Q15DetailReportView reportData={reportData} checkPrecaution={checkPrecaution} locationLegend={locationLegend} activityLegend={activityLegend} checkBox={checkBox} legendcontrol={legendcontrol} InxNum={inx} oneHour={checkPrecaution === "One-Hour" ? true : false} logo={orgData.logo} timeFormat={timeFormat} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    )
}

export default Q15DetailView