import React, { useEffect, useState } from 'react'
import moment from 'moment';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import trilliumImage from './../../assets/images/trillium.png';
import { useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface MultipleReportViewProps {
  multiReportData: any
  View: any
  selectShift: any
  selectShiftView: any
  checkBox: any
  locationLegend: any
  activityLegend: any
  checkPrecaution: any
  legendcontrol: boolean
  timeFormat: boolean
}

const MultipleReportView: React.FC<MultipleReportViewProps> = ({ multiReportData, View, selectShift, selectShiftView, checkBox, activityLegend, locationLegend, checkPrecaution, legendcontrol, timeFormat }) => {

  const [expanded, setExpanded] = useState<string | false>('panel1');
  const { orgData } = useSelector((state: any) => state.Org)
  const [OrgName, setOrgName] = useState<any>('')
  const { precaution } = useSelector((state: any) => state.Dropdown);

  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const formatChange = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    console.log(moment(splitedvalue, ["h:mm A"]).format("HH:mm"), 'valided')
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    if (shiftTimeStart.isAfter(date2)) {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm") + " +1"
    } else {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm");
    }
  }
  const formatChange1 = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    const date1 = moment(`2025-01-08T00:00`)
    if (date2.isAfter(shiftTimeStart)) {
      return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    }
    else {
      if (date2.isAfter(date1)) {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3] + " +1"
      } else {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
      }
    }
  }
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    if (!orgData) return
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])
  const GetNextDate = (reportDate: any) => {
    const validDate = moment(reportDate);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      return moment(nextDay)?.format('MMM DD, YYYY')
    } else {
      console.error("Invalid date:");
      return ""
    }
  }
  // useEffect(() => {
  //   if (!singleDate) return;

  //   const validDate = moment(singleDate[0]);
  //   // console.log(validDate, 'validDate')
  //   if (validDate.isValid()) {
  //     const nextDay = validDate.clone().add(1, 'days');
  //     setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
  //   } else {
  //     console.error("Invalid date:", singleDate);
  //   }
  // }, [singleDate]);
  return (
    <div>
      {/* Multi Report Summary&Details View Start */}
      <div className='container-fluid overflow-auto'>
        {View && multiReportData !== null && multiReportData !== undefined &&
          multiReportData.map((data: any, inx: number) => {
            return (
              <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDropDownIcon />}
                >
                  <Typography>
                    <span style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                      {moment(data.date).format("MMM DD, YYYY")}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <>
                      <div className='container-fluid overflow-auto' id='1to1MultiReport' >
                        {/* Report Time Am View Start */}

                        <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                          {data && <>
                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                              {orgData.logo && orgData.logo !== '' ? (
                                <img
                                  src={orgData.logo}
                                  alt=""
                                  style={{ height: '60px', width: '60px' }}
                                />
                              ) : null}
                            </div>
                            <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                              {OrgName && OrgName}
                            </div>
                            {/* <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="p-col-12 p-md-12">
                                                      Mettler Health Care Organization
                                                    </div> */}
                            <div id="removePadding" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ width: '60%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                                  <div id="removePadding" >
                                    <strong>Date: </strong>
                                    {data.date && moment(data.date).format("MMM DD, YYYY")}
                                    {/* {selectShiftView ? (selectShift === "Shift-C" && `, ${nextDate}`) : ''} */}
                                    {selectShiftView ? (selectShift === "Shift-C" && GetNextDate(data.date)) : ''}
                                  </div>
                                  {selectShiftView && (
                                    <div id="removePadding" >
                                      <strong>Shift: </strong>
                                      {selectShift}
                                    </div>
                                  )}
                                </div>
                                <div id="removePadding" className='' >
                                  <strong>Patient Name: </strong>
                                  {data.patientId}
                                </div>
                              </div>
                              <div className='d-flex row'>
                                <div id="removePadding" className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div>
                                    <strong>Room No: </strong>
                                    {data.assignedBed && data.assignedBed.split('-')[0]}
                                  </div>
                                  <div>
                                    <strong style={{ paddingRight: '12px' }}>Assigned Bed:</strong>
                                    {data.assignedBed && data.assignedBed.split('-')[1]}
                                  </div>
                                </div>
                                <div>
                                  {
                                    data.suicideRisk !== "" && data.suicideRisk !== null ? <><strong>SuicideRisk:</strong><span>{" "}{data?.suicideRisk}</span></> : <div></div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div style={{ border: '1px solid #C9C9C9' }}>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                  <div>
                                    CHECK PRECAUTIONS:
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Every 15 Minutes
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      1:1
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      One Hour
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Eyesight
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* {reportData !== null &&
                                              reportData !== undefined &&
                                              Array.isArray(reportData) &&
                                              reportData.map((data: any, inx: number) => ( */}

                              <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    padding: '0px 10px',
                                  }}
                                >
                                  {precaution.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                                    <div
                                      className="form-check"
                                      style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                                      key={index}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        checked={data?.precaution?.includes(item.value) || false}
                                        style={{ padding: '7px', border: '1.2px solid black' }}
                                      />
                                      <label
                                        className=""
                                        style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                                      >
                                        {item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* ))} */}

                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>LOCATION CODES</div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                    <div className=" " style={{ height: '25px', width: '25%' }} key={inx}>
                                      {/* <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} /> */}
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {item.key}-{" - " + item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>ACTIVITY CODES</div>
                              </div>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>

                                  {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                    <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                        {inx + 1}.{" " + item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                            </div>
                            {!selectShiftView ? <>
                              <div className='' style={{ fontSize: '14px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                  <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                  <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                </div>
                              </div>
                              <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                <div style={{ width: '50%', fontSize: '14px' }}>
                                  {data && data.configResponses?.map((t: any, ind: number) => {
                                    return ind >= 0 && ind <= 23 && (<>
                                      <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                          {!timeFormat
                                            ? `${t?.timeSlot?.split(' ')[0]} ${t?.timeSlot?.split(' ')[3]}`
                                            : formatChange(t?.timeSlot)
                                          }
                                          {/* {t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[3]} */}
                                        </div>
                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                      </div>
                                    </>);
                                  })}
                                </div>
                                <div style={{ width: '50%', fontSize: '14px' }}>
                                  {data && data.configResponses?.map((t: any, ind: number) => {
                                    return ind >= 24 && ind <= 47 && (<>
                                      <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                          {!timeFormat
                                            ? `${t?.timeSlot?.split(' ')[0]} ${t?.timeSlot?.split(' ')[3]}`
                                            : formatChange(t?.timeSlot)
                                          }
                                          {/* {t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[3]} */}
                                        </div>
                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                      </div>
                                    </>);
                                  })}
                                </div>
                              </div>
                            </>
                              :
                              <>
                                {/*  Report Shift based View Start */}
                                <div>
                                  <div className='mt-3' style={{ fontSize: '14px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                      <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                      <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                    </div>
                                  </div>
                                  <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                    <div style={{ width: '50%', fontSize: '14px' }}>
                                      {data && data.configResponses?.map((t: any, ind: number) => {
                                        return ind >= 0 && ind <= (data.configResponses.length / 2) - 1 && (<>
                                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                              {!timeFormat
                                                ? formatChange1(t?.timeSlot)
                                                : formatChange(t?.timeSlot)
                                              }
                                              {/* {t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[3]} {selectShift === "Shift-C" && t.timeSlot.split(' ')[3] === "AM" ? "+1" : ''} */}
                                            </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                          </div>
                                        </>);
                                      })}
                                    </div>
                                    <div style={{ width: '50%', fontSize: '14px' }}>
                                      {data && data.configResponses?.map((t: any, ind: number) => {
                                        return ind >= (data.configResponses.length / 2) && ind <= data.configResponses.length && (<>
                                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                              {!timeFormat
                                                ? formatChange1(t?.timeSlot)
                                                : formatChange(t?.timeSlot)
                                              }
                                            </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                          </div>
                                        </>);
                                      })}
                                    </div>
                                  </div>
                                </div>
                                {/* Report Shift based View End */}
                              </>}

                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                              </div>
                              {
                                data ?
                                  <>
                                    {[1, 2, 3, 4].map((t: any, indx: number) => (
                                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>{selectShiftView && selectShift ? indx === 0 && data?.[`shift${selectShift.split("-")[1]}Incharge`] : data?.[`shift${String.fromCharCode(65 + indx)}Incharge`] ?? ""}</div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        {/* <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div> */}
                                      </div>
                                    ))}
                                  </>
                                  :
                                  <>
                                    {[1, 2, 3, 4].map((t: any, indx: number) => (
                                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                      </div>
                                    ))}
                                  </>
                              }
                            </div>
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                              </div>
                            </div>
                          </>}
                        </section>
                        {/*  Report Time AM View End  */}
                        {/* Report Time PM View Start  */}
                        {!selectShiftView && <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                          {data && <>
                            <div id="removePadding" style={{ textAlign: 'center' }} className="">
                              {orgData.logo && orgData.logo !== '' ? (
                                <img
                                  src={orgData.logo}
                                  alt=""
                                  style={{ height: '60px', width: '60px' }}
                                />
                              ) : null}
                              {/* {orgData && orgData.logo ? (
                                                <Avatar
                                                  sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                                  src={orgData.logo}
                                                >
                                                </Avatar>) : <></>} */}
                            </div>
                            <div style={{ height: '60px', width: '60px' }}></div>
                            <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                              {OrgName && OrgName}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ width: '66.66%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                                  <div>
                                    <strong>Date: </strong>
                                    {data.date && moment(data.date).format("ddd DD, YYYY")}{" - "}{GetNextDate(data.date)}
                                  </div>
                                  {selectShiftView && (
                                    <div>
                                      <strong>Shift: </strong>
                                      {selectShift}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <strong>Patient Name: </strong>
                                  {data.patientId}
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', width: '33.33%' }}>
                                <div>
                                  <strong>Room No: </strong>
                                  {data.assignedBed && data.assignedBed.split('-')[0]}
                                </div>
                                <div>
                                  <strong style={{ paddingRight: '12px' }}>Assigned Bed:</strong>
                                  {data.assignedBed && data.assignedBed.split('-')[1]}
                                </div>
                                <div>
                                  {
                                    data.suicideRisk !== "" && data.suicideRisk !== null ? <><strong>SuicideRisk:</strong><span>{" "}{data?.suicideRisk}</span></> : <div></div>
                                  }
                                </div>
                              </div>
                            </div>

                            <div style={{ border: '1px solid #C9C9C9' }}>
                              <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                  <div>
                                    CHECK PRECAUTIONS:
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Every 15 Minutes
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      1:1
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      One Hour
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One-Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', gap: '3px' }}>
                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                      Eyesight
                                    </label>
                                    <div className="form-check" style={{ height: '15px' }}>
                                      <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    padding: '0px 10px',
                                  }}
                                >
                                  {precaution.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                                    <div
                                      className="form-check"
                                      style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                                      key={index}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled
                                        checked={data?.precaution?.includes(item.value) || false}
                                        style={{ padding: '7px', border: '1.2px solid black' }}
                                      />
                                      <label
                                        className=""
                                        style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                                      >
                                        {item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>LOCATION CODES</div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                  {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                    <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                                      {/* <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} /> */}
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                        {item.key}{" - " + item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                <div>ACTIVITY CODES</div>
                              </div>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                  {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                    <div className=" border " style={{ height: '30px', width: '20%' }} key={inx}>
                                      <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                        {inx + 1}.{" " + item.value}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className='' style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 48 && ind <= 71 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                        {!timeFormat
                                          ? formatChange1(t?.timeSlot)
                                          : formatChange(t?.timeSlot)
                                        }
                                        {/* {t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[3]}{t.timeSlot.split(' ')[3] === "AM" ? "+1" : ""} */}
                                      </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 72 && ind <= 95 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                        {!timeFormat
                                          ? formatChange1(t?.timeSlot)
                                          : formatChange(t?.timeSlot)
                                        }
                                        {/* {t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[3]}{t.timeSlot.split(' ')[3] === "AM" ? "+1" : ""} */}
                                      </div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                              </div>
                              {
                                data?.shiftIncharge !== null ?
                                  <>
                                    {[1, 2, 3, 4].map((t: any, indx: number) => (
                                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>{selectShiftView ? indx === 0 && data?.[`shift${selectShift.split("-")[1]}Incharge`] : data?.[`shift${String.fromCharCode(65 + indx)}Incharge`] ?? ""}</div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        {/* <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div> */}
                                      </div>
                                    ))}
                                  </>
                                  :
                                  <>
                                    {[1, 2, 3, 4].map((t: any, indx: number) => (
                                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                      </div>
                                    ))}
                                  </>
                              }
                            </div>
                            <div style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                              </div>
                            </div>
                          </>
                          }
                        </section>}
                        {/* Single Report Time PM View End  */}
                        {/* Notes Table Start */}
                        {/* <section className={`px-5 ${filterdata.length > 0 ? 'py-3': 'py-0' }`}>
                         {
                           (tableData && filterdata.length>0) && 
                           <>
                             <div className='mt-2' style={{width: '100%', fontSize: '13px'}}>
                               <div style={{ display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                 <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time</div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                 <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                 <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                               </div>
                             </div>
                             <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                               <div style={{ width: '50%', fontSize: '14px' }}>
                                 { filterdata && filterdata?.map((item: any, inx: number) => {
                                   const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                   return inx >=0 && inx <= ((filterdata.length/2)-1) && (
                                     <>
                                       <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                         <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                       </div>
                                     </>  
                                    )
                                   })
                                 }
                               </div>                    
                               <div style={{ width: '50%', fontSize: '14px' }}>
                                 { filterdata && filterdata?.map((item: any, inx: number) => {
                                   const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                   return inx >= (filterdata.length/2) && inx <= (filterdata.length-1) && (
                                     <>
                                       <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                         <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                         <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                       </div>
                                     </>  
                                    )
                                   })
                                 }
                               </div>
                             </div>
                           </> 
                         }
                       </section > */}
                        {/* Notes Table End */}
                      </div>
                    </>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          }
          )
        }
      </div>
      {/* Multi Report Summary&Details View End */}

    </div>
  )
}

export default MultipleReportView