import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, Divider, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FaInbox, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import "./shiftAllocation.css";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { authorize } from "../../helpers/common";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import { getAllRNIncharge } from "../../slices/thunk";
import { Button } from "primereact/button";
import { setStaffRoleDropDown } from "../../slices/staff/reducer";
interface Shift {
  shift_name: string;
  start_time: string;
  end_time: string;
  dayShift: string;
}
const ShiftListTable = ({
  allTeamData,
  noOfShift,
  value,
  staffDropdown,
  getAllTeam,
  getAllStaffByTeam,
  selectedDate,
  selectedDate1,
  incharge,
  setIncharge
}: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const [filteredData, setFilteredData] = useState<any>([]);
  const { header1 } = authorize();
  const [Loading, setLoading] = useState<boolean>(false);
  const [Data, setData] = useState<any>([]);
  const { organization } = useSelector((state: any) => state.Login);
  const [shifts, setShifts] = useState<any>([]);
  const startTime = new Date(`2000-01-01T${shiftStartTime}`);
  const { orgData } = useSelector((state: any) => state.Org);
  const dispatch = useDispatch<any>()
  const [inchargeError, setInchargeError] = useState(false);
  const [staffRoles, setStaffRoles] = useState<any>([])
  const [staffRoles1, setStaffRoles1] = useState<any>([])
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const { staffRoleDropDown } = useSelector((state: any) => state.Staff)
  const { rnInchargeList, socialWorkerList } = useSelector(
    (state: any) => state.PSConfig
  );
  // 24 h AM PM shift 
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    // Function to convert 24-hour time to 12-hour time with AM/PM
    const to12HourFormat = (date: any) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";

      // Convert hour to 12-hour format
      hours = hours % 24;
      if (hours === 0) {
        hours = '00'; // Midnight or noon case
      }

      // Add leading zero for minutes if needed
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // Return formatted time with AM/PM
      return `${hours}:${minutes} `;
    };

    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      ); // duration in hours

      // Format start and end times into 12-hour format with AM/PM
      const startTimeFormatted = to12HourFormat(startTime);
      const endTimeFormatted = to12HourFormat(endTime);

      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
        dayShift: "",
      });

      // Update the start time for the next shift
      startTime = endTime;
    }
    const convertTo24Hour = (time: string) => {
      const [hour, minuteWithPeriod] = time.split(":");
      const [minute, period] = minuteWithPeriod.split(" ");
      let hour24 = parseInt(hour);

      // Handle AM/PM conversion
      if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
      if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

      return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
    };

    const addDayShift = (shift: Shift, addShift: boolean): Shift => {
      const startInMinutes = convertTo24Hour(shift.start_time);
      const endInMinutes = convertTo24Hour(shift.end_time);
      if (endInMinutes < startInMinutes) {
        return { ...shift, dayShift: "+1" };
      }
      if (addShift) {
        return { ...shift, dayShift: "+1" };
      }
      return { ...shift, dayShift: "" };
    };
    let addShift = false;

    // Apply the dayShift logic to each shift
    const updatedShifts = shifts.map((shift: any) => {
      // If we encounter a shift that crosses midnight, set addShift to true
      if (
        !addShift &&
        convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
      ) {
        addShift = true;
      }
      return addDayShift(shift, addShift);
    });
    setShifts(updatedShifts);
  };

  //   const generateShifts = (
  //     startTimeStr: any,
  //     duration: any,
  //     totalShifts: any
  // ) => {
  //     const shifts = [];

  //     let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

  //     for (let i = 0; i < totalShifts; i++) {
  //         let endTime = new Date(
  //             startTime.getTime() + Number(duration) * 60 * 60 * 1000
  //         );
  //         const startTimeFormatted = startTime.toTimeString().slice(0, 5);
  //         const endTimeFormatted = endTime.toTimeString().slice(0, 5);
  //         const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
  //         shifts.push({
  //             shift_name: shiftName,
  //             start_time: startTimeFormatted,
  //             end_time: endTimeFormatted,
  //         });
  //         startTime = endTime;
  //     }
  //     setShifts(shifts);
  // };
  const selectedDateObj = moment(selectedDate1);
  const select1 = moment(selectedDate);

  const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');
  const renderTabContent = () => {
    switch (value) {
      case "Shift-A":
        return 0;
      case "Shift-B":
        return 1;
      case "Shift-C":
        return 2;
      case "Shift-D":
        return 3;
      case "Shift-E":
        return 4;
      case "Shift-F":
        return 5;
      case "General":
        return 6;
      case "Previous":
        return 7;
      default:
        return "No Content Available";
    }
  };
  useEffect(() => {
    setLoading(true);
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    // getAllRNIncharge(dispatch, 'Registered Nurse', organization)

    if (allTeamData?.length === 0 || allTeamData === undefined) {
      const filterData = staffDropdown.filter((item: any) => selectedValues.includes(item.role))
      setData(filterData);
      setLoading(false);
    } else {
      setData(allTeamData);
      setLoading(false);
    }
  }, [allTeamData, value, staffDropdown, selectedValues]);

  const handleCheckboxChange = (id: string) => {
    setData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );

    setFilteredData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );
  };
  const isAllSelected =
    staffRoles?.length > 0 && selectedValues.length === staffRoles?.length;
  const handleselectChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = staffRoles?.map((data: any) => data.value);
      dispatch(setStaffRoleDropDown(selectedValues.length === staffRoles?.length ? [] : allData))
      setSelectedValues(
        selectedValues.length === staffRoles?.length ? [] : allData
      );
      return;
    }
    // if (value.length === 0) {
    //   setSubmitStaff(true);
    // } else {
    //   setSubmitStaff(false);
    // }
    dispatch(setStaffRoleDropDown(value))
    setSelectedValues(value);
  };
  useEffect(() => {
    const getStaffDropDown = async () => {
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=${"Staff Roles"}&organizationId=${organization}&page=${0}&pageSize=&searchValue=${""}`)
        if (response.data.message.code === successCode) {
          setStaffRoles(response.data.data.content[0].list)
          setStaffRoles1(response.data.data.content[0].list)
        } else {
          setStaffRoles([])
          setStaffRoles1([])
        }
      } catch (error: any) {
        console.error("API ERROR:", error)
      }
    }
    getStaffDropDown()
  }, [])
  useEffect(() => {
    if (!staffRoleDropDown) {
      if (staffRoles1.length > 0) {
        const filterData = staffRoles1?.filter((item: any) => item.value === "Social Worker" || item.value === "Registered Nurse" || item.value === "Nurse Practitioner")
        // setStaffRoles(filterData)
        const filterData1 = filterData?.map((item: any) => { return item.value })
        dispatch(setStaffRoleDropDown(filterData1))
        setSelectedValues(filterData1)
      }
    } else {
      setSelectedValues(staffRoleDropDown)
    }
  }, [staffRoles1])
  const handleSaveStaff = async () => {
    let filteredData = Data?.filter((data: any) => data.checkIn === true);
    if (!incharge) {
      setInchargeError(true);
      return toast.error('Incharge field required');
    } else {
      setInchargeError(false);
    }

    const staffIds = Data?.map((staff: any) => ({
      staffId: staff.id,
      staffName: staff.name,
      checkIn: staff.checkIn,
    }));
    let formData = {
      organization: organization,
      shiftName: value,
      shiftIncharge: incharge,
      staff: staffIds,
      startDate: moment(selectedDate)?.format("YYYYMMDD"),
    };
    if (filteredData.length === 0) {
      toast.error('Please select the staff');
    } else {
      try {
        setLoading(true);
        const response = await axios.post(`${baseURL}/shift/register`, formData, {
          headers: header1,
        });
        if (response.data.message && response.data.message.code === successCode) {
          toast.success(response.data.message.description);
          setLoading(false);
          getAllStaffByTeam();
          getAllTeam();
        } else {
          setLoading(false);
          toast.error(response.data.message.description);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSearch = (e: any) => {
    const query = e.target.value;
    // console.log(query, 'query')
    setSearchQuery(query);
    if (query) {
      const filtered = Data?.filter((item: any) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      if (filtered.length > 0) {
        setFilteredData(filtered);
      } else {
        setFilteredData([]);
      }
    } else {
      setFilteredData(Data);
    }
  };
  const formatTimeTo12Hour = (time: string) => {
    return moment(time, 'HH:mm').format('hh:mm A');
  };
  return (
    <div className="overflow-hidden" style={{ height: '69vh' }}>
      {Loading && <Loader />}
      {/* <div style={{ height: '10vh', border: '1px solid black'}}>
        
      </div> */}
      <div className="row " style={{ position: "relative", height: '12vh', borderBottom: "1px groove #ccd2d8" }}>
        <div className="col-md-3 mt-2">
          <div className=" d-flex align-items-center" style={{ fontWeight: '600', fontSize: '18px' }}>
            {moment(selectedDate).format("MMM DD, YYYY")}
          </div>
          <div
            className=" d-flex align-items-center"
            style={{ fontSize: "14px" }}
          >
            <span>
              {renderTabContent() === 6
                ? '09:00 AM'
                : renderTabContent() === 7 ? formatTimeTo12Hour(shifts[noOfShift - 1]?.start_time) : formatTimeTo12Hour(shifts[renderTabContent()]?.start_time)}{" "}
              {""} -
            </span>
            <span style={{ marginLeft: "5px" }}>
              {renderTabContent() === 6
                ? '05:00 PM'
                : renderTabContent() === 7 ? formatTimeTo12Hour(shifts[noOfShift - 1]?.end_time) : formatTimeTo12Hour(shifts[renderTabContent()]?.end_time)}{" "}
              {""}
              {shifts[renderTabContent()]?.dayShift}
            </span>

          </div>
        </div>
        <div className="col-md-9 d-flex justify-content-end align-items-center  gap-2">
          <Autocomplete
            className=""
            style={{ height: "65px", width: '250px', paddingRight: '8px' }}
            fullWidth
            id="floatingSelect"
            onChange={(e, value) => {
              setIncharge(value?.id || '');
              if (value) {
                setInchargeError(false);
              }
            }}

            value={rnInchargeList.find((item: any) => item.id === incharge) || null}
            options={rnInchargeList}
            getOptionLabel={(option) => option.name[0]?.given + ' ' + option.name[0]?.family}
            renderInput={(params) => <TextField {...params} required label="Select RN Incharge" variant="standard" error={inchargeError}
              helperText={inchargeError ? "Please select an RN Incharge" : ""} />}
          />

          <FormControl className="" style={{ height: "65px", width: '250px', paddingRight: '8px' }} variant="outlined" fullWidth>
            <InputLabel color="primary">Staff Role</InputLabel>
            <Select
              placeholder="Select Role"
              multiple
              sx={{ width: "100%" }}
              value={selectedValues}
              onChange={(e: any) => {
                handleselectChange(e)
              }}
              color="primary"
              size="medium"
              label="Staff Role"
              name="staff_role"
              renderValue={(selectedValues) =>
                selectedValues.map((value: any) => value).join(", ")
              }
            >
              {/* Select All / Unselect All MenuItem */}
              {/* {AllTeamData?.length > 0 ?(<> */}
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selectedValues.length > 0 &&
                      selectedValues.length < staffRoles?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>

              {/* Render other staff options */}
              {staffRoles?.map((newData: any, i: number) => (
                <MenuItem key={i} value={newData.value}>
                  <Checkbox
                    checked={selectedValues?.indexOf(newData.value) > -1} // Check if this item is selectedValues
                  />
                  <ListItemText primary={newData?.value} />
                </MenuItem>
              ))}
              {/* </>):(<>
                        <MenuItem >No Options</MenuItem>
                      </>) } */}
            </Select>
            <FormHelperText />
          </FormControl>
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              className="search form-control"
              onChange={(e) => handleSearch(e)}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
      </div>
      <div style={{ borderBottom: "1px groove #ccd2d8" }}>
        <>
          <div className="row" style={{ overflow: "auto", height: "49vh" }}>
            <div>
              <div
                className="row"
                style={{ width: "100%", flexWrap: "wrap" }}
              >
                {filteredData?.length > 0 ? (
                  filteredData.map((item: any) => (
                    <div key={item.id} className="col-md-3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          padding: "5px",
                        }}
                      >
                        <Checkbox
                          checked={item?.checkIn}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                        <div>{item.name}</div>
                      </div>
                    </div>
                  ))
                ) :
                  (Data.length > 0 && searchQuery === "") ? (
                    Data?.map((item: any, index: number) => (
                      <div key={item.id} className="col-md-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "5px",
                          }}
                        >
                          <Checkbox
                            checked={item?.checkIn}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <div>{item.name}</div>
                        </div>
                      </div>
                    ))
                  ) :
                    (
                      <>
                        <Typography variant="body1" className="table_empty">
                          <FaInbox className="empty_svg" />
                          No Staff found for this Shift
                        </Typography>
                      </>
                    )}
              </div>
            </div>
          </div>
          <Divider />
        </>
      </div>
      <Button
        label="Save Changes"
        style={{ backgroundColor: '#0f3995', marginTop: '10px', fontSize: '12px', fontWeight: 'bold', float: 'right' }}
        disabled={!isButtonEnabled}
        onClick={() => handleSaveStaff()}
      >
      </Button>
    </div>
  );
};

export default ShiftListTable;
