import React, { useEffect, useRef, useState } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { primarybg, primarytext } from '../../common/primary';
import { Button } from "primereact/button";
import { authorize } from '../../helpers/common';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import { HttpLogin } from '../../utils/Http';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Autocomplete, List, ListItem, MenuItem, TextField, Tooltip } from '@mui/material';
import { FaQrcode, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import { fetchLocationDropdown, getAllStaffLS } from '../../slices/thunk';
import moment from 'moment';
import LogoutConfirmationModal from '../../components/LogoutModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DateBox from '../../components/calendar/dateBox';
import Loader from '../../components/loader/Loader';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalTitle } from 'react-bootstrap';
import ErrorPopup from '../../components/errorPopup';
import { closeErrorPopup } from '../../slices/staff/reducer';
import { Paginator } from 'primereact/paginator';

const StaffSos = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState<any>();
    const [selectedDate1, setSelectedDate1] = useState<any>();
    const videoRef = useRef(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const dispatch = useDispatch()
    const { isOpen, errorMsg } = useSelector((state: any) => state.PSConfig)
    const { currentDateTime } = useSelector((state: any) => state.LargeScreen)
    const { locationDropdown } = useSelector((state: any) => state.Dropdown);
    const formattedDate = moment(currentDateTime, 'MM/DD/YYYY, hh:mm:ss A').format('YYYYMMDD');
    const [openModal, setOpenModal] = useState(false);
    const [location, setLocation] = useState('');
    const [deviceId, setDeviceId] = useState('')
    const { userData } = useSelector((state: any) => state.Login);
    const shiftDurationHour = [
        { 'id': '01:00', 'value': '01:00' },
        { 'id': '02:00', 'value': '02:00' },
        { 'id': '03:00', 'value': '03:00' },
        { 'id': '04:00', 'value': '04:00' },
        { 'id': '05:00', 'value': '05:00' },
        { 'id': '06:00', 'value': '06:00' },
        { 'id': '07:00', 'value': '07:00' },
        { 'id': '08:00', 'value': '08:00' }
    ]
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [shiftDuration, setShiftDuration] = useState<any>('')
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [activeDetail, setActiveDetail] = useState<any>()
    const [activeMessage, setActiveMessage] = useState<any>('')
    const recordsPerPage = 7;
    const staff = userData?.userDetail;
    const fetchSoSData = async (page: number, selectedDate: Date, searchTerm: any) => {
        const staffId = localStorage.getItem("userID")
        const { header1 } = authorize();
        setLoading(true)
        HttpLogin.axios().get(`${baseURL}/sosAlarm/sosAlarmRecordByDateAndOrgAndStaffId?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&page=${page}&pageSize=${recordsPerPage}&search=${searchTerm}&staffId=${staffId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === 'MHC - 0200') {
                    setSlotRegister1(response?.data?.data?.sosAlarmList);
                    setDeviceId(response.data.data.staffDevice)
                    setTotalRecords(response.data.data.pagination.totalElements || 0);

                    setLoading(false)
                } else {
                    setSlotRegister1([]);
                    console.error('No Record:');
                    setLoading(false)
                }
            })
    }
    let addSlotRegister1 = [
        {
            staff: [
                {
                    deviceId: "",
                    registeredTime: "",
                    staffId: ""
                }
            ]
        }
    ]
    let [slotRegister, setSlotRegister1] = useState<any>(addSlotRegister1);
    useEffect(() => {
        fetchSoSData(currentPage, selectedDate, searchTerm);
        return () => {
            setSlotRegister1([])
        }
    }, [currentPage, selectedDate, searchTerm]);
    useEffect(() => {
        if (!slotRegister) return
        setDeviceId(slotRegister && slotRegister[slotRegister.length - 1]?.deviceId)
        return () => {
            setDeviceId('')
        }
    }, [slotRegister])
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const { organization } = useSelector((state: any) => state.Login)
    const [options, setOptions] = useState<any>([]);
    const [show, setShow] = useState(false);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [ldate, setLdate] = useState<any>()
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const [loading, setLoading] = useState<boolean>(false)
    const { shiftStartTime } = useSelector((state: any) => state.Org);
    const selectedDateObj = moment(selectedDate1);
    const select1 = moment(selectedDate);

    const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');
    useEffect(() => {
        if (scanning) {
            startScanning();
        } else {
            codeReader.reset();
        }

        return () => {
            codeReader.reset();
        };
    }, [scanning]);
    const startScanning = async () => {
        try {
            setScanning(true);
            const videoInputDevices = await codeReader.listVideoInputDevices();
            const selectedDeviceId = videoInputDevices[0].deviceId;
            codeReader.decodeFromVideoDevice(
                selectedDeviceId,
                videoRef.current,
                (result: any, err: any) => {
                    if (result) {
                        let newDeviceId = "";
                        const result1 = result.getText();
                        if (result1 !== "" && result1 !== undefined && result1 !== null) {
                            if (result1.length < 15) {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId;
                            } else {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId.slice(6, 23);
                            }
                            setShow(false);
                            setScanning(false);
                        }
                        const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
                        if (scannedOption) {
                            setDeviceId(scannedOption.deviceId)
                            const modal = document.getElementById("exampleModal");
                            if (modal) {
                                modal.classList.add("show");
                                modal.style.display = "block";
                            }
                        } else {
                            setDeviceId("");
                            toast.error("Scanned device ID not found in the options.");
                        }
                    }
                    if (err && err.name === "NotFoundError") {
                        console.error("No QR code found in the video feed.");
                    }
                    if (err) {
                        console.error("Error during scanning:", err);
                    }
                },
            );
        } catch (error) {
            console.error("Error starting the scanner:", error);
        }
    };

    const handlestartTime = () => {
        const crtime: any = localStorage.getItem("LTime");
        if (crtime) {
            const formattedDate = moment(crtime).format("YYYY-MM-DD");
            const currentTime = moment(crtime).format('HH:mm')
            const OrgStartTime: any = moment(`1970-01-01T${shiftStartTime}`)
            const CurrTime: any = moment(`1970-01-01T${currentTime}`)
            if (shiftStartTime !== CurrTime) {
                if (CurrTime.isAfter(OrgStartTime)) {
                    setSelectedDate(formattedDate);
                    setSelectedDate1(formattedDate)
                } else {
                    if (!formattedDate) return;
                    const validDate = moment(formattedDate);
                    if (validDate.isValid()) {
                        const nextDay = validDate.clone().subtract(1, "days");
                        setSelectedDate(moment(nextDay)?.format('YYYY-MM-DD'));
                        setSelectedDate1(moment(nextDay)?.format('YYYY-MM-DD'))
                    } else {
                        console.error("Invalid date:", formattedDate);
                    }
                }
            } else {
                setSelectedDate(formattedDate);
                setSelectedDate1(formattedDate)
            }
        }
    };
    useEffect(() => {
        handlestartTime();
    }, []);
    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value)
    }
    const handleStartTime = () => {
        const crtime: any = localStorage.getItem('LTime')
        const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm:ss ").toDate();
        setStartTime(formattedDate);
    }
    useEffect(() => {
        handleStartTime()
    }, [])
    useEffect(() => {
        if (selectedDate) {
            setLdate(moment(selectedDate).format('MM/DD/YYYY'))
        }
    }, [selectedDate])


    const triggerEffectInOtherTabs = () => {
        localStorage.setItem('ltrigger', Date.now().toString());
    };

    const handleConfirmModalClo = () => {
        setConfirmModal(!confirmModal)
        setConfirmMessage('')
    }
    const handleSubmit = async (alertValue: boolean) => {

        setLoading(true)
        const { header1 } = authorize();
        let newErrors = {
            deviceId: !deviceId,
            location: !location,
            startTime: !startTime,
            duration: !shiftDuration
        }
        setNameError(newErrors)
        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) {
            toast.error("Please Fill Required Field")
            setLoading(false)
            return;
        }
        const bodyData = {
            staffId: staff.id,
            deviceId: deviceId,
            organization: organization,
            date: moment(selectedDate).format('YYYYMMDD'),
            location: location,
            assignedBy: staff.id,
            alert: alertValue,
            singleAlert: alertValue,
            visitorName: '',
            startTime: moment(startTime).format("HH:mm"),
            duration: shiftDuration
        }
        try {
            const response = await axios.post(`${baseURL}/sosAlarm/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description);
                closeModal()
                triggerEffectInOtherTabs()
                getAllStaffLS(dispatch, formattedDate, organization)
                fetchSoSData(currentPage, selectedDate, searchTerm);
                setConfirmModal(false)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0128') {
                setConfirmMessage('The staff already has another beacon device. Do you want to remove and assign this device?')
                setConfirmModal(true)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0117') {
                setConfirmMessage('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                setConfirmModal(true)
                setLoading(false)
            } else {
                toast.error("Request failed: " + response.data.message.description);
                closeModal()
                setLoading(false)
            }
        } catch (error: any) {
            console.error("Error config:", error.config);
            setLoading(false)
        }
    };

    const handleQrClick = () => {
        setShow(true);
        setScanning(!scanning);
    }
    const closePopup = () => {
        dispatch(closeErrorPopup())
    }
    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getStaffsBeacon?organization=${organization}`, { headers: header1 })
                setOptions(response.data.data);
            } catch (error) {
                setOptions([]);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [slotRegister]);

    useEffect(() => {
        fetchLocationDropdown(dispatch, organization)
    }, [organization, dispatch]);

    const closeModalAndRec = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    }

    const handleClose = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    };

    const handleOpenModal = () => {
        setOpenModal(true)
        setDeviceId('')
        handlecheckSOSData(staff)

    }
    const closeModal = () => {
        setOpenModal(false)
        setLocation('')
        setDeviceId('')
        setShiftDuration('')
        setNameError({ ...nameError, location: false, duration: false, deviceId: false })

    }
    const handleDateChange = (event: any) => {
        setSelectedDate(event);
    };
    const handlecheckSOSData = (staff: any) => {
        const filterData1 = slotRegister?.find((item: any) => staff.id === item.staffId && item.deActivateTime === null)
        if (filterData1) {
            setDeviceId(filterData1?.deviceId)
            setLocation(filterData1?.location)
            setStartTime(filterData1?.startTime)
        }
    }

    const handleChange = (event: any, value: any) => {
        setLocation(value);
    };
    const handleActiveModalOpen = async (organization: any) => {
        if (!organization?.deActivateTime || organization?.deActivateTime === null || organization?.deActivateTime === undefined) {
            setActiveMessage(`Deactivate ${organization?.staffName !== null ? organization?.staffName : organization?.visitorName}-${organization?.deviceId}?`);
            setActiveModal(!activeModal);
            setActiveDetail(organization);
        }
    };
    const handleAvtiveModalClo = () => {
        setActiveModal(!activeModal)
        setActiveDetail('')
        setActiveMessage('')
    }
    const handleActivatedModal = async (staff: any) => {
        try {
            const idName = staff?.staffId ? staff?.staffId : staff?.visitorName;
            const response = await axios.post(`${baseURL}/sosAlarm/manualDeactivation?date=${moment(selectedDate).format('YYYYMMDD')}&duration=${staff?.duration}&organization=${staff?.organization}&staffId=${idName}&startTime=${staff?.startTime}`)
            if (response.data.message.code === "MHC - 0200") {
                toast.success(response.data.message.description)
                getAllStaffLS(dispatch, formattedDate, organization)
                handleAvtiveModalClo()
                fetchSoSData(currentPage, selectedDate, searchTerm);
                setDeviceId('')
                triggerEffectInOtherTabs()
            } else {
                toast.error(response.data.message.description)
            }
        } catch (error: any) {
            console.error("API Error :", error)
        }
    }
    return (
        <div className='' style={{ overflow: 'hidden', width: '99%', marginLeft: '5px', height: '91vh' }}>
            {loading && <Loader />}
            <div className="d-flex align-items-center mt-1" style={{ height: "6vh" }}>
                <h5>Staff SOS Assignment</h5>
            </div>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} dateRange={undefined} />
            </div>
            <div className="d-flex flex-row align-items-center " style={{ height: '8vh' }}>
                <div className="col-md-5 d-flex justify-content-between" style={{ fontWeight: '500' }}>
                    <p style={{ color: primarybg, fontWeight: 'bold', margin: 0 }}>Staff Name: {staff && staff?.name[0]?.given + " " + staff?.name[0].family}</p>
                    Date: {moment(selectedDate).format('MMM DD, YYYY')}
                </div>
                <div className="col-md-7 d-flex justify-content-end gap-3 mt-1 ">
                    <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: isButtonEnabled ? "pointer" : "no-drop", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                        <i style={{ fontSize: "34px", fontWeight: "lighter" }} onClick={() => {
                            if (isButtonEnabled) {
                                handleStartTime()
                                handleOpenModal()
                            }
                        }} className="material-icons">
                            add
                        </i>
                    </div>
                    <div className="mx-0 search-container d-flex align-items-center">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="search form-control"
                            onChange={handleSearch}
                        />
                        <FaSearch className="search-icon " />
                    </div>
                </div>
            </div>
            <div style={{ height: '63vh' }}>
                <Table className="table table-bordered" style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '13px', color: primarytext }} className="text-center table-data">S.No</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Device ID</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Activated Time</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Valid Till</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>De-Activated Time</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Assigned By</th>
                            <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Active Status</th>
                        </tr>
                    </thead>
                    <tbody >
                        {slotRegister?.length > 0 ? (
                            slotRegister?.slice()?.reverse().map((staff: any, index: any) => (
                                <tr key={index}>
                                    <td className="text-center">{currentPage * recordsPerPage + index + 1}</td>
                                    <td style={{ textAlign: "left" }}>{staff.staffName ? staff.staffName : staff.visitorName && staff.visitorName}</td>
                                    <td style={{ textAlign: "left" }}>{staff.deviceId}</td>
                                    <td style={{ textAlign: "left" }}>{staff.location}</td>
                                    <td style={{ textAlign: "left" }}>{staff.activateTime && moment(`${staff.activateTime.slice(0, 4)}-${staff.activateTime.slice(4, 6)}-${staff.activateTime.slice(6, 8)} ${staff.activateTime.slice(8, 10)}:${staff.activateTime.slice(10, 12)}:${staff.activateTime.slice(12, 14)}`).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                    <td style={{ textAlign: "left" }}>{staff.duration}</td>
                                    <td style={{ textAlign: "left" }}>{staff.deActivateTime && moment(`${staff.deActivateTime.slice(0, 4)}-${staff.deActivateTime.slice(4, 6)}-${staff.deActivateTime.slice(6, 8)} ${staff.deActivateTime.slice(8, 10)}:${staff.deActivateTime.slice(10, 12)}:${staff.deActivateTime.slice(12, 14)}`).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                    <td style={{ textAlign: "left" }}>{staff.assignedByName}</td>
                                    <td style={{ textAlign: "center" }}>
                                        <Tooltip
                                            title={staff?.deActivateTime === null ? "Active" : ""}
                                            arrow>
                                            <div className="text-primary">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        fontSize: "14px",
                                                        cursor: staff?.deActivateTime !== null ? 'default' : 'pointer',
                                                        color: staff?.deActivateTime !== null ? '#EF6868' : '#5FB477',
                                                        display: "inline-block",
                                                        textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                        if (staff?.deActivateTime === null) {
                                                            handleActiveModalOpen(staff);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>

                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9} className="text-center">There are no records...</td>
                            </tr>
                        )}

                    </tbody>
                </Table>
                <div className="pagination-container">
                    <div className="d-flex justify-content-center">
                        <Paginator
                            first={currentPage * recordsPerPage}
                            rows={recordsPerPage}
                            totalRecords={totalRecords}
                            onPageChange={(e: any) => {
                                setCurrentPage(e.page);
                            }}
                            currentPageReportTemplate={`Page ${currentPage + 1} of ${Math.ceil(totalRecords / recordsPerPage)}`}
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={openModal} className={`${confirmModal && 'z-2'}`} style={{ transition: `${confirmModal && 'width 2s'}`, top: '7%' }}>
                <ModalHeader closeButton>
                    <ModalTitle>{staff && staff?.name[0]?.given + " " + staff?.name[0].family}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className='col-md-12' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                                <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                                    <TextField
                                        value={deviceId}
                                        label={'Device Id'}
                                        onChange={(e) => {
                                            setDeviceId(e.target.value)
                                            setNameError({ ...nameError, deviceId: false })
                                        }}
                                        sx={{
                                            border: nameError.deviceId ? '1px solid red' : '',
                                            borderRadius: nameError.deviceId ? '5px' : 'none'
                                        }}
                                        style={{ minHeight: '50px', width: '96%', marginLeft: '12px', marginRight: '0px' }}
                                        InputProps={{

                                            endAdornment: (
                                                <FaQrcode
                                                    className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                                    role='button'
                                                    style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                                                />
                                            ),

                                        }}
                                        select
                                        SelectProps={{
                                            IconComponent: () => null,
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '300px',
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        {options?.length > 0 ? options?.map((option: any) => (
                                            <MenuItem key={option.id} value={option.deviceId}
                                            >
                                                {option.deviceId}
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        fontSize: "12px",
                                                        color: !option?.status ? '#5FB477' : '#EF6868',
                                                        display: "inline-block",
                                                        textAlign: "center",
                                                        marginLeft: '10px',
                                                        marginBottom: '1px'
                                                    }}
                                                />
                                            </MenuItem>
                                        )) :
                                            <MenuItem>
                                                No Device Data
                                            </MenuItem>
                                        }
                                    </TextField>
                                </ListItem>
                            </List>
                        </div >
                        <div className='col-md-12 d-flex flex-row justify-content-between gap-1 py-3' style={{ marginRight: '0px' }}>
                            <div className="col-md-6 " style={{}} >
                                <DatePicker
                                    value={ldate === "" ? null : dayjs(ldate)}  // Use dayjs instead of moment
                                    label={'Date'}
                                    format="MM-DD-YYYY"
                                    onChange={(e: any) => {
                                        setLdate(e.$d);
                                    }}
                                    className=""
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div className="col-md-6" >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div style={{ width: '100%' }}>
                                        <TimePicker
                                            label={<span>Start Time</span>}
                                            sx={{ border: "none", borderRadius: "4px" }}
                                            value={startTime}
                                            onChange={(newValue: Date | null) => {
                                                setStartTime(newValue);
                                            }}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className=" row " style={{}} >
                            <div className="col-md-6">
                                <Autocomplete
                                    id={'organizationDetails'}
                                    options={locationDropdown?.map((item: any) => item.value)}
                                    value={location}
                                    onChange={(e, v) => {
                                        handleChange(e, v)
                                        setNameError({ ...nameError, location: false })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Location'} variant="outlined"
                                        error={!!nameError.location}
                                        helperText={nameError.location ? 'Location is required' : ''}
                                    />}
                                />
                            </div>
                            <div className="col-md-6">
                                <Autocomplete
                                    id={'shiftDuration'}
                                    options={shiftDurationHour?.map((item: any) => item.value)}
                                    value={shiftDuration}
                                    onChange={(e: any, v) => {
                                        setShiftDuration(v)
                                        setNameError({ ...nameError, duration: false })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Duration'} variant="outlined"
                                        error={!!nameError.duration}
                                        helperText={nameError.duration ? 'Duration is required' : ''}
                                    />}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ gap: '4px' }}>
                    <Button label={'Close'} variant="secondary" style={{ backgroundColor: '#94a0b7', fontSize: '12px' }} onClick={closeModal} >
                    </Button>
                    <Button label={'Save Changes'} style={{ backgroundColor: '#0f3995', fontSize: '12px' }} onClick={() => handleSubmit(false)} />
                </ModalFooter>
            </Modal>
            <Modal isOpen={show} >
                <ModalHeader closeButton>
                    Scanning
                </ModalHeader>

                <ModalBody>
                    <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        label={'Close'}
                        variant="secondary"
                        onClick={handleClose}
                        style={{ marginRight: '10px' }}
                    />
                    <Button
                        label={scanning ? "Stop Scanning" : "Start Scanning"}
                        style={{ backgroundColor: '#0f3995' }}
                        onClick={closeModalAndRec}
                    />
                </ModalFooter>
            </Modal>
            <ErrorPopup
                closePopup={closePopup}
                errorMsg={errorMsg}
                open={isOpen}
            />
            <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
            <LogoutConfirmationModal open={confirmModal} handleConfirm={() => handleSubmit(true)} handleClose={() => handleConfirmModalClo()} message={confirmMessage} />
        </div>
    )
}
export default StaffSos;