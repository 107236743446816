import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface Q15DetailReportViewProps {
  reportData: any
  checkPrecaution: any
  locationLegend: any
  activityLegend: any
  checkBox: any
  legendcontrol: any
  InxNum: any
  oneHour: boolean
  logo: any
  timeFormat: boolean
}
const Q15DetailReportView: React.FC<Q15DetailReportViewProps> = ({ InxNum, activityLegend, checkBox, checkPrecaution, legendcontrol, locationLegend, logo, oneHour, reportData, timeFormat }) => {
  const [OrgName, setOrgName] = useState<any>('')
  const [reportDataFilterData, setReportDateFilter] = useState<any>([])
  const { orgData } = useSelector((state: any) => state.Org)
  const startTime = orgData?.shift?.startTime;
  const { precaution } = useSelector((state: any) => state.Dropdown);
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const formatChange = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    if (shiftTimeStart.isAfter(date2)) {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm") + " +1"
    } else {
      return moment(splitedvalue, ["h:mm A"]).format("HH:mm");
    }
  }

  const formatChange1 = (time12h: any) => {
    const splitedvalue = time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    const testFormat = moment(splitedvalue, ["h:mm A"]).format("HH:mm")
    const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
    const date2 = moment(`2025-01-08T${testFormat}`)
    const date1 = moment(`2025-01-08T00:00`)
    if (date2.isAfter(shiftTimeStart)) {
      return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
    }
    else {
      if (date2.isAfter(date1)) {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3] + " +1"
      } else {
        return time12h.split(" ")[0] + " " + time12h.split(" ")[3]
      }
    }
  }
  useEffect(() => {
    if (!reportData) return
    if (InxNum !== undefined) {
      const filterData = reportData?.filter((j: any, inx: number) => inx === InxNum)
      setReportDateFilter(filterData)
    } else {
      setReportDateFilter(reportData)
    }
  }, [reportData, InxNum])
  useEffect(() => {
    if (!orgData) return
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])
  return (
    <div>
      {reportDataFilterData !== null && reportDataFilterData !== undefined && Array.isArray(reportDataFilterData) && reportDataFilterData?.map((data: any, inx: number) => {
        return (
          <>
            <div className='container-fluid overflow-auto px-1' >
              {/* Sheet 1 */}
              <section style={{ height: 'auto' }} className={`mt-3 ${InxNum !== undefined ? `report-section${data.date}` : `report-section${data.id}`}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                {data && <>
                  <div id="removePadding" style={{ textAlign: 'center' }} className="orgLogo">
                    {logo && logo !== '' ? (
                      <img
                        src={logo}
                        alt="Org logo"
                        style={{ height: '60px', width: '60px' }}
                      />
                    ) : <div style={{ height: '60px', width: '60px' }}></div>}
                  </div>
                  <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                    {OrgName && OrgName}
                  </div>
                  <div id="removePadding" style={{}}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <div id="removePadding" >
                        <strong>Patient Name: </strong>
                        {data.patientName}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                        <div id="removePadding" >
                          <strong>Date: </strong>
                          {data.date && moment(data.date).format("MMM DD, YYYY")}
                        </div>
                      </div>
                    </div>
                    <div style={{ border: '1px solid #C9C9C9' }}>
                      <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                        <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                          <div>
                            CHECK PRECAUTIONS:
                          </div>
                          <div style={{ display: 'flex', gap: '3px' }}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              Every 15 Minutes
                            </label>
                            <div className="form-check" style={{ height: '15px' }}>
                              <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                            </div>
                          </div>
                          <div style={{ display: 'flex', gap: '3px' }}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              1:1
                            </label>
                            <div className="form-check" style={{ height: '15px' }}>
                              <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                            </div>
                          </div>
                          <div style={{ display: 'flex', gap: '3px' }}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              One Hour
                            </label>
                            <div className="form-check" style={{ height: '15px' }}>
                              <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One-Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                            </div>
                          </div>
                          <div style={{ display: 'flex', gap: '3px' }}>
                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                              Eyesight
                            </label>
                            <div className="form-check" style={{ height: '15px' }}>
                              <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: '0px 10px',
                          }}
                        >
                          {precaution?.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                            <div
                              className="form-check"
                              style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                              key={index}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                disabled
                                checked={data?.precaution?.includes(item.value) || false}
                                style={{ padding: '7px', border: '1.2px solid black' }}
                              />
                              <label
                                className=""
                                style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                              >
                                {item.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                        <div>LOCATION CODES</div>
                      </div>
                      <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                          {locationLegend && locationLegend?.map((item: any, inx: number) => (
                            <div className="" style={{ height: '20px', width: '25%' }} key={inx}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                {item.key}{" - " + item.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                        <div>ACTIVITY CODES</div>
                      </div>
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>

                          {activityLegend && activityLegend?.map((item: any, inx: number) => (
                            <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                {inx + 1}.{" " + item.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {
                      !oneHour ?
                        <>
                          <div className='' style={{ fontSize: '14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                              <div style={{ width: '32.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                            <div style={{ width: '100%', fontSize: '14px' }}>
                              {data && data.data?.map((t: any, ind: number) => {
                                return ind >= 0 && ind <= 31 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                    <div style={{ width: '32%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                      {!timeFormat
                                        ? formatChange1(t?.time)
                                        : formatChange(t.time)
                                      }
                                      {/* {t.time.split(' ')[0]}{" " + t.time.split(' ')[3]} */}
                                    </div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                    <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staffName && t.staffName}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className='' style={{ fontSize: '14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                              <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                            <div style={{ width: '100%', fontSize: '14px' }}>
                              {data && data.data?.map((t: any, ind: number) => {
                                return ind >= 0 && ind <= 31 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                      {!timeFormat
                                        ? formatChange1(t?.time)
                                        : formatChange(t.time)
                                      }
                                      {/* {t.time.split(' ')[0]}{" " + t.time.split(' ')[3]} */}
                                    </div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.enteredBy}</div>
                                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                          </div>
                        </>
                    }

                    <div style={{ fontSize: '14px', marginTop: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                      </div>
                      {
                        data?.shiftIncharge !== null ?
                          <>
                            {[1, 2, 3, 4].map((t: any, indx: number) => (
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>
                                  {t === 1 && !oneHour ? (data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65)}`] ?? "") : oneHour && (data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(65 + indx)}`] ?? "")}
                                </div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              </div>
                            ))}
                          </>
                          :
                          <>
                            {[1, 2, 3, 4].map((t: any, indx: number) => (
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                              </div>
                            ))}
                          </>
                      }
                    </div>
                    <div style={{ fontSize: '14px', marginTop: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                        <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                        <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                        <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                      </div>
                    </div>

                  </div>
                </>}
              </section>
              {/* Sheet 2 */}
              {
                !oneHour && <>
                  <section style={{ height: 'auto' }} className={`mt-3 ${InxNum !== undefined ? `report-section${data.date}` : `report-section${data.id}`}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                    {data && <>
                      <div id="removePadding" style={{ textAlign: 'center' }} className="orgLogo">
                        {logo && logo !== '' ? (
                          <img
                            src={logo}
                            alt="Org logo"
                            style={{ height: '60px', width: '60px' }}
                          />
                        ) : <div style={{ height: '60px', width: '60px' }}></div>}
                      </div>
                      <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                        {OrgName && OrgName}
                      </div>
                      <div id="removePadding" style={{}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <div id="removePadding" >
                            <strong>Patient Name: </strong>
                            {data.patientName}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                            <div id="removePadding" >
                              <strong>Date: </strong>
                              {data.date && moment(data.date).format("MMM DD, YYYY")}
                            </div>
                          </div>
                        </div>
                        <div style={{ border: '1px solid #C9C9C9' }}>
                          <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                              <div>
                                CHECK PRECAUTIONS:
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  Every 15 Minutes
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  1:1
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  One Hour
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One-Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  Eyesight
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: '100%',
                                padding: '0px 10px',
                              }}
                            >
                              {precaution?.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                                <div
                                  className="form-check"
                                  style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                                  key={index}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled
                                    checked={data?.precaution?.includes(item.value) || false}
                                    style={{ padding: '7px', border: '1.2px solid black' }}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                                  >
                                    {item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                            <div>LOCATION CODES</div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                              {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                                  <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                    {item.key}{" - " + item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                            <div>ACTIVITY CODES</div>
                          </div>
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>

                              {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                                  <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                    {inx + 1}.{" " + item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <>
                          <div className='' style={{ fontSize: '14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                              <div style={{ width: '32.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                            <div style={{ width: '100%', fontSize: '14px' }}>
                              {data && data.data?.map((t: any, ind: number) => {
                                return ind >= 32 && ind <= 63 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                    <div style={{ width: '32%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                      {!timeFormat
                                        ? formatChange1(t?.time)
                                        : formatChange(t.time)
                                      }
                                      {/* {t.time.split(' ')[0]}{" " + t.time.split(' ')[3]} */}
                                    </div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                    <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staffName && t.staffName}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                          </div>
                        </>

                        <div style={{ fontSize: '14px', marginTop: '8px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                          </div>
                          {
                            data?.shiftIncharge !== null ?
                              <>
                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>{t === 1 && (data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(66)}`] ?? "")}</div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  </div>
                                ))}
                              </>
                              :
                              <>
                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  </div>
                                ))}
                              </>
                          }
                        </div>
                        <div style={{ fontSize: '14px', marginTop: '8px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                          </div>
                        </div>

                      </div>
                    </>}
                  </section>
                  {/* Sheet 3 */}
                  <section style={{ height: 'auto' }} className={`mt-3 ${InxNum !== undefined ? `report-section${data.date}` : `report-section${data.id}`}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                    {data && <>
                      <div id="removePadding" style={{ textAlign: 'center' }} className="orgLogo">
                        {logo && logo !== '' ? (
                          <img
                            src={logo}
                            alt="Org logo"
                            style={{ height: '60px', width: '60px' }}
                          />
                        ) : <div style={{ height: '60px', width: '60px' }}></div>}
                      </div>
                      <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                        {OrgName && OrgName}
                      </div>
                      <div id="removePadding" style={{}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <div id="removePadding" >
                            <strong>Patient Name: </strong>
                            {data.patientName}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                            <div id="removePadding" >
                              <strong>Date: </strong>
                              {data.date && moment(data.date).format("MMM DD, YYYY")}
                            </div>
                          </div>
                        </div>
                        <div style={{ border: '1px solid #C9C9C9' }}>
                          <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                              <div>
                                CHECK PRECAUTIONS:
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  Every 15 Minutes
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Q15"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  1:1
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "1:1"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  One Hour
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "One-Hour"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '3px' }}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  Eyesight
                                </label>
                                <div className="form-check" style={{ height: '15px' }}>
                                  <input className="form-check-input" type="checkbox" disabled checked={checkPrecaution === "Eyesight"} style={{ padding: '7px', border: '1.2px solid black' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div key={inx} style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: '100%',
                                padding: '0px 10px',
                              }}
                            >
                              {precaution?.length > 0 && precaution !== null && precaution !== undefined && precaution.map((item: any, index: number) => (
                                <div
                                  className="form-check"
                                  style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}
                                  key={index}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled
                                    checked={data?.precaution?.includes(item.value) || false}
                                    style={{ padding: '7px', border: '1.2px solid black' }}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }}
                                  >
                                    {item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                            <div>LOCATION CODES</div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                              {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                <div className="" style={{ height: '25px', width: '25%' }} key={inx}>
                                  <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                    {item.key}{" - " + item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                            <div>ACTIVITY CODES</div>
                          </div>
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>

                              {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                <div className=" " style={{ height: '30px', width: '25%' }} key={inx}>
                                  <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black', lineHeight: '1rem' }} >
                                    {inx + 1}.{" " + item.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <>
                          <div className='' style={{ fontSize: '14px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                              <div style={{ width: '32.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                              <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                              <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                              <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                              <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Register Time</div>
                            </div>
                          </div>
                          <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                            <div style={{ width: '100%', fontSize: '14px' }}>
                              {data && data.data?.map((t: any, ind: number) => {
                                return ind >= 64 && ind <= 96 && (<>
                                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                    <div style={{ width: '32%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>
                                      {!timeFormat
                                        ? formatChange1(t?.time)
                                        : formatChange(t.time)
                                      }
                                      {/* {t.time.split(' ')[0]}{" " + t.time.split(' ')[3]} */}
                                    </div>
                                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                    <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staffName && t.staffName}</div>
                                    <div style={{ width: '37%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.registerTime && (!timeFormat ? moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm a") : moment(t.registerTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm"))}</div>
                                  </div>
                                </>);
                              })}
                            </div>
                          </div>
                        </>
                        <div style={{ fontSize: '14px', marginTop: '8px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                          </div>
                          {
                            data?.shiftIncharge !== null ?
                              <>
                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}>{data?.shiftIncharge?.[`shiftInchargeName${String.fromCharCode(67 + indx)}`] ?? ""}</div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  </div>
                                ))}
                              </>
                              :
                              <>
                                {[1, 2, 3, 4].map((t: any, indx: number) => (
                                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                  </div>
                                ))}
                              </>
                          }
                        </div>
                        <div style={{ fontSize: '14px', marginTop: '8px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                          </div>
                        </div>

                      </div>
                    </>}
                  </section>
                </>
              }
            </div>
          </>
        )
      })}
    </div>
  )
}

export default Q15DetailReportView