import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from 'react-redux';
import { Autocomplete, TextField, Tooltip, Menu } from "@mui/material";
import { Button } from "primereact/button";
import { authorize } from '../../helpers/common';
import { baseURL, successCode } from '../../configuration/url';
import { secondarybt } from '../../common/primary';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatTimeTo12Hour, formatTimeTo12Hour1 } from '../../helpers/common';

interface SlotRegister {
    roomRange: string[];
    deviceId: string;
    staff: string;
    startRoomNo: string;
    endRoomNo: string;
    all: boolean;
    location: string;
    deviceCheck: string
}
interface Child {
    roomRange: string[];
    deviceId: string;
    staff: string;
    startRoomNo: string;
    endRoomNo: string;
    all: boolean;
    location: string;
    deviceCheck: string;
    transferTime: string;
    transferStaffTime: string;
}

interface Parent {
    schedule: Child[];
}
const ShiftView = ({
    selectedTab,
    selectedDate,
    selectedDate1,
    noOfShift,
    rnInchargeList,
    incharge,
    setIncharge,
    socialWorkerList,
    setLoading,
    renderShiftTab,
    previousCheck,
    setPreviousCheck
}: any) => {
    const { shiftStartTime, orgData } = useSelector((state: any) => state.Org);
    const { organization } = useSelector((state: any) => state.Login)
    const [shifts, setShifts] = useState<any>([]);
    const [noOfSlot, setNoOfSlot] = useState<any>()
    const [slotTime, setSlotTime] = useState<any>()
    const [nextDate, setNextDate] = useState<any>('')
    const [selectslotDate, setSelectSlotDate] = useState<any>([])
    const [slotRegister1, setSlotRegister1] = useState<Parent[]>([{
        schedule: [
            {
                roomRange: [],
                deviceId: "",
                staff: "",
                startRoomNo: "",
                endRoomNo: "",
                all: false,
                location: "",
                deviceCheck: "",
                transferTime: "",
                transferStaffTime: ""
            }
        ]
    }
    ])

    const handleSubmit = async () => {
        const { header1 } = authorize();
        if (!incharge) {
            return toast.error('Incharge field required')
        }
        let count = 0
        let dataFilter
        if (slotRegister1.length > 0 && incharge) {
            if (slotRegister1.length > 0 && incharge) {
                for (let d of slotRegister1) {
                    dataFilter = d.schedule.filter((k: any) => k.staff !== '')
                    if (dataFilter.length > 0) {
                        for (let i = 0; i < dataFilter.length; i++) {
                            if (!dataFilter[i].all) {
                                if (dataFilter[i].startRoomNo === "" && dataFilter[i].endRoomNo === "") {
                                    return toast.error('StartRoomNo and EndRoomNo is Empty')
                                }
                            }
                        }
                    } else {
                        count++
                    }
                }
            }
            if (noOfSlot === count) {
                return toast.error('Select Rounding Staff is Empty')
            }
        }
        try {
            setLoading(true)
            const updateDate = slotRegister1?.map((data: any, ind: number) => {
                data.schedule = data?.schedule?.map((item: any, inx: number) => {
                    item.startRoomNo = item.startRoomNo.split(" ")[2] === "A"
                        ? `${item.startRoomNo?.split(" ")[0]} - 01`
                        : item.startRoomNo?.split(" ")[2] === "B"
                            ? `${item.startRoomNo?.split(" ")[0]} - 02`
                            : item.startRoomNo?.split(" ")[2] === "C"
                                ? `${item.startRoomNo?.split(" ")[0]} - 03`
                                : item.startRoomNo;

                    item.endRoomNo = item.endRoomNo.split(" ")[2] === "A"
                        ? `${item.endRoomNo?.split(" ")[0]} - 01`
                        : item.endRoomNo.split(" ")[2] === "B"
                            ? `${item.endRoomNo?.split(" ")[0]} - 02`
                            : item.endRoomNo?.split(" ")[2] === "C"
                                ? `${item.endRoomNo?.split(" ")[0]} - 03`
                                : item.endRoomNo;
                    return item;
                });
                return data;
            });
            let Bodydata = {
                organization,
                date: moment(selectedDate).format('YYYYMMDD'),
                shift: [
                    {
                        shiftName: renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent(),
                        rnIncharge: incharge,
                        startTime: shifts[selectedTab]?.start_time,
                        endTime: shifts[selectedTab]?.end_time,
                        schedule: updateDate.length > 0 && updateDate.map((data: any, inx: number) => {
                            return {
                                time: slotTime[inx]?.split('-')[0]?.trim() + '-' + slotTime[inx]?.split('-')[1]?.trim(),
                                bedStaff: data.schedule
                            }
                        })
                    }
                ]
            }
            const response = await axios.post(`${baseURL}/PSConfig/register`, Bodydata, { headers: header1 });
            if (response.data.message.code === successCode) {
                setLoading(false)
                toast.success(response.data.message.description)
                handleGetData()
            } else {
                toast.error(response.data.message.description);
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            console.error("API ERROR:", error)
        }
    }

    const handleGetData = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${baseURL}/PSConfig/getByDateAndOrganization/${moment(selectedDate).format('YYYYMMDD')}/${organization}`)
            if (response.data.message.code === successCode) {
                setLoading(false)
                const ShiftName = renderTabContent()
                const tabShiftName = previousCheck ? renderShiftTab() : ShiftName
                let newData = response.data.data.configList.shift.length > 0 ? response.data.data.configList.shift.filter((l: any) => (l.shiftName === renderTabContent()))?.map((k: any) => { return k }) : []
                setIncharge(newData[0]?.rnIncharge);
                if (newData[0]?.schedule?.[0]?.bedStaff !== null && newData[0]?.schedule?.[0]?.bedStaff !== undefined) {
                    setSlotRegister1((prev) => {
                        const updatedData = [...prev];

                        while (updatedData.length < newData[0].schedule.length) {
                            updatedData.push({ schedule: [] });
                        }

                        newData[0].schedule.forEach((data: any, inx: number) => {
                            updatedData[inx].schedule = data.bedStaff.map((item: any, inx: number) => {
                                item.startRoomNo = item.startRoomNo.split(" ")[2] === "01"
                                    ? `${item.startRoomNo?.split(" ")[0]} - A`
                                    : item.startRoomNo?.split(" ")[2] === "02"
                                        ? `${item.startRoomNo?.split(" ")[0]} - B`
                                        : item.startRoomNo?.split(" ")[2] === "03"
                                            ? `${item.startRoomNo?.split(" ")[0]} - C`
                                            : item.startRoomNo;

                                item.endRoomNo = item.endRoomNo.split(" ")[2] === "01"
                                    ? `${item.endRoomNo?.split(" ")[0]} - A`
                                    : item.endRoomNo.split(" ")[2] === "02"
                                        ? `${item.endRoomNo?.split(" ")[0]} - B`
                                        : item.endRoomNo?.split(" ")[2] === "03"
                                            ? `${item.endRoomNo?.split(" ")[0]} - C`
                                            : item.endRoomNo;
                                return item;
                            });
                        });
                        return updatedData;
                    });
                    setPreviousCheck(false)
                }
            } else {
                setLoading(false)
                let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
                const addSlots = Array.from({ length: numSlot }).map((k: any, inx: number) => {
                    return {
                        schedule: [
                            {
                                roomRange: [],
                                deviceId: "",
                                staff: "",
                                startRoomNo: "",
                                endRoomNo: "",
                                all: false,
                                location: "",
                                deviceCheck: "",
                                transferTime: "",
                                transferStaffTime: ""
                            }
                        ]
                    }
                })
                setSlotRegister1(addSlots)
                setPreviousCheck(false)
            }
            setLoading(false)
            setPreviousCheck(false)
        } catch (error: any) {
            setLoading(false)
            console.error('API ERROR:', error)
            setPreviousCheck(false)
        }
    }
    const handleCancel = () => {
        setIncharge('');
        let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
        const addSlots = Array.from({ length: numSlot }).map((k: any, inx: number) => {
            return {
                schedule: [
                    {
                        roomRange: [],
                        deviceId: "",
                        staff: "",
                        startRoomNo: "",
                        endRoomNo: "",
                        all: false,
                        location: "",
                        deviceCheck: "",
                        transferTime: "",
                        transferStaffTime: ""
                    }
                ]
            }
        })
        setSlotRegister1(addSlots)
    }
    useEffect(() => {
        handleGetData()
    }, [selectedTab, selectedDate])
    useEffect(() => {
        if (slotRegister1.length < noOfSlot) {
            const additionalSlots = Array.from(
                { length: noOfSlot - slotRegister1.length },
                () => ({
                    schedule: [
                        {
                            roomRange: [],
                            deviceId: "",
                            staff: "",
                            startRoomNo: "",
                            endRoomNo: "",
                            all: false,
                            location: "",
                            deviceCheck: "",
                            transferTime: "",
                            transferStaffTime: ""
                        },
                    ],
                })
            );
            setSlotRegister1((prev) => [...prev, ...additionalSlots]);
        }
    }, [noOfSlot]);
    const [roomNumbers, setRoomNumbers] = useState(new Array<any>());
    useEffect(() => {
        const { header1 } = authorize();
        setLoading(true)
        fetch(`${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 })
            .then(response => response.json())
            .then(data => {
                const roomBedCombinations = data.data?.map((item: any) => {
                    let bedNo = item.bedNo;
                    if (bedNo === '01') {
                        bedNo = 'A';
                    } else if (bedNo === '02') {
                        bedNo = 'B';
                    } else if (bedNo === '03') {
                        bedNo = 'C';
                    }
                    setLoading(false)
                    return `${item.roomNo} - ${bedNo}`;
                });
                setLoading(false)
                setRoomNumbers(roomBedCombinations);
            })
            .catch((error) => {
                console.error('Error fetching room numbers:', error)
                setLoading(false)
            });
    }, []);

    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return "Shift-A";
            case 1:
                return "Shift-B";
            case 2:
                return "Shift-C";
            case 3:
                return "Shift-D";
            case 4:
                return "Shift-E";
            case 5:
                return "Shift-F";
            case 6:
                return "General";
            case 7:
                return "Previous"
            default:
                return "No Content Available";
        }
    };

    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        for (let i = 0; i < totalShifts; i++) {
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            );
            const startTimeFormatted = startTime.toTimeString().slice(0, 5);
            const endTimeFormatted = endTime.toTimeString().slice(0, 5);
            const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
            });
            startTime = endTime;
        }
        setShifts(shifts);
    };
    const handelcompare = (str1: any, str2: any) => {
        const shiftTimestart = moment(`2025-01-08T${shiftStartTime}`)
        const date1 = moment(`2025-01-08T${str1}`)
        const date2 = moment(`2025-01-08T${str2}`)
        if (str1 !== shiftStartTime) {
            if (date1.isAfter(shiftTimestart)) {
                if (date2.isBefore(date1)) {
                    if (str2 === "00:00") {
                        return 2
                    }
                    return 3;
                } else {
                    return 2;
                }
            } else {
                return 1;
            }
        } else {
            return 2
        }
    }

    const handleAutocompleteChange = (event: any, value: any, index1: any, index2: any) => {
        if (value) {
            let data = [...slotRegister1];
            data[index1].schedule[index2].staff = value.staffId;
            setSlotRegister1(data);
        } else {
            let data = [...slotRegister1];
            data[index1].schedule[index2].staff = '';
            setSlotRegister1(data);
        }
    };
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, index1: number, index2: number, register: any, setRegister: any) => {
        let data = [...register];
        data[index1].schedule[index2] = {
            ...data[index1].schedule[index2],
            all: e.target.checked
        };
        setRegister(data);
    };

    const getNumberofSlot = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return duration / orgData?.slotDuration;
    }
    function calculateDateRange(startTime: string, endTime: string) {
        // Get the current date
        const currentDate = new Date(selectedDate);
        const currentDateString = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

        // Parse start and end times into Date objects
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        const [endHours, endMinutes] = endTime.split(":").map(Number);

        const startDateTime = new Date(currentDate);
        startDateTime.setHours(startHours, startMinutes, 0, 0);

        const endDateTime = new Date(currentDate);
        endDateTime.setHours(endHours, endMinutes, 0, 0);

        // Check if end time is earlier than start time (indicates crossing midnight)
        if (endDateTime <= startDateTime) {
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + 1); // Move to next day
            const nextDateString = nextDate.toISOString().split("T")[0];
            return { dates: [currentDateString, nextDateString] }; // Return both dates
        } else {
            return { dates: [currentDateString] }; // Return current date only
        }
    }
    const getSlotTime = () => {
        if (!shifts) return
        const slotStartTimes = renderTabContent() === "Previous" ? shifts[noOfShift - 1]?.start_time : shifts[selectedTab]?.start_time
        const slotEndTimes = renderTabContent() === "Previous" ? shifts[noOfShift - 1]?.start_time : shifts[selectedTab]?.start_time
        const date1 = new Date(`2000-01-01T${slotStartTimes}`);
        const date2 = new Date(`2000-01-01T${slotEndTimes}`);
        let slot;
        let slotTime = [];
        let slotDate = [];

        let startTime = new Date(`1970-01-01T${slotStartTimes}:00`);

        for (let i = 0; i < noOfSlot; i++) {
            let endTime = new Date(
                startTime.getTime() + orgData?.slotDuration * 60 * 60 * 1000
            );

            const startTimeFormatted = startTime.toTimeString().slice(0, 5);
            const endTimeFormatted = endTime.toTimeString().slice(0, 5);
            const start = moment(`2025-01-08T${shiftStartTime}`)
            const date1 = moment(`2025-01-08T${startTimeFormatted}`)
            const date2 = moment(`2025-01-08T${endTimeFormatted}`)
            if (startTimeFormatted !== shiftStartTime) {
                if (date1.isAfter(start)) {
                    if (date2.isBefore(date1)) {
                        slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY') + ' - ' + nextDate}`)
                    } else {
                        slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY')}`)
                    }
                } else {
                    slotDate.push(`${nextDate}`)
                }
            } else {
                slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY')}`)
            }

            slotTime.push(`${startTimeFormatted} - ${endTimeFormatted}`)
            startTime = endTime
        }
        setSlotTime(slotTime);
        setSelectSlotDate(slotDate)
    }

    useEffect(() => {
        if (shifts) {
            getSlotTime()
        }
    }, [selectedTab, shifts])
    useEffect(() => {
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
        let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
        setNoOfSlot(numSlot);
    }, [orgData])

    const handleFieldAdd1 = (inx: any) => {
        setSlotRegister1((prev) => {
            const updatedData = [...prev];
            if (updatedData[inx]) {
                updatedData[inx].schedule = [
                    ...(updatedData[inx].schedule || []),
                    {
                        roomRange: [],
                        deviceId: "",
                        staff: "",
                        startRoomNo: "",
                        endRoomNo: "",
                        all: false,
                        location: "",
                        deviceCheck: "",
                        transferTime: "",
                        transferStaffTime: ""
                    },
                ];
            }
            return updatedData;
        });
    };
    const handleFieldRemove1 = (parentIndex: number, childIndex: number) => {
        setSlotRegister1((prev) => {
            const updatedData = [...prev];
            if (updatedData[parentIndex] && updatedData[parentIndex].schedule) {
                updatedData[parentIndex].schedule = updatedData[parentIndex].schedule.filter(
                    (_, idx) => idx !== childIndex
                );
            }
            return updatedData;
        });
    };
    const selectedDateObj = moment(selectedDate1);
    const select1 = moment(selectedDate);

    const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');

    useEffect(() => {
        if (!selectedDate) return;
        const validDate = moment(selectedDate);
        if (validDate.isValid()) {
            const nextDay = validDate.clone().add(1, 'days');
            setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
        } else {
            console.error("Invalid date:", selectedDate);
        }
    }, [selectedDate]);

    const checkDate = (str1: any, str2: any) => {
        const shiftTimeStart = moment(`2025-01-08T${shiftStartTime}`)
        const date1 = moment(`2025-01-08T${str1}`)
        const date2 = moment(`2025-01-08T${str2}`)
        const zero = moment(`2025-01-08T00:00`)
        if (str2 !== shiftStartTime) {
            if (date1.isAfter(shiftTimeStart)) {

                if (date2.isAfter(shiftTimeStart)) {
                    return 1
                    // return `${str1} - ${str2}`
                } else if (date2.isBefore(date1)) {
                    return 2
                    // return `${str1} - ${str2}`
                } else {
                    return 3
                    // return `${str1} - ${str2}`
                }

            } else if ((date1.isSame(shiftTimeStart))) {
                return 11
                // return `${str1} - ${str2}`
            }
        } else {
            return 4
            // return `${str1} - ${str2}`
        }
    }

    return (
        <div style={{
            height: '70vh',
            // border: '1px solid red'
        }}>
            <div style={{ backgroundColor: "#f2f5f9", height: '70vh' }} >
                <div style={{
                    textAlign: "start",
                    borderBottom: "1px groove #ccd2d8",
                    height: "6vh",
                    lineHeight: 1.5,
                    padding: "0px 0px 0px 10px",
                    width: '100%',
                    margin: '0px 0px'
                }}
                >
                    <span style={{ fontSize: "20px", fontWeight: 600 }}>
                        {/* {moment(selectedDate)?.format('ddd DD')}  */}
                        {moment(selectedDate).format("MMM DD, YYYY")} {renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()} Configuration
                    </span>
                </div>
                <div style={{ height: '55vh', width: '100%', borderBottom: "1px groove #ccd2d8" }} className="overflow-y-scroll overflow-x-hidden" role="tabpanel" aria-labelledby={`${renderTabContent() === "Previous" ? renderShiftTab() : renderTabContent()}-tab`}>
                    <div>
                        <div className="row flex-row" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                            <div className="col-sm-2">
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="floatingStartTime">Start Time</InputLabel>
                                    <Input
                                        id="floatingStartTime"
                                        style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                                        type="text"
                                        value={renderTabContent() === "Previous" ? formatTimeTo12Hour1(shifts[noOfShift - 1]?.start_time) : formatTimeTo12Hour1(shifts[selectedTab]?.start_time)}
                                        disabled
                                        placeholder='Start Time'
                                        inputProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-sm-2">
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="floatingEndTime">End Time</InputLabel>
                                    <Input
                                        id="floatingEndTime"
                                        style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                                        type="text"
                                        value={renderTabContent() === "Previous" ? formatTimeTo12Hour1(shifts[noOfShift - 1]?.end_time) : formatTimeTo12Hour1(shifts[selectedTab]?.end_time)}
                                        disabled
                                        placeholder='End Time'
                                        inputProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-sm-3 form-floating p-1">
                                <Autocomplete
                                    style={{ height: "65px", backgroundColor: "#f2f5f9", paddingRight: '8px' }}
                                    fullWidth
                                    id="floatingSelect"
                                    onChange={(e, value) => setIncharge(value?.id || '')}
                                    value={rnInchargeList.find((item: any) => item.id === incharge) || null}
                                    options={rnInchargeList}
                                    getOptionLabel={(option) => option.name[0]?.given + ' ' + option.name[0]?.family}
                                    renderInput={(params) => <TextField {...params} label="Select RN Incharge" variant="standard" />}
                                />
                            </div>
                        </div>
                        {
                            Array.from({ length: noOfSlot }).map((_, inx: number) => (
                                <div>{
                                    (slotRegister1[inx]?.schedule?.map((addField, index) => (
                                        <div key={inx} >
                                            <div style={{ display: "flex", paddingLeft: "134px", gap: "10px", flexDirection: "row", justifyContent: "space-between", width: "85%" }} >
                                                {index === 0 ? <>
                                                    <div style={{ width: '100%', }} className="d-flex">
                                                        <div className="col-md-2" style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }}>Slot {inx + 1} ---</div>
                                                        <input type="text" className="" style={{ fontSize: '15px', borderStyle: "none", width: '45%' }} value={formatTimeTo12Hour(slotTime[inx]?.split('-')[0]?.trim()) + ' - ' + formatTimeTo12Hour(slotTime[inx]?.split('-')[1]?.trim())} id="floatingEndTime" disabled placeholder='end time' />
                                                        <input type="text" className="" style={{ fontSize: '15px', borderStyle: "none", width: '80%' }} value={selectslotDate[inx]} id="floatingEndTime" disabled placeholder='end time' />
                                                    </div>
                                                </>
                                                    :
                                                    (slotRegister1[inx]?.schedule[index]?.transferStaffTime || slotRegister1[inx]?.schedule[index]?.transferTime) &&
                                                    <>
                                                        <div style={{ width: '100%', }} className="d-flex">
                                                            <div className="col-md-2" style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }}></div>
                                                            <input type="text" className="" style={{ fontSize: '15px', borderStyle: "none", width: '45%' }}
                                                                value={
                                                                    slotRegister1[inx]?.schedule[index + 1] 
                                                                        ? formatTimeTo12Hour(addField?.transferStaffTime?.split('-')[0]?.trim()) + ' - ' + formatTimeTo12Hour(addField?.transferStaffTime?.split('-')[1]?.trim())
                                                                        : formatTimeTo12Hour(addField?.transferTime?.split('-')[0]?.trim()) + ' - ' + formatTimeTo12Hour(addField?.transferTime?.split('-')[1]?.trim())
                                                                }
                                                                id="floatingEndTime"
                                                                disabled
                                                                placeholder='end time'
                                                            />
                                                            <input type="text" className="" style={{ fontSize: '15px', borderStyle: "none", width: '80%' }} value={selectslotDate[inx]} id="floatingEndTime" disabled placeholder='end time' />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="mb-3 col-md-3 form-floating p-1" style={{ display: 'flex', width: "100%", justifyContent: "center", gap: "10px" }}>
                                                <div className="mb-3 col-md-3">
                                                    <Autocomplete
                                                        fullWidth
                                                        // id="floatingSelect"
                                                        options={socialWorkerList}
                                                        disabled={!isButtonEnabled}
                                                        getOptionLabel={(option) => option?.staffName}
                                                        onChange={(event, value) => handleAutocompleteChange(event, value, inx, index)}
                                                        value={socialWorkerList?.find((item: any) => item?.staffId === addField.staff) || null}
                                                        renderInput={(params) => <TextField {...params} label="Rounding Staff" variant="standard" />}
                                                    />
                                                </div>
                                                <div>
                                                    All Rooms
                                                    <input type="checkbox" id="checkbox" name="checkbox" checked={addField.all} disabled={!isButtonEnabled}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e, inx, index, slotRegister1, setSlotRegister1)}
                                                    />
                                                </div>
                                                <div className="form-floating mb-3 col-md-2 p-1">
                                                    <select className="form-select" id="floatingSelect" disabled={addField.all || !isButtonEnabled} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                        let data = [...slotRegister1];
                                                        data[inx].schedule[index].startRoomNo = e.target.value;
                                                        setSlotRegister1(data)
                                                    }} value={addField.startRoomNo}>
                                                        <option value="">-select-</option>
                                                        {
                                                            roomNumbers?.map((item: any) => {
                                                                return (
                                                                    <option value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Start Room</label>
                                                </div>
                                                <div className="form-floating mb-3 col-md-2 p-1">
                                                    <select className="form-select" id="floatingSelect" disabled={addField.all || !isButtonEnabled} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                        let data = [...slotRegister1];
                                                        data[inx].schedule[index].endRoomNo = e.target.value;
                                                        setSlotRegister1(data)
                                                    }} value={addField.endRoomNo}>
                                                        <option value="">-select-</option>
                                                        {
                                                            roomNumbers?.map((item: any) => {
                                                                return (
                                                                    <option value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>End Room</label>
                                                </div>
                                                <div className="mb-2 col-md-2 p-0">
                                                    {/* <select
                                                                                        className="form-select"
                                                                                        id="floatingSelect"
                                                                                        style={{
                                                                                            fontSize: '12px',
                                                                                            width: '100%',
                                                                                            backgroundImage: 'none',
                                                                                            paddingTop: '8px'
                                                                                        }}
                                                                                        onChange={(e: any) => {
                                                                                            let data = [...slotRegister1];
                                                                                            data[index].deviceId = e.target.value;
                                                                                            setSlotRegister1(data);
                                                                                        }}
                                                                                        value={addField.deviceId}
                                                                                        disabled // Disable if a device ID is selected
                                                                                    >
                                                                                        <option value="">-select-</option>
                                                                                        {options?.map((item: any) => (
                                                                                            <option key={item.id} value={item.deviceId}>
                                                                                                {item.deviceId}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select> */}
                                                    <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br />{addField?.deviceId ? addField?.deviceId : 'N/A'}</div>
                                                    <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br />{addField?.location ? addField?.location : 'N/A'}</div>
                                                </div>
                                                <div style={{ position: 'relative', left: '5px', top: "4px" }} role='button'>{slotRegister1?.map((addField, index) => (
                                                    slotRegister1[inx]?.schedule?.length - 1 === index &&
                                                    <div>
                                                        <i style={{ backgroundColor: secondarybt, borderRadius: "4px", cursor: isButtonEnabled ? 'pointer' : 'not-allowed' }} onClick={isButtonEnabled ? () => handleFieldAdd1(inx) : () => { }} className="material-icons">add</i>
                                                    </div>

                                                ))}{slotRegister1[inx]?.schedule?.length !== 1 && (
                                                    <div>
                                                        <i style={{ backgroundColor: secondarybt, borderRadius: "4px", cursor: isButtonEnabled ? 'pointer' : 'not-allowed' }} onClick={isButtonEnabled ? () => handleFieldRemove1(inx, index) : () => { }} className="material-icons">remove</i>
                                                    </div>
                                                )}
                                                </div>
                                            </div>


                                        </div>

                                    )))}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    paddingRight: "20px",
                }}
                >
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold', borderStyle: "none" }}
                        onClick={handleCancel}
                    ></Button>
                    <Button disabled={!isButtonEnabled} label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold', borderStyle: "none" }}
                        onClick={handleSubmit}
                    ></Button>
                </div>
            </div>
        </div>
    )
}

export default ShiftView