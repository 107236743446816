import {
  Autocomplete,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import Loader from "../../components/loader/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { formatTimeTo12Hour, formatTimeTo12Hour1 } from '../../helpers/common';

const TabViewStaff = React.memo(
  ({
    selectedValue,
    selectedDate,
    selectedDate1,
    getByOrganizationData,
    getAllShiftData,
    noOfShift,
  }: any) => {
    const { orgData } = useSelector((state: any) => state.Org);
    const { shiftStartTime } = useSelector((state: any) => state.Org);
    const [shifts, setShifts] = useState<any>([]);
    const [Loading, setLoading] = useState<boolean>(false);
    const { header1 } = authorize();
    const [nextDate, setNextDate] = useState<any>('')
    const [slotTime, setSlotTime] = useState<any>([]);
    const [noOfSlot, setNoOfSlot] = useState<any>()
    const [initialState, setInitialState] = useState<any>([
      {
        shift_name: "",
        start_time: "",
        end_time: "",
        ohsStaff: [
          {
            all: null,
            deviceId: "",
            endRoomNo: "",
            location: null,
            roomRange: [],
            staff: "",
            startRoomNo: "",
            transferTime: "",
            transferStaffTime: "",
          },
        ],
      },
      {
        shift_name: "",
        start_time: "",
        end_time: "",
        ohsStaff: [
          {
            all: null,
            deviceId: "",
            endRoomNo: "",
            location: null,
            roomRange: [],
            staff: "",
            startRoomNo: "",
            transferTime: "",
            transferStaffTime: "",
          },
        ],
      },
    ]);
    let [incharge, setIncharge] = useState<any>("");
    const { rnInchargeList, StaffDropDownOneHour } = useSelector(
      (state: any) => state.PSConfig
    );
    const selectedDateObj = moment(selectedDate1);
    const select1 = moment(selectedDate);

    const isButtonEnabled = select1.isSameOrAfter(selectedDateObj, 'day');

    const [roomNumbers, setRoomNumbers] = useState(new Array<any>());
    const { organization } = useSelector((state: any) => state.Login);

    const renderTabContent = () => {
      switch (selectedValue) {
        case 0:
          return "Shift-A";
        case 1:
          return "Shift-B";
        case 2:
          return "Shift-C";
        case 3:
          return "Shift-D";
        case 4:
          return "Shift-E";
        case 5:
          return "Shift-F";
        case 6:
          return "General";
        case 7:
          return "Previous";
        default:
          return "No Content Available";
      }
    };
    const generateShifts = (
      startTimeStr: any,
      duration: any,
      totalShifts: any
    ) => {
      setLoading(true);
      const shifts = [];

      // Convert the start time string to a Date object
      let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

      // Function to convert 24-hour time to 12-hour time with AM/PM
      const to12HourFormat = (date: any) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "PM" : "AM";

        // Convert hour to 12-hour format
        hours = hours % 24;
        if (hours === 0) {
          hours = 0; // Midnight or noon case
        }

        // Add leading zero for minutes if needed
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours < 10 ? "0" + hours : hours;

        // Return formatted time with AM/PM
        return `${hours}:${minutes}`;
      };

      for (let i = 0; i < totalShifts; i++) {
        // Calculate the end time by adding the duration to the start time
        let endTime = new Date(
          startTime.getTime() + Number(duration) * 60 * 60 * 1000
        ); // duration in hours

        // Format start and end times into 12-hour format with AM/PM
        const startTimeFormatted = to12HourFormat(startTime);
        const endTimeFormatted = to12HourFormat(endTime);

        // Assign shift name (A, B, C, etc.)
        const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

        // Push shift details into the array
        shifts.push({
          shift_name: shiftName,
          start_time: startTimeFormatted,
          end_time: endTimeFormatted,
          dayShift: "",
        });

        // Update the start time for the next shift
        startTime = endTime;
      }
      const splitIntervals = splitShiftIntoIntervals(
        selectedValue === 6
          ? shifts[0]?.start_time
          : selectedValue === 7 ? shifts[noOfShift - 1]?.start_time : shifts[selectedValue]?.start_time,
        selectedValue === 6
          ? shifts[0]?.end_time
          : selectedValue === 7 ? shifts[noOfShift - 1]?.end_time : shifts[selectedValue]?.end_time
      );
      setShifts(shifts);
      setLoading(false);
      return splitIntervals;
    };
    const getNumberofSlot = (duration: number): number => {
      if (duration <= 0) {
        throw new Error("Duration must be a positive number.");
      }

      return duration / Number(orgData?.slotDuration); // Return the number of shifts
    };

    const getSlotTime = () => {
      if (!shifts) return
      const slotStartTimes = selectedValue === 6
        ? shifts[0]?.start_time
        : selectedValue === 7 ? shifts[noOfShift - 1]?.start_time : shifts[selectedValue]?.start_time
      let slot;
      let slotTime = [];
      let slotDate = [];

      let startTime = new Date(`1970-01-01T${slotStartTimes}:00`);

      for (let i = 0; i < noOfSlot; i++) {
        let endTime = new Date(
          startTime.getTime() + Number(orgData?.slotDuration) * 60 * 60 * 1000
        );

        const startTimeFormatted = startTime.toTimeString().slice(0, 5);
        const endTimeFormatted = endTime.toTimeString().slice(0, 5);
        const start = moment(`2025-01-08T${shiftStartTime}`)
        const date1 = moment(`2025-01-08T${startTimeFormatted}`)
        const date2 = moment(`2025-01-08T${endTimeFormatted}`)
        if (startTimeFormatted !== shiftStartTime) {
          if (date1.isAfter(start)) {
            if (date2.isBefore(date1)) {
              slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY') + ' - ' + nextDate}`)
            } else {
              slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY')}`)
            }
          } else {
            slotDate.push(`${nextDate}`)
          }
        } else {
          slotDate.push(`${moment(selectedDate).format('MMM DD, YYYY')}`)
        }

        slotTime.push(`${startTimeFormatted} - ${endTimeFormatted}`)
        startTime = endTime
      }
      setSlotTime(slotDate);
    }
    useEffect(() => {
      if (Array.isArray(getAllShiftData?.ohsSchedule)) {
        let splitIntervals = generateShifts(
          shiftStartTime,
          orgData?.shift?.duration,
          noOfShift
        );

        getAllShiftData?.ohsSchedule?.forEach(
          (schedule: any, index: number) => {
            if (index < splitIntervals.length) {
              // Replace the entire ohsStaff array with the new schedule's ohsStaff
              splitIntervals[index].ohsStaff = schedule?.ohsStaff.map((item: any) => {
                item.startRoomNo = item.startRoomNo.split(" ")[2] === "01"
                  ? `${item.startRoomNo?.split(" ")[0]} - A`
                  : item.startRoomNo?.split(" ")[2] === "02"
                    ? `${item.startRoomNo?.split(" ")[0]} - B`
                    : item.startRoomNo?.split(" ")[2] === "03"
                      ? `${item.startRoomNo?.split(" ")[0]} - C`
                      : item.startRoomNo;

                item.endRoomNo = item.endRoomNo.split(" ")[2] === "01"
                  ? `${item.endRoomNo?.split(" ")[0]} - A`
                  : item.endRoomNo.split(" ")[2] === "02"
                    ? `${item.endRoomNo?.split(" ")[0]} - B`
                    : item.endRoomNo?.split(" ")[2] === "03"
                      ? `${item.endRoomNo?.split(" ")[0]} - C`
                      : item.endRoomNo;
                return item;
              }) || [];
            }
          }
        );
        setInitialState(splitIntervals);
        setIncharge(getAllShiftData?.shiftIncharge)

      } else {
        let newData = generateShifts(
          shiftStartTime,
          orgData?.shift?.duration,
          noOfShift
        );
        setInitialState(newData);
        setIncharge('')

      }

      if (!selectedDate) return;
      const validDate = moment(selectedDate);
      if (validDate.isValid()) {
        const nextDay = validDate.clone().add(1, 'days');
        setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
      } else {
        console.error("Invalid date:", selectedDate);
      }
      let numSlot = getNumberofSlot(Number(orgData?.shift?.duration));
      setNoOfSlot(numSlot);

      getSlotTime();
    }, [selectedValue, selectedDate, getAllShiftData]);

    useEffect(() => {
      setLoading(true)
      fetch(`${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          const roomBedCombinations = data.data?.map((item: any) => {
            let bedNo = item.bedNo;
            if (bedNo === "01") {
              bedNo = "A";
            } else if (bedNo === "02") {
              bedNo = "B";
            } else if (bedNo === "03") {
              bedNo = "C";
            }
            return `${item.roomNo} - ${bedNo}`;
          });
          setRoomNumbers(roomBedCombinations);
        })
        .catch((error) => {
          console.error("Error fetching room numbers:", error);
          setLoading(false);
        })
    }, []);

    const handleAutocompleteChange = (
      event: React.ChangeEvent<{}>,
      value: any,
      index: number,
      roomIndex: number
    ) => {
      // Create a copy of the current state
      const updatedState = [...initialState];

      // Update the staff for the specific roomData in ohsStaff array
      updatedState[index].ohsStaff[roomIndex].staff = value?.staffId; // Assuming value has `id` as the unique identifier for staff

      // Set the new updated state
      setInitialState(updatedState);
    };

    const handleCheck = (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number,
      roomIndex: number
    ) => {
      // Get the current value of the checkbox (whether it's checked or not)
      const isChecked = e.target.checked;

      // Create a copy of the current state
      const updatedState = [...initialState];

      // Update the `roomData?.all` field based on the checkbox value
      updatedState[index].ohsStaff[roomIndex].all = isChecked;

      // Update the state to trigger re-rendering
      setInitialState(updatedState);
    };
    const handleSelectChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      index: number,
      roomIndex: number,
      field: "startRoomNo" | "endRoomNo"
    ) => {
      const updatedState = [...initialState]; // Create a copy of the current state

      // Get the value of the selected room from the select box
      const selectedValue = e.target.value;

      // Update the respective room number in the `ohsStaff` array
      if (field === "startRoomNo") {
        updatedState[index].ohsStaff[roomIndex].startRoomNo = selectedValue;
      } else if (field === "endRoomNo") {
        updatedState[index].ohsStaff[roomIndex].endRoomNo = selectedValue;
      }

      // Update the state with the new values
      setInitialState(updatedState);
    };

    const splitShiftIntoIntervals = (
      start_time: string,
      end_time: string,
      intervalHours = orgData?.slotDuration
    ) => {
      setLoading(true);
      const parseTime = (time: string, baseDate: Date) => {
        const [hours, minutes] = time?.split(":").map(Number);
        return new Date(
          baseDate.getFullYear(),
          baseDate.getMonth(),
          baseDate.getDate(),
          hours,
          minutes
        );
      };

      const formatTime = (date: Date) => {
        return `${date.getHours().toString().padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      };

      const formatDate = (date: Date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      };

      const baseDate = new Date(selectedDate); // Use the current date as the base
      const startDate = parseTime(start_time, baseDate);
      let endDate = parseTime(end_time, baseDate);

      // If end time is earlier than start time, consider end time as the next day
      if (startDate > endDate) {
        endDate.setDate(endDate.getDate() + 1); // Move to the next day
      }

      const initialState: {
        shift_name: string;
        start_time: string;
        end_time: string;

        ohsStaff: any[];
      }[] = [];

      let currentTime = startDate;
      while (currentTime < endDate) {
        const nextTime = new Date(
          currentTime.getTime() + intervalHours * 60 * 60 * 1000
        );

        // Ensure the interval end time does not exceed the end date
        const intervalEnd = nextTime > endDate ? endDate : nextTime;

        const shiftName = renderTabContent(); // Assuming renderTabContent() returns the correct shift name.

        const interval = {
          shift_name: shiftName,
          start_time: formatTime(currentTime),
          end_time: formatTime(intervalEnd),
          start_date: formatDate(currentTime),
          end_date: formatDate(intervalEnd),
          ohsStaff: [
            {
              all: null,
              deviceId: "",
              endRoomNo: "",
              location: null,
              roomRange: [],
              staff: "",
              startRoomNo: "",
            },
          ],
        };

        initialState.push(interval);

        // Move to the next interval
        currentTime = intervalEnd;
      }
      setLoading(false);
      return initialState;
    };

    const handleAddRoom = (shiftIndex: number, roomData: any) => {
      const updatedTabData = [...initialState];
      const newRoomData = {
        all: false,
        deviceId: "",
        endRoomNo: "",
        location: null,
        roomRange: [],
        staff: "",
        startRoomNo: "",
        transferTime: "",
        transferStaffTime: "",
      };

      updatedTabData[shiftIndex].ohsStaff.push(newRoomData);
      setInitialState(updatedTabData);
    };
    const handleRemoveRoom = (index: number, roomIndex: number) => {
      // Make a copy of the initial state
      const updatedState = [...initialState];

      // Remove the roomData from the ohsStaff array
      updatedState[index].ohsStaff = updatedState[index].ohsStaff.filter(
        (staff: any, idx: number) => idx !== roomIndex
      );

      // Update the state with the new data
      setInitialState(updatedState); // Make sure `setInitialState` is the state updater function
    };
    const handleSubmit = async () => {
      setLoading(true)
      if (!incharge) {
        setLoading(false)
        return toast.error('Incharge field required')
      }
      const forDate = moment(selectedDate).format("YYYYMMDD");
      let formattedData = initialState?.map((data: any) => ({
        time: `${data?.start_time}-${data?.end_time}`,
        ohsStaff: data?.ohsStaff.map((item: any) => {
          item.startRoomNo = item.startRoomNo.split(" ")[2] === "A"
            ? `${item.startRoomNo?.split(" ")[0]} - 01`
            : item.startRoomNo?.split(" ")[2] === "B"
              ? `${item.startRoomNo?.split(" ")[0]} - 02`
              : item.startRoomNo?.split(" ")[2] === "C"
                ? `${item.startRoomNo?.split(" ")[0]} - 03`
                : item.startRoomNo;

          item.endRoomNo = item.endRoomNo.split(" ")[2] === "A"
            ? `${item.endRoomNo?.split(" ")[0]} - 01`
            : item.endRoomNo.split(" ")[2] === "B"
              ? `${item.endRoomNo?.split(" ")[0]} - 02`
              : item.endRoomNo?.split(" ")[2] === "C"
                ? `${item.endRoomNo?.split(" ")[0]} - 03`
                : item.endRoomNo;
          return item;
        }),
      }));
      let formData = {
        date: forDate,
        ohsShift: [
          {
            endTime:
              selectedValue === 6
                ? shifts[0]?.end_time
                : selectedValue === 7 ? shifts[noOfShift - 1]?.end_time : shifts[selectedValue]?.end_time,
            ohsSchedule: formattedData,
            shiftIncharge: incharge,
            shiftName: renderTabContent(),
            startTime:
              selectedValue === 6
                ? shifts[0]?.start_time
                : selectedValue === 7 ? shifts[noOfShift - 1]?.start_time : shifts[selectedValue]?.start_time
          },
        ],
        organization: organization,
      };
      try {
        const response = await axios.post(
          `${baseURL}/ohsConfig/register`,
          formData,
          { headers: header1 }
        );
        if (response.data.message.code === successCode) {
          toast.success(response.data.message.description);
          getByOrganizationData();
          setLoading(false);
        } else {
          toast.error(response.data.message.description);
          setLoading(false);
        }
      } catch (error) {
        toast.error("An error occurred during register.");
        setLoading(false);
      }
    };
    return (
      <div
        className=""
        style={{
          height: '70vh',
          // overflow: "hidden"
        }}
      >
        {Loading && <Loader />}
        <div
          style={{
            height: '70vh',
          }}
        >
          <div
            style={{
              textAlign: "start",
              borderBottom: "1px groove #ccd2d8",
              height: "10vh",
              lineHeight: 3.5,
              padding: "3px 0px 0px 10px",
              width: '100%',
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: 600 }}>
              {moment(selectedDate).format("MMM DD, YYYY")} {" "}
              {renderTabContent() === "Previous" ? "" : renderTabContent()}{" "}
              Configuration
            </span>
          </div>
          <div id={`${renderTabContent()}-tab`} style={{ height: '53vh', width: '100%' }} className="overflow-y-scroll overflow-x-hidden">
            <div>
              <div className="row flex-row" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                <div className="col-sm-2">
                  <FormControl variant="filled" fullWidth>
                    <InputLabel htmlFor="floatingStartTime">Start Time</InputLabel>
                    <Input
                      id="floatingStartTime"
                      style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                      type="text"
                      value={
                        selectedValue === 6
                          ? formatTimeTo12Hour1(shifts[0]?.start_time)
                          : selectedValue === 7 ? formatTimeTo12Hour1(shifts[noOfShift - 1]?.start_time) : formatTimeTo12Hour1(shifts[selectedValue]?.start_time)
                      }
                      disabled
                      placeholder="Start Time"
                    />
                  </FormControl>
                </div>
                <div className="col-sm-2">
                  <FormControl variant="filled" fullWidth>
                    <InputLabel htmlFor="floatingEndTime">End Time</InputLabel>
                    <Input
                      id="floatingEndTime"
                      style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                      type="text"
                      value={
                        selectedValue === 6
                          ? formatTimeTo12Hour1(shifts[0]?.end_time)
                          : selectedValue === 7 ? formatTimeTo12Hour1(shifts[noOfShift - 1]?.end_time) : formatTimeTo12Hour1(shifts[selectedValue]?.end_time)
                      }
                      disabled
                      placeholder="End Time"
                    />
                  </FormControl>
                </div>

                <div className="col-sm-3 form-floating p-1">
                  <Autocomplete
                    style={{
                      height: "65px",
                      backgroundColor: "#f2f5f9",
                      paddingRight: "8px",
                    }}
                    fullWidth
                    id="floatingSelect"
                    onChange={(e, value) => { setIncharge(value?.id || ""); }}
                    value={
                      rnInchargeList.find((item: any) => item.id === incharge) ||
                      null
                    }
                    options={rnInchargeList}
                    getOptionLabel={(option) =>
                      option.name[0]?.given + " " + option.name[0]?.family
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select RN Incharge"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
              {initialState?.map((tabData: any, index: number) => {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "134px",
                        gap: "10px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "85%",
                      }}
                    >
                      <div style={{ display: "flex", width: "100%" }} className="">
                        <div
                          className=""
                          style={{
                            lineHeight: 2.7,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            width: "15%",
                          }}
                        >
                          Slot {index + 1} ---
                        </div>
                        <input
                          type="text"
                          className=""
                          style={{
                            fontSize: "15px",
                            borderStyle: "none",
                            width: "90%",
                          }}
                          value={`${tabData?.start_time ? formatTimeTo12Hour(tabData?.start_time) : "--:--"
                            } - ${tabData?.end_time ? formatTimeTo12Hour(tabData?.end_time) : "--:--"
                            }${'  '} ${slotTime[index] ? slotTime[index] : ""}`}

                          id="floatingEndTime"
                          disabled
                        />
                      </div>
                    </div>

                    {tabData?.ohsStaff?.map(
                      (roomData: any, roomIndex: number) => {
                        return (
                          <>
                          {
                            roomIndex !== 0 && (roomData.transferStaffTime || roomData.transferTime) &&
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: "134px",
                                gap: "10px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "85%",
                              }}
                            >
                              <div style={{ display: "flex", width: "100%" }} className="">
                                <div
                                  className=""
                                  style={{
                                    lineHeight: 2.7,
                                    fontWeight: 600,
                                    fontFamily: "Poppins",
                                    width: "15%",
                                  }}
                                >
                                  
                                </div>
                                <input
                                  type="text"
                                  className=""
                                  style={{
                                    fontSize: "15px",
                                    borderStyle: "none",
                                    width: "90%",
                                  }}
                                  value={
                                    tabData?.ohsStaff[roomIndex+1] 
                                    ? formatTimeTo12Hour(roomData.transferStaffTime.split("-")[0]?.trim()) + " - " + formatTimeTo12Hour(roomData.transferStaffTime.split("-")[1]?.trim()) 
                                    : formatTimeTo12Hour(roomData.transferTime.split("-")[0]?.trim()) + " - " + formatTimeTo12Hour(roomData.transferTime.split("-")[1]?.trim())
                                  }
                                  id="floatingEndTime"
                                  disabled
                                />
                              </div>
                            </div>
                          }
                          <div
                            className="mb-3 col-md-3 form-floating p-1"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <div className="mb-3 col-md-3">
                              <Autocomplete
                                fullWidth
                                options={StaffDropDownOneHour}
                                getOptionLabel={(option) =>
                                  option.staffName
                                }
                                onChange={(event, value) => {
                                  handleAutocompleteChange(
                                    event,
                                    value,
                                    index,
                                    roomIndex
                                  )
                                }
                                }
                                value={
                                  StaffDropDownOneHour?.find(
                                    (item: any) => item.staffId === roomData.staff
                                  ) || null
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Rounding Staff"
                                    variant="standard"
                                  />
                                )}
                              />
                            </div>
                            <div>
                              All Rooms
                              <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                checked={roomData?.all || false}
                                value={roomData?.all || false}
                                onChange={
                                  (e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleCheck(e, index, roomIndex) // Pass index and roomIndex for correct identification
                                }
                              />
                            </div>
                            <div className="form-floating mb-3 col-md-2 p-1">
                              <select
                                className="form-select"
                                id="floatingSelect"
                                disabled={roomData?.all}
                                style={{ fontSize: "12px", width: "100%" }}
                                onChange={
                                  (e: React.ChangeEvent<HTMLSelectElement>) =>
                                    handleSelectChange(
                                      e,
                                      index,
                                      roomIndex,
                                      "startRoomNo"
                                    ) // Update start room
                                }
                                value={roomData?.startRoomNo}
                              >
                                <option value="">-select-</option>
                                {roomNumbers?.map((item: any) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                              <label
                                style={{ fontSize: "12px" }}
                                htmlFor="floatingSelect"
                              >
                                Start Room
                              </label>
                            </div>
                            <div className="form-floating mb-3 col-md-2 p-1">
                              <select
                                className="form-select"
                                id="floatingSelect"
                                disabled={roomData?.all}
                                style={{ fontSize: "12px", width: "100%" }}
                                onChange={
                                  (e: React.ChangeEvent<HTMLSelectElement>) =>
                                    handleSelectChange(
                                      e,
                                      index,
                                      roomIndex,
                                      "endRoomNo"
                                    ) // Update start room
                                }
                                value={roomData?.endRoomNo}
                              >
                                <option value="">-select-</option>
                                {roomNumbers?.map((item: any) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                              <label
                                style={{ fontSize: "12px" }}
                                htmlFor="floatingSelect"
                              >
                                End Room
                              </label>
                            </div>
                            <div className="mb-2 col-md-2 p-0">
                              <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br />
                                {roomData?.deviceId ? roomData?.deviceId : 'N/A'}
                              </div>
                              <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br />
                                {roomData?.location ? roomData?.location : 'N/A'}
                              </div>
                            </div>
                            <div style={{ cursor: "pointer", display: "flex", gap: "5px" }}>
                              {/* Add icon always visible */}
                              <div onClick={() => handleAddRoom(index, roomData)}>
                                <i
                                  style={{
                                    backgroundColor: "#d7dee5",
                                    borderRadius: "4px",
                                  }}
                                  className="material-icons"
                                >
                                  add
                                </i>
                              </div>

                              {/* Remove icon visible only when more than 1 row */}
                              {tabData?.ohsStaff?.length > 1 && (
                                <div role="button" onClick={() => handleRemoveRoom(index, roomIndex)}>
                                  <i
                                    style={{
                                      backgroundColor: "#d7dee5",
                                      borderRadius: "4px",
                                    }}
                                    className="material-icons"
                                  >
                                    remove
                                  </i>
                                </div>
                              )}
                            </div>

                          </div>
                          </>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              paddingBottom: "10px",
              paddingTop: "10px",
              borderTop: "2px groove",
              paddingRight: "20px",
            }}
          >
            <Button
              label="Cancel"
              severity="secondary"
              style={{
                color: "#000",
                backgroundColor: "#94a0b7",
                fontSize: "12px",
                fontWeight: "bold",
                borderStyle: "none",
              }}
            ></Button>
            <Button
              label="Save Changes"
              onClick={() => handleSubmit()}
              disabled={!isButtonEnabled}
              style={{
                backgroundColor: "#0f3995",
                fontSize: "12px",
                fontWeight: "bold",
                borderStyle: "none",
              }}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
);

export default TabViewStaff;
