import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import Loader from "../../components/loader/Loader";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { fetchPatientData1 } from '../../slices/thunk';
interface AddOneOneProps {
  IsOpen: any;
  Close: any;
  errors: any;
  setErrors: any;
  selectedDate: any;
  selectedTab: any;
  patientDropdown: any;
  value: any
  setPreviousCheck: any
  renderShiftTab: any
  noOfShift: any
}
interface Shift {
  shift_name: string;
  start_time: string;
  end_time: string;
  dayShift: string;
}

const AddEyesight: React.FC<AddOneOneProps> = ({
  IsOpen, Close, errors, setErrors, selectedDate, selectedTab, patientDropdown, value, setPreviousCheck, renderShiftTab, noOfShift
}) => {
  const { organization } = useSelector((state: any) => state.Login);
  const { staffDropdown, staffDropdown1, staffDropDownEyeSight } = useSelector((state: any) => state.Staff);

  const initFormData = {
    date: "",
    endTime: "",
    id: "",
    oneToOneObserver: "",
    oneToOneObserverName: "",
    organization: "",
    patientId: "",
    patientName: "",
    patientIncharge: "",
    patientInchargeName: "",
    prescribedby: "",
    prescribedbyName: "",
    startTime: ""
  };

  const [Addpatient, setAddpatient] = useState<any>(initFormData);
  const dispatch = useDispatch<any>();
  const [Loading, setLoading] = useState<boolean>(false)

  // const ShiftTimeRange = (values: any) => {
  //   const isGeneralTab = selectedTab() === "General";
  //   const starTime = isGeneralTab
  //     ? '09:00 AM'
  //     : formatTimeTo12Hour(shift[value]?.start_time)
  //   const endTime = isGeneralTab
  //     ? '05:00 PM'
  //     : formatTimeTo12Hour(shift[value]?.end_time)
  //   const testFormat = moment(values, ["h:mm A"]).format("HH:mm")
  //   const shiftstartTime = moment(starTime, ["h:mm A"]).format("HH:mm")
  //   const shiftEndTime = moment(endTime, ["h:mm A"]).format("HH:mm")
  //   const shiftTimeStart = moment(`2025-01-08T${shiftstartTime}`)
  //   const shiftTimeEnd = moment(`2025-01-08T${shiftEndTime}`)
  //   const date1 = moment(`2025-01-08T${testFormat}`)
  //   if (date1.format("HH:mm") !== shiftTimeStart.format("HH:mm") && date1.format("HH:mm") !== shiftTimeEnd.format("HH:mm")) {
  //     if (date1.isAfter(shiftTimeStart) && shiftTimeEnd.isAfter(date1)) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   } else {
  //     return false
  //   }
  // }
  
    const ShiftTimeRange = (values: any) => {
      const isGeneralTab = selectedTab() === "General";
      const starTime = isGeneralTab
        ? '09:00 AM'
        : formatTimeTo12Hour(shift[value]?.start_time);
      const endTime = isGeneralTab
        ? '05:00 PM'
        : formatTimeTo12Hour(shift[value]?.end_time);
  
      const testFormat = moment(values, ["h:mm A"]);
  
      // Check if the moment object is valid
      if (!testFormat.isValid()) {
        console.log("Invalid time format:", values);
        return true;
      }
      // Format the shift start and end times to 24-hour format
      const shiftstartTime = moment(starTime, ["h:mm A"]).format("HH:mm");
      const shiftEndTime = moment(endTime, ["h:mm A"]).format("HH:mm");
      const shiftTimeStart = moment(`2025-01-08T${shiftstartTime}`);
      let shiftTimeEnd = moment(`2025-01-08T${shiftEndTime}`);
      let shiftTimeEnd1 = moment(`2025-01-08T${shiftEndTime}`);
      const zeroTime = moment("2025-01-08T00:00");
      const date1 = moment(`2025-01-08T${testFormat.format("HH:mm")}`);
      const isShiftCrossingMidnight = shiftTimeEnd.isBefore(shiftTimeStart);
      if (isShiftCrossingMidnight) {
        if (
          date1.isBefore(shiftTimeStart)
        ) {
          if (date1.isAfter(shiftTimeEnd)) {
            return true;
          } else {
            return false;
          }
        } else {
          console.log("Date is inside the shift time range.");
          return false;
        }
      } else {
        // Normal shift logic
        if (date1.format("HH:mm") !== shiftTimeStart.format("HH:mm") && date1.format("HH:mm") !== shiftTimeEnd.format("HH:mm")) {
          if (date1.isAfter(shiftTimeStart) && shiftTimeEnd.isAfter(date1)) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      }
    };

  const handleAutocompleteChange = (id: string, value: any, name: string) => {
    setAddpatient({
      ...Addpatient,
      [id]: value ? value.id : null,
      [name]: value ? `${value.name}` : ''
    });
    setErrors({ ...errors, [id]: !value });
  };

  const handleAutocompleteChange1 = (id: string, value: any, name: string) => {
    setAddpatient({
      ...Addpatient,
      [id]: value ? value.staffId : null,
      [name]: value ? `${value.staffName}` : ''
    });
    setErrors({ ...errors, [id]: !value });
  };


  const handleClose = () => {
    Close();
    setErrors({});
    setAddpatient(initFormData);
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      case 5:
        return "Previous";
      default:
        return "No Content Available";
    }
  };
  const startTime = moment(Addpatient.startTime, "hh:mm A").format("HH:mm");
  const endTime = moment(Addpatient.endTime, "hh:mm A").format("HH:mm");
  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    setLoading(true)
    try {
      let newErrors = {
        patientId: !Addpatient.patientId,
        startTime: !Addpatient.startTime,
        endTime: !Addpatient.endTime,
        prescribedby: !Addpatient.prescribedby,
        patientIncharge: !Addpatient.patientIncharge,
      };

      setErrors(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      if (hasErrors) {
        toast.error("Please Fill the Required Field")
        setLoading(false)
        return;
      }

      const checktime = ShiftTimeRange(Addpatient.startTime)
      const checktime1 = ShiftTimeRange(Addpatient.endTime)
      if (checktime) {
        setLoading(false)
        return toast.error("Start time Not in Shift Time Range")
      }
      if (checktime1) {
        setLoading(false)
        return toast.error("End time Not in Shift Time Range")
      }

      const { header1 } = authorize();
      const bodyData = {
        date: moment(selectedDate).format('YYYYMMDD'),
        id: "",
        organization,
        pid: Addpatient.patientId,
        shift: [
          {
            record: [
              {
                endTime: endTime,
                note: [
                  {
                    distance: "",
                    note: "",
                    proximity: "",
                    time: "",
                    updateTime: ""
                  }
                ],
                staffId: Addpatient.patientIncharge,
                startTime: startTime
              }
            ],
            shiftIncharge: Addpatient.prescribedby,
            shiftName: selectedTab() === "Previous" ? renderShiftTab() : selectedTab(),
            shiftTime: ""
          }
        ]


      };

      // bodyData.shift.forEach((shift: any) => shift.shiftName = selectedTab() === "Previous" ? "Shift-C" : selectedTab());
      const response = await axios.post(`${baseURL}/eyesight/register`, bodyData, { headers: header1 });
      if (response.data.message.code === successCode) {
        dispatch({ type: 'SET_FORM_VALUES', payload: response.data.data });
        setLoading(false)
        toast.success(response.data.message.description);
        handleClose();
        // fetchPatientData()
        const tabs = selectedTab() === "Previous" ? renderShiftTab() : selectedTab()
        fetchPatientData1(dispatch, organization, 0, '', moment(selectedDate).format('YYYYMMDD'), tabs, setPreviousCheck)
      } else {
        setLoading(false)
        toast.error(response.data.message.description);

      }
    } catch (error) {
      setLoading(false)
      toast.error("An error occurred during register.");
    }
  };
  const [shift, setShifts] = useState<any>([]);
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const { orgData } = useSelector((state: any) => state.Org);

  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    // Function to convert 24-hour time to 12-hour time with AM/PM
    const to12HourFormat = (date: any) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";

      // Convert hour to 12-hour format
      hours = hours % 24;
      if (hours === 0) {
        hours = '00'; // Midnight or noon case
      }

      // Add leading zero for minutes if needed
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // Return formatted time with AM/PM
      return `${hours}:${minutes} `;
    };

    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      ); // duration in hours

      // Format start and end times into 12-hour format with AM/PM
      const startTimeFormatted = to12HourFormat(startTime);
      const endTimeFormatted = to12HourFormat(endTime);

      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
        dayShift: "",
      });

      // Update the start time for the next shift
      startTime = endTime;
    }
    const convertTo24Hour = (time: string) => {
      const [hour, minuteWithPeriod] = time.split(":");
      const [minute, period] = minuteWithPeriod.split(" ");
      let hour24 = parseInt(hour);

      // Handle AM/PM conversion
      if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
      if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

      return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
    };

    const addDayShift = (shift: Shift, addShift: boolean): Shift => {
      const startInMinutes = convertTo24Hour(shift.start_time);
      const endInMinutes = convertTo24Hour(shift.end_time);
      if (endInMinutes < startInMinutes) {
        return { ...shift, dayShift: "+1" };
      }
      if (addShift) {
        return { ...shift, dayShift: "+1" };
      }
      return { ...shift, dayShift: "" };
    };
    let addShift = false;

    // Apply the dayShift logic to each shift
    const updatedShifts = shifts.map((shift: any) => {
      // If we encounter a shift that crosses midnight, set addShift to true
      if (
        !addShift &&
        convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
      ) {
        addShift = true;
      }
      return addDayShift(shift, addShift);
    });
    console.log(updatedShifts, 'updatedShifts')
    setShifts(updatedShifts);
  };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
  }, [noOfShift, shiftStartTime, orgData])
  const formatTimeTo12Hour = (time: string) => {
    return moment(time, 'HH:mm').format('hh:mm A');
  };
  return (
    <div>
      {Loading && <Loader />}
      <Modal isOpen={IsOpen} centered size="lg">
        <div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader toggle={handleClose}>
                <div className='d-flex ' style={{ width: '500px' }}>
                  <div className='col-md-6'>
                    Add EyeSight Routine
                  </div>
                  <div className='col-md-12'>
                    <div
                      className="d-flex align-items-center gap-2"
                      style={{ fontSize: "18px", marginTop: "0.5%" }}
                    >
                      <div>
                        {selectedTab() === "General"
                          ? 'Shift - General'
                          : selectedTab() === "Previous" ? 'Shift - Previous' : `Shift - ${shift[value]?.shift_name}`}{" "}
                        {" "}
                      </div>
                      <div>
                        {selectedTab() === "General"
                          ? '09:00 AM'
                          : selectedTab() === "Previous" ? formatTimeTo12Hour(shift[noOfShift - 1]?.start_time) : formatTimeTo12Hour(shift[value]?.start_time)}{" "}
                        {""} -
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        {selectedTab() === "General"
                          ? '05:00 PM'
                          : selectedTab() === "Previous" ? formatTimeTo12Hour(shift[noOfShift - 1]?.end_time) : formatTimeTo12Hour(shift[value]?.end_time)}{" "}
                        {""}
                        {/* {shifts[selectedTab()]?.dayShift} */}
                      </div>
                    </div>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "65vh",
                  overflowY: "auto",
                }}
              >
                <div className="row ">
                  <div className="col-12">
                    {/* <label>Select Patient Name</label> */}
                    <Autocomplete
                      id="patientId"
                      options={patientDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={
                        patientDropdown?.find(
                          (patient: any) => patient.id === Addpatient.patientId
                        ) || null
                      }
                      onChange={(event, value) => {
                        setAddpatient({ ...Addpatient, patientId: value ? value.id : null });
                        setErrors({ ...errors, patientId: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Patient Name"
                          variant="outlined"
                          label='Select Patient Name'
                          error={!!errors.patientId}
                          helperText={errors.patientId ? "Patient name is required" : ""}
                        />
                      )}
                    />
                  </div>
                  <div className='row pt-3'>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <div style={{ width: '100%' }}>
                          <TimePicker format="hh:mm A"
                            label={<span >Start Time</span>}
                            value={Addpatient.startTime ? dayjs(Addpatient.startTime, "hh:mm A") : null}
                            onChange={(newValue: any) => {
                              setAddpatient({ ...Addpatient, startTime: newValue ? newValue.format('hh:mm A') : null });
                              setErrors({ ...errors, startTime: false });
                            }}
                            ampm={true}
                            slotProps={{
                              textField: {
                                id: "startTime",
                                placeholder: "hh:mm A",
                                style: {
                                  height: "57px",
                                },
                                error: errors.startTime,
                              },
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <div style={{ width: '100%' }}>
                          <TimePicker format="hh:mm A"
                            label={<span>End Time</span>}
                            value={Addpatient.endTime ? dayjs(Addpatient.endTime, "hh:mm A") : null}
                            onChange={(newValue: any) => {
                              setAddpatient({ ...Addpatient, endTime: newValue ? newValue.format("hh:mm A") : null });
                              setErrors({ ...errors, endTime: false });
                            }}
                            ampm={true}
                            slotProps={{
                              textField: {
                                id: "startTime",
                                placeholder: "hh:mm A",
                                style: {
                                  height: "57px",
                                },
                                error: errors.endTime,
                              },
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-6'>
                      <Autocomplete
                        id="prescribedby"
                        options={staffDropdown1?.length > 0 ? staffDropdown1 : []}
                        getOptionLabel={(option) => `${option.name} - ${option.role}`}
                        value={staffDropdown1?.find(
                          (staff: any) => staff.id === Addpatient.prescribedby
                        ) || null}
                        onChange={(event, value) => {
                          // Handle the change, only update if value is not null
                          if (value) {
                            handleAutocompleteChange("prescribedby", value, "prescribedbyName");
                          } else {
                            // Handle case when no value is selected (optional logic)
                          }
                        }}
                        style={{ width: '107%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              staffDropdown1?.length > 0 ? "Select Prescribed By" : "No Options"
                            }
                            variant="outlined"
                            label="Prescribed By"
                            error={!!errors.prescribedby}
                            helperText={errors.prescribedby ? "Prescribed by is required" : ""}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4 ">
                    <Autocomplete
                      id="Technician"
                      options={staffDropDownEyeSight}
                      getOptionLabel={(option) => `${option.staffName} - ${option.jobTitle}`}
                      value={
                        staffDropDownEyeSight?.find(
                          (staff: any) => staff.staffId === Addpatient.patientIncharge
                        ) || null
                      }
                      onChange={(event, value) =>
                        handleAutocompleteChange1("patientIncharge", value, "patientInchargeName")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Technician"
                          variant="outlined"
                          placeholder="Select Staff Name"
                          InputLabelProps={{
                            shrink: !!params.inputProps.value,
                          }}
                          error={!!errors.patientIncharge}
                          helperText={
                            errors.patientIncharge ? "Technician is required" : ""
                          }
                        />
                      )}
                    />

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex gap-3">
                  <Button
                    label="Cancel"
                    severity="secondary"
                    style={{
                      color: "#000",
                      backgroundColor: "#94a0b7",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  ></Button>
                  <Button
                    label="Save Changes"
                    style={{
                      backgroundColor: "#0f3995",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleSaveClick}
                  ></Button>
                </div>
              </ModalFooter>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddEyesight;